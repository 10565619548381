import React, { useState, useCallback, useEffect, useRef } from 'react';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/component/graphic';
import 'echarts/lib/chart/radar';
import 'echarts/lib/chart/gauge';
import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/line';
import 'echarts/lib/chart/pie';
import 'echarts/lib/component/tooltip';

// import echartsTheme from '../../utils/echartsTheme.json';
import ShareReportModal from './shareReportModal.js';
import reportIcon from '../../images/Contacts_full@3x.png';
import shareIcon from '../../images/share-alt-solid.svg';

import getParameterByName from '../../utils/getParameterByName';
var Chinese = require('chinese-s2t');

import './report.css';
import ReportRichTextContent from './reportRichTextContent.js';

const ReportGraphParagraph = function (props) {
  const chart = props.chart;

  const [showShareModal, setShowShareModal] = useState(false);
  const [isTc] = useState(getParameterByName('lang') === 'tc');
  const echartRef = useRef(null);

  console.log(showShareModal);

  useEffect(() => {
    // delete global echart instance after unmount
    return () => {
      if (window.echartInstance && chart && chart.id) {
        delete window.echartInstance[chart.id];
      }
    };
  }, [chart]);

  if (!chart) {
    return null;
  }

  function getGaugeChartOption() {
    const resultValue = chart.data[0].value;

    let PIE_CHART_CURVE = 180.0 + 30.0 + 30.0;
    const thresholds = chart.thresholds || [];
    let gaugeThresholdMap = {};
    let min; //= thresholds[0].min;
    let max; //= thresholds[thresholds.length - 1].max

    let thresholdsData = thresholds.map(function (threshold) {
      let value;
      gaugeThresholdMap[threshold.max] = true;
      gaugeThresholdMap[threshold.min] = true;
      value = threshold.max - threshold.min;
      min = thresholds[0].min;
      max = thresholds[thresholds.length - 1].max;
      //add whitespace code snippet: .match(/.{1,5}/g).join('\n')
      if (getParameterByName('lang') === 'en') {
        //English do not line break
        return {
          value,
          name: threshold.description
        };
      }
      return {
        value: value,
        name: isTc
          ? Chinese.s2t(threshold.description.match(/.{1,4}/g).join('\n'))
          : threshold.description.match(/.{1,4}/g).join('\n')
      };
    });

    const dataRange = max - min;
    const splitNumber = dataRange <= 1 ? dataRange * 100 : dataRange; //for small data ranges, support decimal places
    let whiteSpaceRange =
      ((360.0 - PIE_CHART_CURVE) * dataRange) / PIE_CHART_CURVE;
    thresholdsData.push({
      value: whiteSpaceRange,
      name: '',
      itemStyle: {
        color: 'white'
        // color: '#f8f8f8'
        //color: "#99ddff"
      }
    });
    var option = {
      series: [
        {
          name: 'score',
          type: 'pie',
          radius: ['40%', '50%'],
          avoidLabelOverlap: false,
          label: {
            normal: {
              show: true,
              position: 'outside',
              padding: 0,
              rotate: false,
              color: '#414141',
              fontSize: 12
            },

            emphasis: {
              show: true
            }
          },
          center: ['50%', '37%'],

          labelLine: {
            normal: {
              show: true,
              length: 10,
              length2: 8,
              smooth: true
            }
          },
          data: thresholdsData,
          startAngle: 210,
          endAngle: -30
        },

        {
          name: '业务指标',
          type: 'gauge',
          startAngle: 210,
          endAngle: -30,
          center: ['50%', '37%'],
          min: min,
          max: max,
          splitNumber: splitNumber,
          detail: {
            show: false
            // formatter: function (value) {
            //   return "";
            // },
            // color: '#1e1e1e',
            // fontSize: 15
          },
          data: [{ value: resultValue, name: '一切良好' }],
          pointer: {
            length: '45%',
            width: 3
          },
          itemStyle: {
            color: '#000000'
          },
          radius: '40%',
          title: {
            show: false
          },
          splitLine: {
            // 分隔线
            length: 0, // 属性length控制线长
            lineStyle: {
              // 属性lineStyle（详见lineStyle）控制线条样式
              color: 'transparent',
              width: 0
            }
          },
          axisTick: {
            // 坐标轴小标记
            length: 0, // 属性length控制线长
            lineStyle: {
              // 属性lineStyle控制线条样式
              color: 'transparent',
              width: 0
            }
          },
          axisLine: {
            lineStyle: {
              // 属性lineStyle控制线条样式
              width: 0
            }
          },

          axisLabel: {
            show: true,

            formatter: function (value) {
              if (gaugeThresholdMap[value.toString()]) return value;
              return '';
            },

            color: '#aaa',
            fontSize: 10
          }
        }
      ]
    };
    console.log('OPTION!!', option);
    return option;
  }

  function getRadarChartOption() {
    let indicator = [];
    let value = [];
    const data = chart.data;
    data.map(item => {
      indicator.push({
        name: item.description.match(/.{1,6}/g).join('\n'),
        max: item.max || 20,
        min: item.min
      });
      value.push(item.value);
    });

    var option = {
      animation: false,
      radar: {
        // shape: 'circle',
        splitArea: {
          show: false
        },
        name: {
          textStyle: {
            color: '#414141',
            fontSize: 12,

            // padding: [-2, -2],
            padding: [-8, -8]
            // fontFamily:"SiYuanHeiTi",
            // lineHeight: 60,
          },
          fontSize: 12
        },
        nameGag: -20,
        radius: '75%',
        indicator: indicator
      },

      series: [
        {
          name: '预算 vs 开销（Budget vs spending）',
          type: 'radar',
          // areaStyle: {normal: {}},
          data: [
            {
              value: value,
              name: '预算分配（Allocated Budget）',
              areaStyle: {
                opacity: 0.8,
                color: new echarts.graphic.RadialGradient(0.5, 0.5, 1, [
                  {
                    color: '#edf4ff',
                    offset: 0
                  },
                  {
                    color: '#2979ff',
                    offset: 1
                  }
                ])
              },
              symbolSize: 10,
              lineStyle: {
                type: 'dashed'
              },
              label: {
                normal: {
                  show: false,
                  formatter: function (params) {
                    return params.value.toFixed && params.value.toFixed(2);
                  },
                  fontSize: 10,
                  position: 'insideTop'
                  // distance: 3,
                  // fontFamily: 'SiYuanHeiTi'
                  // color:"#000"
                }
              }
            }
          ]
        }
      ]
    };

    return option;
  }

  function getBarChartOption() {
    const range = chart.range;
    const rangeMin = range && range.min;
    const rangeMax = range && range.max;
    let barData = [];
    let avgData = [];

    let categoryData = [];
    const data = chart.data;
    data.forEach((item, index) => {
      let label = item.description.split(/[,，、]/).join(',\n');
      if (index % 2 !== 0 && data.length > 5) {
        label = '\n' + label;
      }
      categoryData.push(label);
      barData.push(item.value);
      avgData.push(item.average);
    });

    var option = {
      animation: false,
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      xAxis: {
        data: categoryData,

        axisTick: {
          show: true,
          alignWithLabel: true
        },

        axisLabel: {
          lineHeight: 12,
          interval: 0,
          // rotate:-0.1,
          fontSize: 9,
          align: 'center'
        }
      },
      yAxis: {
        min: rangeMin,
        max: rangeMax
      },
      series: [
        {
          type: 'bar',
          name: '分数',
          symbolSize: 10,
          barGap: 0,
          data: barData,
          barMaxWidth: 20,
          itemStyle: {
            normal: {
              // color: '#77bef7'
            }
          },
          label: {
            show: true,
            position: 'top'
          },
          markLine: {
            itemStyle: {
              normal: {
                lineStyle: { type: 'solid' },
                label: { show: false, position: 'left' }
              }
            },
            symbol: 'none',
            data: categoryData.map(function (label) {
              return {
                xAxis: label,
                lineStyle: { color: '#ccc', width: 1 }
              };
            })
          }
        },

        {
          type: 'line',
          name: '均值',
          data: avgData,
          symbolSize: 5,
          symbol: 'diamond',
          lineStyle: {
            normal: {
              width: 0,
              type: 'dotted',
              color: '#304553',
              borderType: 'dotted'
            }
          },
          itemStyle: {
            normal: {
              // borderWidth: 10,
              // borderColor: 'yellow',
              color: '#304553',
              size: 10
            }
          }
        }
      ]
    };

    console.log('BAR CHAR OPTION', option);
    return option;
  }

  function getChartOption() {
    if (chart.type === 'gauge') {
      return getGaugeChartOption();
    }
    if (chart.type === 'radar-chart') {
      return getRadarChartOption();
    }
    if (chart.type === 'bar-chart') {
      return getBarChartOption();
    }
    // const option = {
    //   title: {
    //     text: '世界人口总量',
    //     subtext: '数据来自网络'
    //   },
    //   tooltip: {
    //     trigger: 'axis',
    //     axisPointer: {
    //       type: 'shadow'
    //     }
    //   },
    //   legend: {
    //     data: ['2011年', '2012年'],
    //     right: 'right'
    //   },
    //   grid: {
    //     left: '3%',
    //     right: '4%',
    //     bottom: '3%',
    //     containLabel: true
    //   },
    //   xAxis: {
    //     type: 'value',
    //     boundaryGap: [0, 0.01]
    //   },
    //   yAxis: {
    //     type: 'category',
    //     data: ['巴西', '印尼', '美国', '印度', '中国', '世界人口(万)']
    //   },
    //   series: [
    //     {
    //       name: '2011年',
    //       type: 'bar',
    //       data: [18203, 23489, 29034, 104970, 131744, 630230]
    //     },
    //     {
    //       name: '2012年',
    //       type: 'bar',
    //       data: [19325, 23438, 31000, 121594, 134141, 681807]
    //     }
    //   ]
    // };
    // return option;
  }

  function getChartStyle() {
    if (chart.type === 'gauge') {
      return { height: 330, width: "80vw" };
    }
    if (chart.type === 'radar-chart') {
      return { height: 220, width: "80vw" };
    }
    if (chart.type === 'bar-chart') {
      return { height: 220, width: "80vw" };
    }
  }

  function renderLongFactorDetail(factor) {
    return (
      <div
        style={{
          // marginBottom: 20,
          // marginTop: 20,
          paddingTop: 20,
          marginLeft: -20,
          marginRight: -20,
          backgroundColor: '#f9f9f9'
        }}
      >
        <div
          style={{
            padding: 20,
            backgroundColor: 'white',
            // marginTop: 20,
            // marginBottom: 20,
            borderRadius: 5
          }}
        >
          <div
            style={{ display: 'flex', flexDirection: 'row', marginBottom: 5 }}
          >
            <div
              style={{ fontWeight: 'bold', marginRight: 10 }}
              dangerouslySetInnerHTML={{ __html: factor.description }}
            ></div>
            <div
              style={{ fontWeight: 'bold', marginRight: 20, color: '#2979ff' }}
            >
              {factor.value.toFixed(0)}
            </div>
            <div
              style={{ color: '#a0a0a0' }}
              dangerouslySetInnerHTML={{ __html: factor.resultName }}
            ></div>
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: factor.resultDescription }}
          ></div>
        </div>
      </div>
    );
  }

  function renderFactorDetail(factor) {
    if (factor.style === 'LONG_RESULT_DESC') {
      return renderLongFactorDetail(factor);
    }
    return (
      <div className="factor-detail-block">
        <div className="factor-detail">
          <div
            className="factor-description"
            dangerouslySetInnerHTML={{
              __html: isTc
                ? Chinese.s2t(factor.description)
                : factor.description
            }}
          ></div>
          <span
            className="factor-result-name"
            dangerouslySetInnerHTML={{
              __html: isTc ? Chinese.s2t(factor.resultName) : factor.resultName
            }}
          ></span>
          <span
            className="factor-result-description"
            dangerouslySetInnerHTML={{
              __html: isTc
                ? Chinese.s2t(factor.resultDescription)
                : factor.resultDescription
            }}
          ></span>
        </div>

        <div className="factor-result">
          <span className="factor-result-value">{factor.value.toFixed(0)}</span>
          <span className="factor-result-suffix">分</span>
        </div>
      </div>
    );
  }

  function renderFactorDetails() {
    if (chart.hideFactorScoresInReport) {
      return null;
    }
    const factorDetailList = chart.data;
    return (
      <div>
        {factorDetailList.map(d => {
          return renderFactorDetail(d);
        })}
      </div>
    );
  }

  function renderChartResults() {
    if (chart.results) {
        const contents = chart.results.filter(chartResult => chartResult).map(chartResult => chartResult.description).join("");
        return <ReportRichTextContent content={contents} />;
    }
    return null;
  }

  // function renderShareModal() {
  //   if (!showShareModal || props.hideShare) {
  //     return null;
  //   }

  //   return (
  //     <div
  //       className="modal-background"
  //       onClick={event => {
  //         if (
  //           event.target.className &&
  //           event.target.className.match(/modal-background/)
  //         ) {
  //           setShowShareModal(false);
  //         }
  //       }}
  //       style={{
  //         position: 'fixed',
  //         height: '100vh',
  //         width: '100vw',
  //         zIndex: 10000,
  //         top: 0,
  //         left: 0,
  //         backgroundColor: 'rgba(0,0,0,0.5)',
  //         display: 'flex',
  //         justifyContent: 'center',
  //         alignItems: 'center',
  //         flexDirection: 'column'
  //       }}
  //     >
  //       <ShareReportModal chart={chart}></ShareReportModal>

  //       <div
  //         style={{ marginTop: 30, color: 'white' }}
  //         className="element-animation"
  //       >
  //         {isTc ? '⬆️ 長按圖片保存或分享' : '⬆️ 长按图片保存或分享'}
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <div>
      <div className="report-section">
        <div
          style={{
            height: 220,
            overflowX: "hidden",
            display: "inline-flex",
            overflowY:
              'hidden' /* 一定要加overflowY:hidden, 不然在一些古董机器上要出bug */
          }}
        >
          {props.description ? <div style={{ alignSelf: "center", writingMode: "vertical-rl", letterSpacing: "0.8em", fontWeight: "bold" }}>{props.description}</div> : null}
          <ReactEchartsCore
            echarts={echarts}
            option={getChartOption()}
            theme="light"
            style={getChartStyle()}
            ref={echartRef}
            // opts={{ renderer: 'svg' }}
            onChartReady={() => {
              setTimeout(() => {
                if (!window.echartInstance) {
                  window.echartInstance = {};
                }
                window.echartInstance[chart.id] = echartRef.current;
              }, 1);
            }}
          />
        </div>
        <div>{renderFactorDetails()}</div>
        <div>{renderChartResults()}</div>
      </div>
      {/* {!props.hideShare ? (
        <div
          className="report-section"
          style={{ display: 'flex', justifyContent: 'space-between' }}
          onClick={() => {
            setShowShareModal(true);
          }}
        >
          <div style={{ display: 'flex' }}>
            <img
              className="share-chart-icon"
              src={reportIcon}
              height="30"
              width="35"
            />
            <div className="share-chart-text-container">
              <div className="share-chart-section-title">
                {getParameterByName('lang') === 'tc'
                  ? '分享測試分數'
                  : '分享测试分数'}
              </div>
              <div className="share-chart-section-subtitle">
                MENTAL HEALTH REPORT
              </div>
            </div>
          </div>
          <img className="share-icon" src={shareIcon} height="25" />
        </div>
      ) : null}
      {renderShareModal()} */}
    </div>
  );
};
ReportGraphParagraph.defaultProps = {
  chart: null
};
export default ReportGraphParagraph;
