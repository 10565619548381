import _ from 'lodash';
var MobileDetect = require('mobile-detect');
export default function getDeviceVersion(type) {
  let userAgentInfo = new MobileDetect(navigator.userAgent);
  let os = userAgentInfo.os();
  switch (type) {
    case 'phoneBrand':
      let model = '';
      if (os == "iOS") {
        model = userAgentInfo.mobile();
      } else if (os == "AndroidOS") {
        let brandInfos = navigator.userAgent.split(";");
        let brandInfo = brandInfos.filter((item, index) => {
          return index == 2;
        });
        if (brandInfo.length > 0) {
          model = brandInfo[0].substring(0, brandInfo[0].indexOf("Build/"));
          model = model.trim();
        }
      }
      return model;
    // return userAgentInfo.mobile();
    case 'osVersion':
      if (os == "iOS") {
        os = userAgentInfo.os() + userAgentInfo.version("iPhone");
      } else if (os == "AndroidOS") {
        os = userAgentInfo.os() + userAgentInfo.version("Android");
      }
      return os;
    case 'wechatVersion':
      return _.get(navigator.userAgent.match(/MicroMessenger\/([\d\.]+)/i), '1', '');
    case 'browser':
      return userAgentInfo.userAgent();
    case 'userAgentString':
      return navigator.userAgent;
    default:
      break;
  }
  return '';
}