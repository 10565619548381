import React, { useState, useCallback, useEffect } from 'react';
import { Page } from 'framework7-react';
import ReportTitle from './reportTitle';
import ReportRichTextParagraph from './reportRichTextParagraph';
import ReportGraphParagraph from './reportGraphParagraph';
import ShareTestParagraph from './shareTestParagraph';
import SaveReportParagraph from './saveReportParagraph';
import ShareReportParagraph from './shareReportParagraph';
import { getReportData } from '../../modules/shufu.js';
import getParameterByName from '../../utils/getParameterByName';

const Report = function (props) {
  const [report, setReport] = useState(null);
  const [hideShare] = useState(getParameterByName('hideShare') === 'true');
  const [hideContact] = useState(getParameterByName('hideContact') === 'true');

  useEffect(() => {
    async function getReport() {
      window.$$f7.preloader.show();

      try {
        const reportId = props.reportId;
        const _report = await getReportData(reportId);
        setReport(_report);
      } catch (e) {
        window.$$f7.dialog.alert(`${e}`);
      }

      window.$$f7.preloader.hide();
    }

    getReport();
  }, [props.reportId]);

  function renderCharts(section) {
    const charts = section && section.charts;
    if (!charts) {
      return null;
    }

    return charts.map(chart => {
      return <ReportGraphParagraph chart={chart} hideShare={hideShare} description={section.description} />;
    });
  }

  function renderSectionResults(section) {
    const sectionResults = section && section.contents;
    if (!sectionResults) {
      return null;
    }

    if (section.mergeIntoOneSection) {
      const contents = sectionResults.filter(sectionResult => sectionResult).map(sectionResult => sectionResult.description).join("");
      return <ReportRichTextParagraph content={contents} />;
    }

    return sectionResults.map(sectionResult => {
      return <ReportRichTextParagraph content={sectionResult.description} />;
    });
  }

  function renderPatientEducation(section) {
    const patientEducation = section && section.contents;
    if (!patientEducation) {
      return null;
    }

    if (section.mergeIntoOneSection) {
      const contents = patientEducation.filter(item => item).map(item => item.description).join("");
      return <ReportRichTextParagraph content={contents} />;
    }

    return patientEducation.map(item => {
      return <ReportRichTextParagraph content={item.description} />;
    });
  }

  function renderFormAnswerResults(section) {
    const formAnswerResults = section && section.contents;
    const description = section.description || '';
    if (!formAnswerResults) {
      return null;
    }

    return (
      <ReportRichTextParagraph
        content={`<div>${description}</div><div>${formAnswerResults
          .map(r => `<p>${r.description}</p>`)
          .join('')}</div>`}
      ></ReportRichTextParagraph>
    );
  }

  function renderRecommendations(section) {
    const recommendations = section && section.contents;
    if (!recommendations) {
      return null;
    }
    if (section.mergeIntoOneSection) {
      const contents = recommendations.filter(recommendation => recommendation).map(recommendation => recommendation.description).join("");
      return <ReportRichTextParagraph content={contents} />;
    }
    return recommendations.map(recommendation => {
      return (
        recommendation && (
          <ReportRichTextParagraph content={recommendation.description} />
        )
      );
    });
  }
  function renderCdsStrings(section) {
    const cdsStrings = section && section.contents;
    if (!cdsStrings) {
      return null;
    }
    return cdsStrings.map(cdsString => {
      return (
        <ReportRichTextParagraph
          content={cdsString.description.trim().replace(/\n/g, '<br/>')}
          title={section.description}
        />
      );
    });
  }

  function renderSections() {
    const sections = report && report.sections;
    if (!sections || !sections.map) {
      return null;
    }

    return sections.map(section => {
      // if (!section.contents || !section.contents.length) {
      //   return null;
      // }
      if (section.id === 'charts') {
        return renderCharts(section);
      }
      if (section.id === 'sectionResults') {
        return renderSectionResults(section);
      }
      if (section.id === 'patientEducation') {
        return renderPatientEducation(section);
      }
      if (section.id === 'formAnswerResults') {
        // return renderFormAnswerResults(section);
        return renderFormAnswerResults(section);
      }
      if (section.id === 'recommendations') {
        return renderRecommendations(section);
      }
      if (section.id === 'cdsString') {
        return renderCdsStrings(section);
      }

      if (section.id === 'shareReportQr') {
        return [renderShareReport(section), renderBannerBottom()];
      }
    });

    // {}
    // {}
    // {renderRecommendations()}
  }

  function renderContactUsParagraph() {

    return (
      <div className="report-section">
        {getParameterByName('lang') === 'tc'
          ? '聯繫我們: '
          : getParameterByName('lang') === 'en'
            ? 'Contact Us: '
            : '联系我们: '}
        <a href="mailto:marketing@siuvo.cn">marketing@siuvo.cn</a>{' '}
      </div>
    );
  }

  function renderShareTest() {
    return <ShareTestParagraph report={report} />;
  }

  function renderShareReport(section) {
    return <ShareReportParagraph report={report} section={section} />;
  }

  function renderSaveReport() {
    return <SaveReportParagraph report={report} />;
  }

  function renderBannerTop() {
    if (!report) {
      return null;
    }
    const topBannerImg =
      report.shareConfig &&
      report.shareConfig.banner_top &&
      report.shareConfig.banner_top.imageUrl;
    const topBannerUrl =
      report.shareConfig &&
      report.shareConfig.banner_top &&
      report.shareConfig.banner_top.linkUrl;

    if (topBannerImg && topBannerUrl) {
      return (
        <div
          style={{ width: '100%' }}
          onClick={() => {
            __bl &&
              __bl.api &&
              __bl.api(
                'bannerClick_top',
                true,
                null,
                null,
                `LINK[${topBannerUrl}]IMAGE[${topBannerImg}]`
              );
            window.location.href = topBannerUrl;
          }}
        >
          <img src={topBannerImg} style={{ width: '100%' }} />
        </div>
      );
    }
    return null;
  }

  function renderBannerBottom() {
    if (!report) {
      return null;
    }
    const bottomBannerImg =
      report.shareConfig &&
      report.shareConfig.banner_bottom &&
      report.shareConfig.banner_bottom.imageUrl;
    const bottomBannerUrl =
      report.shareConfig &&
      report.shareConfig.banner_bottom &&
      report.shareConfig.banner_bottom.linkUrl;

    if (bottomBannerImg && bottomBannerUrl) {
      return (
        <div
          style={{ width: '100%' }}
          onClick={() => {
            __bl &&
              __bl.api &&
              __bl.api(
                'bannerClick_bottom',
                true,
                null,
                null,
                `LINK[${bottomBannerUrl}]IMAGE[${bottomBannerImg}]`
              );
            window.location.href = bottomBannerUrl;
          }}
        >
          <img
            src={bottomBannerImg}
            style={{ width: '100%', display: 'block' }}
          />
        </div>
      );
    }
    return null;
  }

  function renderFooter() {
    if (!report) {
      return null;
    }
    const footerImg =
      report.shareConfig &&
      report.shareConfig.footer &&
      report.shareConfig.footer.imageUrl;
    const footerUrl =
      report.shareConfig &&
      report.shareConfig.footer &&
      report.shareConfig.footer.linkUrl;

    if (footerImg) {
      return (
        <div
          style={{ width: '100%' }}
          onClick={() => {
            if (!footerUrl) return;
            __bl &&
              __bl.api &&
              __bl.api(
                'bannerClick_top',
                true,
                null,
                null,
                `LINK[${footerUrl}]IMAGE[${footerImg}]`
              );
            window.location.href = footerUrl;
          }}
        >
          <img src={footerImg} style={{ width: '100%' }} />
        </div>
      );
    }
    return <div style={{ marginBottom: 70 }}></div>;
  }

  return (
    <Page className="reportPage" noToolbar>
      {/* <Navbar>
        <NavLeft>
          <Link back>
            <Icon icon="icon-back"></Icon> <span> 返回</span>
          </Link>
        </NavLeft>
        <NavTitle>{report && report.title}</NavTitle>
      </Navbar> */}

      {renderBannerTop()}

      <div id="report_export_content">
        <ReportTitle report={report} />
        {renderSections()}
      </div>
      {/* {!hideShare ? renderShareReport() : null} */}

      {/* <ReportRichTextParagraph /> */}

      {!hideShare ? renderShareTest() : null}
      {/* {renderSaveReport()} */}
      {!hideContact ? renderContactUsParagraph() : null}
      {renderFooter()}
      {/* {renderBannerBottom()} */}
    </Page>
  );
};
Report.defaultProps = {
  reportId: 'NO_REPORT_ID',
  onLoginSuccess: () => {
    console.warn('onLoginSuccess is not defined');
  }
};
export default Report;
