// 患者输入身份证号后进入到患者病历列表
import React, { useState, useCallback } from 'react';
import {
  Page,
  Navbar,
  NavLeft,
  Link,
  Icon,
  Block,
  NavTitle,
  List,
  ListItem,
  BlockFooter,
  Button,
  Preloader
} from 'framework7-react';
import 'framework7-icons';
import _ from 'lodash';
import { startServiceBySceneId, getRegisterWithRicn } from '../../modules/shufu.js';
import getParameterByName from '../../utils/getParameterByName';
import {
  setToken,
  setCurrentUserId,
  getCurrentUserId,
  setAnnouncement,
  setPatientInfo,
  removeAnnouncement,
  setShowHistoryReportButton
} from '../../utils/localStorage.js';
import { initShuaiCloud as initShuaiCloudFromClientInstance } from '../../modules/shuaicloud/clientInstance';

export default function MedicalRecordList(props) {
  const [loading, setLoading] = useState(false);
  const medicalRocordList = props.medicalRocordList;
  const identityId = props.identityId;
  const sceneId = getParameterByName('sceneId');
  let chatUrl = '/chat/';

  function renderMedicalRocordList() {

    return (
      medicalRocordList && medicalRocordList.length > 0
        ? (
          <>
            <List mediaList>
              {
                medicalRocordList.map((item, index) => {
                  return (
                    <ListItem
                      key={index}
                      title={`姓名:${item.name ? item.name : '暂无'}`}
                      subtitle={`挂号序号:${item.encounterIdentifier ? item.encounterIdentifier : '暂无'}`}
                      after={`医生:${item.doctor ? item.doctor : '暂无'}`}
                      text={`科室:${item.department ? item.department : '暂无'}`}
                      link="#"
                      onClick={() => {
                        setLoading(true);
                        handleStartServiceCheckEncounterAndRestart(item);
                      }}
                    >
                    </ListItem>
                  )
                })
              }
            </List>
          </>
        )
        : (
          <>
            <List mediaList>
              <ListItem>暂无数据</ListItem>
            </List>
          </>
        )
    )
  }

  // const _startServiceCheckEncounterAndRestart = useCallback(async (patientInfoMap) => {
  //   let params = {
  //     sceneId,
  //     patientInfoMap,
  //     customerId: getCurrentUserId(),
  //     trackingId: window.__tid || null
  //   }
  //   let newServiceInstance = await startServiceBySceneId(params);
  //   const uiConfig = _.get(newServiceInstance, ['serviceInstance', 'uiConfig']) || '{}';
  //   const announcement = _.get(JSON.parse(uiConfig), 'announcement', "");
  //   const showHistoryReportButton = _.get(JSON.parse(uiConfig), 'showHistoryReportButton', "");
  //   setShowHistoryReportButton(showHistoryReportButton);
  //   if (announcement) {
  //     setAnnouncement(announcement);
  //   } else {
  //     removeAnnouncement();
  //   }
  // }, [sceneId]);

  const _getRegisterWithRicn = useCallback(async () => {
    let params = {
      ricn: identityId,
    }
    let res = await getRegisterWithRicn(params);
    // 拿到token，连接socket
    const token = _.get(res, ['token']);
    const userid = _.get(res, ['userid']);
    setCurrentUserId(userid);
    setToken(token);
    if (token) {
      await initShuaiCloudFromClientInstance();
    }
  }, [identityId]);

  const handleStartServiceCheckEncounterAndRestart = useCallback(async (patientInfoMap) => {
    await _getRegisterWithRicn();
    // await _startServiceCheckEncounterAndRestart(patientInfoMap);
    setPatientInfo(patientInfoMap);
    setLoading(false);
    props.f7router.navigate(`/chat/?sceneId=${sceneId}`, {
      reloadCurrent: true,
      // props: {
      //   noRestart: true,
      // },
    });
  }, [sceneId, props.f7router, _getRegisterWithRicn,]);

  return (
    <Page
      noToolbar
      noSwipeback
    >
      <Navbar>
        <NavLeft backLink='返回' backLinkUrl='/loginWithIdentityId' sliding>
        </NavLeft>
        <NavTitle>病历列表</NavTitle>
      </Navbar>
      {
        renderMedicalRocordList()
      }
      {/* <Block>
        <BlockFooter>
          xxxxxxxxxxxxx
        </BlockFooter>
      </Block> */}
      {
        loading
          ? <div
            style={{
              width: '100%',
              display: 'flex',
              margin: 20,
              justifyContent: 'center'
            }}
          >
            <Preloader size={30} />
          </div>
          : null
      }
    </Page>
  )
}