// 山东患者病历列表
import React, { useState, useCallback } from 'react';
import {
  Page,
  Navbar,
  NavLeft,
  Link,
  Icon,
  Block,
  NavTitle,
  List,
  ListItem,
  BlockFooter,
  Button,
  Preloader
} from 'framework7-react';
import 'framework7-icons';
import _ from 'lodash';
import { registerWithHospitalUser } from '../../modules/shufu.js';
import getParameterByName from '../../utils/getParameterByName.js';
import {
  setToken,
  setCurrentUserId,
  getCurrentUserId,
  setAnnouncement,
  setPatientInfo,
  removeAnnouncement,
  setShowHistoryReportButton
} from '../../utils/localStorage.js';
import { initShuaiCloud as initShuaiCloudFromClientInstance } from '../../modules/shuaicloud/clientInstance.js';
import './loginWithCertificate_shandong.css';
import moment from 'moment';

export default function MedicalRecordListXuyafang(props) {
  const { qrList, idCardType, organizationId } = props;
  const [loading, setLoading] = useState(false);

  // 手机号脱敏
  function hidePhoneInfo(phone) {
    if(!phone) {
      return '--'
    }
    const phoneReg = /(\d{3})\d{4}(\d{4})/;
    return phone.replace(phoneReg, '$1****$2');
  }

  function renderList() {
    return (
      <div>
        {
          qrList.map((item, index) => {
            const { patientProfile, sceneData, encounterIdentifierParams, enabled } = item;
            if (!enabled) {
              return null
            }
            return (
              // <ListItem
              //   key={index}
              //   title={`测试项目名:${sceneData.sn ? sceneData.sn : '暂无'}`}
              //   subtitle={`姓名:${patientProfile.name ? patientProfile.nickname : '暂无'}`}
              //   link="#"
              //   onClick={() => {
              //     setLoading(true);
              //     handleStartServiceCheckEncounterAndRestart(item);
              //   }}
              // >
              // </ListItem>
              <div key={index} 
                className='list-item-content' 
                onClick={() => {
                  setLoading(true);
                  handleStartServiceCheckEncounterAndRestart(item);
                }}
              >
                <div className='test-name'>{`${sceneData.sn ? sceneData.sn : '暂无'}`}</div>
                <div className='item-base-info-content'>
                  <div className='item-user-name'>{`姓名：${patientProfile.name ? patientProfile.nickname : '暂无'}`}</div>
                  <div>{`手机号：${encounterIdentifierParams && hidePhoneInfo(encounterIdentifierParams.phone)}`}</div>
                </div>
                <div className='item-start-content'>
                  <div>{`开单时间：${moment(item.createdTime).format('YYYY-MM-DD HH:mm')}`}</div>
                  <Button className='item-start-btn'>开始测试</Button>
                </div>
              </div>
            )
          })
        }
      </div>
    )
  }
  function renderMedicalRocordList() {
    return (
      qrList && qrList.length > 0
        ? renderList() : (
          <>
            <List mediaList>
              <ListItem>暂无数据</ListItem>
            </List>
          </>
        )
    )
  }

  const handleStartServiceCheckEncounterAndRestart = useCallback(async (wechatQrInfo) => {
    const { sceneId, patientProfile } = wechatQrInfo;
    props.f7router.navigate(`/chat-for-his/`, {
      reloadCurrent: true,
      props: {
        noRestart: false,
        sceneId: sceneId,
        userId: patientProfile.id,
      }
    });
  }, [props.f7router]);

  return (
    <Page
      noToolbar
      noSwipeback
    >
      <Navbar>
        <NavLeft backLink='返回' backLinkUrl='/loginWithCertificateShandong' sliding>
        </NavLeft>
        <NavTitle>心理测评量表列表</NavTitle>
      </Navbar>
      {
        renderMedicalRocordList()
      }
      {/* <Block>
        <BlockFooter>
          xxxxxxxxxxxxx
        </BlockFooter>
      </Block> */}
      {
        loading
          ? <div
            style={{
              width: '100%',
              display: 'flex',
              margin: 20,
              justifyContent: 'center'
            }}
          >
            <Preloader size={30} />
          </div>
          : null
      }
    </Page>
  )
}