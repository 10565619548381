import React, { useState, useEffect, useCallback } from 'react';
import { Message } from 'framework7-react';
import './MessageBlock.css';
import {
  getJsonFormById,
  replyToForm,
  getAllComplaintsByTag
} from '../modules/shufu.js';
import _ from 'lodash';
import { answerBoardEmitter } from '../modules/answerBoardEmitter.js';
import robot from '../images/robot.png';
import { useTranslation } from 'react-i18next'
import { getTitle, getAnswerDisplay } from './SymptomDetailPopup.js'
import { submitPatientEvent } from './SubmitPatientEvent.js'

MessageBlockCategorySearch.defaultProps = {
  messageJson: {},
  isLastMessage: false,
  messageId: Date.now(),
  message: {}
};

function parseLanguage(text, language) {
  const str = text ? `${text}` : text;
  if (str && str.includes('|~|')) {
    const [text_zh, text_en] = str.split('|~|');
    return language && language === 'en' ? text_en : text_zh;
  } else {
    return str;
  }
}

export default function MessageBlockCategorySearch(props) {
  const [schema, setSchema] = useState(null);
  const [remoteJsonForm, setRemoteJsonForm] = useState(null);
  const [symptomObject, setSymptomObject] = useState({});
  const [model, setModel] = useState(null); // this one stores newly answered result, for rendering result
  const [formId, setFormId] = useState(null);
  const { t, i18n } = useTranslation();
  const [isFold, setFold] = useState(true);

  const { messageJson } = props;

  useEffect(() => {
    const updateFn = async () => {
      const _form = JSON.parse(messageJson._lctext || '{}');
      const partSchema = _form.schema || {};
      let form = JSON.parse(remoteJsonForm.form);
      let _schema = Object.assign({}, form.schema, partSchema);
      setSchema(_schema);
    };
    if (remoteJsonForm)
      updateFn()
  }, [messageJson, remoteJsonForm])

  useEffect(() => {
    const getChiefComplaints = async () => {
      const _symptomList = await getAllComplaintsByTag(
        schema.category,
        schema.avSchemaIdSuffix,
        schema.subcategories
      );
      setSymptomObject(
        _.keyBy(
          _.flatten(_symptomList.map(_symptom => _symptom.chiefComplaints)),
          'id'
        )
      );
    }
    if (schema)
      getChiefComplaints();
  }, [schema])

  useEffect(() => {
    const _form = JSON.parse(messageJson._lctext || '{}');
    const partSchema = _form.schema || {};
    const shufuFormId = partSchema.shufuFormId;
    setFormId(shufuFormId);
  }, [messageJson])

  useEffect(() => {
    const getForm = async () => {
      try {
        const remoteJsonForm = await getJsonFormById(formId);
        setRemoteJsonForm(remoteJsonForm);

      } catch (e) {
        console.error("unable to get form with id=", formId, "error=", e);
      }
    }
    console.log("formId update: ", formId);
    if (formId)
      getForm();
  }, [formId])

  useEffect(() => {
    if (props.isLastMessage && schema) {
      answerBoardEmitter.emit('set-answer-board', {
        type: 'categorySearch',
        category: schema.category,
        avSchemaIdSuffix: schema.avSchemaIdSuffix,
        subcategories: schema.subcategories,
        buttonText: schema.buttonText_zh,
        title: schema.title_zh,
        defaultSymptomsTags: schema.defaultSymptomsTags ? schema.defaultSymptomsTags : schema.defaultSymptomsTag ? [schema.defaultSymptomsTag] : undefined,
        removeCategoryPage: schema.removeCategoryPage,
        limit: schema.limit || 1
      });
    }
  }, [props.isLastMessage, schema]);

  const submitFormHandler = useCallback(
    async symptoms => {
      let model = {
        symptoms
      };

      // console.log(messageJson);
      let answerObject = JSON.parse(messageJson._lctext || '{}');
      answerObject.model = model;
      // return;

      const messageId = props.messageId;
      console.log(messageId);

      const text = JSON.stringify(answerObject);

      let body = {
        from: props.message.from,
        to: props.message.to,
        'msg-id': props.message['msg-id'],
        timestamp: new Date(props.message.timestamp).getTime(),
        data: {
          _lctext: text,
          _lcattrs: {
            ...messageJson._lcattrs,
            processId: answerObject.interaction.processId, // FIXME workaround here
            businessKey: answerObject.interaction.businessKey // FIXME workaround here
          },
          _lctype: -1 // <-- not sure what's this..., just use -1 by default
        }
      };
      setModel(model);
      // scroll to the end
      setTimeout(() => {
        var messageListWrapper = document.getElementById(
          'message_list_wrapper'
        );
        if (messageListWrapper) {
          // console.log("scroll to top MessageBlockCategorySearch: ", messageListWrapper.scrollTop);
          messageListWrapper.scrollTop = messageListWrapper.scrollHeight;
        }
      }, 500);
      const EventParams = {
        formId,
        endTime: new Date().getTime(),
        messageId: messageId,
        serviceInstanceId: _.get(props, 'messageJson._lcattrs.ssid', '')
      }
      submitPatientEvent(EventParams, 'onReplyToForm');
      try {
        const res = await replyToForm(body);
        if (!res) {
          submitPatientEvent(EventParams, 'onReplyToFormFailed');
        }
      } catch (e) {
        console.log("error: ", e);
      }
    },
    [messageJson._lctext, messageJson._lcattrs, props, formId]
  );

  // add submit form emitter
  useEffect(() => {
    if (props.isLastMessage) {
      answerBoardEmitter.on('submit', submitFormHandler);
    }

    return () => {
      answerBoardEmitter.removeListener('submit', submitFormHandler);
    };
  }, [submitFormHandler, props.isLastMessage]);

  let form = JSON.parse(messageJson._lctext || '{}');
  const isProcessSuccess = messageJson._lcattrs.isProcessSuccess;
  let askMessage = (
    <Message
      key={`${props.messageId}_ask`}
      className="shufu-text-message-bubble"
      style={{ marginBottom: 5, marginTop: 5 }}
      type="received"
      avatar={robot}
    >
      {form.schema && form.schema.instructions_zh ?
        <div dangerouslySetInnerHTML={{ __html: form.schema.instructions_zh }}>
        </div> : null}
    </Message>
  );

  const getAnswerMessage = () => {
    const symptoms = ((model || form.model || {})['symptoms'] || []);
    const hasAttributeValueModel = symptoms.some(symptom => symptom.attributeValueModel && Object.keys(symptom.attributeValueModel).length > 0);
    return <Message
      key={`${props.messageId}_answer`}
      className="shufu-text-message-bubble"
      style={{ marginBottom: 5, marginTop: 5 }}
      type="sent"
    >
      {symptoms
        .map(symptom => {
          const title = symptom.synonyms_zh[0];
          const id = symptom.id;
          const symptomModel = symptom.attributeValueModel || {};
          const schema = _.get(symptomObject, `${id}.attributeValueSchema`);
          const text = isFold
            ? ''
            : Object.keys(symptomModel)
              .map(questionKey => {
                // console.log("schema: ", schema, "questionKey: ", questionKey, "model: ", model);
                const questionTitle = getTitle(schema, symptomModel, schema[questionKey]);
                const questionText = getAnswerDisplay(schema, symptomModel, schema[questionKey], symptomModel[questionKey]);
                return `${questionTitle}：${questionText}`.replace("？：", "：");
              })
              .join('，\n');
          return `${title}\n${text}`;
        })
        .join('；\n')}
      {hasAttributeValueModel ? <div className="shufu-text-fold" onClick={() => {
        setFold(!isFold)
      }}>{isFold ? '点击查看更多' : '点击收起'}</div> : null}
    </Message>
  }

  const answerMessage =
    (isProcessSuccess || model) && schema && Object.keys(schema).length > 0 ? (
      getAnswerMessage()
    ) : null;
  return [askMessage, answerMessage];
}
