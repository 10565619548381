import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Page,
  Navbar,
  NavLeft,
  Link,
  Icon,
  Block,
  Card,
  NavTitle,
  Input,
  List,
  ListItem,
  Button,
  Radio,
  Checkbox,
  NavRight
} from 'framework7-react';
import _ from 'lodash';
import parseLanguage from '../utils/parseLanguage.js';
import isAnswerValid from '../utils/isAnswerValid.js';
import './StaticFormPopup.css';
import InputWithSuffix from './InputWithSuffix.js';
import DateInput from './DateInput.js';
import i18n from '../i18n';

StaticFormPopup.defaultProps = {
  schema: {
    order: [],
    properties: {},
    title_zh: 'Default表格'
  },
  submitHandler: function () {
    console.warn('submitHandler not defined');
  },
  model: null
};

import FOO_FORM from '../modules/mock/psych.hospitalHistory.json';

import StaticFormObjectArrayList from './StaticFormObjectArrayList.js';

//https://stackoverflow.com/questions/39821320/recursively-collect-values-for-property-using-lodash
_.mixin({
  toPairsDeep: obj =>
    _.flatMap(_.toPairs(obj), ([k, v]) =>
      _.isObjectLike(v) ? _.toPairsDeep(v) : [[k, v]]
    )
});

function getFlattenedValue(obj) {
  return _(obj)
    .toPairsDeep()
    .map(1)
    .value();
}

function getPaths(obj, paths = [], path = null) {
  if (obj == null) {
    return [];
  }
  if (typeof obj !== "object") {
    return [`${path}/${obj}`];
  }
  if (Array.isArray(obj)) {
    return (Object.keys(obj).reduce((paths, propKey) => {
      const currPath = path != null ? path : null
      return paths.concat(getPaths(obj[propKey], [], currPath));
    }, paths));
  }
  return (Object.keys(obj).reduce((paths, propKey) => {
    const currPath = path != null ? `${path}/${propKey}` : propKey;
    return paths.concat(getPaths(obj[propKey], [], currPath))
  }, paths));
}

function checkAskIf(askedif = [], model, sectionPath, askedIfExpression = null) {
  // 如果askedif为undefined，或者为null（jsonform中的值为null），需要做特殊处理

  if (!askedif) {
    askedif = [];
  }

  const global = (model && model.global) || [];
  const sectionModel = sectionPath ? _.get(model, sectionPath) : model;
  const flattenAnswers = getFlattenedValue(sectionModel).concat(getPaths(model)).concat(global);


  if (askedIfExpression) {
    // console.log("calculating askedIf expression for question: ", askedIfExpression);
    let getAskedIf = null;
    try {
      getAskedIf = new Function(
        'answers',
        'model',
        `return ${askedIfExpression}`
      );
    } catch (e) {
      console.error("Invalid askedIfExpression: ", askedIfExpression, "error=", e);
      return false;
    }
    try {
      return getAskedIf(flattenAnswers, model);
    } catch (e) {
      console.error("Error evaluating askedIfExpression: ", askedIfExpression, "error=", e);
      return false;
    }
  }


  if (askedif.length === 0) {
    return true;
  }
  return (
    askedif.filter(answerKey => flattenAnswers.includes(answerKey)).length > 0
  );
}

function getAdditionalDefaultAnswers(schema, fullModel, partialModel) {
  // console.log("partialModel: ", partialModel);
  if (!schema) return null;
  const { type, defaultValue, askedif, askedIfExpression } = schema;
  if (askedif && askedif.length > 0 && !checkAskIf(askedif, fullModel, undefined, askedIfExpression)) return null;
  if (defaultValue != null && partialModel == null) return defaultValue
  if (type === 'object') {
    const additionalDefaultAnswers = Object.keys(schema.properties).reduce((currModel, propertyKey) => {
      const defaultPropertyModel = getAdditionalDefaultAnswers(schema.properties[propertyKey], fullModel, partialModel ? partialModel[propertyKey] : null);
      return defaultPropertyModel != null ? Object.assign({}, currModel, { [propertyKey]: defaultPropertyModel }) : currModel
    }, {})
    if (partialModel == null || Object.keys(additionalDefaultAnswers).length > 0) return additionalDefaultAnswers;
  }
}

function updateModelWithDefaultAnswers(schema, fullModel, path = []) {
  if (!schema) return null;
  const { type, defaultValue, askedif, askedIfExpression } = schema;
  const partialModel = path.length === 0 ? fullModel : _.get(fullModel, path);
  // console.log("partialModel: ", partialModel, path, fullModel, schema);
  if (askedif && askedif.length > 0 && !checkAskIf(askedif, fullModel, undefined, askedIfExpression)) {
    if (partialModel != null) {
      _.unset(fullModel, path);
      return true;
    }
  } else if (partialModel == null && defaultValue != null) {
    if (type === "array" && !Array.isArray(defaultValue)) return false;
    if (typeof defaultValue === "string" && (defaultValue.includes("__INPUT_FORMDATA__") || defaultValue.includes("__INPUT_USER__"))) return false;
    _.set(fullModel, path, defaultValue);
    return true;
  } else if (type === 'object') {
    return Object.keys(schema.properties).reduce((isChanged, propertyKey) => {
      return updateModelWithDefaultAnswers(schema.properties[propertyKey], fullModel, path.concat(propertyKey)) || isChanged;
    }, false);
  }
  return false;
}


export default function StaticFormPopup(props) {
  // const selectedSymptom = props.symptom;
  const { canSubmit, schema, requireConfirm, model: inputModel, submitHandler, symptom, hideNavBar } = props;

  const [model, setModel] = useState(inputModel || {});


  useEffect(() => {
    // console.log("model: ", model);
    const updatedModel = _.cloneDeep(model);
    const isChanged = updateModelWithDefaultAnswers(schema, updatedModel);
    if (isChanged) {
      setModel(updatedModel);
    }
  }, [model, schema]);

  const onInnerInputWithSuffixChange = useCallback((value, { sectionPath, questionKey }) => {
    const questionPath = `${sectionPath}.${questionKey}`;

    const newModel = {
      ...model
    };

    if (!value) {
      _.set(newModel, questionPath, undefined);
      setModel(newModel);
    }

    _.set(newModel, questionPath, value);
    setModel(newModel);
  }, [model]);

  // useEffect(()=>{
  //   const schema = props.schema;
  //   if (!schema.properties) {
  //     // array type
  //     return ;
  //   }
  //   let defaultModel = {}
  //   let sectionKeyList = schema.order || Object.keys(schema.properties);
  //   sectionKeyList.forEach(sectionKey => {
  //       let section = schema.properties[sectionKey];
  //       if(section.d){}

  // })

  function renderInformationButton(information, style) {
    return <span
      style={{ marginLeft: 10, ...style }}
      className="shufu-answer-button-information-icon"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        window.$$f7.dialog.alert(information, false, { cssClass: 'dialog-text-left' });
      }
      }>?</span>
  }

  function renderSectionTitle(title, path, model, isRequired, information, style = {}) {
    const answer = _.get(model, path);
    const answerExists = isAnswerValid(answer);

    return <div style={style} className="static-form-popup-section-title">
      {title}
      {isRequired && !answerExists ? (<span className="is-required-asterisk">(*)</span>) : null}
      {information ? renderInformationButton(information) : null}
    </div>
  }



  function renderArray(schema, key) {
    let itemType = schema.items.type;
    if (itemType === 'object') {
      return renderObjectArray(schema, key);
    }
    else if (itemType === 'string' && schema.items.enum) {
      return renderEnumArray(schema, key);
    }
  }


  function renderOtherEnum(schema, key, enumKey) {
    const { items: { enum: enums, enumInfo } } = schema;

    const viewType = _.get(enumInfo || {}, [enumKey, "viewType"], "default");
    const listItemType = viewType === 'paragraph' ? "textarea" : "text";
    const placeholder = parseLanguage(enums[enumKey]);
    const otherEnumKey = (model[key] || []).find(
      enumKey => enumKey.match(/^__OTHER__/)
    )
    const otherEnumInput = otherEnumKey ? otherEnumKey.replace("__OTHER__", '') : '';
    return (
      <ListItem
        style={{ padding: 0 }}
        key={`${key}.${enumKey}`}
        name={key}
        className='square-border-checkbox'
        checkbox
        disabled={!canSubmit}
        checked={
          ((model || {})[key] || []).some(enumKey => enumKey.match(/^__OTHER__/))
        }
        title={
          <div>
            <Input
              name={key}
              // maxlength={50}
              resizable
              type={listItemType}
              placeholder={placeholder}
              clearButton={true}
              disabled={!canSubmit}
              wrap
              inputStyle={{}}
              value={
                otherEnumInput
              }
              onChange={event => {
                const input = event.target.value;
                if (input && input.length > 50) {
                  window.$$f7.dialog.alert(i18n.t('maxlength_error_message'), false);
                  return;
                }

                let newModel = {
                  ...model
                };

                newModel[key] = (newModel[key] || []).filter(
                  enumKey => !enumKey.match(/^__OTHER__/)
                );
                if (input) {
                  newModel[key].push('__OTHER__' + input);
                  newModel[key] = newModel[key].filter(enumKey => enumKey !== 'noneOfTheAbove');
                }
                setModel(newModel);
              }}
            />
          </div>
        }
        value={enumKey}
        onChange={e => {
          let checked = e.target.checked;
          let value = e.target.value;
          let newModel = {
            ...model
          };
          if (checked) {
            newModel[key] = newModel[key].filter(enumKey => enumKey !== 'noneOfTheAbove');
            if (newModel[key]) {
              newModel[key].push(value);
            } else {
              newModel[key] = [value];
            }
          } else {
            newModel[key] = newModel[key].filter(
              e => !e.match(/^__OTHER__/)
            );
          }
          setModel(newModel);
        }}
      ></ListItem>
    );
  }

  function renderSingleArrayEnum(schema, key, enumKey) {
    if (enumKey === '__OTHER__') return renderOtherEnum(schema, key, enumKey);

    const askedIf = _.get(schema, ['items', 'enumInfo', enumKey, 'askedIf']);
    const information_zh = _.get(schema, ['items', 'enumInfo', enumKey, 'information_zh']);

    const onChange = e => {
      const checked = e.target.checked;
      const value = e.target.value;
      const newModel = {
        ...model
      };
      if (checked) {
        if (schema.limit && newModel[key] && newModel[key].length === schema.limit) {
          window.$$f7.dialog.alert(`请最多选择${schema.limit}个选项`, false);
        } else {
          if (newModel[key]) {
            if (value === 'noneOfTheAbove') {
              newModel[key] = ['noneOfTheAbove']
            } else {
              // 判断是否包含noneOfTheAbove，如果包含，选了其他选项，则noneOfTheAbove反选
              const index = newModel[key].indexOf('noneOfTheAbove')
              if (index > -1) {
                newModel[key].splice(index, 1);
              }
              newModel[key].push(value);
            }
          } else {
            newModel[key] = [value];
          }
        }
      } else {
        newModel[key] = newModel[key].filter(e => e !== value);
      }
      setModel(newModel);
    }

    if (askedIf && askedIf.length > 0) {
      if (!askedIf.some((requiredEnumKey) => model[key] && model[key].includes(requiredEnumKey))) {
        if (model[key] && model[key].includes(enumKey)) {
          const newModel = {
            ...model
          };
          newModel[key] = newModel[key].filter(currKey => currKey !== enumKey);
          setModel(newModel);
        }
        return null;
      };
    }
    return <ListItem
      style={{ padding: 0 }}
      key={`${key}.${enumKey}`}
      name={key}
      checkbox
      className='square-border-checkbox'
      disabled={!canSubmit}
      checked={
        ((model || {})[key] || []).includes(enumKey)
      }
      title={<div>{parseLanguage(schema.items.enum[enumKey])}{
        information_zh ?
          renderInformationButton(information_zh, { paddingTop: 1, paddingBottom: 1 }) : null
      }</div>}
      value={enumKey}
      onChange={onChange}
    ></ListItem>;
  }

  function renderEnumArray(schema, key) {
    let title = schema.title_zh + '（多选）'
    const limit = schema.limit;
    if (limit) title = schema.title_zh + `（最多选${limit}个 ）`
    let order =
      (schema.items && schema.items.order) || Object.keys(schema.items.enum);

    return (
      <Card
        key={key}
        padding={false}
        content={
          <div>
            {renderSectionTitle(title, key, model, schema.isRequired, schema.information_zh)}
            <List>
              {order.map(enumKey => renderSingleArrayEnum(schema, key, enumKey))}
            </List>
          </div>
        }
      ></Card>
    );
  }

  function renderEnum(schema, key) {
    let order = schema.order || Object.keys(schema.enum);
    return (
      <Card
        key={key}
        padding={false}
        content={
          <div>
            {renderSectionTitle(schema.title_zh, key, model, schema.isRequired, schema.information_zh)}
            {order.length > 0 ? <List>
              {order.map(enumKey => {
                return (
                  <ListItem
                    style={{ padding: 0 }}
                    key={`${key}.${enumKey}`}
                    name={key}
                    disabled={!canSubmit}
                    radio
                    className='round-border-radio'
                    title={parseLanguage(schema.enum[enumKey])}
                    value={enumKey}
                    checked={model && model[key] === enumKey}
                    onChange={e => {
                      setModel({
                        ...model,
                        [key]: e.target.value
                      });
                    }}
                  ></ListItem>
                );
              })}
            </List> : null}
          </div>
        }
      ></Card>
    );
  }
  function renderString(type, schema, key) {
    return (

      <Card
        key={key}
        content={
          <div>
            {renderSectionTitle(schema.title_zh, key, model, schema.isRequired, schema.information_zh, {
              paddingTop: 0,
              paddingLeft: 0
            })}
            <div
              style={{ backgroundColor: '#f0f0f0', padding: 5, marginTop: 10 }}
            >
              <InputWithSuffix
                resizable
                questionKey={key}
                type={type === 'number' ? 'number' : 'textarea'}
                inputmode={type === 'number' ? 'decimal' : 'text'}
                pattern={type === 'number' ? '\d*' : ''}
                placeholder="请输入"
                disabled={!canSubmit}
                suffixList={schema.suffix}
                model={model[key]}
                onChange={value => {
                  setModel({
                    ...model,
                    [key]: value
                  });
                }}
              />
            </div>
          </div>
        }
      ></Card>
    );
  }
  function renderObject(schema, sectionPath, renderTitle = true) {
    const order = schema.order || Object.keys(schema.properties);
    return (
      <Card
        key={sectionPath}
        padding={false}
        content={
          <div>
            {renderTitle ? renderSectionTitle(schema.title_zh, sectionPath, model, schema.isRequired, schema.information_zh, { fontSize: 18 }) : null}
            <div className="list">
              {order.map(questionKey => renderInnerQuestion(`${sectionPath}.${questionKey}`))}
            </div>
          </div>
        }
      ></Card>
    );
  }

  function renderInnerQuestion(questionPath, isLink = false) {
    const questionPathArr = questionPath.split(".");
    const sectionPath = questionPathArr.slice(0, questionPathArr.length - 1).join(".");
    const questionKey = questionPathArr[questionPathArr.length - 1];
    const questionSchemaPath = `properties.${questionPathArr.join(".properties.")}`;
    const questionSchema = _.get(schema, questionSchemaPath);

    if (!questionSchema) {
      console.error("no existing questionSchemaPath: ", questionSchemaPath, schema);
      return;
    }

    const { type, viewType, title_zh, information_zh, isRequired, suffix, enum: enums, enumInfo, order, flexDirection, askedif, items, askedIfExpression, isLinked } = questionSchema;
    if (isLinked && !isLink) {
      return null;
    }
    const answer = _.get(model, questionPath);
    const answerExists = isAnswerValid(answer);

    let shouldAsk = checkAskIf(askedif, model, sectionPath, askedIfExpression);
    if (!shouldAsk) {
      const newModel = { ...model }
      if (_.get(newModel, sectionPath) !== undefined) {
        if (_.get(newModel, questionPath) !== undefined) {
          _.set(newModel, questionPath, undefined);
          setModel(newModel);
        }
      }
      return null;
    }
    let content = null;

    if (type === 'boolean') {
      content = renderInnerBoolean(questionKey, canSubmit, sectionPath);
    }
    else if (enums) {
      content = renderInnerEnum({ questionKey, canSubmit, sectionPath, enums, enumInfo, order, flexDirection });
    }
    else if (type === 'string') {
      content = renderInnerString(sectionPath, questionKey, suffix);
    }
    else if (type === 'number') {
      if (viewType === "date") {
        content = renderInnerDateInput(sectionPath, questionKey);
      } else {
        content = renderInnerNumber(sectionPath, questionKey, suffix);
      }
    }
    else if (type === "array" && items && items.enum) {
      content = renderInnerArrayEnum({ questionKey, canSubmit, sectionPath, enums: items.enum, enumInfo: items.enumInfo, order: items.order, flexDirection })
    }
    else if (type === 'object') {
      content = renderObject(questionSchema, questionPath, false);
    } else {
      content = <div>UNSUPPORTED TYPE PLACEHOLDER</div>;
    }

    return (
      <div
        key={questionKey}
        style={{
          marginBottom: 10,
          marginTop: 5,
          marginLeft: 10,
          marginRight: 10
        }}
      >
        <div style={{ fontSize: 16, fontWeight: 'bold' }}>
          {title_zh}
          {isRequired && !answerExists ? (
            <span className="is-required-asterisk">(*)</span>
          ) : null}
          {information_zh ? renderInformationButton(information_zh) : null}
        </div>
        {content}
      </div>
    );
  }

  function renderInnerString(sectionPath, questionKey, suffix) {
    const questionPath = `${sectionPath}.${questionKey}`
    return <InputWithSuffix
      key={`${questionPath}`}
      resizable
      questionKey={questionKey}
      type={'textarea'}
      inputmode={'text'}
      pattern={''}
      placeholder="请输入"
      suffixList={suffix}
      model={_.get(model, questionPath)}
      style={{ alignItems: "center" }}
      params={{ sectionPath, questionKey }}
      onChange={onInnerInputWithSuffixChange}
    />
  }

  function renderInnerNumber(sectionPath, questionKey, suffix) {
    const questionPath = `${sectionPath}.${questionKey}`
    return <div style={{ display: "flex", flexDirection: "row", marginTop: 5 }}>
      <InputWithSuffix
        key={`${questionPath}`}
        resizable
        questionKey={questionKey}
        type={'number'}
        inputmode={'decimal'}
        pattern={'\d*'}
        placeholder="请输入"
        suffixList={suffix}
        model={_.get(model, questionPath)}
        style={{ background: 'white', padding: "0px 8px 0px 8px" }}
        params={{ sectionPath, questionKey }}
        onChange={onInnerInputWithSuffixChange}
      /></div>
  }

  function renderInnerBoolean(questionKey, canSubmit, sectionPath) {
    const questionPath = `${sectionPath}.${questionKey}`
    return <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginTop: 5
      }}
    >
      <div style={{ flex: 1 }}>
        <Radio
          name={questionKey}
          value={true}
          style={{ marginRight: 20 }}
          disabled={!canSubmit}
          checked={
            _.get(model || {}, questionPath) === true
          }
          onChange={e => {
            const { name /*, value*/ } = e.target;
            let newModel = {
              ...model
            };
            _.set(newModel, questionPath, true);
            setModel(newModel);
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: 0,
              fontSize: 16,
              left: 20,
              paddingLeft: 20,
              paddingRight: 20,
              minWidth: 40
            }}
          >
            有
          </div>
        </Radio>
      </div>
      <div style={{ flex: 1 }}>
        <Radio
          name={questionKey}
          value={false}
          style={{ marginRight: 20 }}
          disabled={!canSubmit}
          checked={
            _.get(model || {}, questionPath) === false
          }
          onChange={e => {
            const { name /*, value*/ } = e.target;
            let newModel = {
              ...model
            };
            _.set(newModel, questionPath, false);
            setModel(newModel);
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: 0,
              fontSize: 16,
              left: 20,
              paddingLeft: 20,
              paddingRight: 20,
              minWidth: 40
            }}
          >
            没有
          </div>
        </Radio>
      </div>
    </div>
  }

  function renderInnerArrayOtherEnum(sectionPath, questionKey, canSubmit, enums, enumInfo, enumKey) {

    const questionPath = `${sectionPath}.${questionKey}`;

    const viewType = _.get(enumInfo || {}, [enumKey, "viewType"], "default");
    const listItemType = viewType === 'paragraph' ? "textarea" : "text";
    const placeholder = parseLanguage(enums[enumKey]);
    let answers = _.get(model || {}, questionPath, []);
    const otherEnumKey = answers.find(
      enumKey => enumKey.match(/^__OTHER__/)
    ) || "__OTHER__";
    const otherEnumInput = otherEnumKey ? otherEnumKey.replace("__OTHER__", '') : '';

    return (<div key={`${questionPath}.${enumKey}`} style={{ flex: 1, flexDirection: "row", marginTop: 5 }}>
      <Checkbox
        className='square-border-checkbox'
        name={`${questionPath}.${enumKey}`}
        value={enumKey}
        style={{ marginRight: 20, width: '100%', display: "flex", alignItems: "center" }}
        disabled={!canSubmit}
        checked={
          _.get(model || {}, questionPath, []).includes(otherEnumKey)
        }
        onChange={e => {
          let newModel = {
            ...model
          };

          if (answers.includes('noneOfTheAbove')) {
            answers = answers.filter(answer => answer !== 'noneOfTheAbove')
          }

          if (answers.includes(otherEnumKey)) {
            answers = answers.filter(answer => answer !== otherEnumKey);
          }
          else {
            answers.push(otherEnumKey);
          }
          _.set(newModel, questionPath, answers);
          setModel(newModel);
        }}
      >
        <Input
          name={`${questionPath}.${enumKey}`}
          // maxlength={50}
          resizable
          type={listItemType}
          placeholder={placeholder}
          clearButton={true}
          disabled={!canSubmit}
          wrap
          inputStyle={{ paddingLeft: 20, paddingRight: 20 }}
          value={
            otherEnumInput
          }
          onChange={event => {
            const input = event.target.value;
            console.log("input: ", input);
            console.log("otherEnumInput: ", otherEnumInput);
            if (input && input.length > 50) {
              window.$$f7.dialog.alert(i18n.t('maxlength_error_message'), false);
              return;
            }

            let newModel = {
              ...model
            };

            let answers = _.get(model, questionPath, []);
            answers = answers.filter(
              enumKey => !enumKey.match(/^__OTHER__/)
            );

            if (input) {
              answers.push('__OTHER__' + input);
              answers = answers.filter(enumKey => enumKey !== 'noneOfTheAbove');
            }

            _.set(newModel, questionPath, answers);

            setModel(newModel);
          }}
        />
      </Checkbox>
    </div>);
  }

  function renderInnerOtherSingleEnum(sectionPath, questionKey, canSubmit, enums, enumInfo, enumKey) {

    const questionPath = `${sectionPath}.${questionKey}`;

    const viewType = _.get(enumInfo || {}, [enumKey, "viewType"], "default");
    const listItemType = viewType === 'paragraph' ? "textarea" : "text";
    const placeholder = parseLanguage(enums[enumKey]);
    let answer = _.get(model || {}, questionPath);
    let otherEnumKey = (answer && answer.match(/^__OTHER__/)) ? answer : "__OTHER__";
    const otherEnumInput = otherEnumKey ? otherEnumKey.replace("__OTHER__", '') : '';

    return (<div key={`${questionPath}.${enumKey}`} style={{ flex: 1, flexDirection: "row", marginTop: 5 }}>
      <Radio
        name={`${questionPath}.${enumKey}`}
        value={enumKey}
        style={{ marginRight: 20, width: '100%', display: "flex", alignItems: "center" }}
        disabled={!canSubmit} s
        checked={
          _.get(model || {}, questionPath) === otherEnumKey
        }
        onChange={e => {
          let newModel = {
            ...model
          };

          _.set(newModel, questionPath, answer);
          setModel(newModel);
        }}
      >
        <Input
          name={`${questionPath}.${enumKey}`}
          // maxlength={50}
          resizable
          type={listItemType}
          placeholder={placeholder}
          clearButton={true}
          disabled={!canSubmit}
          wrap
          inputStyle={{ paddingLeft: 20, paddingRight: 20 }}
          value={
            otherEnumInput
          }
          onChange={event => {
            const input = event.target.value;
            console.log("input: ", input);
            console.log("otherEnumInput: ", otherEnumInput);
            if (input && input.length > 50) {
              window.$$f7.dialog.alert(i18n.t('maxlength_error_message'), false);
              return;
            }

            let newModel = {
              ...model
            };

            if (input) {
              answer = '__OTHER__' + input;
            }

            _.set(newModel, questionPath, answer);
            setModel(newModel);
          }}
        />
      </Radio>
    </div>);
  }


  function renderInnerEnum({ questionKey, canSubmit, sectionPath, enums, enumInfo, order, flexDirection = 'column' }) {
    const enumOrder = order || Object.keys(enums);
    const questionPath = `${sectionPath}.${questionKey}`;

    return <div
      style={{
        display: 'flex',
        flexDirection,
        marginTop: 5
      }}
    >
      {enumOrder.map((enumKey) => {


        const enumContent = enumKey.includes("__OTHER__")
          ? renderInnerOtherSingleEnum(sectionPath, questionKey, canSubmit, enums, enumInfo, enumKey)
          : renderSingleInnerEnum(questionPath, enums, enumInfo, enumKey, canSubmit);

        const linkedQuestionPaths = _.get(enumInfo, [enumKey, "linkedQuestionPaths"], []);


        const linkedQuestions = linkedQuestionPaths.map((questionPath) => renderInnerQuestion(questionPath, true));

        return <div>{enumContent}{linkedQuestions}</div>;
      })}
    </div>
  }

  function renderSingleInnerEnum(questionPath, enums, enumInfo, enumKey, canSubmit) {
    return <div key={`${questionPath}.${enumKey}`} style={{ flex: 1, flexDirection: "row", marginTop: 5 }}>
      <Radio
        name={`${questionPath}.${enumKey}`}
        value={enumKey}
        style={{ marginRight: 20, width: '100%', display: "flex" }}
        disabled={!canSubmit}
        checked={
          _.get(model || {}, questionPath) === enumKey
        }
        onChange={e => {
          const { value } = e.target;
          let newModel = {
            ...model
          };
          _.set(newModel, questionPath, value);
          setModel(newModel);
        }}
      >
        <div
          style={{
            fontSize: 16,
            paddingLeft: 20,
            paddingRight: 20,
            minWidth: 40
          }}
        >
          {parseLanguage(enums[enumKey])}
        </div>
      </Radio>
    </div>
  }



  function renderInnerArrayEnum({ questionKey, canSubmit, sectionPath, enums, enumInfo, order, flexDirection = 'column' }) {
    const enumOrder = order || Object.keys(enums);
    const questionPath = `${sectionPath}.${questionKey}`;

    return <div
      style={{
        display: 'flex',
        flexDirection,
        marginTop: 5
      }}
    >
      {enumOrder.map((enumKey) => {
        const enumContent = enumKey.includes("__OTHER__")
          ? renderInnerArrayOtherEnum(sectionPath, questionKey, canSubmit, enums, enumInfo, enumKey)
          : renderSingleInnerArrayEnum(questionPath, enums, enumInfo, enumKey, canSubmit);

        const linkedQuestionPaths = _.get(enumInfo, [enumKey, "linkedQuestionPaths"], []);


        const linkedQuestions = linkedQuestionPaths.map((questionPath) => renderInnerQuestion(questionPath, true));

        return <div>{enumContent}{linkedQuestions}</div>;

      })}
    </div>
  }

  function renderSingleInnerArrayEnum(questionPath, enums, enumInfo, enumKey, canSubmit) {
    return <div key={`${questionPath}.${enumKey}`} style={{ flex: 1, flexDirection: "row", marginTop: 5 }}>
      <Checkbox
        className='square-border-checkbox'
        name={`${questionPath}.${enumKey}`}
        value={enumKey}
        style={{ marginRight: 20, width: '100%', display: "flex" }}
        disabled={!canSubmit}
        checked={
          _.get(model || {}, questionPath, []).includes(enumKey)
        }
        onChange={e => {
          const { value } = e.target;
          let newModel = {
            ...model
          };
          let answers = _.get(model || {}, questionPath, []);
          if (value === 'noneOfTheAbove') {
            answers = [];
          }
          else if (answers.includes('noneOfTheAbove')) {
            answers = answers.filter(answer => answer !== 'noneOfTheAbove')
          }

          if (answers.includes(value)) {
            answers = answers.filter(answer => answer !== value);
          }
          else {
            answers.push(value);
          }
          _.set(newModel, questionPath, answers);
          setModel(newModel);
        }}
      >
        <div
          style={{
            fontSize: 16,
            paddingLeft: 20,
            paddingRight: 20,
            minWidth: 40
          }}
        >
          {parseLanguage(enums[enumKey])}
        </div>
      </Checkbox>
    </div>
  }

  function renderObjectArray(schema, key) {

    return (
      <Card
        key={key}
        padding={false}
        content={
          <div>
            {renderSectionTitle(schema.title_zh, key, model, schema.isRequired, schema.information_zh, { fontSize: 18 })}
            {
              <StaticFormObjectArrayList
                schema={schema}
                canSubmit={canSubmit}
                model={(model || {})[key] || []}
                onChange={_model => {
                  setModel({
                    ...model,
                    [key]: _model
                  });
                }}
              />
            }
          </div>
        }
      ></Card>
    );
  }

  function renderInnerDateInput(sectionPath, questionKey) {
    const questionPath = `${sectionPath}.${questionKey}`
    const currValue = _.get(model || {}, questionPath, null);
    return <DateInput
      disabled={!canSubmit}
      style={{ paddingTop: 10 }}
      value={currValue}
      onChange={date => {
        const newModel = _.set(model, questionPath, date);
        setModel(newModel);
      }}
    />
  }

  function renderDateInput(schema, key) {
    return (
      <Card
        key={key}
        padding={false}
        content={
          <div>
            {renderSectionTitle(schema.title_zh, key, model, schema.isRequired, schema.information_zh)}
            {
              <DateInput
                schema={schema}
                disabled={!canSubmit}
                style={{ paddingTop: 10 }}
                value={(model || {})[key] || null}
                onChange={date => {
                  setModel({
                    ...model,
                    [key]: date
                  });
                }}
              />
            }
          </div>
        }
      ></Card>
    );
  }

  function renderSection(schema, key) {
    const { askedif, viewType, askedIfExpression } = schema;
    if (!checkAskIf(askedif, model, undefined, askedIfExpression)) return null;
    if (schema.type === 'array') {
      return renderArray(schema, key);
    } else if (schema.type === 'string' && schema.enum) {
      return renderEnum(schema, key);
    } else if (schema.type === 'object') {
      return renderObject(schema, key);
    } else if (viewType === 'date') {
      return renderDateInput(schema, key);
    } else {
      return renderString(schema.type, schema, key);
    }
  }

  function checkModel() {
    if (!schema.properties) {
      // array type
      return true;
    }
    let sectionKeyList = schema.order || Object.keys(schema.properties);
    return sectionKeyList
      .map(sectionKey => {
        let section = schema.properties[sectionKey];
        if (section.type === 'object') {
          let questionKeys = section.order || Object.keys(section.properties);
          return questionKeys
            .map(questionKey => {
              const question = section.properties[questionKey];
              if (!question.isRequired) return true;
              if (!checkAskIf(question.askedif, model, sectionKey, question.askedIfExpression)) return true;
              const answer = model[sectionKey] && model[sectionKey][questionKey];
              const isValid = isAnswerValid(answer);
              // if (!isValid) console.log("INVALID 1: ", sectionKey, questionKey, model);
              return isValid;
            })
            .every(e => e);
        } else if (
          section.type === 'array' &&
          section.items.type === 'object'
        ) {
          if (section.isRequired) {
            if (!model[sectionKey] || model[sectionKey].length == 0) {
              // console.log("INVALID 2: ", sectionKey, model);
              return false;
            }
          }
          if (model[sectionKey] == null) return true;
          const isValid = model[sectionKey].every &&
          model[sectionKey].every(item => !!item.__ok);
          // if (!isValid) console.log("INVALID 3: ", sectionKey, model);
          return isValid
        } else {
          if (!section.isRequired || !checkAskIf(section.askedif, model, undefined, section.askedIfExpression)) return true;

          const answer = model[sectionKey];
          const isValid = isAnswerValid(answer);
          // if (!isValid) console.log("INVALID 4: ", sectionKey, model);
          return isValid

        }
      })
      .every(e => e);
  }

  function confirmToSubmit() {
    if (checkModel()) {
      // 增加确认弹框，二次确认后提交
      if (requireConfirm) {
        window.$$f7.dialog.confirm('请检查您的选择，确认提交后将不得修改。是否确认提交？', '提示', () => {
          submitHandler(model);
        })
      } else {
        submitHandler(model);
      }
    } else {
      window.$$f7.dialog.alert(i18n.t('missing_answers'), false, undefined, () => {
        const requiredElements = document.getElementsByClassName('is-required-asterisk');
        const firstRequiredElement = requiredElements[0];
        if (firstRequiredElement) {
          firstRequiredElement.scrollIntoView({ block: "center", behavior: "smooth" });
        }
      });
    }
  }

  if (schema) {
    // const schema = FOO_FORM.schema;
    let sectionKeys = schema.order || Object.keys(schema.properties || {});
    return (
      <Page key={symptom && symptom.id}>
        {!hideNavBar
          ? <Navbar>
            <NavLeft>
              <Link popupClose>
                <Icon icon="icon-back"></Icon> <span> 返回</span>
              </Link>
            </NavLeft>
            <NavTitle>{_.get(props, 'schema.title_zh')}</NavTitle>
            {/* {canSubmit ? (
            <NavRight>
              <Link
                onClick={() => {
                  confirmToSubmit()
                }}
              >
                <span> 提交</span>
              </Link>
            </NavRight>
          ) : null} */}
          </Navbar>
          : null}
        <Block>
          {sectionKeys.map(sectionKey => {
            return renderSection(schema.properties[sectionKey], sectionKey);
          })}
        </Block>
        {canSubmit ? (
          <Block>
            <Button
              large
              raised
              fill
              round
              onClick={() => {
                confirmToSubmit()
              }}
            >
              提交
            </Button>
          </Block>
        ) : null}
      </Page>
    );
  } else {
    return null;
  }
}
