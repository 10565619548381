import { Message, Messages } from 'framework7-react';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import robot from '../images/robot.png';
import { answerBoardEmitter } from '../modules/answerBoardEmitter.js';
import { messageEventEmitter } from '../modules/shuaicloud/index.js';
import { replyToForm } from '../modules/shufu.js';
import wcstCardOrder from '../utils/wcstCardOrder.js';
import './MessageBlock.css';
import { submitPatientEvent } from './SubmitPatientEvent.js';


const wcstCardOrderList = wcstCardOrder();
const ruleOrder = ["color", "form", "number", "color", "form", "number"];
const cardImages = {};
wcstCardOrderList.forEach(cardName => {
  const cardImage = require(`../../src/images/wcstCards/${cardName}.png`);
  cardImages[cardName] = cardImage;
});

export default function MessageBlockWCSTForm(props) {
  const messageJson = props.messageJson;
  const messageLcText = JSON.parse(messageJson._lctext || '{}');
  const { schema, model: inputModel } = messageLcText;
  const firstStimulusCardName = wcstCardOrderList[0];
  const firstStimulusCard = cardImages[firstStimulusCardName];

  const introductionMessageBubbles = [
    {
      type: 'received',
      avatar: robot,
      text: '本量表是一种单项神经心理测定，首先由Berg（1948）用于检测正常人的抽象思维能力。反映的认知功能包括：抽象概括，认知转移，注意，工作记忆，信息提取，分类维持，分类转换，刺激再识和加工，感觉输入和运动输出等。主要用于精神疾病患者、脑损伤者的执行能力的测试。'
    },
    {
      type: 'received',
      avatar: robot,
      text: '请找出你认为与应答卡图案相匹配的刺激卡，并用手指（鼠标）点中，我（电脑）会告诉你选对了还是选错了。如果选错了请你不要更改，争取把下一张选对就是了。好，听明白了我们就开始。'
    }
  ];
  // 需要跨组件传递的消息

  let initialMessageBubbles = [];
  if (inputModel && inputModel.categories) {
    console.log("inputModel: ", inputModel);
    initialMessageBubbles = inputModel.categories.reduce((currArr, category) => {
      return currArr.concat(category.responses.reduce((currArr, response) => {
        const { stimulus, isCorrect } = response;
        const stimulusCard = cardImages[stimulus];
        const stimulusCardBubble = {
          type: 'received',
          avatar: robot,
          image: stimulusCard,
          formType: 'wcst',
          createdTime: Date.now(),
          cardName: stimulus,
        }
        const answerBubble = {
          type: "sent",
          text: isCorrect ? "正确" : "错误"
        }
        return currArr.concat([stimulusCardBubble, answerBubble]);
      }, []));
    }, introductionMessageBubbles)
  } else {
    initialMessageBubbles = [...introductionMessageBubbles, {
      type: 'received',
      avatar: robot,
      image: firstStimulusCard,
      formType: 'wcst',
      createdTime: Date.now(),
      cardName: firstStimulusCardName,
    }]
  }

  const [messageBubbles, setMessageBubbles] = useState(initialMessageBubbles);

  // 是否是最后一个message
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [currentRuleIndex, setCurrentRuleIndex] = useState(0);
  // 连续答对题目的数量
  const [numPersistentCorrect, setNumPersistentCorrect] = useState(0);

  const modelRef = useRef({
    categories: [
      {
        id: 1,
        rule: ruleOrder[currentRuleIndex],
        responses: []
      }
    ]
  });

  function getResponseRules(responseCardName, stimulusCardName) {
    const [responseNumber, responseColor, responseForm] = responseCardName.split("-");
    const [stimulusNumber, stimulusColor, stimulusForm] = stimulusCardName.split("-");
    const responseRules = [];
    if (responseNumber === stimulusNumber) responseRules.push("number");
    if (responseColor === stimulusColor) responseRules.push("color");
    if (responseForm === stimulusForm) responseRules.push("form");
    return responseRules;
  }

  function getIsCorrect(responseRules, rule) {
    return responseRules.includes(rule);
  }

  const submitFormHandler = useCallback(async () => {


    messageLcText.model = modelRef.current;
    messageLcText.interaction.isReply = true;
    const messageId = props.messageId;
    console.log('messageId', messageId, "messageJson: ", messageJson);

    const text = JSON.stringify(messageLcText);

    let body = {
      from: props.message.from,
      to: props.message.to,
      'msg-id': props.message['msg-id'],
      timestamp: new Date(props.message.timestamp).getTime(),
      data: {
        _lctext: text,
        _lcattrs: {
          ...messageJson._lcattrs,
          processId: messageLcText.interaction.processId, // FIXME workaround here
          businessKey: messageLcText.interaction.businessKey // FIXME workaround here
        },
        _lctype: -1 // <-- not sure what's this..., just use -1 by default
      }
    };
    const EventParams = {
      formId: schema.shufuFormId,
      endTime: new Date().getTime(),
      messageId: messageId,
      serviceInstanceId: _.get(props, 'messageJson._lcattrs.ssid', '')
    }
    // window.$$f7.dialog.preloader('提交中');
    answerBoardEmitter.emit('submit');

    // 数据打点
    submitPatientEvent(EventParams, 'onReplyToForm');
    try {
      const res = await replyToForm(body);
      if (!res) {
        submitPatientEvent(EventParams, 'onReplyToFormFailed');
      }
    } catch (e) {
      console.log("error: ", e);
      //alert error
    }
    // window.$$f7.dialog.close();

  }, [messageJson, messageLcText, props, schema.shufuFormId]);

  // 将传来的MessageBlockWCSTForm的props的值setState到messageText

  // emit 'set-answer-board'渲染底部的andwerBoard
  useEffect(() => {
    if (props.isLastMessage) {
      answerBoardEmitter.emit('set-answer-board', {
        type: 'WCSTView'
      });
    }
  }, [props.isLastMessage]);

  const updateModelWithResponse = (number, isCorrect, stimulusCard, responseCard, responseRules) => {
    const responseInfo = {
      number,
      isCorrect,
      stimulus: stimulusCard,
      response: responseCard,
      responseRules
    }
    const model = modelRef.current;
    const currentModelCategory = model.categories[model.categories.length - 1];
    currentModelCategory.responses.push(responseInfo);
  }

  const updateModelWithNewCategory = (id, currentRule) => {
    const newCategoryInfo = {
      id,
      rule: currentRule,
      responses: []
    };
    const model = modelRef.current;
    model.categories.push(newCategoryInfo);
  }

  const getNextStimulusCardBubble = (updatedCardIndex) => {
    const nextStimulusCardName = wcstCardOrderList[updatedCardIndex];
    const nextStimulusCard = cardImages[nextStimulusCardName];
    return {
      type: 'received',
      avatar: robot,
      image: nextStimulusCard,
      formType: 'wcst',
      createdTime: Date.now(),
      cardName: nextStimulusCardName,
    };
  }

  const getAnswerBubble = (isCorrect) => {
    return {
      type: "sent",
      text: isCorrect ? "正确 " : "错误"
    }
  }

  const answerHandler = useCallback((responseCard) => {
    const stimulusCard = wcstCardOrderList[currentCardIndex];
    const currentRule = ruleOrder[currentRuleIndex];
    const responseRules = getResponseRules(responseCard, stimulusCard);
    const isCorrect = getIsCorrect(responseRules, currentRule)
    console.log("received response card: ", responseCard, ', current stimulus card: ', stimulusCard, isCorrect);
    updateModelWithResponse(currentCardIndex + 1, isCorrect, stimulusCard, responseCard, responseRules);
    const answerMessageBubble = getAnswerBubble(isCorrect);

    if (currentCardIndex === wcstCardOrderList.length - 1) {
      setMessageBubbles(messageBubbles.concat([answerMessageBubble]));
      submitFormHandler();
      return;
    }
    const updatedCardIndex = currentCardIndex + 1;
    let updatedNumPersistentCorrect = isCorrect ? numPersistentCorrect + 1 : 0;
    let updatedRuleIndex = currentRuleIndex;
    console.log("updatedNumCorrect: ", updatedNumPersistentCorrect);
    if (updatedNumPersistentCorrect === 10) {
      if (currentRuleIndex === ruleOrder.length - 1) {
        setMessageBubbles(messageBubbles.concat([answerMessageBubble]));
        submitFormHandler();
        return;
      }

      updatedRuleIndex = currentRuleIndex + 1;
      const currentRule = ruleOrder[updatedRuleIndex];
      updateModelWithNewCategory(updatedRuleIndex + 1, currentRule);
      updatedNumPersistentCorrect = 0;
    }
    console.log("model: ", modelRef.current);

    const stimulusCardBubble = getNextStimulusCardBubble(updatedCardIndex);
    setCurrentCardIndex(updatedCardIndex);
    setCurrentRuleIndex(updatedRuleIndex);
    setNumPersistentCorrect(updatedNumPersistentCorrect);
    setMessageBubbles(messageBubbles.concat([answerMessageBubble, stimulusCardBubble]));

    setTimeout(() => {
      var messageListWrapper = document.getElementById(
        'message_list_wrapper'
      );
      if (messageListWrapper) {
        // console.log("scroll to top MessageBlockInlineForm: ", messageListWrapper.scrollTop);
        messageListWrapper.scrollTop = messageListWrapper.scrollHeight;
      }
    }, 10);

  }, [currentCardIndex, currentRuleIndex, messageBubbles, numPersistentCorrect, submitFormHandler]);

  useEffect(() => {

    console.log("creating emitter event ");
    if (props.isLastMessage) {
      answerBoardEmitter.on('answer', answerHandler);
    }
    return () => {
      console.log("removing emitter event");
      answerBoardEmitter.removeListener('answer', answerHandler);
    }

  }, [answerHandler, props.isLastMessage]);

  return (
    <div>
      <Messages>
        {messageBubbles.map((message, index) => (
          <Message
            key={index}
            type={message.type}
            image={message.image}
            avatar={robot}
            className='shufu-text-message-bubble'
            style={{
              width: '80%',
            }}
          >
            {message.text && (
              <span slot="text" dangerouslySetInnerHTML={{ __html: message.text }} />
            )}
          </Message>
        ))}
      </Messages>
    </div>
  )
}