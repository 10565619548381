import React, { useState, useCallback, useEffect } from 'react';
import './report.css';
import getParameterByName from '../../utils/getParameterByName';
import ReportRichTextContent from './reportRichTextContent';
var Chinese = require('chinese-s2t');

const ReportRichTextParagraph = function (props) {
  const content = props.content;
  const noHref = getParameterByName('noHref') === 'true';

  if (!content) {
    return null;
  }

  const parseContent = (content) => {
    return content.replace(/\\n/g, '<br/>')
  }

  let htmlString =
    parseContent(content) ||
    `<p style="text-align:center;"><span style="font-size:20px"><span style="color:#003ba5">我觉得可以吗？</span></span></p><p><span style="font-size:14px">我觉得不可以，<span style="color:#f32784">你为什么觉得不可以</span>？我觉得主要是这几点：</span></p><ul><li><span style="font-size:14px">这个不可以</span></li><li><span style="font-size:14px">那个不可以</span></li><li><span style="font-size:14px">全部都不可以</span></li><li><span style="font-size:14px">到底可以不可以？</span></li></ul><p></p><div class="media-wrap image-wrap align-center" style="text-align:center"><img src="https://upload.wikimedia.org/wikipedia/commons/6/6c/An_expression_about_wiki_BY_FHH440.jpg" width="200px" height="200px" style="width:200px;height:200px"/></div><p></p>`;
  if (getParameterByName('lang') === 'tc') {
    htmlString = Chinese.s2t(htmlString);
  }

  return (
    <div className="report-section">
      {props.title ? (
        <div style={{ fontSize: 24, textAlign: 'center', marginBottom: 15 }}>
          {props.title}
        </div>
      ) : null}
      <ReportRichTextContent content={content} />
    </div>
  );
};
ReportRichTextParagraph.defaultProps = {
  content: null,
  title: null
};
export default ReportRichTextParagraph;
