import React, { useState, useCallback, useEffect } from 'react';
import { Icon } from 'framework7-react';
import ShareReportModal from './shareReportModal';
import shareIcon from '../../images/share-alt-solid.svg';

import getParameterByName from '../../utils/getParameterByName';
import reportIcon from '../../images/Contacts_full@3x.png';

import './report.css';
import parseLanguage from '../../utils/parseLanguage';
const ShareReportParagraph = function (props) {
  const [showShareModal, setShowShareModal] = useState(false);
  const { report, section } = props;
  const { description = '' } = section;
  const description_zh = parseLanguage(description, "zh");
  const description_en = parseLanguage(description, "en");

  function renderShareModal() {
    if (!showShareModal) {
      return null;
    }
    const isTc = getParameterByName('lang') === 'tc';

    return (
      <div
        className="modal-background"
        onClick={event => {
          if (
            event.target.className &&
            event.target.className.match(/modal-background/)
          ) {
            setShowShareModal(false);
          }
          console.log(event.target.className);
        }}
        style={{
          display: 'flex'
        }}
      >
        <ShareReportModal
          reportConfig={report.shareConfig.reportConfig}
          reportBackgroundUrl={
            isTc
              ? report.shareConfig.reportBackgroundImgUrl_tc ||
              report.shareConfig.reportBackgroundImgUrl
              : report.shareConfig.reportBackgroundImgUrl
          }
        />
      </div>
    );
  }

  return (
    <div>
      <div
        className="report-section"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
        onClick={() => {
          setShowShareModal(true);
        }}
      >
        <div style={{ display: 'flex' }}>
          <img
            className="share-chart-icon"
            src={reportIcon}
            height="30"
            width="35"
          />
          <div className="share-chart-text-container">
            <div className="share-chart-section-title">
              {getParameterByName('lang') === 'tc'
                ? '分享測試分數'
                : description_zh || '分享测试分数'}
            </div>
            <div className="share-chart-section-subtitle">
              {description_en || "SHARE REPORT SCORE"}
            </div>
          </div>
        </div>

        <div>
          <div
            style={{
              backgroundColor: '#2979ff',
              borderRadius: 100,
              padding: '0px 10px 0px 10px'
            }}
          >
            <Icon
              f7="square_arrow_up"
              size="16px"
              style={{ color: 'white' }}
            ></Icon>
            <span style={{ color: 'white', fontSize: 12, marginLeft: 5 }}>
              分享
            </span>
          </div>
        </div>
      </div>

      {renderShareModal()}
    </div>
  );
};
ShareReportParagraph.defaultProps = {
  report: {
    shareConfig: {
      reportConfig: {},
      reportBackgroundImgUrl: ''
    }
  }
};
export default ShareReportParagraph;
