import React, { useState, useEffect } from 'react';
import {
  Page,
  Card,
  Input
} from 'framework7-react';
import './chooseSale.css';
import { initShuaiCloud as initShuaiCloudFromClientInstance } from '../../modules/shuaicloud/clientInstance';
import { initUser } from '../welcome/welcome';
import { useTranslation } from 'react-i18next';
import {
  verifyCodeReturnUsersOrRegister,
} from '../../modules/shufu';


const ChooseScalesSmhcTaping = function (props) {
  const { t, i18n } = useTranslation();


  useEffect(() => {
    const loginWithCellphoneCode = async (_cellphone, _code) => {
      if (_cellphone && _code) {
        // do nothing
      } else {
        if (!cellphone || !code) {
          alert('请输入手机和验证码');
          return;
        }

        if (needName && !name) {
          alert('请输入姓名');
          return;
        }
      }

      window.$$f7.preloader.show();

      try {
        let result = await verifyCodeReturnUsersOrRegister(
          _cellphone || cellphone,
          _code || code,
          name
        );

        const userId = result.data.userId || result.data.userid; // there's maybe a typo from backend
        const token = result.data.token;
        await initUser(userId, null, token);
        window.$$f7.preloader.hide();

      } catch (err) {
        console.error(err);
        window.$$f7.preloader.hide();
        window.$$f7.dialog.alert(t('server_busy'));
      }
    }

    async function tryAutomaticLogin() {

      // if it's not wechat browser, or wechat login failed, use original login
      loginWithCellphoneCode(
        `GG_${Date.now()}_${Math.floor(Math.random() * 100)}`,
        '9999'
      );
    }

    async function initShuaiCloud() {
      try {
        await initShuaiCloudFromClientInstance();
        window.$$f7.preloader.hide();
      } catch (e) {
        console.err(e);
        window.$$f7.preloader.hide();
      }
    }

    tryAutomaticLogin();
  }, [t])

  const handleClick = (sceneId) => {
    props.f7router.navigate(
      `/chat/?sceneId=${sceneId}`, {
      reloadCurrent: true
    }
    );
  }

  const renderHospitalIdInput = () => {
    return (
      <div style={{ padding: '10px 10px 50px 10px', display: "flex", flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
        <div style={{ fontSize: '16px', margin: '0 20 0 20' }}>
          请输入患者住院号
        </div>
        <div style={{ display: 'flex', margin: '0 40 0 40', width: '100%' }}>
          <div
            style={{
              flex: 1,
              height: 35,
              borderRadius: 10,
              border: `solid 1px #2979ff`,
              backgroundColor: '#deeaff',
              display: 'flex',
              alignItems: 'center',
              width: '100%'
            }}
          >
            <Input
              style={{ flex: '1', marginLeft: 10, marginRight: 10, width: '100%' }}
              type="text"
              // value={ricnStr}
              // onChange={event => {
              //   const input = event.target.value;
              //   setRicnStr(input);
              // }}
              placeholder=""
            />
          </div>
        </div>
      </div>
    );
  }


  const renderPage = () => {
    return (
      <div style={{ padding: 10, height: "100%" }}>
        {/* <LoginScreenTitle>心理健康关爱系列测评</LoginScreenTitle> */}
        {/* <div className="ul-reportButton">
          <button
            className="historyReportButton"
            onClick={() => {
              // self.$f7.dialog.confirm(text, title);
              this.props.f7router.navigate('/historyReport/');
            }}
          >
            <p className="historyReportButtonTip">历史测试报告</p>
          </button>
        </div> */}

        {/* <Card className="card">
          <button className="scaleButtons" onClick={() => this.handleLesson()}>
            <img className="scaleImage" src={backbone}></img>
            <p className="scaleButtonTips">脊椎健康养护教程</p>
          </button>
        </Card> */}
        {renderHospitalIdInput()}
        <Card>
          <button
            className="scaleButtons"
            onClick={() =>
              handleClick("87c2a5d5b15843c")
            }
          >
            {/* <img className="scaleImage" src={sleep}></img> */}
            <p className="scaleButtonTips">阳性和阴性综合征量表（PANSS）</p>
          </button>
        </Card>
        <Card>
          <button
            className="scaleButtons"
            onClick={() =>
              handleClick("e73f3c8f13364df")
            }
          >
            {/* <img src={healthy} className="scaleImage"></img> */}
            <p className="scaleButtonTips">表简明精神病量表（BPRS）</p>
          </button>
        </Card>

        <Card>
          <button
            className="scaleButtons"
            onClick={() =>
              handleClick("cdeeeba68e134aa")
            }
          >
            {/* <img className="scaleImage" src={burnout}></img> */}
            <p className="scaleButtonTips">汉密尔顿焦虑量表</p>
          </button>
        </Card>

        <Card>
          <button
            className="scaleButtons"
            onClick={() =>
              handleClick("d004dd501620457")
            }
          >
            {/* <img className="scaleImage" src={pressure}></img> */}
            <p className="scaleButtonTips">汉密尔顿抑郁量表</p>
          </button>
        </Card>
      </div>
    );
  }


  return (
    <Page
      style={{ height: "100%" }}
      className="scalesSection"
      noToolbar
      noNavbar
      noSwipeback
      loginScreen
    >
      {renderPage()}

      <div
        style={{ width: '100%', marginTop: 10, height: 50 }}
        onClick={() => {
          let pass = window.prompt('请输入代码');
          if (pass === 'siuvo') {
            window.location.href =
              'https://app.siuvo.cn/liveInfo/1872930c-89c5-4618-a561-b5912e92b373';
          }
        }}
      ></div>
    </Page>
  );

}
export default ChooseScalesSmhcTaping;
