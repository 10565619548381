import Welcome from './pages/welcome/welcome.js';
import ScanBarCode from './pages/scanBarCode/scanBarCode.js';
import childCareWelcome from './pages/childCareWelcome/childCareWelcome.js';
import SmhcWelcome from './pages/smhcWelcome/smhcWelcome.js';
import RJWelcome from './pages/organizationLogin/organizationLogin.js';
import LoginWithPatientIdentifier from './pages/loginWithPatientIdentifier/loginWithPatientIdentifier';
import LoginWithIdentityId from './pages/loginWithIdentityId/loginWithIdentityId';
import LoginWithRicn from './pages/loginWithIdentityId/LoginWithRicn';
import LoginWithCertificate from './pages/loginWithCertificate/loginWithCertificate';
import Option from './pages/option/option.js';
import Chat from './pages/chat/chat.js';
import ChatForHis from './pages/chat/chat_shufu_his.js';
import ChooseScale from './pages/chooseScale/chooseScale';
import ChooseScaleSmhcTaping from './pages/chooseScale/chooseScaleSmhcTaping';
import Landing from './pages/landing/landing';
import ScaleIntroduction from './pages/scaleIntroduction/scaleIntroduction';
import ScaleIntroductionWithReport from './pages/scaleIntroductionWithReport/scaleIntroductionWithReport';
import HistoryReportForSix from './pages/scaleIntroductionWithReport/historyReport/historyReport';
import UserLanding from './pages/userLanding/userLanding';
import HistoryReport from './pages/historyReport/historyReport';
import LessonIntroduction from './pages/lessonIntroduction/lessonIntroduction';
import MedicalRecordList from './pages/medicalRecordList/medicalRecordList';
import MedicalRecordListXuyafang from './pages/medicalRecordList/medicalRecordList_xuyafang';
import Report from './pages/report/report.js';
import Questionnaire from './pages/followUP/questionnaire';
import Register from './pages/followUP/register';
import SuccessfulRegister from './pages/followUP/successfulRegister';
import SmhcScaleIntroduction from './pages/smhcScaleIntroduction/smhcScaleIntroduction';
import SmchWeclomeForChooseForm from './pages/smchWeclomeForChooseForm/smchWeclomeForChooseForm.js';
import loginWithCertificateShandong from './pages/loginWithCertificate_shandong/loginWithCertificate_shandong';
import wechatQrList from './pages/loginWithCertificate_shandong/wechatQrList';
import YiyaoLogin from './pages/yiyaoLogin'
const routes = [
  {
    path: '/',
    component: Welcome,
    name: 'Welcome',
    keepAlive: true
  },
  {
    path: '/scanBarCode',
    component: ScanBarCode,
    name: 'ScanBarCode',
    keepAlive: true
  },
  {
    path: '/loginWithPatientIdentifier',
    component: LoginWithPatientIdentifier,
    name: 'LoginWithPatientIdentifier',
    keepAlive: true
  },
  {
    path: '/loginWithIdentityId',
    component: LoginWithIdentityId,
    name: 'LoginWithIdentityId',
    keepAlive: true
  },
  {
    path: '/loginWithRicn',
    component: LoginWithRicn,
    name: 'LoginWithIdentityId',
    keepAlive: true
  },
  {
    path: '/loginWithCertificateShandong',
    component: loginWithCertificateShandong,
    name: 'loginWithCertificateShandong',
    keepAlive: true,
  },
  {
    path: '/wechatQrList',
    component: wechatQrList,
    name: 'wechatQrList',
    keepAlive: true,
  },
  {
    path: '/loginWithCertificate',
    component: LoginWithCertificate,
    name: 'loginWithCertificate',
    keepAlive: true
  },
  {
    path: '/childCareWelcome/',
    component: childCareWelcome,
    name: 'childCareWelcome',
    keepAlive: true
  },
  {
    path: '/smhcWelcome/',
    component: SmhcWelcome,
    name: 'SmhcWelcome',
    keepAlive: true
  },
  {
    path: '/organizationLogin/',
    component: RJWelcome,
    name: 'organizationLogin',
    keepAlive: true
  },
  {
    path: '/landing/',
    component: Landing,
    name: 'Landing'
  },
  {
    path: '/userLanding/',
    component: UserLanding,
    name: 'UserLanding'
  },
  {
    path: '/chooseScale/',
    component: ChooseScale,
    name: 'ChooseScale'
  },
  {
    path: '/chooseScaleSmhcTaping/',
    component: ChooseScaleSmhcTaping,
    name: 'ChooseScaleSmhcTaping'
  },
  {
    path: '/welcome/',
    component: Welcome,
    name: 'Welcome',
    keepAlive: true
  },
  {
    path: '/option/',
    component: Option,
    keepAlive: true,
    name: 'Option'
  },
  {
    path: '/chat/',
    component: Chat,
    keepAlive: true,
    name: 'Chat'
  },
  {
    path: '/chat-for-his/',
    component: ChatForHis,
    keepAlive: true,
    name: 'ChatForHis',
    options: {
      history: true,
    },
  },
  {
    path: '/scaleIntroduction/',
    component: ScaleIntroduction,
    name: 'ScaleIntroduction'
  },
  {
    path: '/scaleIntroductionWithReport/',
    component: ScaleIntroductionWithReport,
    name: 'ScaleIntroductionWithReport'
  },
  // 精总自选量表登录入口
  {
    path: '/smchWeclomeForChooseForm/',
    component: SmchWeclomeForChooseForm,
    name: 'smchWeclomeForChooseForm',
    keepAlive: true
  },
  // 精总新增介绍页入口，可自定义选择量表
  {
    path: '/smhcScaleIntroduction',
    component: SmhcScaleIntroduction,
    name: 'SmhcScaleIntroduction'
  },
  // 六院历史报告
  {
    path: '/scaleIntroductionWithReport/historyReport/',
    component: HistoryReportForSix,
    name: 'HistoryReportForSix'
  },
  {
    path: '/historyReport/',
    component: HistoryReport,
    name: 'HistoryReport'
  },
  {
    path: '/lessonIntroduction',
    component: LessonIntroduction,
    name: 'LessonIntroduction'
  },
  {
    path: '/medicalRecordList',
    component: MedicalRecordList,
    name: 'MedicalRecordList',
  },
  {
    path: '/medicalRecordList-xuyafang',
    component: MedicalRecordListXuyafang,
    name: 'MedicalRecordListXuyafang',
  },
  {
    path: '/report/:reportId',
    component: Report,
    name: 'Report'
  },
  {
    path: '/follow-up/questionnaire',
    component: Questionnaire,
    name: 'Questionnaire',
  },
  {
    path: '/follow-up/register',
    component: Register,
    name: 'Register',
  },
  {
    path: '/follow-up/successfulRegister',
    component: SuccessfulRegister,
    name: 'SuccessfulRegister',
  },
  {
    path: '/yiyaoLogin',
    component: YiyaoLogin,
    name: 'yiyaoLogin',
    keepAlive: true
  }
];

routes.forEach(route => {
  route.on = {
    pageBeforeIn: function (event, page) {
      // do something before page gets into the view
    },
    pageAfterIn: function (event, page) {
      try {
        // do something after page gets into the view
        console.log('page after in', route.name);
        if (__bl && __bl.$at !== route.name) {
          __bl.setPage(route.name);
        }
        
      } catch (error) {
        console.error('aliyun arms load failed')  
      }
    },
    pageInit: function (event, page) {
      // do something when page initialized
    },
    pageBeforeRemove: function (event, page) {
      // do something before page gets removed from DOM
    }
  };
});

export default routes;
