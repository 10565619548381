import React, { useEffect, useState, useCallback } from 'react';
import { Message, Popup } from 'framework7-react';
import './MessageBlock.css';
import { getJsonFormById, replyToForm } from '../modules/shufu.js';
import _ from 'lodash';
import { answerBoardEmitter } from '../modules/answerBoardEmitter.js';
import robot from '../images/robot.png';
import SelectBodyPartFormPopup from './SelectBodyPartFormPopup';
import SelectBodyJointFormPopup from './SelectBodyJointFormPopup';
import { submitPatientEvent } from './SubmitPatientEvent.js'

MessageBlockSelectBodyPartForm.defaultProps = {
  messageJson: {},
  isLastMessage: false,
  messageId: Date.now(),
  message: {},
  bodyType: ''
};

export default function MessageBlockSelectBodyPartForm(props) {
  const [model, setModel] = useState(null); // this one stores newly answered result, for rendering result
  const [form, setForm] = useState(null);
  const [formId, setFormId] = useState(null);
  const [popupOpen, setPopupOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);


  useEffect(() => {
    async function getForm(formId, model) {
      try {
        let jsonForm = await getJsonFormById(formId);
        let form = JSON.parse(jsonForm.form);
        setForm(form);
        if (model)
          form.model = model
        if (props.isLastMessage && props.bodyType === 'bodyPart') {
          answerBoardEmitter.emit('set-answer-board', {
            type: 'selectBodyPartPictureForm',
            form: form
          });
        } else if (props.isLastMessage && props.bodyType === 'joint') {
          answerBoardEmitter.emit('set-answer-board', {
            type: 'selectBodyPartPictureFormV2',
            form: form
          });
        }
      } catch (e) {
        // do nothing
      }
    }
    let _form = JSON.parse(props.messageJson._lctext || '{}');
    let partSchema = _form.schema || {};
    let shufuFormId = partSchema.shufuFormId;
    if (shufuFormId) {
      if (formId !== shufuFormId) {
        setFormId(shufuFormId);
        getForm(shufuFormId, _form.model);
      }
    }
  }, [props.isLastMessage, props.messageJson, formId, props.bodyType]);

  const submitFormHandler = useCallback(
    async model => {
      // let model = {
      //   symptoms
      // };

      // console.log(props.messageJson);
      let answerObject = JSON.parse(props.messageJson._lctext || '{}');
      answerObject.model = model;
      // return;

      const text = JSON.stringify(answerObject);

      let body = {
        from: props.message.from,
        to: props.message.to,
        'msg-id': props.message['msg-id'],
        timestamp: new Date(props.message.timestamp).getTime(),
        data: {
          _lctext: text,
          _lcattrs: {
            ...props.messageJson._lcattrs,
            processId: answerObject.interaction.processId, // FIXME workaround here
            businessKey: answerObject.interaction.businessKey // FIXME workaround here
          },
          _lctype: -1 // <-- not sure what's this..., just use -1 by default
        }
      };
      setModel(model);
      setIsSubmitted(true);
      // scroll to the end
      setTimeout(() => {
        var messageListWrapper = document.getElementById(
          'message_list_wrapper'
        );
        if (messageListWrapper) {
          // console.log("scroll to top MessageBlockStaticForm: ", messageListWrapper.scrollTop);
          messageListWrapper.scrollTop = messageListWrapper.scrollHeight;
        }
      }, 500);
      // return;
      const EventParams = {
        formId: _.get(props, ['schema', 'shufuFormId'], false),
        endTime: new Date().getTime(),
        messageId: props.messageId,
        serviceInstanceId: _.get(props, 'messageJson._lcattrs.ssid', '')
      }
      submitPatientEvent(EventParams, 'onReplyToForm');
      try {
        const res = await replyToForm(body);
        if (!res) {
          submitPatientEvent(EventParams, 'onReplyToFormFailed');
        }
      } catch (e) {
        console.log("error: ", e);
      }
    },
    [props]
  );

  // add submit form emitter
  useEffect(() => {
    if (props.isLastMessage) {
      answerBoardEmitter.on('submit', submitFormHandler);
    }

    return () => {
      answerBoardEmitter.removeListener('submit', submitFormHandler);
    };
  }, [submitFormHandler, props.isLastMessage]);

  let _form = JSON.parse(props.messageJson._lctext || '{}');
  const isProcessSuccess = props.messageJson._lcattrs.isProcessSuccess;
  if (_form.model && !model) {
    setModel(_form.model);
  }


  let askMessage = (
    <Message
      key={`${props.messageId}_ask`}
      className="shufu-text-message-bubble"
      style={{ marginBottom: 5, marginTop: 5 }}
      type="received"
      avatar={robot}
      // textHeader={'bbb'}
      text={_form.schema && _form.schema.shufuFormSubject_zh}
    ></Message>
  );

  let answerMessage =
    isProcessSuccess || isSubmitted ? (
      <Message
        key={`${props.messageId}_answer`}
        className="shufu-text-message-bubble"
        style={{ marginBottom: 5, marginTop: 5 }}
        type="sent"
        onClick={() => {
          setPopupOpen(true);
        }}
      >
        <span style={{ borderBottom: '1px solid white' }}>点击查看作答</span>
        <Popup
          className="static-form-popup popup-pc-style"
          opened={popupOpen}
          onPopupClosed={() => {
            setPopupOpen(false);
          }}
        >
          {
            props.bodyType !== 'joint' ?
              <SelectBodyPartFormPopup
                schema={form && form.schema || {}}
                model={model}
                submitHandler={null}
                canSubmit={false}
              ></SelectBodyPartFormPopup>
              : <SelectBodyJointFormPopup
                schema={form && form.schema || {}}
                model={model}
                propsData={props}
                submitHandler={null}
                canSubmit={false}
              ></SelectBodyJointFormPopup>
          }
        </Popup>
      </Message>
    ) : null;


  return [askMessage, answerMessage]
}