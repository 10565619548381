import React, { useState, useCallback, useEffect } from 'react';
import SaveReportModal from './saveReportModal';
import shareIcon from '../../images/share-alt-solid.svg';
import reportIcon from '../../images/Contacts_full@3x.png';
import './report.css';
const SaveReportParagraph = function(props) {
  const [showSaveModal, setShowSaveModal] = useState(false);

  function renderSaveModal() {
    if (!showSaveModal) {
      return null;
    }

    return (
      <div
        className="modal-background"
        onClick={event => {
          if (
            event.target.className &&
            event.target.className.match(/modal-background/)
          ) {
            setShowSaveModal(false);
          }
          console.log(event.target.className);
        }}
        style={{
          display: 'flex'
        }}
      >
        <SaveReportModal report={props.report} />
        <div
          style={{ marginTop: 30, color: 'white' }}
          className="element-animation"
        >
          ⬆️ 长按保存报告图片
        </div>
      </div>
    );
  }

  return (
    <div className="report-section" style={{}}>
      <div
        style={{ display: 'flex', justifyContent: 'space-between' }}
        onClick={() => {
          setShowSaveModal(true);
        }}
      >
        <img
          className="share-chart-icon"
          src={reportIcon}
          height="30"
          width="35"
        />
        <div className="share-chart-text-container">
          <div className="share-chart-section-title">保存报告内容</div>
          <div className="share-chart-section-subtitle">
            MENTAL HEALTH REPORT
          </div>
        </div>
        <Icon f7="cloud_download" size="38px" style={{ color: '#bbb' }}></Icon>

        {/* <img className="share-icon" src={shareIcon} height="25" /> */}
      </div>
      {renderSaveModal()}
    </div>
  );
};
SaveReportParagraph.defaultProps = {
  report: {
    name: ''
  }
};
export default SaveReportParagraph;
