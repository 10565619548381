import React, { Component } from "react";
import Dropzone from "../dropzone/Dropzone";
import "./Upload.css";
import Progress from "../progress/Progress";
import { getUploadImageUrl } from "../../../modules/shufu.js";
import imageCompression from 'browser-image-compression';
import { Icon } from 'framework7-react';


class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      imageUrls: [],
      maxUploadNumber: props.maxUploadNumber || false, // 设置上传最大数量，可以不设置
      uploading: false,
      uploadProgress: {},
      successfullUploaded: false
    };

    // 判断是否有需要通过上传接口上传的文件
    setTimeout(() => {
      if (props.uploadFile) {
        this.onFilesAdded(props.uploadFile)
      }
    }, 500);

    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
    this.compressAndUploadFile = this.compressAndUploadFile.bind(this);
    this.renderActions = this.renderActions.bind(this);
  }

  async onFilesAdded(files) {
    this.setState(prevState => {
      return {
        files: prevState.files.concat(files)
      }
    }
    );
    const promises = [];
    files.forEach(file => {

      promises.push(this.compressAndUploadFile(file));
    });
    try {
      const imageUrls = await Promise.all(promises);
      const updatedImageUrls = this.state.imageUrls.concat(imageUrls)
      this.props.onImageUrlsChange && this.props.onImageUrlsChange(updatedImageUrls);
      this.setState({ imageUrls: updatedImageUrls })
    } catch (e) {
      console.error("uploading files error");
    }
  }

  async compressAndUploadFile(file) {
    const fileSizeInMB = file.size / 1024 / 1024;
    console.log(`originalFile size ${fileSizeInMB} MB`);
    let fileToUpload = file;
    try {
      if (fileSizeInMB > 0.5) {
        console.log('originalFile instanceof Blob', file instanceof Blob); // true
        const compressionOptions = {
          maxSizeMB: 0.5
        }
        const compressedFile = await imageCompression(file, compressionOptions);
        console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
        console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
        fileToUpload = compressedFile;
      }
      return await this.sendRequest(fileToUpload);
    } catch (err) {
      console.err(err);
    }
  }

  async uploadFiles() {
    this.setState({ uploadProgress: {}, uploading: true });
    const promises = [];
    this.state.files.forEach(file => {
      promises.push(this.compressAndUploadFile(file));
    });
    try {
      await Promise.all(promises);

      this.setState({ successfullUploaded: true, uploading: false });
    } catch (e) {
      // Not Production ready! Do some error handling here instead...
      this.setState({ successfullUploaded: true, uploading: false });
    }
  }

  sendRequest(file) {
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();

      req.upload.addEventListener("progress", event => {
        if (event.lengthComputable) {
          const copy = { ...this.state.uploadProgress };
          copy[file.name] = {
            state: "pending",
            percentage: Math.max((event.loaded / event.total) * 100 - 10, 0)
          };
          this.setState({ uploadProgress: copy });
        }
      });

      req.onload = (event) => {
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = { state: "done", percentage: 100 };
        this.setState({ uploadProgress: copy });
        console.log(event.target);
        console.log(JSON.stringify(req));
        resolve(JSON.parse(req.response).url);
      }

      req.upload.addEventListener("error", event => {
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = { state: "error", percentage: 0 };
        this.setState({ uploadProgress: copy });
        reject(req.response);
      });

      const formData = new FormData();
      formData.append("image", file, file.name);

      req.open("POST", getUploadImageUrl());
      req.send(formData);
    });
  }

  renderProgress(file, index) {
    const uploadProgress = this.state.uploadProgress[file.name];
    const isUploaded = uploadProgress && uploadProgress.state === "done";
    // if (this.state.uploading || this.state.successfullUploaded) {
    return (
      <div style={{ margin: 10, position: 'relative' }} key={index}>
        <div
          style={{ position: 'absolute', top: '-10px', right: '-10px', zIndex: 1 }}
          onClick={() => {
            this.setState((preState) => {
              preState.files.splice(index, 1);
              preState.imageUrls.splice(index, 1);
              this.props.onImageUrlsChange(preState.imageUrls);
              return preState
            })
          }
          }
        >
          <Icon
            f7="multiply_circle_fill"
            size={20}
            style={{ color: '#e20000' }}
          ></Icon>
        </div>
        {!isUploaded ? <Progress progress={uploadProgress ? uploadProgress.percentage : 0} /> : null}
        <img style={{ opacity: uploadProgress && uploadProgress.state === "done" ? 1 : 0.5 }} src={URL.createObjectURL(file)} height={100} width={100} />
      </div>
    );
  }

  renderActions() {
    if (this.state.successfullUploaded) {
      return (
        <button
          onClick={() =>
            this.setState({ files: [], successfullUploaded: false })
          }
        >
          Clear
        </button>
      );
    } else {
      return (
        <button
          disabled={this.state.files.length < 0 || this.state.uploading}
          onClick={this.uploadFiles}
        >
          Upload
        </button>
      );
    }
  }

  renderUploadedImage(imageUrl, index) {
    return <img key={index} style={{ margin: 5 }} src={imageUrl} height={100} width={100} />
  }

  render() {
    return (
      <div className="Upload">
        <div className="Content">
          {this.props.disabled && this.props.imageUrls ? this.props.imageUrls.map((imageUrl, index) => this.renderUploadedImage(imageUrl, index)) : null}
          {!this.props.disabled && this.state.files.map((file, index) => this.renderProgress(file, index))}
          {(!this.props.disabled && (!this.state.maxUploadNumber || this.state.imageUrls.length < this.state.maxUploadNumber)) ? <Dropzone
            onFilesAdded={this.onFilesAdded}
          // disabled={this.state.uploading || this.state.successfullUploaded}
          /> : null}
        </div>
      </div>
    );
  }
}

export default Upload;
