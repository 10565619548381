import { getOrganizationId } from './localStorage';

const allOrganizationConfig = {
  shenwanClinic_psychiatry: {
    backbone: {
      url:
        'https://h5.siuvo.com.cn/#!/form/2d90a547-507b-4bde-927f-d06eaa0296a2?channel=shengWanChannel1'
    },
    sleep: {
      sceneId: '5d08cdb142b64b7'
    },
    healthy: {
      sceneId: '711b457ebc494bd'
    },
    burnout: {
      sceneId: '25bc95b62b1645d'
    },
    pressure: {
      sceneId: 'd1255612eae1468'
    },
    marriage: {
      sceneId: '94641924f6224d9'
    },
    attention: {
      sceneId: 'cfbf6df48b51445'
    },
    coronavirus: {
      sceneId: 'c8091f5c886749c'
    },
    coronavirus_fever: {
      sceneId: '66dc61e8ea6f475'
    }
  },
  pingAnClinic_psychiatry: {
    backbone: {
      url:
        'https://h5.siuvo.com.cn/#!/form/2d90a547-507b-4bde-927f-d06eaa0296a2?channel=pingAnChannel6'
    },
    sleep: {
      sceneId: '5d08cdb142b64b7_pingan'
    },
    healthy: {
      sceneId: '711b457ebc494bd_pingan'
    },
    burnout: {
      sceneId: '25bc95b62b1645d_pingan'
    },
    pressure: {
      sceneId: 'd1255612eae1468_pingan'
    },
    marriage: {
      sceneId: '94641924f6224d9_pingan'
    },
    attention: {
      sceneId: 'cfbf6df48b51445_pingan'
    },
    coronavirus: {
      sceneId: 'c8091f5c886749c'
    },
    coronavirus_fever: {
      sceneId: '66dc61e8ea6f475s'
    }
  }
};

const getOrganizationConfig = () => {
  const organizationId = getOrganizationId();
  return (
    allOrganizationConfig[organizationId] ||
    allOrganizationConfig.shenwanClinic_psychiatry
  );
};

export default getOrganizationConfig;
