import React, { useState, useEffect } from 'react';
import moment from 'moment';
import './DateInput.css';

import { Input } from 'framework7-react';
import i18n from '../i18n';


DateInput.defaultProps = {
    disabled: false,
    value: null,
    style: {},
    onChange: function () {
        console.warn('DateInput onChange not implemented');
    }
};
export default function DateInput(props) {
    const { onChange, style, value, disabled } = props;

    let inputYear = '';
    let inputMonth = '';
    let inputDay = '';
    if (value != null) {
        const dateMoment = moment(value);
        inputYear = dateMoment.year().toString();
        inputMonth = (dateMoment.month() + 1).toString();
        inputDay = dateMoment.date().toString();

    }

    const [date, setDate] = useState(undefined);
    const [isValid, setIsValid] = useState(true);
    const [year, setYear] = useState(inputYear);
    const [month, setMonth] = useState(inputMonth);
    const [day, setDay] = useState(inputDay);



    useEffect(() => {
        if (year.length === 0 && month.length === 0 && day.length === 0) {
            setIsValid(true);
            if (date != undefined) {
                setDate(undefined);
                onChange(undefined);
            }
        } else if (year.length === 4 &&
            (parseInt(month) >= 1 && parseInt(month) <= 12) &&
            (parseInt(day) >= 1 && parseInt(day) <= 31)) {
            const dateStr = year + '-' + ('0' + month).slice(-2) + '-' + ('0' + day).slice(-2);
            const dateTimeStamp = moment(dateStr).valueOf();
            if (dateTimeStamp !== date) {
                // console.log("dateinfo: ", dateStr, dateTimeStamp, date);
                setIsValid(true);
                setDate(dateTimeStamp);
                onChange(dateTimeStamp);
            }
        } else {
            setIsValid(false);
            if (date != undefined) {
                setDate(undefined);
                onChange(undefined);
            }
            return;
        }
    }, [year, month, day, onChange, date])

    // console.log("isValid: ", isValid);
    return (
        <div className="answer-date-input-wrapper" style={style}>
            <div className={[!isValid ? 'answer-date-inputs-div-invalid' : '', "answer-date-inputs-div"].join(' ')}>
                <Input
                    id="datepickerYear"
                    type="number"
                    disabled={disabled}
                    value={year}
                    pattern="\d*"
                    className="answer-board-inline-form-input"
                    placeholder={i18n.t('date_year')}
                    onChange={(e) => {
                        if (!e.target.value) setYear(undefined);
                        setYear(e.target.value)
                    }}
                />
            </div>
            <div className="answer-date-inputs-text">
                /
            </div>
            <div className={[!isValid ? 'answer-date-inputs-div-invalid' : '', "answer-date-inputs-div"].join(' ')}>
                <Input
                    id="datepickerMonth"
                    type="number"
                    disabled={disabled}
                    value={month}
                    pattern="\d*"
                    className="answer-board-inline-form-input"
                    placeholder={i18n.t('date_month')}
                    onChange={(e) => {
                        if (!e.target.value) setMonth(undefined);
                        setMonth(e.target.value)
                    }}
                />
            </div>
            <div className="answer-date-inputs-text">
                /
            </div>
            <div className={[!isValid ? 'answer-date-inputs-div-invalid' : '', "answer-date-inputs-div"].join(' ')}>
                <Input
                    id="datepickerDate"
                    type="number"
                    disabled={disabled}
                    pattern="\d*"
                    value={day}
                    className="answer-board-inline-form-input"
                    placeholder={i18n.t('date_date')}
                    onChange={(e) => {
                        if (!e.target.value) setDay(undefined);
                        setDay(e.target.value)
                    }}
                />
            </div>
        </div>
    );
}
