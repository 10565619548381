import React, { useState, useCallback, useEffect } from 'react';
import {
  Page,
  Button,
  Radio,
  Input
} from 'framework7-react';

import {
  getSceneDataBySceneIdAndTimestamp,
  createUserAndStartServiceInternal
} from '../../modules/shufu';

import getParameterByName from '../../utils/getParameterByName';

import robot_smile from '../../images/robot_smile.png';
import shufu_logo from '../../images/shufu-logo.png';

import {
  setServiceInstanceId,
} from '../../utils/localStorage.js';

import '../welcome/welcome.css';
import _ from 'lodash';

import { useTranslation } from 'react-i18next';
import { initUser } from '../welcome/welcome';

const YiyaoLogin = function (props) {

  const [title, setTitle] = useState(document.title || getParameterByName('title') || '');
  const [isValid, setIsValid] = useState(true);
  const { t, i18n } = useTranslation();
  const [patientIdentifierStr, setPatientIdentifierStr] = useState(''); // 患者学号
  const [name, setName] = useState(''); // 患者姓名

  const sceneId = getParameterByName('sceneId');
  const qrTimestamp = getParameterByName('qrTimestamp');
  const toPage = getParameterByName('toPage');
  let chatUrl = '/chat/';

  if (toPage) {
    chatUrl = `/${toPage}/`;
  }

  if (typeof sceneId !== 'undefined' && sceneId !== null && sceneId !== '') {
    chatUrl = `${chatUrl}?sceneId=${sceneId}&title=${title}&noRestart=true`;
  } else if (typeof title !== 'undefined' && title !== null && title !== '') {
    chatUrl = `${chatUrl}?title=${title}&noRestart=true`;
  }
  console.log(chatUrl);

  const [organizationId] = useState(getParameterByName('organizationId'));

  useEffect(() => {
    async function checkSceneId() {
      if (!sceneId) {
        return;
      }
      const sceneResponse = await getSceneDataBySceneIdAndTimestamp(
        sceneId,
        qrTimestamp
      );
      if (sceneResponse.error) {
        setIsValid(false);
      }
    }
    checkSceneId();
  }, [sceneId, qrTimestamp]);

  useEffect(() => {
    async function tryGetSceneData() {
      if (!sceneId) {
        return;
      }
      const sceneResponse = await getSceneDataBySceneIdAndTimestamp(
        sceneId,
        qrTimestamp
      );
      if (sceneResponse.status === 'expired') {
        setIsValid(false);
      }
      const sceneResult = sceneResponse.wechatQrInfo;
      if (_.get(sceneResult, ['sceneData', 'title'], '') === '儿童保健') {
        props.f7router.navigate('/childCareWelcome/', {
          reloadCurrent: true
        });
      }
    }
    tryGetSceneData();
  }, [sceneId, qrTimestamp, props.f7router]);

  // 获取患者信息后，创建用户
  const submitHandle = useCallback(async ({ patientIdentifier, userId }) => {
    window.$$f7.preloader.show();
    try {
      const data = await createUserAndStartServiceInternal({
        organizationId,
        sceneId,
        patientIdentifier,
        patientName: name,
        userId
      })
      window.$$f7.preloader.hide();
      if (data.status === 'failed') {
        window.$$f7.dialog.alert(data.message, false);
      } else {
        let { userId, token, serviceInstanceId } = data;
        setServiceInstanceId(serviceInstanceId);
        await initUser(userId, organizationId, token);
        setTimeout(() => {
          props.f7router.navigate(chatUrl, {
            reloadCurrent: true
          });
        }, 200);
      }
    } catch (e) {
      window.$$f7.preloader.hide();
      window.$$f7.dialog.alert('服务器异常，请稍后再试', false);
    }
  }, [chatUrl, organizationId, props.f7router, name, sceneId])

  if (!isValid) {
    return (
      <Page noToolbar noNavbar noSwipeback loginScreen>
        <div className="welcome-container">
          <div className="welcome-title">
            {title || '舒辅智慧'}
            <br />
            对不起，测试已过期！
          </div>
        </div>
      </Page>
    );
  }

  const renderRicnInput = () => {
    return (
      <div>
        <div style={{ fontSize: '18px', margin: '20px' }}>
          请输入您的姓名
        </div>
        <div style={{ display: 'flex', margin: '0 20px', justifyContent: 'center', alignItems: 'center' }}>
          <div
            style={{
              flex: 1,
              height: 35,
              borderRadius: 10,
              border: `solid 1px #2979ff`,
              backgroundColor: '#deeaff',
              display: 'flex',
              alignItems: 'center'
            }}>
            <Input
              style={{ flex: '1', marginLeft: 10, marginRight: 10 }}
              type="text"
              value={name}
              onChange={event => {
                const input = event.target.value;
                setName(input);
              }}
              placeholder="请输入您的姓名"
            />
          </div>
        </div>
        <div style={{ fontSize: '18px', margin: '20px' }}>请输入您的学号</div>
        <div style={{ display: 'flex', margin: '0 20px', justifyContent: 'center', alignItems: 'center' }}>
          <div
            style={{
              flex: 1,
              height: 35,
              borderRadius: 10,
              border: 'solid 1px #2979ff',
              backgroundColor: '#deeaff',
              display: 'flex',
              alignItems: 'center'
            }}>
            <Input
              style={{ flex: '1', marginLeft: 10, marginRight: 10 }}
              type="text"
              value={patientIdentifierStr}
              onChange={event => {
                const input = event.target.value;
                setPatientIdentifierStr(input);
              }}
              placeholder="请输入您的学号"
            />
          </div>
        </div>
      </div>
    );
  }

  const isInputsValid = () => {
    if (!name) return false;
    if (!patientIdentifierStr) return false;
    return true;
  }
  const renderSubmitButton = () => {
    return <Button
      raised
      fill
      large
      style={{
        backgroundColor: isInputsValid() ? '#2979ff' : '#c2c2c2',
      }}
      className='welcome-login-button'
      onClick={() => {
        if (!isInputsValid()) return;
        submitHandle({ patientIdentifier: patientIdentifierStr })
      }}
    >确定
  </Button>
  }

  return (
    <Page noToolbar noNavbar noSwipeback loginScreen style={{ marginTop: '-15%' }} className='welcome-container-body'>
      <img className='welcome-shufu-logo' src={shufu_logo}></img>
      <div className='welcome-words'>
        <span className='welcome-words-text'>欢迎！</span>
        <img className='welcome-words-image' src={robot_smile}></img>
      </div>
      {renderRicnInput()}
      {renderSubmitButton()}
      <div style={{ margin: 20, color: "crimson", fontSize: 15, fontWeight: "bold" }}>
        请您谨慎填写量表，分享/多次随意测评导致报告结果错误，由您方承担全部责任）
      </div>
      <div style={{ fontSize: '14px', color: '#8c8c8c', position: 'fixed', textAlign: 'center', width: '100%', bottom: 40 }}>
        Powered by Siuvo.Inc
      </div>
    </Page>
  );
};
export default YiyaoLogin;
