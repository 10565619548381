import React, { useState, useEffect, useCallback } from 'react';

import { List, ListInput, Button, Radio } from 'framework7-react';
import getDeviceVersion from './DeviceVersion.js'
import Upload from './FileUpload/upload/Upload.js';
import _ from 'lodash';
import './ReportBugs.css';
import {
  getCurrentUserId,
  getServiceInstanceId,
  getServiceItemId
} from '../utils/localStorage.js';
import { createErrorReport } from '../modules/shufu.js';
import i18n from '../i18n.js';

export default function ReportBugs(props) {
  const [bugInfo, setBugInfo] = useState(null);
  const [choiceBugType, setChoiceBugType] = useState('');
  const bugTypeDivs = [[
    { flex: 3, name: '加载不出题目' },
    { flex: 2, name: '进度丢失' }
  ], [
    { flex: 3, name: '题目无法继续' },
    { flex: 2, name: '无法输入' }
  ], [
    { flex: 3, name: '无法勾选' },
    { flex: 3, name: '无法提交' },
    { flex: 2, name: '其他' }
  ]];

  useEffect(() => {
    let bugInfo = {
      userId: getCurrentUserId(),
      serviceInstanceId: getServiceInstanceId(),
      serviceItemId: getServiceItemId(),
      timestamp: new Date().getTime(),
      phoneBrand: getDeviceVersion('phoneBrand'),
      osVersion: getDeviceVersion('osVersion'),
      wechatVersion: getDeviceVersion('wechatVersion'),
      browser: getDeviceVersion('browser'),
      bugDescription: '',
      screenShot: '',
      userAgentString: getDeviceVersion('userAgentString')
    }
    setBugInfo(bugInfo);
  }, []);

  // 提交bug
  const submitErrorReport = useCallback(
    async () => {
      try {
        if (!bugInfo.bugDescription) {
          window.$$f7.dialog.alert(i18n.t('please_input_bug_description'), false);
        } else if (!bugInfo.screenShot) {
          window.$$f7.dialog.alert(i18n.t('please_upload_image'), false);
        } else {
          let result = await createErrorReport(bugInfo);
          console.log(result);
          window.$$f7.dialog.alert(i18n.t('uploadSuccess'), false, { cssClass: 'dialog-text-left' }, () => {
            window.location.reload();
          });
          props.updatePopupStatus(false);
        }
      } catch (err) {
        console.log(err);
      }
    }, [bugInfo, props]
  );

  // 切换显示bug
  function changeBugType(bugTypevalue) {
    setChoiceBugType(bugTypevalue);
    const tmpBugInfo = { ...bugInfo };
    tmpBugInfo.bugDescription = bugTypevalue;
    setBugInfo(tmpBugInfo);
  }

  return (
    <div style={{ wordBreak: 'break-all', padding: '0 20px 20px 20px', boxSizing: 'border-box' }}>
      <List>
        <div style={{ fontWeight: 'bold' }}>选择您遇到的问题：</div>
        <div>
          {bugTypeDivs.map((bugTypeDiv, bugTypeDivIndex) => {
            return <div style={{ display: 'flex' }} key={bugTypeDivIndex}>
              {bugTypeDiv.map((bugType, bugTypeIndex) => {
                return <div className="bug-report-checkbox" key={bugTypeIndex} style={{ flex: bugType.flex }}>
                  <Radio name="demo-radio-inline" value={bugType.name} onChange={e => {
                    changeBugType(e.target.value);
                  }}>{bugType.name}</Radio>
                </div>
              })}
            </div>
          })}
        </div>
        {choiceBugType === '其他' ? (<div>
          <ListInput
            className="horizontal-no-padding"
            type="textarea"
            placeholder={i18n.t('please_input_bug_description')}
            defaultValue={_.get(bugInfo, 'bugDescription', '')}
            onInput={e => {
              setBugInfo({
                ...bugInfo,
                bugDescription: e.target.value
              });
            }}
          ></ListInput>
        </div>) : null}
      </List>
      <div style={{ padding: '0 5px' }}>{i18n.t('please_upload_image')}:</div>
      <Upload
        onImageUrlsChange={imageUrls => {
          setBugInfo({
            ...bugInfo,
            screenShot: _.get(imageUrls, '0', '')
          });
        }}
        maxUploadNumber={1}
        uploadFile={props.bugReportFile ? [props.bugReportFile] : null}
      />
      <Button
        raised
        fill
        large
        className='welcome-login-button'
        style={{ marginTop: 50 }}
        onClick={() => {
          submitErrorReport();
        }}
      >
        {i18n.t('submit')}
      </Button>
    </div>
  );
}
