import { createPatientEvent } from '../modules/shufu.js';
import CONFIG from 'GlobalConfigFile';
import { getCurrentUserId, getSessionId, getSceneId, getDeviceId } from '../utils/localStorage.js';

export function submitPatientEvent(answerData, type) {
  const closePatientEvent = CONFIG.closePatientEvent; // 配置是否关闭数据打点
  console.log('****closePatientEvent*******', closePatientEvent)
  if (!closePatientEvent) {
    const formId = answerData.formId;
    const parsedFormId = formId ? formId.replace(/\.\d{13}$/, '') : formId;
    const params = {
      name: type + (parsedFormId ? ('/' + parsedFormId) : ''),
      source: 'browser',
      userId: getCurrentUserId(),
      deviceId: getDeviceId(),
      sessionId: getSessionId(),
      occurOn: new Date().getTime(),
      env: CONFIG.server,
      userAgent: navigator.userAgent,
      props: {
        sceneId: getSceneId(),
        ...answerData
      },
    }
    if (type === 'onAnswerQuestion' && answerData.path) params.name = params.name + '/' + answerData.path
    createPatientEvent(params);
  }
}