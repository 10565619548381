import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import getParameterByName from './utils/getParameterByName';
// the translations
// (tip move them in a JSON file and import them)
const resources = {
  zh: {
    translation: {
      shufu_zhihui: '舒辅智慧',
      click_to_change_answer: '修改',
      submit: '确认',
      submit_text: '确认后无法修改该部分答案。',
      please_wait: '请稍候...',
      please_input: '请输入',
      consent_word: '我已经阅读了',
      consent_name: '知情同意书',
      close: '关闭',
      tips: '提示',
      must_choose_consent: '请阅读并勾选知情同意书',
      start_test: '开始测试',
      send: '发送',
      skip: '跳过',
      skipConfirm: '请确认是否跳过该题',
      skipped: '已跳过',
      server_busy: '对不起，服务器正忙。请刷新页面或稍后再试。',
      incorrect_code : '验证码错误',
      process_terminated: '对不起，您的测评链接已超时，请刷新后重试。',
      ok: '确认',
      cancel: '取消',
      date_error_message: '请输入正确日期！',
      date_year: '年',
      date_month: '月',
      date_date: '日',
      uploadBug: '帮助反馈',
      screenshotFail: '该系统版本不支持自动截图功能，请截图后上传，谢谢！',
      please_input_bug_description: '请选择您遇到的问题',
      please_upload_image: '请上传截图',
      uploadSuccess: '上传成功，谢谢您的宝贵意见，我们将尽快查看并处理！',
      missing_answers: '请回答所有带<span style="color:red">（*）</span>的题目',
      maxlength_error_message: '本部分限输入50字以内'
    }
  },
  de: {
    translation: {
      shufu_zhihui: 'Siuvo',
      click_to_change_answer: 'Antwort bearbeiten',
      submit: 'einreichen',
      submit_text: 'Sie haben alle Fragen in diesem Tei beantwortet.<br>Nach der Bestätigung können die Antworten nicht mehr geändert werden und dann gehen Sie zum nächsten Teil.',
      please_wait: 'Bitte warten..',
      please_input: 'Bitte hier eingeben......',
      consent_word: 'Ich habe diese informierte ',
      consent_name: 'Zustimmung gelesen',
      close: 'schließen',
      tips: 'Tipps',
      must_choose_consent: 'Bitte lesen und ankreuzen Sie das Einverständniserklärung-Formular',
      start_test: 'Weiter',
      send: 'senden',
      skip: 'überspringen',
      skipConfirm: 'Bitte bestätigen Sie, ob Sie die Frage überspringen möchten',
      skipped: 'übersprungen',
      server_busy: 'Entschuldigung, der Server ist derzeit überlastet. Bitte laden Sie die Seite neu oder versuchen es später noch einmal. Vielen Dank!',
      incorrect_code : 'Der Verifizierungscode ist falsch.',
      process_terminated: 'Es tut mir leid, Assessment link beendet wurde. Bitte aktualisieren und neu starten Sie den Dienst.',
      ok: "OK",
      cancel: "Cancel",
      date_error_message: 'Bitte geben Sie das richtige Datum ein！',
      date_year: 'Jahr',
      date_month: 'Monat',
      date_date: 'Datum',
      uploadBug: 'Hilfe Feedback',
      screenshotFail: 'Die Systemversion unterstützt keine automatische Screenshot-Funktion, bitte nach dem Screenshot hochladen, danke',
      please_input_bug_description: 'Bitte wählen Sie das Problem aus',
      please_upload_image: 'Bitte laden Sie den Screenshot',
      uploadSuccess: 'Upload erfolgreich, danke für Ihre wertvollen Kommentare, wir werden prüfen und behandeln so schnell wie möglich',
      missing_answers: 'Bitte beantworten Sie alle Fragen mit<span style="color:red">（*）</span>',
      maxlength_error_message: 'Dieser Abschnitt ist auf maximal 50 Zeichen begrenzt'

    }
  },
  en: {
    translation: {
      shufu_zhihui: 'Siuvo',
      click_to_change_answer: 'Edit answer',
      submit: 'Submit',
      submit_text: 'You have completed this part of the questions.<br>After confirmation, you will not be able to modify them and go to the next step.',
      please_wait: 'Please wait..',
      please_input: 'Please input......',
      consent_word: 'I have read this ',
      consent_name: 'informed consent',
      close: 'Close',
      tips: 'Tips',
      must_choose_consent: 'Please read and tick the informed consent form',
      start_test: 'Start',
      send: 'Send',
      skip: 'Skip',
      skipConfirm: 'Please confirm whether to skip the question',
      skipped: 'Skipped',
      server_busy: 'Sorry the server is busy right now, please refresh the page or try again later.',
      incorrect_code : 'Verification code is incorrect.',
      process_terminated: 'Sorry your session has been terminated, please refresh and restart the service.',
      ok: "OK",
      cancel: "Cancel",
      date_error_message: 'Please enter the correct date！',
      date_year: 'year',
      date_month: 'month',
      date_date: 'date',
      uploadBug: 'Help feedback',
      screenshotFail: 'The system version does not support automatic screenshot function, please upload after screenshot, thank you!',
      please_input_bug_description: 'Please select the problem you encountered',
      please_upload_image: 'Please upload the screenshot',
      uploadSuccess: 'Upload successful, thank you for your valuable comments, we will check and handle as soon as possible!',
      missing_answers: 'Please answer all questions with<span style="color:red">（*）</span>',
      maxlength_error_message: 'max: 50 characters'
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: getParameterByName('lang') || 'zh',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
