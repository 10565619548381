import React, { useState, useCallback, useEffect } from 'react';
import {
  Page,
  Button,
  Radio,
  Input
} from 'framework7-react';

import { initShuaiCloud as initShuaiCloudFromClientInstance } from '../../modules/shuaicloud/clientInstance';
import { isIphonex } from '../../App.js';

import {
  wechatLoginWithWebAuthCode,
  getSceneDataBySceneIdAndTimestamp,
  createUserAndStartServiceInternal
} from '../../modules/shufu';
import { wxEventEmitter } from '../../modules/wxEventEmitter.js';
import { WX_EVENT } from '../../modules/event.js';

import getParameterByName from '../../utils/getParameterByName';

import robot_smile from '../../images/robot_smile.png';
import shufu_logo from '../../images/shufu-logo.png';

import {
  setCurrentUserId,
  setServiceInstanceId,
  setToken
} from '../../utils/localStorage.js';

import '../welcome/welcome.css';
import _ from 'lodash';

import { useTranslation } from 'react-i18next';
import { initUser } from '../welcome/welcome';
import CellphoneLogin from '../../components/CellphoneLogin';

// import { setUUID } from '../../utils/localStorage.js';
// import  generateUUID from '../../utils/generateUuid.js';

const defaultLoginOptions = {
  displayWardOrRegInput: true,
  displayEncounterIdentifierInput: false,
  displayRicnInput: false,
  displayDefaultButton: false,
  displayCellphoneLogin: false
}


const ScanBarCode = function (props) {
  const [urlWardOrReg] = useState(getParameterByName('wardOrReg'));
  const[ clinicTypeHintText, setClinicTypeHintText] = useState('');
  const [title, setTitle] = useState(document.title || getParameterByName('title') || '');
  const [isValid, setIsValid] = useState(true);
  const { t, i18n } = useTranslation();
  const [visitType, setVisitType] = useState('2'); // visitType: 1.扫码输入  2.手动输入
  const [scanQrAllowed, setScanQrAllowed] = useState(false); // visitType: 1.扫码输入  2.手动输入
  const [encounterIdentifierStr, setEncounterIdentifierStr] = useState(''); // 手动输入绑定的对应的value
  const [ricnStr, setRicnStr] = useState(''); // 手动输入绑定的对应的value
  const [wardOrReg, setWardOrReg] = useState(urlWardOrReg); // 0：门诊 1：住院 3：体检
  const [loginOptions, setLoginOptions] = useState(defaultLoginOptions);

  const {
    displayWardOrRegInput,
    displayEncounterIdentifierInput,
    displayRicnInput,
    displayDefaultButton,
    displayCellphoneLogin
  } = loginOptions;

  const sceneId = getParameterByName('sceneId');
  const qrTimestamp = getParameterByName('qrTimestamp');
  const toPage = getParameterByName('toPage');
  let chatUrl = '/chat/';

  if (toPage) {
    chatUrl = `/${toPage}/`;
  }

  if (typeof sceneId !== 'undefined' && sceneId !== null && sceneId !== '') {
    chatUrl = `${chatUrl}?sceneId=${sceneId}&title=${title}&noRestart=true`;
  } else if (typeof title !== 'undefined' && title !== null && title !== '') {
    chatUrl = `${chatUrl}?title=${title}&noRestart=true`;
  }
  console.log(chatUrl);

  const [organizationId] = useState(getParameterByName('organizationId'));


  const onWXScan = async () => {
    wx.scanQRCode({
      needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
      scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
      success: async res => {
        const result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
        console.warn('sceneId:', sceneId);
        console.warn('scan-result:', result);
        const encounterIdentifierStr = result.split(',')[1];
        submitHandle({ encounterIdentifier: encounterIdentifierStr })
      },
      fail: err => {
        console.log('wx-scanQRCode-err-message-fail:', err)
      }
    });
  }
  const setWxStatusHandle = useCallback(_status => {
    console.log("wxStatusHandle: ", _status);
    if (_status === 'failed') {
      setVisitType(2);
      setScanQrAllowed(false);
    }
    if (_status === 'success') {
      setVisitType(1);
      setScanQrAllowed(true);
    }
  }, [setVisitType, setScanQrAllowed])

  useEffect(() => {
    wxEventEmitter.on(WX_EVENT.WX_SIGNATURE_STATUS, setWxStatusHandle)
  }, [setWxStatusHandle])

  useEffect(() => {
    switch (wardOrReg) {
      case '0': {
        //门诊
        setLoginOptions({
          displayWardOrRegInput: false,
          displayEncounterIdentifierInput: false,
          displayRicnInput: false,
          displayDefaultButton: false,
          displayCellphoneLogin: true
        })
        break;
      }
      case '1': {
        setClinicTypeHintText('请输入您的住院号');
        //住院
        setLoginOptions({
          displayWardOrRegInput: false,
          displayEncounterIdentifierInput: true,
          displayRicnInput: true,
          displayDefaultButton: true,
          displayCellphoneLogin: false
        })
        break;
      }
      case '3': {
        setClinicTypeHintText('请输入您的体检号');
        //体检
        setLoginOptions({
          // displayWardOrRegInput: false,
          // displayEncounterIdentifierInput: false,
          // displayRicnInput: true,
          // displayDefaultButton: true,
          // displayCellphoneLogin: false
          displayWardOrRegInput: false,
          displayEncounterIdentifierInput: true,
          displayRicnInput: true,
          displayDefaultButton: true,
          displayCellphoneLogin: false
        })
        break;
      }
      default: {
        //ask patient to input wardOrReg
        setLoginOptions({
          displayWardOrRegInput: true,
          displayEncounterIdentifierInput: false,
          displayRicnInput: false,
          displayDefaultButton: false,
          displayCellphoneLogin: false
        })
      }
    }
  }, [wardOrReg])


  useEffect(() => {
    const ua = navigator.userAgent.toLowerCase();
    const isWeixin = ua.indexOf('micromessenger') !== -1;
    if (!isWeixin) {
      setVisitType('2');
    }
  }, [chatUrl, organizationId, props.f7router]);

  useEffect(() => {
    async function tryGetSceneData() {
      if (!sceneId) {
        return;
      }
      const sceneResponse = await getSceneDataBySceneIdAndTimestamp(
        sceneId,
        qrTimestamp
      );
      if (sceneResponse.status === 'expired') {
        setIsValid(false);
      }
      const sceneResult = sceneResponse.wechatQrInfo;
      if (_.get(sceneResult, ['sceneData', 'title'], '') === '儿童保健') {
        props.f7router.navigate('/childCareWelcome/', {
          reloadCurrent: true
        });
      }
    }
    tryGetSceneData();
  }, [sceneId, qrTimestamp, props.f7router]);

  // 获取患者信息（手动或者扫码）后，创建用户
  const submitHandle = useCallback(async ({ encounterIdentifier, userId }) => {
    window.$$f7.preloader.show();
    try {
      const data = await createUserAndStartServiceInternal({
        organizationId,
        sceneId,
        encounterIdentifier,
        wardOrReg,
        ricn: ricnStr,
        patientIdentifier: ricnStr,
        userId
      })
      window.$$f7.preloader.hide();
      if (data.status === 'failed') {
        window.$$f7.dialog.alert(data.message, false);
      } else {
        // setUUID(generateUUID());
        let { userId, token, serviceInstanceId } = data;
        setServiceInstanceId(serviceInstanceId);
        await initUser(userId, organizationId, token);
        setTimeout(() => {
          props.f7router.navigate(chatUrl, {
            reloadCurrent: true,
            props: {
              noRestart: true
            }
          });
        }, 200);
      }
    } catch (e) {
      window.$$f7.preloader.hide();
      window.$$f7.dialog.alert('服务器异常，请稍后再试', false);
    }
  }, [chatUrl, organizationId, props.f7router, ricnStr, sceneId, wardOrReg])

  if (!isValid) {
    return (
      <Page noToolbar noNavbar noSwipeback loginScreen>
        <div className="welcome-container">
          <div className="welcome-title">
            {title || '舒辅智慧'}
            <br />
            对不起，测试已过期！
          </div>
        </div>
      </Page>
    );
  }

  const renderWardOrRegInput = () => {
    return <div style={{ justifyContent: 'center' }}>
      <div style={{ fontSize: '18px', margin: '0 0 20px 20px' }}>
        <div>请选择您的就诊类型</div>
        <div style={{ fontSize: 16, paddingTop: 10 }}>
          <span style={{ marginRight: 15 }}><Radio defaultChecked={wardOrReg === '0'} name='visit-type' value='0' onChange={e => setWardOrReg(e.target.value)} />&nbsp;&nbsp;门诊</span>
          <span style={{ marginRight: 15 }}><Radio name='visit-type' value='1' defaultChecked={wardOrReg === '1'} onChange={e => setWardOrReg(e.target.value)} />&nbsp;&nbsp;住院</span>
          <span style={{ marginRight: 15 }}><Radio name='visit-type' value='3' defaultChecked={wardOrReg === '3'} onChange={e => setWardOrReg(e.target.value)} />&nbsp;&nbsp;体检</span>
        </div>
      </div>

    </div>
  }

  const renderEncounterIdentifierInput = () => {
    let placeholder = clinicTypeHintText;
    if (wardOrReg === '1') {
      placeholder += '(7位)'
    }
    const showHintText = wardOrReg === '1' && encounterIdentifierStr && encounterIdentifierStr.length !== 7;
    return visitType === '1' ?
      <div>
        <div style={{ fontSize: '18px', margin: '20px' }}>
          请点击下方的扫一扫，扫描您收费单据上的条形码，开始测试
          </div>
        <Button
          raised
          fill
          large
          style={{ backgroundColor: isRicnValid() ? "#2979FF" : "#c2c2c2" }}
          className='welcome-login-button'
          onClick={() => isRicnValid() ? onWXScan() : null}
        >
          扫一扫
          </Button>
        <div onClick={() => { setVisitType('2') }} style={{ textAlign: 'center', fontSize: '16px' }}>无法扫码？<span style={{ color: '#2979ff' }}>点击手动输入</span></div>
      </div>
      : <div>
        <div style={{ fontSize: '18px', margin: '20px' }}>
          {clinicTypeHintText}，开始测试
          </div>
        <div style={{ display: 'flex', margin: '0 20px', justifyContent: 'center', alignItems: 'center' }}>
          <div
            style={{
              flex: 1,
              height: 35,
              borderRadius: 10,
              border: 'solid 1px #2979ff',
              backgroundColor: '#deeaff',
              display: 'flex',
              alignItems: 'center'
            }}>
            <Input
              style={{ flex: '1', marginLeft: 10, marginRight: 10 }}
              type="text"
              value={encounterIdentifierStr}
              onChange={event => {
                const input = event.target.value;
                const patrn = /^(-)?\d+(\.\d+)?$/;
                // input.length === 0  最后一位可删除
                if ((patrn.test(input) && input.length < 8) || input.length === 0) {
                  setEncounterIdentifierStr(input);
                }
              }}
              placeholder={placeholder}
            />
             {showHintText &&
              <div
                style={{ fontSize: '16px', margin: '20px', color: "maroon" }}
              >
                住院号为7位
            </div>
            }
          </div>
        </div>
        {
          scanQrAllowed &&  <div onClick={() => { setVisitType('1') }} style={{ textAlign: 'center', fontSize: '16px', marginTop: 15 }}>点我&nbsp;<span style={{ color: '#2979ff' }}>扫码输入</span></div>
        }
      </div>

  }

  const isRicnValid = () => {
    if (!ricnStr) return false;
    const patternMainLand = new RegExp(/^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/); // 大陆
    const patternHongkong = /^((\s?[A-Za-z])|([A-Za-z]{2}))\d{6}(\([0−9aA]\)|[0-9aA])$/; // 香港
    const patternTaiwan = /^[a-zA-Z][0-9]{9}$/; // 台湾
    const patternMacao = /^[1|5|7][0-9]{6}\([0-9Aa]\)/; // 澳门
    console.log(patternMainLand.test(ricnStr));
    return patternMainLand.test(ricnStr)
      || patternHongkong.test(ricnStr)
      || patternTaiwan.test(ricnStr)
      || patternMacao.test(ricnStr);
  }

  const renderRicnInput = () => {
    const borderColor = (!ricnStr || isRicnValid()) ? "#2979ff" : "maroon";
    return (
      <div>
        <div style={{ fontSize: '18px', margin: '20px' }}>
          请输入您的身份证号
        </div>
        <div style={{ display: 'flex', margin: '0 20px', justifyContent: 'center', alignItems: 'center' }}>
          <div
            style={{
              flex: 1,
              height: 35,
              borderRadius: 10,
              border: `solid 1px ${borderColor}`,
              backgroundColor: '#deeaff',
              display: 'flex',
              alignItems: 'center'
            }}>
            <Input
              style={{ flex: '1', marginLeft: 10, marginRight: 10 }}
              type="text"
              value={ricnStr}
              onChange={event => {
                const input = event.target.value;
                setRicnStr(input);
              }}
              placeholder="请输入您的身份证号"
            />
            {(ricnStr && !isRicnValid()) ?
              <div
                style={{ fontSize: '16px', margin: '20px', color: "maroon" }}
              >
                身份证号有误
            </div> : null
            }
          </div>
        </div>
      </div>
    );
  }

  const isInputsValid = () => {
    if (!wardOrReg) return false;
    if (!ricnStr) return false;
    if (!isRicnValid()) return false;
    if (wardOrReg === '1' && encounterIdentifierStr.length !== 7) return false;

    return true;
  }

  const renderSubmitButton = () => {
    return <Button
      raised
      fill
      large
      style={{
        backgroundColor: isInputsValid() ? '#2979ff' : '#c2c2c2',
      }}
      className='welcome-login-button'
      onClick={() => {
        if (!isInputsValid()) return;
        submitHandle({ encounterIdentifier: encounterIdentifierStr })
      }}
    >确定
  </Button>
  }

  const renderWardOrReg = () => {
    const visitTypeDisplays = {
      "0": "门诊",
      "1": "住院",
      "3": "体检"
    }
    return <div style={{ margin: 20 }}>就诊类型: {visitTypeDisplays[wardOrReg]}</div>
  }

  const onCellphoneLogin = (userId) => {
    submitHandle({ userId })
  }

  return (
    <Page noToolbar noNavbar noSwipeback loginScreen style={{ marginTop: '-15%' }} className='welcome-container-body'>
      <img className='welcome-shufu-logo' src={shufu_logo}></img>
      <div className='welcome-words'>
        <span className='welcome-words-text'>欢迎！</span>
        <img className='welcome-words-image' src={robot_smile}></img>
      </div>
      {renderWardOrReg()}
      {displayWardOrRegInput ? renderWardOrRegInput() : null}
      {displayRicnInput ? renderRicnInput() : null}
      {displayEncounterIdentifierInput ? renderEncounterIdentifierInput(wardOrReg) : null}
      {displayDefaultButton ? renderSubmitButton() : null}
      {displayCellphoneLogin ? <CellphoneLogin organizationId={organizationId} needName={false} onLogin={onCellphoneLogin} /> : null}
      <div style={{ margin: 20, color: "crimson", fontSize: 15, fontWeight: "bold" }}>
        请患方谨慎填写量表，分享/多次随意测评导致报告结果错误，由患方承担全部责任）
      </div>
      <div style={{ fontSize: '14px', color: '#8c8c8c', position: 'fixed', textAlign: 'center', width: '100%', bottom: 40 }}>
        Powered by Siuvo.Inc
      </div>
    </Page>
  );
};
export default ScanBarCode;
