export default function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  url = url.replace(/\?/g, '&');
  // console.log('URL', url);

  // eslint-disable-next-line
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
