import React, { useState, useEffect } from 'react';
import { initShuaiCloud as initShuaiCloudFromClientInstance } from '../../modules/shuaicloud/clientInstance';

import getParameterByName from '../../utils/getParameterByName';
import { verifyUserLandingOrganizationIdAndUserData } from '../../modules/shufu';
import {
  setCurrentUserId,
  setToken,
  setOrganizationId
} from '../../utils/localStorage.js';
import {
  LoginScreenTitle,
  Page,
  Preloader,
  BlockFooter,
  List
} from 'framework7-react';

async function initUser(userId, organizationId, token) {
  console.log({ userId, organizationId, token });
  async function initShuaiCloud() {
    await initShuaiCloudFromClientInstance();
  }

  setCurrentUserId(userId);
  setOrganizationId(organizationId);
  setToken(token);
  await initShuaiCloud();
}

const UserLanding = function(props) {
  const [title, setTitle] = useState(null);
  // const [initLoading, setInitLoading] = useState(false);

  const loadingSentences = [
    '正在获取AI神经元参数...',
    '正在呼叫人工智能AI...',
    '正在搭建智能模块链路...',
    '正在载入知识图谱...',
    '正在开启咨询服务...'
  ];

  const [sentenceIndex, setSentenceIndex] = useState(0);
  useEffect(() => {
    setTitle(getParameterByName('title') || '');
  }, []);

  useEffect(() => {
    async function getUserData() {
      const organizationId = getParameterByName('organizationId');
      const identifier = getParameterByName('identifier');
      const name = getParameterByName('name');

      const title = '欢迎您';
      const text =
        '平台对用户提供的测试信息严格保密，仅用于评估测试者自评心理健康状态。';

      console.log({ organizationId, userId: identifier, name });
      if (organizationId && identifier) {
        try {
          // setInitLoading(true)
          // window.$$f7.preloader.show();
          setSentenceIndex(1);
          // setInitLoading(true);
          const result = await verifyUserLandingOrganizationIdAndUserData(
            organizationId,
            identifier,
            name
          );
          console.log('res', result);
          const { userId, token } = result;
          setSentenceIndex(2);
          await initUser(userId, organizationId, token);
          console.log('Init userInformation');
          setSentenceIndex(3);
          setTimeout(() => {
            window.$$f7.dialog
              .create(
                { text, title, buttons: [{ text: '开始测试' }] },
                function() {}
              )
              .open();
            props.f7router.navigate(
              `/chooseScale/?title=${getParameterByName('title')}`,
              {
                reloadCurrent: true
              }
            );
          }, 200);
        } catch (e) {
          // setInitLoading(false);
          window.$$f7.dialog.alert(e);
        }
      } else {
        window.$$f7.dialog.alert('缺少必要的参数');
      }
    }

    setTimeout(() => {
      getUserData();
    }, 1000);

    // getUserData();
  }, [props.f7router]);

  return (
    <Page noToolbar noNavbar noSwipeback loginScreen className="landing-page">
      <LoginScreenTitle>{title}</LoginScreenTitle>
      {/* <Spin spinning={initLoading} /> */}

      <List>
        <div style={{ textAlign: 'center', marginBottom: 100, marginTop: 100 }}>
          <Preloader size={80} color="#2979ff"></Preloader>
        </div>
      </List>

      <List>
        <BlockFooter>
          初始化中，请稍候
          <br />
          <br />
          {loadingSentences[sentenceIndex]}
        </BlockFooter>
      </List>
    </Page>
  );
};

UserLanding.defaultProps = {
  onLoginSuccess: () => {
    console.log('onLoginSuccess is not defined');
  }
};

export default UserLanding;
