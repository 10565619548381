import React from 'react';
import { Icon, Link, Navbar, NavLeft, Page, Popup } from 'framework7-react';
import './PopupStyle.css';
import './AnswerBoard.css';

function FullScreenImagesPopUp (props) {
  const {fullImagePopupOpen, imageUrl, onPopClose} = props;
  return <Popup
      opened={fullImagePopupOpen}
      onPopupClosed={() => {
        onPopClose();
      }}
    >
    <Page>
      <Navbar>
          <NavLeft>
              <Link popupClose>
                  <Icon icon="icon-back"></Icon> <span> 返回</span>
              </Link>
          </NavLeft>
      </Navbar>
      <div className="shufu-image-fullscreen-page">
        <img className="shufu-image-answer-full-screen" src={imageUrl}/>
      </div>
    </Page>
  </Popup>
}

export default FullScreenImagesPopUp;