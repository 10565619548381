// 成功提交的页面
import React, { useEffect, useState } from 'react';
import './successfulRegister.css';
import {
  Block,
  Button,
  Page
} from 'framework7-react';
import successfulImg from '../../images/successful.svg';

function SuccessfulRegister() {

  return (
    <Page
      noToolbar
      noNavbar
      noSwipeback
      // loginScreen
      className='register-page'
    >
      <div className='successful-register'>
        <div className='successful-img'>
          <img src={successfulImg} style={{ width: '30%' }} />
        </div>
        <div className='successful-text'>
          您已成功提交信息
        </div>
        <Block>
          <Button
            large
            raised
            fill
            round
            style={{
              background: '#E99D42'
            }}
            onClick={() => {
              window.open("about:blank","_self")
              window.close();
            }}
          >
            我知道了
          </Button>
        </Block>
      </div>
    </Page>
  )
}

export default SuccessfulRegister;

