import React, { useState, useEffect, useCallback } from 'react';
import { answerBoardEmitter } from '../modules/answerBoardEmitter.js';
import { Button, Popup } from 'framework7-react';
import StaticFormPopup from './StaticFormPopup.js';
import i18n from '../i18n.js';
// import parseLanguage from '../utils/parseLanguage.js';
import './AnswerBoard.css';
// import { Input, Preloader } from 'framework7-react';
// import { getCurrentUserInfo } from '../utils/localStorage.js';
// import MaskedInput from 'react-maskedinput';
// import moment from 'moment';
AnswerBoardStaticForm.defaultProps = {
  content: {
    form: {
      schema: {}
    }
  }
};
export default function AnswerBoardStaticForm(props) {
  const [popupOpen, setPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { question, content: { form: { schema }, model }, jumpToStaticForm } = props;


  useEffect(() => {
    setLoading(false);
  }, [question]);

  const submitHandler = useCallback(model => {
    setLoading(true);
    answerBoardEmitter.emit('submit', model);
  }, []);

  return (
    <div className="answer-board-action-button-wrapper">
      <div style={{ flex: 1, height: '100%', display: 'flex' }}>
        {schema.allowSkip ? <div
          className="inline-form-skip-button"
          style={{ flex: 1 }}
          onClick={() => {
            submitHandler(schema.defaultValue || {})
          }}
        >
          {i18n.t('skip')}
        </div> : null}
        <div className="inline-form-submit-button" style={{ flex: 2, backgroundColor: '#fff', color: '#2979ff' }}
          onClick={() => {
            if (loading) {
              return;
            }
            setPopupOpen(true);
          }}
        >
          {schema.buttonText_zh || '请点击此处填写'}
        </div>
      </div>


      <Popup
        className="static-form-popup"
        opened={popupOpen || props.jumpToStaticForm}
        onPopupClosed={() => {
          setPopupOpen(false);
        }}
      >
        <StaticFormPopup
          schema={schema}
          model={model}
          submitHandler={model => {
            submitHandler(model);
            setPopupOpen(false);
            jumpToStaticForm ? window.$$f7.preloader.show() : null;
          }}
          requireConfirm={true}
          canSubmit={true}
          hideNavBar={jumpToStaticForm}
        ></StaticFormPopup>
      </Popup>
    </div>
  );
}
