import React, { useState, useEffect, useRef } from 'react';
import {
  Page,
  Navbar,
  NavLeft,
  Link,
  Icon,
  Subnavbar,
  NavTitle,
  NavRight,
  Searchbar,
  List,
  ListItem
} from 'framework7-react';
import _ from 'lodash';
// import parseLanguage from '../utils/parseLanguage.js';
import './CategorySymptomPopup.css';

CategorySymptomPopup.defaultProps = {
  fullSymptomList: [],
  limit: 1,
  onSelectHandler: function () {
    console.warn('selectHandler not defined');
  },
  onSubmitHandler: function () {
    console.warn('submitHandler not defined');
  },
  title: ''
};

export default function CategorySymptomPopup(props) {

  const [selectedCategory, setSelectedCategory] = useState(null);

  const [searchResult, setSearchResult] = useState(null);

  const [isCategoryScrolledToBottom, setIsCategoryScrolledToBottom] = useState(true);

  const categoryScrollRef = useRef();

  const { title, fullSymptomList, limit, onSelectHandler, onSubmitHandler, selectedSymptoms, isOpen } = props;


  useEffect(() => {
    if (!isOpen) return;
    setIsCategoryScrolledToBottom(true);
    setTimeout(() => {
      const { current } = categoryScrollRef;
      if (!current) {
        console.warn("category scroll not found");
        return;
      }
      // console.log(current.scrollHeight, current.clientHeight);
      if (current.scrollHeight !== current.clientHeight) {
        setIsCategoryScrolledToBottom(false);
      } else {
        setIsCategoryScrolledToBottom(true);
      }
    }, 500);
  }, [isOpen]);

  useEffect(() => {
    const { current } = categoryScrollRef;
    if (!current) {
      console.warn("category scroll not found");
      return;
    }
    const categoryScrollListener = (event) => {
      var element = event.target;
      if (element.scrollHeight - element.scrollTop === element.clientHeight) {
        setIsCategoryScrolledToBottom(true);
      } else {
        setIsCategoryScrolledToBottom(false);
      }
    };
    current.addEventListener('scroll', categoryScrollListener);
    return () => current.removeEventListener("scroll", categoryScrollListener);
  }, []);


  useEffect(() => {
    // set default selected category
    if (fullSymptomList.length) {
      setSelectedCategory(fullSymptomList[0].tagName);
    }
  }, [fullSymptomList]);

  function renderChiefComplaint(chiefComplaint) {
    const isSelected = selectedSymptoms.some(symptom => symptom.id === chiefComplaint.id);
    const informationButton = chiefComplaint.description_zh ? renderInformationButton(chiefComplaint.description_zh) : null;
    return (
      <div key={`${selectedCategory}.${chiefComplaint.id}`} onClick={() => {
        setSearchResult(null);
        onSelectHandler(chiefComplaint, selectedCategory);
      }}>
        <ListItem
          title={_.get(chiefComplaint, ["synonyms_zh", 0], chiefComplaint.id)}
          key={`${selectedCategory}.${chiefComplaint.id}`}
          className={["CategorySymptomItem", isSelected ? "CategorySymptomItem-selected" : ''].join(' ')}
        >
          {informationButton}
        </ListItem>
      </div>
    );
  }

  function renderSearchResult() {
    if (searchResult === null) {
      return null;
    }

    return (
      <div
        style={{
          background: 'white',
          width: 'calc(100% - 10px)',
          // minHeight: 100,
          position: 'absolute',
          top: 88,
          zIndex: 10000,
          maxHeight: '60vh',
          overflowY: 'scroll',
          marginLeft: 5,
          marginRight: 5,
          borderRadius: 5,
          boxShadow: '0px 0px 10px 5px #bbb'
        }}
      >
        <List style={{ margin: 0, height: '100%' }} simple-list>
          {searchResult.map(chiefComplaint => renderChiefComplaint(chiefComplaint))}
        </List>
      </div>
    );
  }

  function renderInformationButton(information) {
    return <span
      style={{ marginLeft: 10 }}
      className="shufu-answer-button-information-icon"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        window.$$f7.dialog.alert(information, false, { cssClass: 'dialog-text-left' });
      }}>?</span>
  }

  function renderCategoryDownArrow() {
    return !isCategoryScrolledToBottom ? <div
      className="down-arrow"
      style={{
        height: 20,
        width: 20,
        bottom: 30,
        left: 50,
        borderRadius: 10
      }} >
      <Icon style={{ "alignSelf": "center" }} f7="chevron_down" color="white" size="13px" />
    </div> : null
  }
  // 搜索auto complete 症状去重
  function arrDeDuplication(arr) {
    let result = [];
    const obj = {};
    result = arr.reduce((prev, cur) => {
      const name = cur.synonyms_zh[0]
      obj[name] ? '' : (obj[name] = true && prev.push(cur));
      return prev;
    }, [])
    return result;
  }

  function renderCategories() {
    return <div
      ref={categoryScrollRef}
      style={{
        width: 60,
        display: 'flex',
        backgroundColor: '#f4f4f4',
        flexDirection: 'column',
        overflowY: 'auto',
        height: '100%'
      }}
    >
      {fullSymptomList.map(category => {
        return (
          <div
            key={category.tagName}
            style={{
              paddingTop: 10,
              paddingBottom: 10,
              paddingLeft: 5,
              paddingRight: 5,
              // marginBottom: 5,
              // marginTop: 5,
              textAlign: 'center',
              backgroundColor:
                selectedCategory === category.tagName ? 'white' : '#f4f4f4'
            }}
            onClick={() => {
              setSelectedCategory(category.tagName);
            }}
          >
            <img
              alt={category.tagName}
              src={category.icon}
              style={{ width: 30, height: 30 }}
            ></img>
            <div style={{ textAlign: 'center', fontSize: 12 }}>
              {category.tagName}
            </div>
          </div>
        );
      })}
      {renderCategoryDownArrow()}
    </div>
  }

  function renderChiefComplaints() {
    return <div
      style={{
        flex: 1,
        display: 'flex',
        width: '100%',
        overflowX: 'hidden'
      }}
    >
      <div style={{ width: '100%' }}>
        <List style={{ margin: 0 }} simple-list>
          {(
            _.get(
              _.keyBy(fullSymptomList || [], 'tagName'),
              selectedCategory || '',
              {}
            ).chiefComplaints || []
          ).map(chiefComplaint => renderChiefComplaint(chiefComplaint))}
        </List>
      </div>
    </div>
  }
  return (
    <Page>
      <Navbar>
        <NavLeft>
          <Link popupClose>
            <Icon icon="icon-back"></Icon> <span> 返回</span>
          </Link>
        </NavLeft>
        <NavTitle>{title}</NavTitle>
        {limit > 1 ? <NavRight>
          <Link onClick={() => {
            window.$$f7.dialog.confirm('请检查您的选择，确认提交后将不得修改。是否确认提交？', '提示', () => {
              onSubmitHandler(selectedSymptoms);
            });
          }}>
            <span className='symptom-search-submit-button'>提交</span>
          </Link>
        </NavRight> : null}
        <Subnavbar inner={false}>
          <Searchbar
            placeholder="搜索"
            disableButtonText="取消"
            // searchContainer=".search-list"
            // searchIn=".item-title"
            // disableButton={!this.$theme.aurora}
            onSearchbarClear={() => {
              setSearchResult(null);
            }}
            onChange={event => {
              const value = event.target.value;
              if (!value) {
                setSearchResult(null);
                return;
              }
              let filtered = fullSymptomList.reduce((acc, curr) => {
                return acc
                  .concat(
                    curr.chiefComplaints.map(chiefComplaint => {
                      chiefComplaint.tagName = curr.tagName;
                      return chiefComplaint;
                    })
                  )
                  .filter(
                    chiefComplaint =>
                      chiefComplaint.synonyms_zh &&
                      chiefComplaint.synonyms_zh[0] &&
                      chiefComplaint.synonyms_zh[0].match(value)
                  );
              }, []);
              let filteredResult = arrDeDuplication(filtered)
              setSearchResult(filteredResult);
              console.log('filtered filtered filtered', filteredResult);
              console.log('onChange SEARCH BAR', event.target.value);
            }}
          ></Searchbar>
        </Subnavbar>
      </Navbar>
      <div
        style={{
          height: '100vh',
          width: '100%',
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'row',
          boxSizing: 'border-box',
          overflowY: 'hidden',
          paddingTop: 88, // search bar and navbar
          marginTop: -44,
          position: 'relative'
        }}
      >
        {renderSearchResult()}
        {renderCategories()}
        {renderChiefComplaints()}
      </div>
    </Page>
  );
}
