import React, { useEffect, useState, useMemo } from 'react';

import MessageBlockText from './MessageBlockText.js';
import MessageBlockInlineForm from './MessageBlockInlineForm.js';
import MessageBlockCategorySearch from './MessageBlockCategorySearch.js';
import MessageBlockSymptomSearch from './MessageBlockSymptomSearch.js';
import MessageBlockStaticForm from './MessageBlockStaticForm.js';
import MessageBlockDemoForm from './MessageBlockDemoForm.js';
import MessageBlockSelectBodyPartForm from './MessageBlockSelectBodyPartForm.js';
import MessageBlockWCSTForm from './MessageBlockWCSTForm.js';
import _ from 'lodash';


MessageBlock.defaultProps = {
  fullMessage: {},
  formStatus: null,
  isLastMessage: false,
};
export default function MessageBlock(props) {
  const { fullMessage, userId, formVariables } = props;
  const message = fullMessage.message;
  // const messageData = JSON.parse(message.data);
  // return null;

  let [pressCount, setPressCount] = useState(0);
  let [robotAvatarImageUrl, setRobotAvatarImageUrl] = useState('../images/robot.png');
  // let [chatBubbleCustomerStyle, setChatBubbleCustomerStyle] = useState('');
  let [customerAvatarImageUrl, setCustomerAvatarImageUrl] = useState('');


  useEffect(() => {
    // 机器人头像
    setRobotAvatarImageUrl(_.get(props, ['sceneData', 'robotAvatarImageUrl'], '') || '');
    // // 用户聊天框背景
    // setChatBubbleCustomerStyle(_.get(props, ['sceneData', 'chatBubbleCustomerStyle'], ''));
    // 用户头像
    setCustomerAvatarImageUrl(_.get(props, ['sceneData', 'customerAvatarImageUrl'], ''));
    //   // 设置背景图
    //   let chatPageBackgroundImageUrl = _.get(props, ['sceneData', 'chatPageBackgroundImageUrl'], ''),
    //     messageListWrapperDom = document.getElementsByClassName('messageListWrapper')[0];
    //   if (chatPageBackgroundImageUrl && messageListWrapperDom) messageListWrapperDom.style.backgroundImage = 'url(' + chatPageBackgroundImageUrl + ')'
    //   // 机器人聊天框背景
    //   let styleSheet = document.styleSheets[0];
    //   // styleSheet.insertRule('.shufu-text-message-bubble.message-received .message-bubble {background-color:black!important;}', 0);
    //   styleSheet.insertRule('.shufu-text-message-bubble.message-received .message-bubble {' + _.get(props, ['sceneData', 'chatBubbleRobotStyle'], '') + '}', 0);
    //   // 复选标记背景色
    //   const themeColor = _.get(props, ['sceneData', 'themeColor'], '');
    //   if (themeColor) styleSheet.insertRule('.multiple-choice-checkmark {background-color: ' + themeColor + '!important}', 0);
    //   // 选中选项样式
    //   // if (_.get(props, ['sceneData', 'title'], null) === '儿童保健') {  
    //   // styleSheet.insertRule('.shufu-answer-button-selected {background-color: #deeaff!important;color: #2979ff!important;}', 0);
    //   // styleSheet.insertRule('.shufu-answer-button:active {background-color: #deeaff!important;color: #2979ff!important;}', 0);
    //   // styleSheet.insertRule('.shufu-answer-button-selected:before{content: "√"!important;position: absolute;left:0;right:0;z-index: 999;margin: -18px auto 0;background-color: #ff8fc2;border: 1px solid #ffffff;width: 20px;height: 20px;line-height: 20px;border-radius: 20px;font-size: 16px;color: white;}', 0);
    //   // }
  }, [props, setRobotAvatarImageUrl]);


  // alert(type);

  return useMemo(() => {
    const messageJson = JSON.parse(message.data);
    const messageData = JSON.parse(_.get(message, 'data', '{}'))


    let type;
    if (messageJson._lcattrs && messageJson._lcattrs.type === 'text') {
      type = 'text';
    } else if (
      messageJson._lcattrs &&
      messageJson._lcattrs.type === 'jsonSchema'
    ) {
      let form = JSON.parse(messageJson._lctext);
      if (false /** not inline form */) {
        type = 'jsonSchema';
      } else if (form.schema.shufuFormChatType === 'categorySearch') {
        type = 'categorySearch';
      } else if (form.schema.shufuFormChatType === 'symptomSearch') {
        type = 'symptomSearch';
      } else if (form.schema.shufuFormChatType === 'staticForm') {
        type = 'staticForm';
      } else if (form.schema.shufuFormChatType === 'demoChooseItemsAndViewInvoiceForm') {
        type = 'demoChooseItemsAndViewInvoiceForm';
      } else if (form.schema.shufuFormChatType === 'selectBodyPartPicture') {
        type = 'selectBodyPartForm';
      } else if (form.schema.shufuFormChatType === 'selectBodyPartPictureV2') {
        type = 'selectBodyPartFormV2';
      } else if (form.schema.shufuFormChatType === 'WCSTView') {
        type = 'WCSTView';
      } else {
        type = 'inlineJsonSchema';
      }
    }

    let message_lctext, shufuFormId;
    switch (type) {
      case 'text':
        return (
          <MessageBlockText
            key={message['msg-id']}
            messageId={message['msg-id']}
            messageJson={messageJson}
            isLastMessage={props.isLastMessage}
            robotAvatarImageUrl={robotAvatarImageUrl}
          ></MessageBlockText>
        );
      case 'inlineJsonSchema':
        return (
          <MessageBlockInlineForm
            key={message['msg-id']}
            messageJson={messageJson}
            messageId={message['msg-id']}
            isLastMessage={props.isLastMessage}
            message={message}
            robotAvatarImageUrl={robotAvatarImageUrl}
            // chatBubbleCustomerStyle={chatBubbleCustomerStyle}
            customerAvatarImageUrl={customerAvatarImageUrl}
            formVariables={formVariables}
          ></MessageBlockInlineForm>
        );
      case 'categorySearch':
        return (
          <MessageBlockCategorySearch
            key={message['msg-id']}
            messageJson={messageJson}
            messageId={message['msg-id']}
            isLastMessage={props.isLastMessage}
            message={message}
            robotAvatarImageUrl={robotAvatarImageUrl}
          ></MessageBlockCategorySearch>
        );
      case 'symptomSearch':
        return (
          <MessageBlockSymptomSearch
            key={message['msg-id']}
            messageJson={messageJson}
            messageId={message['msg-id']}
            isLastMessage={props.isLastMessage}
            message={message}
            robotAvatarImageUrl={robotAvatarImageUrl}
          ></MessageBlockSymptomSearch>
        );
      case 'staticForm':
        return (
          <MessageBlockStaticForm
            key={message['msg-id']}
            userId={userId}
            messageJson={messageJson}
            messageId={message['msg-id']}
            isLastMessage={props.isLastMessage}
            message={message}
            robotAvatarImageUrl={robotAvatarImageUrl}
            formVariables={formVariables}
          ></MessageBlockStaticForm>
        );
      case 'selectBodyPartForm':
        return (
          <MessageBlockSelectBodyPartForm
            key={message['msg-id']}
            messageJson={messageJson}
            messageId={message['msg-id']}
            isLastMessage={props.isLastMessage}
            message={message}
            robotAvatarImageUrl={robotAvatarImageUrl}
            bodyType="bodyPart"
          >
          </MessageBlockSelectBodyPartForm>
        );
      case 'selectBodyPartFormV2':
        return (
          <MessageBlockSelectBodyPartForm
            key={message['msg-id']}
            messageJson={messageJson}
            messageId={message['msg-id']}
            isLastMessage={props.isLastMessage}
            message={message}
            robotAvatarImageUrl={robotAvatarImageUrl}
            bodyType="joint"
          >
          </MessageBlockSelectBodyPartForm>
        );
      case 'WCSTView' :
        return (
          <MessageBlockWCSTForm
            key={message['msg-id']}
            messageJson={messageJson}
            messageId={message['msg-id']}
            isLastMessage={props.isLastMessage}
            message={message}
            robotAvatarImageUrl={robotAvatarImageUrl}
          >
          </MessageBlockWCSTForm>
        )
      default:
        message_lctext = JSON.parse(_.get(messageData, '_lctext', '{}'))
        shufuFormId = _.get(message_lctext, ['schema', 'shufuFormId'], '');
        return (
          <MessageBlockDemoForm
            shufuFormId={shufuFormId}
          ></MessageBlockDemoForm>
        );
    }
  }, [customerAvatarImageUrl, formVariables, message, props.isLastMessage, robotAvatarImageUrl, userId])
}
