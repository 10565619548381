
import React, { Component } from 'react';
import robot from '../images/robot.png';
import voice from '../images/voice.png';
import playCustomVoice from '../utils/voiceUtils.js';
import { Message } from 'framework7-react';

TextToSpeechMessage.defaultProps = {
    speechText: '',
    messageType: ''
}
// 点击声音按钮播报对应的问题
function audioTone(audioPlayUrl) {
    // 获取dom元素audio
    let audio = document.getElementById("hintAudio");
    // 动态设置audio的地址
    audio.src = audioPlayUrl;
    audio.play();
}

const isIOS = (/iPad|iPhone|iPod/.test(navigator.platform) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
    !window.MSStream

function removeHtmlTags(str) {
    return str.replace(/(<([^>]+)>)/ig, '');
}

function removeEmojis(str) {
    return str.replace(/(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g, '');
}

function removeUrl(str) {
    return str.replace(/(?:https?):\/\/[\n\S]+/g, '');

}

function TextToSpeechMessage(props) {
    // 删除语音播报文字中的emoji

    const processedSpeechText = removeUrl(removeEmojis(removeHtmlTags(props.speechText)));
    // 拼接语音播报的url
    const audioPlayUrl = `http://tts.baidu.com/text2audio?lan=zh&ie=UTF-8&spd=5&text=${processedSpeechText}`
    return <div className='shufu-voice-content' onClick={() => isIOS ? playCustomVoice(processedSpeechText) : audioTone(audioPlayUrl)}>
        <audio id="hintAudio" controls="controls" hidden></audio>
        <Message
            className={props.messageType === 'instructions' ? 'shufu-text-message-bubble-instructions' : 'shufu-text-message-bubble'}
            style={{ marginBottom: 5, marginTop: 5 }}
            type="received"
            avatar={robot}
        >
            {props.children}
        </Message>
        <img className='shufu-voice-img' src={voice}></img>
    </div>
}

export default TextToSpeechMessage