import _ from 'lodash';
import { calculateFactors } from './calculateFactors';
import { convertAnswerValToAnswerText } from '../components/AnswerMessageBubble'

export function convertQuestionStructure(question) {
  // function convertQuestionStructure(question) {

  if (question.viewType && question.viewType === 'imageSelect') {
    question.images = question.items.images;
  }
  if (question.type === 'boolean') {
    question.enum = {

      false: '否|~|No',
      true: '是|~|Yes'
    };
    question.enumOrder = [false, true];
  } //fake enum option for boolean type
  if (question.type === 'array' && question.items) {
    if (question.items.enum) {
      question.enumOrder = question.items.order
        ? question.items.order
        : Object.keys(question.items.enum);
      question.enumInfo = question.items.enumInfo;
      if (!question.removeNoneOfTheAbove) {
        question.enum = Object.assign({
          noneOfTheAbove: '以上都不符合|~|None of the above'
        }, question.items.enum);
      } else question.enum = question.items.enum;
      if (!question.removeNoneOfTheAbove && !question.enumOrder.includes('noneOfTheAbove'))
        question.enumOrder.push('noneOfTheAbove');

      if (question.allowSkip) {
        question.enum = Object.assign({
          SKIPPED: '已跳过|~|Skip'
        }, question.enum);
      }
    }
    delete question.items;
  } else {
    if (question.enum) {
      question.enumOrder = question.order
        ? question.order
        : Object.keys(question.enum);
      if (question.allowSkip) {
        question.enum = Object.assign({
          SKIPPED: '已跳过|~|Skip'
        }, question.enum);
      }
    }
  }
}

// eslint-disable-next-line
function _convertToFloats(model) {
  Object.keys(model).forEach(key => {
    if (typeof model[key] === 'string') {
      if (!isNaN(model[key])) {
        model[key] = parseFloat(model[key]);
      }
    } else {
      _convertToFloats(model[key]);
    }
  });
}

function getValueScoreAndId(val) {
  if (typeof val === 'string' && val.includes('|')) {
    const [valueId, valueScore] = val.split('|');
    return { valueId, valueScore }; //value format is <valueId>|<valueScore>, valueId is used in askedIfExpression, valueScore is used in scoring
  } else return { valueId: val, valueScore: val };
}

function flatten(data) {
  let result = {};
  function recurse(cur, prop) {
    if (Object(cur) !== cur) {
      const { valueId } = getValueScoreAndId(cur);
      result[prop] = valueId;
    } else if (Array.isArray(cur)) {
      for (var i = 0, l = cur.length; i < l; i++)
        recurse(cur[i], `${prop}[${i}]`);
      if (l === 0) result[prop] = [];
    } else {
      let isEmpty = true;
      for (let p in cur) {
        isEmpty = false;
        recurse(cur[p], prop ? `${prop}.${p}` : p);
      }
      if (isEmpty && prop) result[prop] = {};
    }
  }
  recurse(data, '');
  return result;
}


/**
 * @typedef {Object} ShufuFormChat
 * @property {Function} constructor input callback functions for creating messages, schema, and model. Initializes state. Returns ShufuFormChat.
 * @property {Function} getNewState input answer + question, returns new state
 * @property {Function} editAnswer input message to be edited, returns new state
 * @property {Function} convertSubmitModel input current userId, returns the model to be submitted to server
 * @property {Function} setInitialState input cached state, returns initial state based on cache
 * @property {Function} getInitialState initializes state, returns initial state
 * @property {Object} formVariables variables passed in from serviceInstance that can be used in the form
 */
export default class ShufuFormChat {

  /**
   * FormState definition
   * @typedef {Object} FormState 
   * @property {Object[]} messagesShown
   * @property {Object} model
   * @property {Object} currentQuestionId
   * @property {Object[]} wholeOrder (order of questionIds)
   * @property {Object[]} statestack list of states (usually used for caching, currently not being used)
   * @property {Object[]} questionStack list of questions to be asked, (currently not being used)
   * @property {String[]} questionsAnswered list of questionIds that have been answered (currently not being used)
   * @property {Array<String|Number|Boolean>} answers list of answers (currently not being used)
   * @property {Number} questionNumber the question number of the current question being asked
   */

  /**
   * There are two use cases:
   *  When we need a chat GUI with separate question and answer bubbles,
   *  pass in props.createAnswerMessage. This will determine the data
   *  structure of the answer messages.
   *  When we need a GUI that does not need separate and answer bubbles,
   *  (like a slideshow), pass in props.updateMessageWithAnswer. This will determine
   *  how we modify the original questionMessage to include the answer information.
   *  props.createQuestionMessage, props.createAnswerMessage, props.updateMessageWithAnswer all modify/create the messages in the FormState.messagesShown
   * @param {Object} props 
   * @param {Function} props.createQuestionMessage inputs: questionId (string), messageObject (object with all questionId:question), language(en/zh), model(object, current model of the form). 
   * Make sure that the returned message has a unique "_id" field, this will be used as a unique identifier
   * @param {Function} [props.createAnswerMessage] input: questionId(string), answerKey (string), answerVal (string), question (object from messageObject[questionId])
   * Make sure that the returned message has a unique "_id" field, this will be used as a unique identifier
   * @param {Function} [props.updateMessageWithAnswer] input: message, answerKey (string), answerVal (string)
   * @param {Function} [props.isReplyProcessedSuccess] returns whether the reply to the form is processed successfully
   * @param {Object} props.newMessage original jsonSchema of form
   * @param {Object} [props.model={}]
   * @param {Object} [props.globalAnswers={}] additional information passed into model
   * @param {Object} [props.mappedIntentions={}] 匹配的意图所存储的意图集合
   */
  constructor(props) {
    this.createQuestionMessage = props.createQuestionMessage;
    this.createAnswerMessage = props.createAnswerMessage;
    this.updateMessageWithAnswer = props.updateMessageWithAnswer;
    this.isReplyProcessedSuccess = props.isReplyProcessedSuccess;
    this.originalSchema = JSON.parse(JSON.stringify(props.newMessage));
    this.formTitle = this.originalSchema.schema.title_zh;
    // 是否隐藏引导语的表名props.excludeTitleInInstructions
    this.excludeTitleInInstructions = this.originalSchema.schema.excludeTitleInInstructions || props.excludeTitleInInstructions;
    this.model = props.model || this.originalSchema.model || {};
    this.globalAnswers = props.globalAnswers || this.model.global || [];
    this.factors = {};
    this.formVariables = props.formVariables;
    const {
      messageObject,
      firstMessage,
      questionStack,
      wholeOrder
    } = this.splitMessage(this.originalSchema, props.language, this.model);
    this.messagesShown = [firstMessage]; // the message have shown.
    this.messageObject = messageObject; // message object for further use
    this.currentQuestionId = questionStack[0].id;
    this.wholeOrder = wholeOrder; // the order for the message;
    this.statestack = {}; // stack containing each question's questionstack
    this.questionStack = questionStack; //questio-n stack
    this.questionsAnswered = []; //questionhas been answered
    this.answers = [];
    this.flatAnswers = [];
    this.language = props.language || 'en'; //the default language
    this.answerIndex = 0;
    this.answerDisplays = {};
    // 新增意图
    this.intentions = {};
    this.mappedIntentions = {};
  }

  /**
   * @return {FormState} the form state
   */
  getInitialState() {
    return {
      messagesShown: this.messagesShown,
      model: this.model,
      answerDisplays: this.answerDisplays,
      messageObject: this.messageObject,
      currentQuestionId: this.currentQuestionId,
      wholeOrder: this.wholeOrder,
      statestack: this.statestack,
      questionStack: this.questionStack,
      questionsAnswered: this.questionsAnswered,
      answers: this.answers,
      questionNumber: this.messagesShown[this.messagesShown.length - 1]
        .questionNumber,
      willAskArray: this.getWillAskArray(this.currentQuestionId),
      intentions: this.intentions,
      mappedIntentions: this.mappedIntentions,
    };
  }

  /**
   * 
   * @param {FormState} state 
   */
  setInitalState(state) {
    this.messagesShown = state.messagesShown;
    this.model = state.model;
    this.messageObject = state.messageObject;
    this.currentQuestionId = state.currentQuestionId;
    this.wholeOrder = state.wholeOrder;
    this.statestack = state.statestack;
    this.questionStack = state.questionStack;
    this.questionsAnswered = state.questionsAnswered;
    this.answers = state.answers;
    this.questionNumber = state.questionNumber;
    this.intentions = state.intentions;
    this.mappedIntentions = state.mappedIntentions;
  }

  splitMessage(jsonSchema, language = 'en', model) {
    let messageObject = {};
    let sections = jsonSchema.schema.properties;
    let sectionOrder = jsonSchema.schema.order;
    let sectionKeys = sectionOrder ? sectionOrder : Object.keys(sections);
    let titleSwitch = `title_${language}`;
    let wholeOrder = [];
    const totalQuestions = this.getTotalQuestions(sections, sectionKeys);

    this.addSectionMessages(sections, sectionKeys, titleSwitch, messageObject, wholeOrder, totalQuestions);

    this.addProgressMessages(jsonSchema, totalQuestions, messageObject, wholeOrder);

    const firstMessageId = this.findFirstMessageId(messageObject, wholeOrder);
    const firstMessage = this.createQuestionMessage(
      firstMessageId,
      messageObject,
      language,
      model
    );

    return {
      messageObject,
      firstMessage,
      questionStack: [{ id: firstMessageId }],
      wholeOrder
    };
  }

  addSectionMessages(sections, sectionKeys, titleSwitch, messageObject, wholeOrder, totalQuestions) {
    let questionNumber = 1;
    sectionKeys.forEach((sectionKey) => {
      const questionOrders = sections[sectionKey].order
        ? sections[sectionKey].order
        : Object.keys(sections[sectionKey].properties);

      this.addInstructionsMessage(sections, sectionKey, titleSwitch, messageObject, wholeOrder);

      questionNumber = this.addQuestionMessagesAndReturnNewQuestionNumber(questionOrders, sections, sectionKey, questionNumber, totalQuestions, messageObject, wholeOrder);

      this.addLastMessage(sections, sectionKey, messageObject, wholeOrder);
    });
  }

  getTotalQuestions(sections, sectionKeys) {
    return sectionKeys.reduce((sum, sectionKey) => {
      const questionOrders = sections[sectionKey].order
        ? sections[sectionKey].order
        : Object.keys(sections[sectionKey].properties);
      const visibleQuestions = questionOrders.filter(questionKey => !this.isQuestionHidden(sections[sectionKey].properties[questionKey]));
      return sum + visibleQuestions.length;
    }, 0);
  }

  isQuestionHidden(question) {
    if (question.hide) return true;
    if (question.hideIfDefaultExists && question.defaultValueFormVariable) {
      return this.formVariables[question.defaultValueFormVariable] != null;
    }
    return false;
  }

  addQuestionMessagesAndReturnNewQuestionNumber(questionOrders, sections, sectionKey, questionNumber, totalQuestions, messageObject, wholeOrder) {
    const questionTypes = ['array', 'string', 'number', 'object', 'boolean'];
    questionOrders.forEach(questionOrder => {
      const num = `${sectionKey}/${questionOrder}`;
      let question = sections[sectionKey].properties[questionOrder];
      if (!question) {
        console.error(
          `"${sectionKey}/${questionOrder}" is not found. Please check your form structure.`
        );
      } else if (!questionTypes.includes(question.type)) {
        console.error(
          `"${question.type}" is not a valid question type. Please check question "${sectionKey}/${questionOrder}"`
        );
      } else {
        question.model = sectionKey;
        question.messageType = 'question';
        const defaultValueFormVariable = question.defaultValueFormVariable;
        if (defaultValueFormVariable && this.formVariables[defaultValueFormVariable] != null) {
          question.defaultValue = this.formVariables[defaultValueFormVariable];
          if (question.hideIfDefaultExists) {
            question.hide = true;
            _.set(this.model, [sectionKey, questionOrder], question.defaultValue);
          }
        }
        question.questionNumber = question.hide ? null : questionNumber;
        question.totalQuestions = totalQuestions;
        convertQuestionStructure(question);
        this.updateQuestionMessageObjectEnumInfoAskedIf(question);
        messageObject = Object.assign(messageObject, { [num]: question });
        if (!question.hide) {
          questionNumber = questionNumber + 1;
          wholeOrder.push(num);
        }
      }
    });
    return questionNumber;
  }

  addLastMessage(sections, sectionKey, messageObject, wholeOrder) {
    if (sections[sectionKey].lastMessage) {
      const lastMessageId = `${sectionKey}_lastMessage`;
      const lastMessage = {
        title: sections[sectionKey].lastMessage,
        type: 'lastMessage',
        messageType: 'lastMessage',
        enum: { 0: '下一步|~|Next' },
      }
      messageObject = Object.assign(messageObject, { [lastMessageId]: lastMessage });
      wholeOrder.push(lastMessageId);
    };
  }

  addInstructionsMessage(sections, sectionKey, titleSwitch, messageObject, wholeOrder) {
    const instructionsText = sections[sectionKey][titleSwitch]
      ? sections[sectionKey][titleSwitch]
      : sections[sectionKey].title;

    if (instructionsText) {
      const instructionsFullText = this.getInstructionsFullText(instructionsText);
      const instructionsId = `${sectionKey}_instructions`;
      const requireConfirmInstructions = !!sections[sectionKey].requireConfirmInstructions;
      const instructionBtnText = sections[sectionKey].instructionBtnText;
      const instructions = {
        title_zh: instructionsFullText,
        type: 'instructions',
        messageType: 'instructions',
        badge: sections[sectionKey].badge,
        order: sections[sectionKey].order,
        requireConfirmInstructions,
        enum: { 0: instructionBtnText || '了解' },
        enumOrder: [0],
        askedif: sections[sectionKey].instructionsAskedIf,
        // next: { 0: `${sectionKey}/${questionOrders[0]}` }
      };
      Object.assign(messageObject, { [instructionsId]: instructions });
      wholeOrder.push(instructionsId);
    }
  }

  addProgressMessages(jsonSchema, totalQuestions, messageObject, wholeOrder) {
    if (jsonSchema.schema.progressMessages) {
      const questionNumberToIdMap = Object.keys(messageObject).reduce((currMap, questionId) => {
        const questionNumber = messageObject[questionId].questionNumber;
        return Object.assign(currMap, { [questionNumber]: questionId })
      }, {});
      for (const progressMessageConfig of jsonSchema.schema.progressMessages) {
        const { percentage, message } = progressMessageConfig;
        const progressQuestionNumber = Math.ceil(totalQuestions * (percentage / 100)) + 1;
        const orderIndex = wholeOrder.findIndex((questionId) => questionId === questionNumberToIdMap[progressQuestionNumber]);
        const progressMessageId = `progress_${percentage}`;
        const progressMessage = {
          title: message,
          type: 'progressMessage',
          messageType: 'progressMessage',
          enum: { 0: '下一步|~|Next' },
        }
        messageObject = Object.assign(messageObject, { [progressMessageId]: progressMessage });
        wholeOrder.splice(orderIndex, 0, progressMessageId);
      }
    }
  }

  findFirstMessageId(messageObject, wholeOrder) {
    return wholeOrder.find(questionId =>
      this.getWillAsk(messageObject[questionId], this.globalAnswers)
    );
  }

  getInstructionsFullText(instructionsText) {
    if (!this.excludeTitleInInstructions && this.formTitle) {
      return `${this.formTitle}\n\n${instructionsText}`;
    } else {
      return instructionsText;
    }
  }

  updateMessagesShownWithAnswer(currentQuestionId, answer, answerVal, question, newMessagesShown, messageToBeUpdated) {
    if (this.createAnswerMessage) {
      const answerDisplay = convertAnswerValToAnswerText(answerVal, question);
      this.answerDisplays[currentQuestionId] = answerDisplay;
      const answerMessage = this.createAnswerMessage(
        currentQuestionId,
        answer,
        answerVal,
        question,
      );
      newMessagesShown.push(answerMessage);
    }

    if (this.updateMessageWithAnswer) {
      const messageToBeUpdatedInArray = newMessagesShown.find((message) => message._id === messageToBeUpdated._id);
      if (!messageToBeUpdatedInArray)
        throw new Error('Cannot find message with _id: ', messageToBeUpdated._id, 'in messagesShown to update with answers');
      this.updateMessageWithAnswer(messageToBeUpdatedInArray, answer, answerVal);
    }
  }

  updateMessagesShownWithNLP(questionId, response, nlp_path) {
    if (this.createQuestionMessage) {
      const question = this.messageObject[questionId];
      const { information_zh: information, questionNumber, type, questionsToDisplay, imageUrl, badge, viewType, requireConfirmInstructions } = question;
      const titleSwitch = `title_zh`;
      let title =
        question[titleSwitch] || question.title;

      const newQuestionMessage = {
        _id: `${questionId}_nlp`,
        direction: 'received',
        questionId,
        text: response,
        information: '',
        questionNumber,
        type: 'boolean',
        badge,
        requireConfirmInstructions,
        viewType: 'instructions',
        relatedQuestionsList: null,
        imageUrl
      };
      this.messagesShown.unshift(newQuestionMessage);
      return newQuestionMessage;
    }
  }


  /**
   * Gets new form state based on current question and answer
   * @param {Object} answer the answer of the question, should match the final model value
   * @param {Object} question the question object, requires the "type" field
   * @param {string} question.type
   * @param {Object} [message] the message that is being answered. if updateMessageWithAnswer exists, this message will be updated. 
   * only required if updateMessageWithAnswer is also passed in. 
   * @return {FormState} the new state after answering the question.
   */
  getNewState(answer, question, message = null, intentions, nlp_response) {
    let model = this.model;
    let messageObject = this.messageObject;
    let answerVal = this.getAnswerVal(question, answer);
    if (question.type === 'boolean') {
      answer = Boolean(answer === 'true');
    } // check if the type is boolean


    let newQuestionMessage = {};

    let questionType = question.type;
    let viewType = question.viewType;
    let requireConfirmInstructions = question.requireConfirmInstructions;
    let newStateStack = JSON.parse(JSON.stringify(this.statestack));
    let newQuestionsAnswered = JSON.parse(
      JSON.stringify(this.questionsAnswered)
    );
    let newAnswers = JSON.parse(JSON.stringify(this.answers));
    let newQuestionStack = this.questionStack;
    let newMessagesShown = JSON.parse(
      JSON.stringify(this.messagesShown)
    ).reverse();
    let currentQuestionId = this.currentQuestionId;
    let nextModelPath = null;

    if (question.factorExpressions) {
      // console.log("calculating factors: ", question.factorExpressions);
      const updatedModel = JSON.parse(JSON.stringify(model));
      if (currentQuestionId.includes('/')) {
        const [sectionKey, questionKey] = currentQuestionId.split('/');
        if (updatedModel[sectionKey]) {
          updatedModel[sectionKey][questionKey] = answer;
        }
        else {
          updatedModel[sectionKey] = { [questionKey]: answer };
        }
      }
      const newFactors = calculateFactors(updatedModel, this.factors, this.originalSchema, question.factorExpressions);
      // console.log("newFactors: ", newFactors);
      Object.assign(this.factors, newFactors);
    }

    do {
      if (nextModelPath) {
        //if not on first loop
        answer = model[nextModelPath[0]][nextModelPath[1]];
        question = messageObject[currentQuestionId];
        questionType = question.type;
        viewType = question.viewType;
        requireConfirmInstructions = question.requireConfirmInstructions;
        answerVal = this.getAnswerVal(question, answer);

      }
      newStateStack[currentQuestionId] = newQuestionStack;
      newQuestionsAnswered.push(currentQuestionId); //update questionsAnswered
      newAnswers.push(answer); //update Answers

      if (currentQuestionId.includes('/')) {
        const [sectionKey, questionKey] = currentQuestionId.split('/');
        if (model[sectionKey]) {
          model[sectionKey][questionKey] = answer;
        }
        else {
          model[sectionKey] = { [questionKey]: answer };
        }
        // 意图存储
        if (intentions) {
          if (this.intentions[sectionKey]) {
            this.intentions[sectionKey][questionKey] = intentions;
            this.mappedIntentions[sectionKey][questionKey] = intentions.mappedIntention;
          } else {
            this.intentions[sectionKey] = { [questionKey]: intentions };
            this.mappedIntentions[sectionKey] = { [questionKey]: intentions.mappedIntention };
          }
        }
      }

      if (requireConfirmInstructions || (questionType !== 'instructions' && questionType !== 'lastMessage' && questionType !== 'progressMessage' && viewType !== 'instructions')) {
        this.updateMessagesShownWithAnswer(currentQuestionId, answer, answerVal, question, newMessagesShown, message);
      }

      const isEarlySubmit = _.get(question, ["enumInfo", answer, "triggerEarlySubmit"], false);

      //delete any answers after the early submit question
      if (isEarlySubmit) {
        const orderIndex = this.wholeOrder.findIndex((orderQuestionId) => orderQuestionId === currentQuestionId);
        for (let i = orderIndex + 1; i < this.wholeOrder.length; i++) {
          this.deleteAnswer(this.wholeOrder[i], true);
        }
      }
      if (nlp_response) {
        let { response, nlp_path } = nlp_response;
        let newQuestionMessage = this.updateMessagesShownWithNLP(currentQuestionId, response, nlp_path);
        newMessagesShown.push(newQuestionMessage);
      };
      let nextQuestionId = isEarlySubmit ? "SUBMIT" : this.getNext(currentQuestionId, newAnswers, this.mappedIntentions);
      if (nextQuestionId !== 'SUBMIT') {
        const questionMessageObject = this.messageObject[nextQuestionId];
        this.updateQuestionMessageObjectValidatorExpression(questionMessageObject);
        this.updateQuestionMessageObjectEnumInfoAskedIf(questionMessageObject);
        this.updateQuestionMessageObjectTitle(questionMessageObject);
        newQuestionMessage = this.createQuestionMessage(
          nextQuestionId,
          this.messageObject,
          this.language,
          this.model
        );
        newMessagesShown.push(newQuestionMessage);
      }
      //update nextModelPath, currentQuestionId, ansVal, ansKey
      nextModelPath =
        nextQuestionId === 'SUBMIT' ? null : nextQuestionId.split('/');
      currentQuestionId = nextQuestionId;
    } while (
      (this.isReplyProcessedSuccess() && (questionType === 'instructions' || questionType === 'lastMessage' || questionType === 'progressMessage')) ||
      (nextModelPath &&
        model[nextModelPath[0]] &&
        model[nextModelPath[0]][nextModelPath[1]] !== undefined)
    );
    this.model = model;
    this.statestack = newStateStack;
    this.questionsAnswered = newQuestionsAnswered;
    this.answers = newAnswers;
    this.questionStack = newQuestionStack;
    this.messagesShown = newMessagesShown.reverse();
    this.currentQuestionId =
      currentQuestionId === 'SUBMIT' ? 'SUBMIT' : currentQuestionId;
    if (currentQuestionId === 'SUBMIT') {
      return {
        messagesShown: newMessagesShown,
        currentQuestionId: 'SUBMIT',
        questionStack: newQuestionStack,
        questionsAnswered: newQuestionsAnswered,
        answers: newAnswers,
        finished: true,
        questionNumber: question.totalQuestions
      };
    } else {
      const willAskArray = this.getWillAskArray(currentQuestionId);
      let newState = {
        messageObject: this.messageObject,
        statestack: newStateStack,
        currentQuestionId: currentQuestionId,
        questionStack: newQuestionStack,
        questionsAnswered: newQuestionsAnswered,
        answers: newAnswers,
        messagesShown: newMessagesShown,
        willAskArray // for object type
      };
      if (newQuestionMessage.questionNumber) {
        newState.questionNumber = newQuestionMessage.questionNumber;
        newState.totalQuestions = newQuestionMessage.totalQuestions;
      }
      // if (newQuestionMessage.imageUrl) {
      //   newState.imageUrl = newQuestionMessage.imageUrl;
      // }
      return newState;
    }
  }

  getAnswerDisplay(questionPath) {
    const answer = _.get(this.model, questionPath);
    const questionId = questionPath.replace(".", "/");
    const question = this.messageObject[questionId];
    return convertAnswerValToAnswerText(this.getAnswerVal(question, answer), question);
  }

  updateQuestionMessageObjectTitle(questionMessageObject) {
    if (questionMessageObject.title_zh && questionMessageObject.title_zh.match(/^__SCRIPT__/)) {
      questionMessageObject.title_script = questionMessageObject.title_zh.replace(/^__SCRIPT__/, '');
    }
    const script = questionMessageObject.title_script;

    if (!script) {
      return;
    }
    let getParsedTitle;
    try {
      getParsedTitle = new Function(
        'context',
        "return `" + script + "`"
      );
    } catch (e) {
      console.error("Invalid script expresssion: ", questionMessageObject.title_zh, "question=", questionMessageObject.id, "error=", e);
      return;
    }
    try {
      const context = { factors: this.factors, model: this.model, answerDisplays: this.answerDisplays };

      const parsedTitle = getParsedTitle(context);
      questionMessageObject.title_zh = parsedTitle;
    } catch (e) {
      console.error("Error evaluating title_script: ", questionMessageObject.title_zh, "question=", questionMessageObject.id, "error=", e);
      return;
    }
  }

  updateQuestionMessageObjectValidatorExpression(questionMessageObject) {
    if (questionMessageObject.validatorExpression) {
      const validatorExpression = questionMessageObject.validatorExpression;
      const requiredVar = questionMessageObject.requiredVar;
      const varValues = requiredVar.map(varPath => {
        const questionId = varPath.replace(".", "/");
        const varType = _.get(this.messageObject, [questionId, "type"], 'string');
        const modelValue = _.get(this.model, varPath);
        if (modelValue == null) {
          console.error("validatorExpression error: did not find a model value with path: ", varPath);
          window.$$f7.dialog.alert(
            `validatorExpression error: did not find a model value with path: ${varPath}`,
            false
          );
        }
        return varType === 'number' ? parseFloat(modelValue) : modelValue;
      });
      const varNames = [];
      let parsedValidatorExpression = validatorExpression;
      requiredVar.forEach((varPath) => {
        const varName = varPath.replace(".", "_");
        parsedValidatorExpression = parsedValidatorExpression.replace(varPath, varName);
        varNames.push(varName);
      })
      questionMessageObject.parsedValidatorExpression = parsedValidatorExpression;
      questionMessageObject.varValues = varValues;
      questionMessageObject.varNames = varNames;
    }
  }

  updateQuestionMessageObjectEnumInfoAskedIf(questionMessageObject) {
    const { enumInfo } = questionMessageObject;
    if (enumInfo) {
      for (const enumKey in enumInfo) {
        if (enumInfo[enumKey].askedif && enumInfo[enumKey].askedif.length > 0) {
          const askEnum = this.getWillAsk(enumInfo[enumKey], this.flatAnswers);
          if (!askEnum) {
            questionMessageObject.enumOrder = questionMessageObject.enumOrder.filter(orderKey => orderKey !== enumKey);
            questionMessageObject.order = questionMessageObject.order.filter(orderKey => orderKey !== enumKey);
            delete questionMessageObject.enum[enumKey];
          }
        }
      }
    }
  }

  removeHtmlTags(str) {
    return str.replace(/(<([^>]+)>)/ig, '');
  }

  getAnswerVal(question, answer) {
    const questionType = question.type || '';
    if (questionType === 'object') {
      const answers = Object.keys(answer)
        .map(key => {
          const subSchema = question.properties[key];
          const { enum: enums, title_zh, type, viewType, templateList, properties } = subSchema;
          const subModel = answer[key];
          if (enums) {
            return `${title_zh}:${enums[subModel]
              }`;
          } else if (type === 'object' && viewType === 'template') {
            return getTemplateResultString(subModel, properties, templateList);
          } else return subModel;
        }).filter((ansStr) => ansStr);
      if (_.every(answers, (answer => answer === 'SKIPPED'))) return ['SKIPPED'];
      return answers;
    }

    if (questionType === 'array') {
      return question.enum
        ? answer.map(ans => question.enum[ans] != null ? this.removeHtmlTags(question.enum[ans]) : ans)
        : answer
    }

    else {
      return question.enum
        ? question.enum[answer] != null ? this.removeHtmlTags(question.enum[answer]) : answer
        : answer;
    }
  }

  getWillAskArray(questionId, flatAnswers = null) {
    if (questionId === "SUBMIT") return [];
    let willAskArray = null; // for object type
    if (this.messageObject[questionId].type === 'object') {
      willAskArray = this.messageObject[questionId].order || Object.keys(
        this.messageObject[questionId].properties || {}
      ).filter(propertyKey => {
        return this.getWillAsk(
          this.messageObject[questionId].properties[propertyKey], flatAnswers
        );
      });
    }
    return willAskArray
  }

  getValues(object) {
    return Object.keys(object).map(key => object[key]);
  }

  getNext(currentQuestionId, answers, intentions = {}) {
    //return next question id
    const order = this.wholeOrder;
    const messageObject = this.messageObject;
    this.flatAnswers = this.globalAnswers.concat(
      this.getValues(flatten(answers)), this.getValues(flatten(intentions))
    );
    if (
      order.indexOf(currentQuestionId) < 0 &&
      currentQuestionId.includes('/')
    ) {
      //don't alert for instruction messages
      console.error(`Did not find "${currentQuestionId}" in form order`);
    }
    let orderIndex = order.indexOf(currentQuestionId) + 1;
    let willAsk = false;
    let nextQuestionId = 'SUBMIT';
    while (willAsk === false && orderIndex < order.length) {
      const tempNextQuestionId = order[orderIndex];
      const tempNextQuestion = messageObject[tempNextQuestionId];
      if (tempNextQuestion === undefined) {
        console.error(
          `${tempNextQuestionId} is in the form order but is not a question id.`
        );
      } else {
        willAsk = this.getWillAsk(tempNextQuestion, this.flatAnswers);
        nextQuestionId = willAsk ? tempNextQuestionId : 'SUBMIT';
      }
      orderIndex++;
    }
    return nextQuestionId;
  }

  getWillAsk(question, flatAnswers = null) {
    if (!flatAnswers) {
      flatAnswers = this.globalAnswers.concat(
        this.getValues(flatten(this.answers))
      );
    }

    if (question.askedIfExpression) {
      // eslint-disable-next-line
      console.log("calculating askedIf expression for question: ", question);
      let getAskedIf = null;
      try {
        getAskedIf = new Function(
          'answers',
          'factors',
          'model',
          `return ${question.askedIfExpression}`
        );
      } catch (e) {
        console.error("Invalid askedIfExpression: ", question.askedIfExpression, "question=", question.id, "error=", e);
        return false;
      }
      try {
        return getAskedIf(flatAnswers, this.factors, this.model);
      } catch (e) {
        console.error("Error evaluating askedIfExpression: ", question.askedIfExpression, "question=", question.id, "error=", e);
        return false;
      }
    } else if (question.askedif) {
      return question.askedif.reduce(
        (isIn, ansKey) => isIn || flatAnswers.includes(ansKey),
        false
      );
    }
    else return true;
  }

  /**
   * 
   * @return {Object} the final model of the form to be sent to the server
   */
  convertSubmitModel() {
    const model = JSON.parse(JSON.stringify(this.model));
    const intentions = JSON.parse(JSON.stringify(this.intentions));
    const jsonSchemaClone = JSON.parse(JSON.stringify(this.originalSchema));
    const schemaClone = JSON.parse(JSON.stringify(jsonSchemaClone.schema));
    const submitBody = {
      schema: {
        shufuFormId: schemaClone.shufuFormId,
        "siuvo:operations:transform": schemaClone["siuvo:operations:transform"],
        "siuvo:operations:target": schemaClone["siuvo:operations:target"]
      },
      interaction: jsonSchemaClone.interaction,
      model,
      intentions
    };

    return submitBody;
  }

  /**
   * Adds try-catch for deleting model[sectionKey][questionKey]
   * @param {String} questionId ex. Q/Q1
   */
  deleteAnswer(questionId, suppressError = false) {
    console.log("deleting questionId: ", questionId);
    const [sectionKey, questionKey] = questionId.split('/');
    try {
      delete this.model[sectionKey][questionKey];
      delete this.answerDisplays[questionId];
      // 修改答案，如果有intention，同时删除intention的对应key
      delete this.intentions[sectionKey][questionKey];
      delete this.mappedIntentions[sectionKey][questionKey];
    } catch (e) {
      if (!suppressError) {
        console.error(
          `Failed to delete answer from model: cannot find questionId ${questionId} in the model`
        );
      }
    }
  }

  /**
   * @param {Object} message the message that is getting edited
   * @return {FormState} the new form state that is ready to be edited.
   */
  editAnswer(message) {
    const { questionId, answer } = message;
    let messagesShown = JSON.parse(
      JSON.stringify(this.messagesShown)
    ).reverse();
    let questionsAnswered = JSON.parse(JSON.stringify(this.questionsAnswered));
    let answers = JSON.parse(JSON.stringify(this.answers));
    let questionStack = JSON.parse(JSON.stringify(this.questionStack));

    const statestack = JSON.parse(JSON.stringify(this.statestack));
    const messageIndex = messagesShown.findIndex((currMessage) => currMessage._id === message._id);

    const answerIndex = questionsAnswered.indexOf(questionId);
    questionStack = statestack[questionId];
    //if this form is a dynamic form, we also need to remove previous answers from the model
    const questionsToBeChecked = questionsAnswered.slice(answerIndex); //all the answers after the answerpos
    this.deleteAnswer(questionId);

    //check each question to see if it needs to be deleted
    questionsToBeChecked.forEach(questionId => {
      const currentFlatAnswers = this.globalAnswers.concat(
        this.getValues(flatten(this.model)));
      const willAsk = this.getWillAsk(this.messageObject[questionId], currentFlatAnswers);
      const originalWillAskArray = this.getWillAskArray(questionId, this.flatAnswers);
      const currentWillAskArray = this.getWillAskArray(questionId, currentFlatAnswers);
      if (!willAsk || !_.isEqual(originalWillAskArray, currentWillAskArray)) {
        this.deleteAnswer(questionId);
      }
    });

    questionsAnswered = questionsAnswered.slice(0, answerIndex);
    answers = answers.slice(0, answerIndex);
    // questionsAnswered.push(questionId);
    messagesShown = messagesShown.slice(0, messageIndex);
    const willAskArray = this.getWillAskArray(questionId);
    this.updateQuestionMessageObjectValidatorExpression(this.messageObject[questionId]);
    this.updateQuestionMessageObjectEnumInfoAskedIf(this.messageObject[questionId]);
    this.currentQuestionId = questionId;
    this.questionStack = questionStack || [];
    this.questionsAnswered = questionsAnswered;
    this.messagesShown = messagesShown.reverse();
    this.answers = answers;
    return {
      currentQuestionId: this.currentQuestionId,
      questionStack: this.questionStack,
      questionsAnswered: this.questionsAnswered,
      messagesShown: this.messagesShown,
      answers: this.answers,
      questionNumber: messagesShown[0].questionNumber,
      willAskArray,
      intentions: this.intentions,
      mappedIntentions: this.mappedIntentions
    };
  }
}
