import React, { useState, useCallback, useEffect } from 'react';
import {
  Page,
  List,
  ListInput,
  Button,
  Popup
} from 'framework7-react';

import { initShuaiCloud as initShuaiCloudFromClientInstance } from '../../modules/shuaicloud/clientInstance';
import { isIphonex } from '../../App.js';

import ChooseUserListPopup from '../../components/ChooseUserListPopup.js';
import {
  createUserAndStartServiceInternal,
  loginWithUserIdAndShufuToken,
  registerWithCellphoneAndShufuToken,
  wechatLoginWithWebAuthCode,
  getSceneDataBySceneIdAndTimestamp,
  deactivateUser
} from '../../modules/shufu';

import getParameterByName from '../../utils/getParameterByName';

import robot_smile from '../../images/robot_smile.png';
import shufu_logo from '../../images/shufu-logo.png';

import {
  setCurrentUserId,
  setOrganizationId,
  setToken,
  setServiceInstanceId
} from '../../utils/localStorage.js';
import isWeixinBrowser from '../../utils/isWeixinBrowser';

import '../welcome/welcome.css';
import _ from 'lodash';

import { useTranslation } from 'react-i18next';
import CONFIG from 'GlobalConfigFile';

export async function initUser(userId, organizationId, token) {
  console.log({ userId, token });
  async function initShuaiCloud() {
    await initShuaiCloudFromClientInstance();
  }

  setCurrentUserId(userId);
  setOrganizationId(organizationId);
  setToken(token);
  await initShuaiCloud();
}

function sleep(ms) {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, ms);
  });
}

const Welcome = function (props) {
  const [title, setTitle] = useState(document.title || getParameterByName('title') || '');
  const [isValid, setIsValid] = useState(true);
  const { t, i18n } = useTranslation();

  // const title = getParameterByName('title') || "舒辅智慧";
  const removeLogo = getParameterByName('removeLogo');
  const backgroundImageUrl = getParameterByName('backgroundImageUrl') || 'default';

  const sceneId = getParameterByName('sceneId');
  const qrTimestamp = getParameterByName('qrTimestamp');
  const toPage = getParameterByName('toPage');
  let chatUrl = '/chat/';

  if (toPage) {
    chatUrl = `/${toPage}/`;
  }

  if (typeof sceneId !== 'undefined' && sceneId !== null && sceneId !== '') {
    chatUrl = `${chatUrl}?sceneId=${sceneId}&title=${title}`;
  } else if (typeof title !== 'undefined' && title !== null && title !== '') {
    chatUrl = `${chatUrl}?title=${title}`;
  }
  console.log(chatUrl);

  const [patientIdentifier, setPatientIdentifier] = useState('');
  const [patientName, setPatientName] = useState('');
  const [needName] = useState(getParameterByName('needName') === 'true');
  const [name, setName] = useState(undefined);
  const [chooseUserListPopupOpen, setChooseUserListPopupOpen] = useState(false);

  const [tempShufuToken, setTempShufuToken] = useState(null);
  const [userInfoList, setUserInfoList] = useState([]);

  const [nobody] = useState(getParameterByName('nobody') === 'true');
  const [organizationId] = useState(getParameterByName('organizationId'));

  const [introductionImageUrl, setIntroductionImageUrl] = useState(undefined);


  const loginWithCellphoneCode = useCallback(
    async () => {
      if (organizationId) {
        if (!patientName) {
          alert('请输入患者姓名');
          return;
        }
        if (!patientIdentifier) {
          alert('请输入病历号');
          return;
        }
        window.$$f7.preloader.show();
        try {
          let result = await createUserAndStartServiceInternal({
            organizationId,
            sceneId,
            patientIdentifier,
            patientName
          });
          window.$$f7.preloader.hide();
          console.log('result:', result)
          if (result.status === 'failed') {
            window.$$f7.dialog.alert(result.message);
          } else {
            // setUUID(generateUUID());
            let { userId, token, serviceInstanceId } = result;
            setServiceInstanceId(serviceInstanceId);
            await initUser(userId, organizationId, token);
            setTimeout(() => {
              props.f7router.navigate(chatUrl, {
                reloadCurrent: true
              });
            }, 200);
          }
          // if (result.status === 'USERS_EXIST') {
          //   window.$$f7.preloader.hide();
          //   setChooseUserListPopupOpen(true);
          //   setTempShufuToken(result.shufuToken);
          //   setUserInfoList(result.data.userInfo);
          // } else if (result.status === 'NO_USER_EXISTS') {
          //   const userId = result.data.userId || result.data.userid; // there's maybe a typo from backend
          //   const token = result.data.token;
          //   await initUser(userId, organizationId, token);

          //   if (!introductionImageUrl) {
          //     props.f7router.navigate(chatUrl, {
          //       reloadCurrent: true
          //       // pushState: false
          //     });
          //   } else {
          //     window.$$f7.preloader.hide();
          //   }
          // }
        } catch (err) {
          window.$$f7.preloader.hide();
          console.error(err);
          window.$$f7.dialog.alert(t('incorrect_code'));
        }
      } else {
        window.$$f7.dialog.alert('缺少必要的参数');
      }
    },
    [
      patientIdentifier,
      chatUrl,
      patientName,
      organizationId,
      props.f7router,
      t,
      sceneId
    ]
  );

  useEffect(() => {
    async function tryAutomaticLogin() {

      console.log('NOBODY', getParameterByName('nobody'));
      if (nobody) {
        // only wechat browser and h5.siuvo.cn uses wechat login
        if (isWeixinBrowser() && window.location.href.match('h5.siuvo.cn')) {
          let code = getParameterByName('code');
          let redirectState = getParameterByName('state');

          const appId = CONFIG.APPID;
          // const appId = 'wx04ac68f0c2bb796d'; // sandbox
          // const appId = 'wx8457bf764fcc834b'; // real
          const state = 'wechat_login_tried';
          const scope = 'snsapi_base';
          const currentURL = window.location.href;
          // const currentURL = 'https://sit.siuvo.cn';

          console.log('!!!!CODECODE', { code, redirectState });
          if (code && redirectState === 'wechat_login_tried') {
            // try to use code to login
            window.$$f7.preloader.show();

            console.log('!!!!', { code, redirectState });

            try {
              let result = await wechatLoginWithWebAuthCode({ code, scope });
              const userId = result.data.userId || result.data.userid; // there's maybe a typo from backend
              const token = result.data.token;
              await initUser(userId, organizationId, token);
              if (!introductionImageUrl) {
                props.f7router.navigate(chatUrl, {
                  reloadCurrent: true
                  // pushState: false
                });
              } else {
                window.$$f7.preloader.hide();
              }

              console.log(result);
              return;
            } catch (err) {
              window.$$f7.dialog.alert('微信登录失败');
              window.$$f7.preloader.hide();
              console.log(err);
            }
          } else if (!code && redirectState === 'wechat_login_tried') {
            // do nothing
          } else {
            // try to use wechat login

            let jumpUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${encodeURIComponent(
              currentURL
            )}&response_type=code&scope=${scope}&state=${state}#wechat_redirect`;
            try {
              window.location.replace(jumpUrl); // try to use wechat login
              return;
            } catch (e) {
              alert(e);
            }
          }
        }
        // if it's not wechat browser, or wechat login failed, use original login
        loginWithCellphoneCode(
          `GG_${Date.now()}_${Math.floor(Math.random() * 100)}`,
          '9999'
        );
      }
    }

    console.log('introductionImageUrl11111', introductionImageUrl);

    if (introductionImageUrl === undefined) {
      return;
    }
    tryAutomaticLogin();
  }, [loginWithCellphoneCode, nobody, introductionImageUrl, chatUrl, organizationId, props.f7router]);
  const onSelectUser = async _userId => {
    window.$$f7.preloader.show();
    try {
      let result = await loginWithUserIdAndShufuToken(_userId, tempShufuToken);
      let { token, userid } = result;
      setChooseUserListPopupOpen(false);
      await initUser(userid, organizationId, token);
      setTimeout(() => {
        // do not dismiss loading if need to navigate to chat page
        // window.$$f7.preloader.hide();
        props.f7router.navigate(chatUrl, {
          reloadCurrent: true
          // pushState: false
        });
      }, 700);
    } catch (err) {
      window.$$f7.preloader.hide();
      window.$$f7.dialog.alert(err.message);
    }
  };

  const onDeleteUser = async _userId => {
    window.$$f7.preloader.show();
    try {
      await deactivateUser(_userId);
      window.$$f7.preloader.hide();
    } catch (err) {
      window.$$f7.preloader.hide();
      window.$$f7.dialog.alert(err.message);
    }
  };

  const onCreateNewUser = async () => {
    window.$$f7.preloader.show();
    try {
      let result = await registerWithCellphoneAndShufuToken(
        patientIdentifier,
        tempShufuToken
      );
      let { token, userid } = result;
      setChooseUserListPopupOpen(false);
      await initUser(userid, organizationId, token);
      console.log('INIT COMPLETE');
      setTimeout(() => {
        console.log('SET TIMEOUT COMPLETE');
        // do not dismiss loading if need to navigate to chat page
        // window.$$f7.preloader.hide();
        props.f7router.navigate(chatUrl, {
          reloadCurrent: true
          // pushState: false
        });
      }, 700);
    } catch (err) {
      console.error(err);
      window.$$f7.preloader.hide();
      window.$$f7.dialog.alert(t('server_busy'));
    }
  };

  useEffect(() => {
    async function tryGetSceneData() {
      if (!sceneId) {
        return;
      }
      const sceneResponse = await getSceneDataBySceneIdAndTimestamp(
        sceneId,
        qrTimestamp
      );
      if (sceneResponse.status === 'expired') {
        setIsValid(false);
      }
      const sceneResult = sceneResponse.wechatQrInfo;
      if (_.get(sceneResult, ['sceneData', 'title'], '') === '儿童保健') {
        props.f7router.navigate('/childCareWelcome/', {
          reloadCurrent: true
        });
      }
      const _introductionImageUrl =
        (sceneResult && sceneResult['introductionImageUrl']) ||
        (sceneResult &&
          sceneResult['sceneData'] &&
          sceneResult['sceneData']['introductionImageUrl']) ||
        getParameterByName('introductionImageUrl');
      if (_introductionImageUrl) {
        console.log(
          'setIntroductionImageUrl(_introductionImageUrl)',
          _introductionImageUrl
        );
        setIntroductionImageUrl(_introductionImageUrl);

        setTimeout(() => {
          console.log('introductionImageUrl', introductionImageUrl);
        }, 1000);
      } else {
        console.log('setIntroductionImageUrl(null)');
        setIntroductionImageUrl(null);
      }
    }

    tryGetSceneData();
  }, [sceneId, introductionImageUrl, qrTimestamp, props.f7router]);

  if (!isValid) {
    return (
      <Page noToolbar noNavbar noSwipeback loginScreen>
        <div className="welcome-container">
          <div className="welcome-title">
            {title || '舒辅智慧'}
            <br />
            对不起，测试已过期！
          </div>
        </div>
      </Page>
    );
  }

  if (nobody) {
    //seems like only weixin browser handles the additional padding at the bottom of iphoneX correctly, this is a workaround for other browsers
    const marginBottom = isIphonex() && !isWeixinBrowser() ? 150 : 34;
    const startButton = (
      <div
        style={{
          width: '100%',
          paddingTop: 10,
          paddingBottom: 10,
          textAlign: 'center',
          backgroundColor: '#2979ff',
          color: 'white',
          marginBottom
        }}
        onClick={() => {
          window.$$f7.preloader.show();
          props.f7router.navigate(chatUrl, {
            reloadCurrent: true
            // pushState: false
          });
        }}
      >
        开 始
      </div>
    );
    return (
      <Page noToolbar noNavbar noSwipeback loginScreen>
        <div
          className="welcome-container"
          style={{
            backgroundImage: introductionImageUrl
              ? `unset`
              : removeLogo
                ? "url('///image.siuvo.com.cn/wechat/h5TitleBackgroundImage_noLogo.jpg')"
                : backgroundImageUrl === 'mentalHealthNoLogo'
                  ? "url('///image.siuvo.com.cn/wechat/h5TitleBackgroundImage_mentalHealth_noLogo.jpeg')"
                  : undefined,
            backgroundColor: introductionImageUrl ? `white` : undefined,
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          {introductionImageUrl === null ? (
            <div className="welcome-title">{title || t('shufu_zhihui')}</div>
          ) : (
            [
              <div style={{ flex: 1, overflowY: 'scroll' }}>
                <img
                  src={introductionImageUrl}
                  style={{
                    width: '100%'
                  }}
                ></img>
              </div>,
              //racing condition between getting the wechatQrInfo and rendering the startButton.
              //This condition makes sure that the imageUrl exists before rendering the button
              introductionImageUrl ? startButton : null
            ]
          )}
        </div>
      </Page>
    );
  }
  return (
    <Page noToolbar noNavbar noSwipeback loginScreen className='welcome-container-body'>
      <img className='welcome-shufu-logo' src={shufu_logo}></img>
      <div className='welcome-words'>
        <span className='welcome-words-text'>欢迎！</span>
        <img className='welcome-words-image' src={robot_smile}></img>
      </div>
      {/* <LoginScreenTitle>{title || t('shufu_zhihui')}</LoginScreenTitle> */}

      <List form>
        <ListInput
          label="患者姓名"
          type="text"
          placeholder="请输入您的姓名"
          value={patientName}
          onInput={e => {
            setPatientName(e.target.value);
          }}
        >
        </ListInput>
        <ListInput
          label="病历号"
          type="text"
          placeholder="请输入您的病历号"
          value={patientIdentifier}
          onInput={e => {
            setPatientIdentifier(e.target.value);
          }}
        >
        </ListInput>

        {needName ? (
          <ListInput
            label="姓名"
            type="text"
            placeholder="请输入姓名"
            value={name}
            onInput={e => {
              setName(e.target.value);
              // this.setState({ password: e.target.value});
            }}
          />
        ) : null}
      </List>
      <Button
        raised
        fill
        large
        style={patientIdentifier && patientName ? {} : { background: '#bfbfbf' }}
        className='welcome-login-button'
        onClick={() => {
          loginWithCellphoneCode();
        }}
      >
        登录
      </Button>
      <div style={{ textAlign: 'center', fontSize: '14px', color: '#8c8c8c' }}>
        Powered by Siuvo.Inc
      </div>

      <Popup
        // className="demo-popup"
        opened={chooseUserListPopupOpen}
        onPopupClosed={() => {
          setChooseUserListPopupOpen(false);
        }}
        id='chooseUserListPopup'
      >
        <ChooseUserListPopup
          userList={userInfoList}
          onSelectUser={onSelectUser}
          onDeleteUser={onDeleteUser}
          onCreateNewUser={onCreateNewUser}
        ></ChooseUserListPopup>
      </Popup>
    </Page>
  );
};
Welcome.defaultProps = {
  onLoginSuccess: () => {
    console.warn('onLoginSuccess is not defined');
  }
};
export default Welcome;
