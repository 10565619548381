import React, { useState, useCallback, useEffect } from "react";
import { Page, List } from "framework7-react";

import "./option.css";
import checkmark from "../../images/checkmark.png";
import { setShowTextToSpeech, setHtmlZoom } from '../../utils/localStorage.js';
const Option = function (props) {
  let sizeType = "large";
  window.$$f7.preloader.hide();

  // 点击确定跳转到做量表界面
  function setOptionAndStart() {
    if (sizeType === 'large') {
      // document.body.style.zoom = 1.2;
      const scale = 1.2;
      setHtmlZoom(scale)
      document.querySelector('meta[name="viewport"]').setAttribute('content','width=100, initial-scale=' + scale + ', maximum-scale=' + scale + ', minimum-scale=' + scale + ', user-scalable=no');
      setShowTextToSpeech('true');
    } else {
      // document.body.style.zoom = 1;
      setHtmlZoom(1);
      setShowTextToSpeech('false');
    }
    props.f7router.navigate("/chat/", {
      reloadCurrent: true,
    });
  }

  // 点击设置模式类型
  function setOption(size) {
    if (size == "large") {
      document.getElementById("largeType").classList.add("font-type-active");
      document
        .getElementById("normalType")
        .classList.remove("font-type-active");
      sizeType = "large";
    } else {
      document.getElementById("normalType").classList.add("font-type-active");
      document.getElementById("largeType").classList.remove("font-type-active");
      sizeType = "normal";
    }
  }
  return (
    <Page noToolbar noNavbar noSwipeback loginScreen>
      <List>
        <div className="font-title">模式选择</div>
        <div className="font-type-subtitle">
          <div>请根据使用者的具体情况选择合适的答题模式</div>
          <div className="font-type-tip">
            <div className="font-type-tip-title">提示</div>
            <div className="font-type-tip-content">
              大字体模式专为老年用户设置
            </div>
          </div>
        </div>
      </List>
      <List>
        <div>
          <div className="font-types">
            <div
              className="font-type font-type-active"
              id="largeType"
              onClick={() => setOption("large")}
            >
              <div className="font-type-recommend">
                <div>推荐</div>
              </div>
              <div className="font-type-name">大字体模式</div>
              <div className="font-type-comment">语音读题</div>
              <div className="font-type-comment">超大字体</div>
              <div className="font-type-text font-type-large">
                这是一段测试文字
              </div>
              <div className="active-type">
                <img className="active-type-icon" src={checkmark} />
              </div>
            </div>
            <div
              className="font-type"
              id="normalType"
              onClick={() => setOption("normal")}
            >
              <div className="font-type-name">正常模式</div>
              <div className="font-type-comment">青年群体</div>
              <div className="font-type-comment">中年用户</div>
              <div className="font-type-text font-type-normal">
                这是一段测试文字
              </div>
              <div className="active-type">
                <img className="active-type-icon" src={checkmark} />
              </div>
            </div>
          </div>
          <div
            className="btn-submit"
            onClick={() => {
              setOptionAndStart();
            }}
          >
            确定
          </div>
        </div>
      </List>
    </Page>
  );
};
export default Option;
