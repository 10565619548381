import { Icon, Link, Navbar, NavLeft, NavRight, Page, Popup } from 'framework7-react';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { ReactComponent as SelectBodyPart } from '../images/body_joint_foward.svg';
import { ReactComponent as SelectBodyPartBack } from '../images/body_joint_back.svg';
import './AnswerBoard.css';
import './AnswerBoardSelectBodyPartForm.css';
import './PopupStyle.css';
import StaticFormPopup from './StaticFormPopup.js';
import { getJsonFormById } from '../modules/shufu.js';

// import DemoChooseItemAndViewInvoicePopup from './DemoChooseItemAndViewInvoicePopup.js';

SelectBodyJointFormPopup.defaultProps = {
    schema: {},
    model: {},
    submitHandler: null,
    canSubmit: true
};
const backJoint = ["cervicalVertebra", "thoracicVertebra", "lumberVertebra"];
export default function SelectBodyJointFormPopup(props) {
    const [staticPopupOpen, setStaticPopupOpen] = useState(false);
    const [model, setModel] = useState(props.model);
    const [bodySide, setBodySide] = useState('front');
    const [bodyPart, setBodyPart] = useState(null);
    const [jointList, setJointList] = useState([]);
    const [lastClickJoint, setLastClickJoint] = useState([]);
    const [bodyInformationConfirm, setBodyInformationConfirm] = useState(null);

    const { schema, submitHandler, canSubmit, propsData } = props;

    useEffect(() => {
    }, [])

    useEffect(() => {
        if (propsData) {
            const _shufuFormId = _.get(JSON.parse(_.get(JSON.parse(_.get(propsData, ['message', 'data'], '{}')), '_lctext', '{}')), ['schema', 'shufuFormId'], '');
            getForm(_shufuFormId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    async function getForm(formId) {
        try {
            let jsonForm = await getJsonFormById(formId);
            let form = JSON.parse(jsonForm.form);
            const _enum = _.get(form.schema, ['properties', 'bodyParts', 'items', 'enum'], {});
            const _enumInfo = _.get(form.schema, ['properties', 'bodyParts', 'items', 'enumInfo'], {});
            const jointInfo = [];
            for (const i in _enum) {
                const imageUrls = _.get(_enumInfo, [i, 'imageUrls'], null);
                const information_zh = _.get(_enumInfo, [i, 'information_zh'], null);
                jointInfo.push({
                    name: _enum[i],
                    ename: i,
                    imageUrls,
                    information_zh
                });
            }
            setJointList(jointInfo);
            const selectJointPart = _.get(JSON.parse(_.get(propsData, ['messageJson', '_lctext'], '{}')), ['model', 'bodyParts'], []);
            setLastClickJoint(_.get(props, ['model', 'bodyParts'], null) || selectJointPart);
            renderAllColor(selectJointPart);
        } catch (e) {
            // do nothing
        }
    }

    useEffect(() => {
        const _enum = _.get(schema, ['properties', 'bodyParts', 'items', 'enum'], {});
        const _enumInfo = _.get(schema, ['properties', 'bodyParts', 'items', 'enumInfo'], {});
        const jointInfo = [];
        for (const i in _enum) {
            const imageUrls = _.get(_enumInfo, [i, 'imageUrls'], null);
            const information_zh = _.get(_enumInfo, [i, 'information_zh'], null);
            jointInfo.push({
                name: _enum[i],
                ename: i,
                imageUrls,
                information_zh
            });
        }
        setJointList(jointInfo);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.schema])

    useEffect(() => {
        const selectedBodyParts = Object.keys(model[bodySide] || []);
        for (const selectedBodyPart of selectedBodyParts) {
            const bodyPartSvgs = document.querySelectorAll(`[id='${selectedBodyPart}']`);
            for (const bodyPartSvg of bodyPartSvgs) {
                if (bodyPartSvg) {
                    bodyPartSvg.setAttribute("fill", "#ffcc66");
                } else {
                    console.warn("failed to find bodyPart: ", selectedBodyPart);
                }
            }
        }
    }, [model, bodySide])

    function confirmToSubmit() {
        if (submitHandler && lastClickJoint.length > 0) {
            // 增加确认弹框，二次确认后提交
            window.$$f7.dialog.confirm('请检查您的选择，确认提交后将不得修改。是否确认提交？', '提示', () => {
                console.log({ bodyParts: lastClickJoint });
                submitHandler({ bodyParts: lastClickJoint });
            })
        } else {
            window.$$f7.dialog.alert("请至少选择一个部位。", false);
        }
    }

    function clickJoint(joint) {
        if (propsData) {
            return;
        }
        setBodySide(backJoint.includes(joint.ename) ? 'back' : 'front');
        if (joint.imageUrls) {
            if (lastClickJoint.includes(joint.ename)) {
                renderJointColor(joint.ename, "white");
                const i = lastClickJoint.indexOf(joint.ename);
                let _lastClickJoint = [...lastClickJoint];
                _lastClickJoint.splice(i, 1);
                setLastClickJoint(_lastClickJoint);
            } else {
                setBodyInformationConfirm(joint);
            }
        } else {
            setTimeout(() => {
                if (lastClickJoint.includes(joint.ename)) {
                    renderJointColor(joint.ename, "white");
                    const i = lastClickJoint.indexOf(joint.ename);
                    let _lastClickJoint = [...lastClickJoint];
                    _lastClickJoint.splice(i, 1);
                    setLastClickJoint(_lastClickJoint);
                } else {
                    for (let i in jointList) {
                        if (joint.ename === jointList[i].ename) {
                            renderJointColor(jointList[i].ename, "pink");
                        }
                    }
                    setLastClickJoint([...lastClickJoint, joint.ename]);
                }
            }, 0);
        }
    }

    function renderJointColor(ename, color) {
        // 手部关节id是三个关节组成：proximalFingerJoint-distalFingerJoint-palmFingerJiont
        if (ename === 'proximalFingerJoint' || ename === 'distalFingerJoint' || ename === 'palmFingerJiont') {
            ename = 'proximalFingerJoint-distalFingerJoint-palmFingerJiont';
        }
        // UI 切图问题，时间来不及，临时解决方案，髋部四个部位Id手动写死区分
        if(ename === 'hip') {
            if (color === 'white') {
                // 髋部是stroke不是fill,且默认颜色是蓝色
                fillJointColor('hip-stroke', '#2979ff', 'stroke');
            } else {
                fillJointColor('hip-stroke', color, 'stroke');
            }
        }
        fillJointColor(ename, color, 'fill');
        
    }

    function  fillJointColor(ename, color, attr) {
        const bodyPartSvgs = document.querySelectorAll(`[id='${ename}']`);
        for (const bodyPartSvg of bodyPartSvgs) {
            if (bodyPartSvg) {
                bodyPartSvg.setAttribute(attr, color);
            } else {
                console.warn("failed to find bodyPart: ", selectedBodyPart);
            }
        }
    }

    function renderAllColor(selectJointList = null) {
        setTimeout(() => {
            const list = (selectJointList && selectJointList.length > 0) ? selectJointList : lastClickJoint;
            for (let i in list) {
                renderJointColor(list[i], "pink");
            }
        }, 0);
    }

    return (
        <Page>
            <Navbar>
                <NavLeft>
                    <Link popupClose>
                        <Icon icon="icon-back"></Icon> <span> 返回</span>
                    </Link>
                </NavLeft>
                {/* <NavTitle>{_.get(props, 'schema.title_zh')}</NavTitle> */}
                {canSubmit ? <NavRight>
                    <Link
                        onClick={() => {
                            confirmToSubmit()
                        }}
                    >
                        <span> 提交</span>
                    </Link>
                </NavRight> : null}
            </Navbar>
            <div style={{ padding: 20 }}>
            {propsData
                    ? <div className='body-content-joint '>已选择关节：</div>
                    : <div className='body-content-joint '>{schema.title_zh || ''}</div>}
                <div style={{ float: 'left', borderRadius: 5, marginBottom: 10 }} onClick={() => {
                    setBodySide(bodySide === 'front' ? 'back' : 'front');
                    renderAllColor();
                }}>
                    <span style={{ borderRadius: "10px 0px 0px 10px" }} className={bodySide === 'front' ? 'highlight' : 'no-highlight'}>正面</span>
                    <span style={{ borderRadius: "0px 10px 10px 0px" }} className={bodySide === 'front' ? 'no-highlight' : 'highlight'}>背面</span>
                </div>
                <div style={{ clear: 'both' }}></div>
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 2 }}>
                        <div style={{ display: bodySide === 'front' ? 'block' : 'none' }} className='body-part body-part-joint'>
                            <SelectBodyPart
                                className='body-part-joint-svg'
                                onClick={e => {
                                    if (!e.target.id) return;
                                    if (canSubmit || _.get(model, [bodySide, e.target.id])) {
                                        setBodyPart(e.target.id);
                                        setStaticPopupOpen(true);
                                    }
                                }}
                            >
                            </SelectBodyPart>
                        </div>
                        <div style={{ display: bodySide !== 'front' ? 'block' : 'none' }} className='body-part body-part-joint'>
                            <SelectBodyPartBack
                                className='body-part-joint-svg'
                                onClick={e => {
                                    if (!e.target.id) return;
                                    if (canSubmit || _.get(model, [bodySide, e.target.id])) {
                                        setBodyPart(e.target.id);
                                        setStaticPopupOpen(true);
                                    }
                                }}
                            >
                            </SelectBodyPartBack>
                        </div>
                        {/* {bodySide === 'front' ? (
                            <div className='body-part body-part-joint'>
                                <SelectBodyPart
                                    className='body-part-joint-svg'
                                    onClick={e => {
                                        if (!e.target.id) return;
                                        if (canSubmit || _.get(model, [bodySide, e.target.id])) {
                                            setBodyPart(e.target.id);
                                            setStaticPopupOpen(true);
                                        }
                                    }}
                                >
                                </SelectBodyPart>
                            </div>
                        ) : (
                            <div className='body-part body-part-joint'>
                                <SelectBodyPartBack
                                    className='body-part-joint-svg'
                                    onClick={e => {
                                        if (!e.target.id) return;
                                        if (canSubmit || _.get(model, [bodySide, e.target.id])) {
                                            setBodyPart(e.target.id);
                                            setStaticPopupOpen(true);
                                        }
                                    }}
                                >
                                </SelectBodyPartBack>
                            </div>
                        )} */}
                    </div>
                    <div style={{ flex: 1, padding: 10 }}>
                        {jointList.map(joint => <div
                            className={lastClickJoint.includes(joint.ename) ? 'body-part-each-joint body-part-each-joint-active' : 'body-part-each-joint'}
                            onClick={() => clickJoint(joint)}
                        >{joint.name}</div>)}
                    </div>
                </div>
            </div>
            <Popup
                className="static-form-popup"
                opened={staticPopupOpen}
                onPopupClosed={() => {
                    setStaticPopupOpen(false);
                }}
            >
                <StaticFormPopup
                    key={`static-form-${bodySide}-${bodyPart}`}
                    schema={_.get(schema, ['properties', bodySide, 'properties', bodyPart], {})}
                    model={_.get(model, [bodySide, bodyPart], {})}
                    submitHandler={bodyPartModel => {
                        const newModel = _.cloneDeep(model);
                        _.set(newModel, [bodySide, bodyPart], bodyPartModel);
                        setModel(newModel);
                        setStaticPopupOpen(false);
                    }}
                    canSubmit={canSubmit}
                    requireConfirm={false}
                ></StaticFormPopup>
            </Popup>
            {bodyInformationConfirm ? <div className='body-information-confirm'>
                <div className='body-information-confirm-imgs'>
                    {bodyInformationConfirm.imageUrls.map(img => <img src={img} style={{ width: '100%' }}></img>)}
                </div>
                <div className='body-information-confirm-text'>{bodyInformationConfirm.information_zh}</div>
                <div className='body-information-confirm-btns'>
                    <div
                        className='body-information-confirm-btn body-information-confirm-btn-cancel'
                        onClick={() => {
                            setBodyInformationConfirm(null);
                            renderAllColor();
                        }}
                    >选错了，重新选择</div>
                    <div
                        className='body-information-confirm-btn body-information-confirm-btn-ok'
                        onClick={() => {
                            for (let i in jointList) {
                                if (bodyInformationConfirm.ename === jointList[i].ename) {
                                    renderJointColor(jointList[i].ename, "pink");
                                }
                            }
                            setLastClickJoint([...lastClickJoint, bodyInformationConfirm.ename]);
                            setBodyInformationConfirm(null);
                            renderAllColor();
                        }}
                    >确认无误</div>
                </div>
            </div> : null}
        </Page>
    );
}
