import React, { useState, useEffect } from 'react';
import {
  Page,
  Navbar,
  NavLeft,
  Link,
  Icon,
  Block,
  Card,
  NavTitle,
  Input,
  List,
  ListItem,
  Button,
  Checkbox
} from 'framework7-react';
import _ from 'lodash';
import parseLanguage from '../utils/parseLanguage.js';
import isAnswerValid from '../utils/isAnswerValid.js';
import { getShowTextToSpeech } from '../utils/localStorage.js';
import './SymptomDetailPopup.css';
import { useTranslation } from 'react-i18next';
import TemplateQuestion from '../components/TemplateQuestion.js';

import InputWithSuffix from './InputWithSuffix.js';
import voice from '../images/voice.png';
import playCustomVoice from '../utils/voiceUtils.js';

import i18n from '../i18n';

SymptomDetailPopup.defaultProps = {
  symptom: null,
  selectedCategory: '',
  submitHandler: function () {
    console.warn('submitHandler not defined');
  }
};

export function getTitle(symptomSchema, symptomModel, schema) {
  if (!schema) return '';
  const title = schema.type === "array" ? schema.title_zh + "（多选）" : schema.title_zh;
  const evaluatedTitle = evaluateScript(symptomSchema, symptomModel, title, schema.requiredVar);
  return evaluatedTitle;
}

export function getAnswerDisplay(symptomSchema, symptomModel, schema, model) {
  if (!schema) return '';
  if (model === 'SKIPPED') return schema.skipText || i18n.t('skipped');

  if (schema.type === "string" && schema.enum) {
    if (model.match(/^__OTHER__.+/)) {
      return model.replace(/^__OTHER__/, '');
    }
    return evaluateScript(symptomSchema, symptomModel, parseLanguage(schema.enum[model]));
  }

  if (schema.type === "array") {
    return model.map((modelItem) => {
      if (modelItem.match(/^__OTHER__.+/)) {
        return modelItem.replace(/^__OTHER__/, '');
      }
      return evaluateScript(symptomSchema, symptomModel, parseLanguage(schema.items.enum[modelItem]));
    }).join("、");
  }

  return model;
}

function evaluateScript(symptomSchema, symptomModel, script, requiredVar = []) {
  const varRegex = /\${(.*?)}/g;
  let matchTemp = [], matchResult = [];
  while ((matchTemp = varRegex.exec(script)) != null) {
    matchResult = matchResult.concat([...matchTemp])
  }
  const allRequiredVar = _.uniq(matchResult.concat(requiredVar));
  if (allRequiredVar.length === 0) return script; //if no variables are required

  const availableVar = allRequiredVar.filter((varName) => symptomModel[varName] != null);
  if (availableVar.length != allRequiredVar.length) return undefined; //if not all required variables are available

  const scriptFn = new Function(...availableVar, "try { return `" + script + "`; } catch (e) {return undefined;}");
  const evaluatedValue = scriptFn(...availableVar.map(varName => getAnswerDisplay(symptomSchema, symptomModel, symptomSchema[varName], symptomModel[varName])));
  return evaluatedValue;
}

export default function SymptomDetailPopup(props) {
  const selectedSymptom = props.symptom;
  const selectedCategory = props.selectedCategory;
  const symptomSchema = selectedSymptom && selectedSymptom.attributeValueSchema;
  const [model, setModel] = useState({});
  const showTextToSpeech = getShowTextToSpeech();


  function renderArray(schema, key) {
    const { isRequired, information_zh: information, items: { order, enum: enums, enumInfo } } = schema;
    const enumOrder = order || Object.keys(enums);
    const title = getTitle(symptomSchema, model, schema);
    if (title === undefined) return null;

    return (
      <Card
        key={key}
        padding={false}
        content={
          <div>
            {renderSectionTitle(title, key, model, true, isRequired, information)}

            <List>
              {enumOrder.map(enumKey => {
                const information = _.get(enumInfo || {}, [enumKey, "information_zh"]);
                const informationButton = information ? renderInformationButton(information) : null;
                const answerDisplay = getAnswerDisplay(symptomSchema, model, schema, [enumKey]);
                const answerContent = <div>{answerDisplay}{informationButton}</div>;
                if (answerDisplay === undefined) return null;

                if (enumKey === '__OTHER__') {
                  const viewType = _.get(enumInfo || {}, [enumKey, "viewType"], "default");
                  const listItemType = viewType === 'paragraph' ? "textarea" : "text";
                  return (
                    <ListItem
                      style={{ padding: 0 }}
                      key={`${selectedCategory}.${selectedSymptom.id}.${key}.${enumKey}`}
                      name={key}
                      checkbox
                      className='square-border-checkbox'
                      title={
                        <div>
                          <Input
                            maxlength={50}
                            name={key}
                            type={listItemType}
                            placeholder={answerDisplay}
                            clearButton
                            resizable
                            wrap={true}
                            inputStyle={{}}
                            onChange={event => {

                              let checkItem = document.querySelector(
                                `input[name='${key}'][type='checkbox'][value='__OTHER__']`
                              );

                              const value = event.target.value;
                              let newModel = {
                                ...model
                              };

                              newModel[key] = (newModel[key] || []).filter(
                                e => !e.match(/^__OTHER__/)
                              );
                              if (value) {
                                newModel[key].push('__OTHER__' + value);
                                checkItem.checked = true;
                                let checkItemNoneOfTheAbove = document.querySelector(
                                  `input[name='${key}'][type='checkbox'][value='noneOfTheAbove']`
                                );
                                if (checkItemNoneOfTheAbove) {
                                  checkItemNoneOfTheAbove.checked = false;
                                  newModel[key] = newModel[key].filter(
                                    item => item !== 'noneOfTheAbove'
                                  );
                                }
                              } else {
                                checkItem.checked = false;
                              }
                              setModel(newModel);
                              // console.log(newModel);
                            }}
                          />
                        </div>
                      }
                      value={enumKey}
                      onChange={e => {
                        let checked = e.target.checked;
                        let value = e.target.value;
                        let newModel = {
                          ...model
                        };
                        if (checked) {
                          if (newModel[key]) {
                            newModel[key].push(value);
                          } else {
                            newModel[key] = [value];
                          }
                        } else {
                          newModel[key] = newModel[key].filter(
                            e => e !== value
                          );
                        }
                        // console.log(e.target.value, e.target.checked);
                        setModel(newModel);
                      }}
                    ></ListItem>
                  );
                }
                return (
                  <ListItem
                    style={{ padding: 0 }}
                    key={`${selectedCategory}.${selectedSymptom.id}.${key}.${enumKey}`}
                    name={key}
                    checkbox
                    className='square-border-checkbox'
                    title={answerContent}
                    value={enumKey}
                    onChange={e => {
                      let checked = e.target.checked;
                      let value = e.target.value;
                      let newModel = {
                        ...model
                      };
                      if (checked) {
                        if (value === 'noneOfTheAbove' || value === 'SKIPPED') {
                          let checkItemList = document.querySelectorAll(
                            `input[name='${key}'][type='checkbox']`
                          );
                          checkItemList.forEach(checkItem => {
                            if (checkItem.value !== value) {
                              checkItem.checked = false;
                            }
                          });
                          newModel[key] = ['noneOfTheAbove'];

                          let otherInputItem = document.querySelector(
                            `input[name='${key}'][type='text']`
                          );
                          if (otherInputItem) otherInputItem.value = '';
                        } else if (newModel[key]) {

                          const noneOfTheAboveItem = document.querySelector(
                            `input[name='${key}'][type='checkbox'][value='noneOfTheAbove']`
                          );
                          if (noneOfTheAboveItem) {
                            noneOfTheAboveItem.checked = false;
                          }

                          const skippedItem = document.querySelector(
                            `input[name='${key}'][type='checkbox'][value='SKIPPED']`
                          );
                          if (skippedItem) {
                            skippedItem.checked = false;
                          }


                          newModel[key] = newModel[key].filter(
                            item => item !== 'noneOfTheAbove' && item !== 'SKIPPED'
                          );
                          newModel[key].push(value);
                        } else {
                          const noneOfTheAboveItem = document.querySelector(
                            `input[name='${key}'][type='checkbox'][value='noneOfTheAbove']`
                          );
                          if (noneOfTheAboveItem) {
                            noneOfTheAboveItem.checked = false;
                          }

                          const skippedItem = document.querySelector(
                            `input[name='${key}'][type='checkbox'][value='SKIPPED']`
                          );
                          if (skippedItem) {
                            skippedItem.checked = false;
                          }

                          newModel[key] = [value];
                        }
                      } else {
                        newModel[key] = newModel[key].filter(e => e !== value);
                      }
                      // console.log(e.target.value, e.target.checked);
                      setModel(newModel);
                    }}
                  ></ListItem>
                );
              })}
            </List>
          </div>
        }
      ></Card>
    );
  }
  function renderEnum(schema, key) {
    const { isRequired, information_zh: information, enum: enums, enumInfo } = schema;
    let order = schema.order || Object.keys(enums);
    const title = getTitle(symptomSchema, model, schema);
    if (title === undefined) return null;
    return (
      <Card
        key={key}
        padding={false}
        content={
          <div>
            {renderSectionTitle(title, key, model, true, isRequired, information)}


            <List>
              {order.map(enumKey => {
                const information = _.get(enumInfo || {}, [enumKey, "information_zh"]);
                const informationButton = information ? renderInformationButton(information) : null;
                const answerDisplay = getAnswerDisplay(symptomSchema, model, schema, enumKey);
                const answerContent = <div>{answerDisplay}{informationButton}</div>;
                if (answerDisplay === undefined) return null;

                if (enumKey === '__OTHER__') {
                  const viewType = _.get(enumInfo || {}, [enumKey, "viewType"], "default");
                  const listItemType = viewType === 'paragraph' ? "textarea" : "text";
                  return (
                    <ListItem
                      style={{ padding: 0 }}
                      key={`${selectedCategory}.${selectedSymptom.id}.${key}.${enumKey}`}
                      name={key}
                      radio
                      className='round-border-radio'
                      title={
                        <div>
                          <Input
                            type={listItemType}
                            placeholder={answerDisplay}
                            clearButton
                            wrap={false}
                            inputStyle={{}}
                            onChange={event => {
                              let checkItem = document.querySelector(
                                `input[name='${key}'][type='radio'][value='__OTHER__']`
                              );
                              const value = event.target.value;
                              let newModel = {
                                ...model
                              };
                              if (value) {
                                newModel[key] = '__OTHER__' + value;
                                checkItem.checked = true;
                              } else {
                                delete newModel[key];
                                checkItem.checked = false;
                              }
                              setModel(newModel);
                              // console.log(newModel);
                            }}
                          />
                        </div>
                      }
                      value={enumKey}
                      onChange={e => {
                        // console.log(e.target.value);
                        setModel({
                          ...model,
                          [key]: e.target.value
                        });
                      }}
                    ></ListItem>
                  );
                }
                return (
                  <ListItem
                    style={{ padding: 0 }}
                    key={`${selectedCategory}.${selectedSymptom.id}.${key}.${enumKey}`}
                    name={key}
                    radio
                    className='round-border-radio'
                    title={answerContent}
                    value={enumKey}
                    onChange={e => {
                      setModel({
                        ...model,
                        [key]: e.target.value
                      });
                    }}
                  ></ListItem>
                );
              })}
            </List>
          </div>
        }
      ></Card>
    );
  }
  function renderString(schema, key) {
    const { isRequired, information_zh: information, suffix, allowSkip, skipText } = schema;
    const title = getTitle(symptomSchema, model, schema);
    if (title === undefined) return null;
    return (
      <Card
        key={key}
        content={
          <div>
            {renderSectionTitle(title, key, model, false, isRequired, information)}
            <div
              style={{ backgroundColor: '#f0f0f0', padding: 5, marginTop: 10 }}
            >
              <InputWithSuffix
                resizable
                questionKey={key}
                type="textarea"
                placeholder="请输入"
                suffixList={suffix}
                model={model[key]}
                onChange={value => {
                  // console.log(value);
                  setModel({
                    ...model,
                    [key]: value
                  });
                }}
              />
            </div>
            {allowSkip ? renderSkipOption(key, skipText) : null}
          </div>
        }
      ></Card>
    );
  }
  function renderNumber(schema, key) {
    const { isRequired, information_zh: information, suffix, allowSkip, skipText } = schema;
    const title = getTitle(symptomSchema, model, schema);
    if (title === undefined) return null;
    return (
      <Card
        key={key}
        content={
          <div>
            {renderSectionTitle(title, key, model, false, isRequired, information)}
            <div
              style={{ backgroundColor: '#f0f0f0', padding: 5, marginTop: 10 }}
            >
              <InputWithSuffix
                resizable
                questionKey={key}
                placeholder="请输入"
                type="text"
                inputType="number"
                inputmode="decimal"
                pattern="\d*"
                suffixList={suffix}
                model={model[key]}
                onChange={value => {
                  // console.log(value);
                  setModel({
                    ...model,
                    [key]: value
                  });
                }}
              />
            </div>
            {allowSkip ? renderSkipOption(key, skipText) : null}
          </div>
        }
      ></Card>
    );
  }

  function renderSkipOption(key, skipText) {
    return (<div style={{ marginTop: 10 }}>
      <Checkbox
        style={{ marginRight: 10 }}
        key={`${selectedCategory}.${selectedSymptom.id}.${key}.skip`}
        name={key}
        checked={model[key] === 'SKIPPED'}
        onChange={() => {
          if (model[key] === 'SKIPPED') {
            setModel({
              ...model,
              [key]: undefined
            });
          }
          else {
            setModel({
              ...model,
              [key]: 'SKIPPED'
            });
          }
        }}
      ></Checkbox>
      <span>{skipText || i18n.t('skip')}</span>
    </div>)
  }

  function renderTemplateQuestion(schema, key) {
    const { isRequired, information_zh: information, templateSchema, templateList } = schema;
    const title = getTitle(symptomSchema, model, schema);
    if (title === undefined) return null;
    return (
      <Card
        key={key}
        content={
          <div>
            {renderSectionTitle(title, key, model, false, isRequired, information)}
            <div
              style={{ padding: 5, marginTop: 10 }}
            >
              <TemplateQuestion
                schema={schema}
                templateSchema={templateSchema}
                templateList={templateList.map((template) => evaluateScript(symptomSchema, model, template))}
                templateKey={key}
                onChange={value => {
                  // console.log(value);
                  setModel({
                    ...model,
                    [key]: value
                  });
                }}
              />
            </div>
          </div>
        }
      ></Card>
    );
  }


  function renderSectionTitle(title, path, model, padding = true, isRequired = false, information) {

    const answer = _.get(model, path);
    let answerExists = isAnswerValid(answer);

    const isRequiredIcon = isRequired && !answerExists ? <b style={{ color: 'red', paddingLeft: 5 }}>(*)</b> : null;
    const informationButton = information ? renderInformationButton(information) : null;

    if (showTextToSpeech === "true") {
      return (
        <div className='shufu-voice-content' onClick={() => playCustomVoice(evaluatedTitle)}>
          <div
            style={{
              color: 'black',
              fontSize: 16,
              fontWeight: 'bold',
              paddingTop: padding ? 15 : 0,
              paddingLeft: padding ? 15 : 0,
              paddingRight: padding ? 15 : 0
            }}
          >
            {title}{isRequiredIcon}{informationButton}
          </div>
          <img className='shufu-voice-symptom-detail-img' src={voice}></img>
        </div>);
    }

    return (
      <div
        style={{
          color: 'black',
          fontSize: 16,
          fontWeight: 'bold',
          paddingTop: padding ? 15 : 0,
          paddingLeft: padding ? 15 : 0,
          paddingRight: padding ? 15 : 0
        }}
      >
        {title}{isRequiredIcon}
      </div>
    )
  }

  function checkAskedIf(schema) {
    const askedIf = schema.askedIf || [];

    if (askedIf.length === 0) return true;

    const answers = _.flatten(_.values(model));

    return answers.some(answer => {
      return askedIf.filter(r => r === answer).length > 0;
    });

  }

  function renderInformationButton(information) {
    return <span
      style={{ marginLeft: 10 }}
      className="shufu-answer-button-information-icon"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        window.$$f7.dialog.alert(information, false, { cssClass: 'dialog-text-left' });
      }}>?</span>
  }

  function renderSection(schema, key) {
    const needAsk = checkAskedIf(schema);

    if (!needAsk) {
      if (model[key]) {
        let newModel = { ...model };
        delete newModel[key];
        setModel(newModel);
      }
      return null;
    }
    if (schema.type === 'array') {
      return renderArray(schema, key);
    } else if (schema.type === 'string' && schema.viewType === 'template') {
      return renderTemplateQuestion(schema, key);
    } else if (schema.type === 'string' && schema.enum) {
      return renderEnum(schema, key);
    } else if (schema.type === 'number') {
      return renderNumber(schema, key);
    } else {
      return renderString(schema, key);
    }
  }

  useEffect(() => {
    setModel({});
    // if symptom changed, set model to default
  }, [props.symptom]);

  function checkModel() {
    const sectionKeyList = symptomSchema.order || Object.keys(symptomSchema);
    return sectionKeyList
      .map(sectionKey => {
        const section = symptomSchema[sectionKey];
        if (!checkAskedIf(section)) return true;
        if (section.type === 'object') {
          let questionKeys = section.order || Object.keys(section.properties);

          return questionKeys
            .map(questionKey => {
              const question = section.properties[questionKey];
              if (!question.isRequired) return true;
              return model[sectionKey] &&
                model[sectionKey][questionKey] !== undefined
            })
            .every(e => e);
        } else if (
          section.type === 'array' &&
          section.items.type === 'object'
        ) {
          if (section.isRequired) {
            if (!model[sectionKey] || model[sectionKey].length == 0) return false;
          }
          return (
            model[sectionKey] &&
            model[sectionKey].every &&
            model[sectionKey].every(item => !!item.__ok)
          );
        } else {
          if (!section.isRequired) return true;
          const answer = model[sectionKey];
          return isAnswerValid(answer);
        }
      })
      .every(e => e);
  }

  function confirmToSubmit() {
    if (checkModel()) {
      // 增加确认弹框，二次确认后提交
      const symptom = JSON.parse(JSON.stringify(props.symptom));
      if (symptom.attributeValueSchema) delete symptom.attributeValueSchema;
      if (symptom.attributeValueSchemaStr) delete symptom.attributeValueSchemaStr;
      symptom.attributeValueModel = model
      props.submitHandler(symptom);
    } else {
      window.$$f7.dialog.alert(i18n.t('missing_answers'), false);
    }
  }

  if (props.symptom) {
    let sectionKeys = Object.keys(symptomSchema || {});
    console.log('symptom:', props.symptom)
    return (
      <Page key={props.symptom && props.symptom.id}>
        <Navbar>
          <NavLeft>
            <Link popupClose>
              <Icon icon="icon-back"></Icon> <span> 返回</span>
            </Link>
          </NavLeft>
          <NavTitle>{_.get(selectedSymptom, 'synonyms_zh[0]') || _.get(selectedSymptom, 'displayName_zh')}</NavTitle>
        </Navbar>
        <Block className="symptom-detail-popup-block">
          {sectionKeys.map(sectionKey => {
            return renderSection(
              symptomSchema[sectionKey],
              sectionKey
            );
          })}
        </Block>
        <Block>
          <Button
            large
            raised
            fill
            round
            onClick={() => {
              confirmToSubmit();

              return;
            }}
          >
            提交
          </Button>
        </Block>
      </Page>
    );
  } else {
    return null;
  }
}
