
/**
 * 根据value 获取key
 * @param {*} obj 
 * @param {*} value 
 * @returns 
 */
function findKeyByValue(obj, value) {
  for(let key in obj) {
    if (obj[key] === value) {
      return key 
    }
  }
  return null
}


/**
 *  解析意图，组装数据
 * @param {*} intentions  原始意图列表
 * @param {*} nlpResult  nlp解析结果
 * @param {*} defaultIntention 
 * @returns {intention1: score1, intention2: score2, matchIntention: matchIntention}
 */
export function parseIntentions(intentions, nlpResult, defaultIntention) {
  try {
    const { scores, labels } = nlpResult;
    const topScore = scores[0];
    const matchingLabel = labels[0];
    // 根据最高分，获取意图的key
    // 组装用于保存的数据
    let result = labels.reduce((pre, cur, index) => {
      const matchKey = findKeyByValue(intentions, cur);
      matchKey && (pre[matchKey] = scores[index]);
      return pre
    }, {})
    if (topScore > 0.8) {
      const mappedIntention = findKeyByValue(intentions, matchingLabel);
      result['mappedIntention'] = mappedIntention;
    } else {
      result['mappedIntention'] = defaultIntention;
    }
    return result;
  } catch (e) {
    console.log('parseIntentions error:', e)
    return {
      mappedIntention: defaultIntention
    };
  }
}