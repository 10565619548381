import React, { useState, useCallback, useEffect } from 'react';
import { Button } from 'framework7-react';
import ShareTestModal from './shareTestModal';
import shareIcon from '../../images/share-alt-solid.svg';

import getParameterByName from '../../utils/getParameterByName';

import './report.css';
const ShareTestParagraph = function(props) {
  const [showShareModal, setShowShareModal] = useState(false);

  function renderShareModal() {
    if (!showShareModal) {
      return null;
    }
    const isTc = getParameterByName('lang') === 'tc';

    return (
      <div
        className="modal-background"
        style={{display: 'flex'}}
        onClick={event => {
          if (
            event.target.className &&
            event.target.className.match(/modal-background/)
          ) {
            setShowShareModal(false);
          }
          console.log(event.target.className);
        }}
      >
        <ShareTestModal
          testConfig={props.report.shareConfig.testConfig}
          testBackgroundUrl={
            isTc
              ? props.report.shareConfig.testBackgroundUrl_tc ||
                props.report.shareConfig.testBackgroundUrl
              : props.report.shareConfig.testBackgroundUrl
          }
        />
      </div>
    );
  }

  return (
    <div
      style={{
        boxShadow:
          '0 2px 4px 0 rgba(0, 0, 0, 0.15), 0 3px 9px 0 rgba(0, 0, 0, 0.09)',
        position: 'fixed',
        bottom: 100,
        right: 10,
        backgroundColor: '#ffffff',
        borderRadius: 8
      }}
    >
      <div className="share-test-button">
        <Button
          onClick={() => {
            setShowShareModal(true);
          }}
        >
          {getParameterByName('lang') === 'tc'
            ? '邀請朋友也來測一測'
            : '邀请朋友也来测一测'}
        </Button>
        <img className="share-icon" src={shareIcon} height="20" />
        {renderShareModal()}
      </div>
    </div>
  );
};
ShareTestParagraph.defaultProps = {
  report: {
    shareConfig: {
      testConfig: {},
      testBackgroundUrl: ''
    }
  }
};
export default ShareTestParagraph;
