import React, { useEffect } from 'react';
import { Message, Link } from 'framework7-react';
import './MessageBlock.css';

import { getShowTextToSpeech } from '../utils/localStorage.js';
import { answerBoardEmitter } from '../modules/answerBoardEmitter.js';
import TextToSpeechMessage from './TextToSpeechMessage';
import robot from '../images/robot.png';
MessageBlockText.defaultProps = {
  messageJson: {},
  isLastMessage: false
};
var Chinese = require('chinese-s2t');
import getParameterByName from '../utils/getParameterByName';

function parseLanguage(text, language) {
  const str = text ? `${text}` : text;
  if (str && str.includes('|~|')) {
    const [text_zh, text_en] = str.split('|~|');
    return language && language === 'en' ? text_en : text_zh;
  } else {
    return str;
  }
  // document.getElementById("result").innerHTML = res;
}

function getHtmlStrContent(htmlStr) {
  return <div
    dangerouslySetInnerHTML={{ __html: htmlStr }}
  ></div>
}

function parseLinks(inputString) {
  const reg = /(http:\/\/|https:\/\/)((\w|:|\=|\?|\.|\/|&|-|[0-9])+)/g;
  let match;
  let parsedIndex = 0;
  let parsedHtml = [];
  inputString = inputString.replace("\\t", '\n');
  while ((match = reg.exec(inputString)) != null) {
    const textBeforeUrl = inputString.slice(parsedIndex, match.index);
    const url = inputString.slice(match.index, reg.lastIndex);
    parsedHtml.push(
      <span key={match.index}>
        {textBeforeUrl}
        <a className="external" href={url}>{url}</a>
      </span>
    );
    parsedIndex = reg.lastIndex;
  }
  parsedHtml.push(<span key={parsedIndex}>{inputString.slice(parsedIndex, inputString.length)}</span>)
  return parsedHtml;
}

export default function MessageBlockText(props) {
  useEffect(() => {
    if (props.isLastMessage) {
      answerBoardEmitter.emit('set-answer-board', {
        type: 'empty'
      });
    }
  }, [props.isLastMessage]);

  const showTextToSpeech = getShowTextToSpeech();

  let text = props.messageJson['_lctext'];

  if (getParameterByName('lang') === 'tc') {
    text = Chinese.s2t(text);
  }

  // workaround for 冠状病毒 report
  if (text.match(/http.+\/report\/.+$/)) {
    let url = new URL(text.match(/http.+\/report\/.+$/)[0]);

    text = text.replace(/http.+\/report\/.+$/, '');

    return (
      <Message
        className="shufu-text-message-bubble"
        style={{ marginBottom: 5, marginTop: 5 }}
        type="received"
        avatar={props.robotAvatarImageUrl || robot}
      // class='external'
      >
        {text.trim()}
        <Link href={url.pathname} style={{ marginTop: 10 }}>
          {getParameterByName('lang') === 'tc'
            ? '點擊查看'
            : getParameterByName('lang') === 'en'
              ? 'Click here to view your report'
              : '点击查看'}
        </Link>
      </Message>
    );
  }



  const content = text.match(/^__HTML__{([\s\S]+)}$/)
    ? getHtmlStrContent(text.match(/^__HTML__{([\s\S]+)}$/)[1])
    : parseLanguage(text).split('\\n')
      .map((p, index) => {
        return p.trim() ? <div key={index}>{parseLinks(p.trim())}</div> : <div><br /></div>;
      })


  if (showTextToSpeech === 'true') {
    return <TextToSpeechMessage
      key={props.messageId}
      speechText={parseLanguage(text)}
    >
      {content}
    </TextToSpeechMessage>
  }

  return (
    <Message
      key={props.messageId}
      className="shufu-text-message-bubble"
      style={{ marginBottom: 5, marginTop: 5 }}
      type="received"
      avatar={props.robotAvatarImageUrl || robot}
    // class='external'
    >
      {content}

    </Message>
  );
}
