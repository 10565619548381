// 威斯康辛底部四张照片
import {
  Col,
  Row
} from 'framework7-react';
import React, { useCallback } from 'react';
import one_red_triangle from '../images/wcstCards/1-red-triangle.png';
import two_green_stars from '../images/wcstCards/2-green-star.png';
import three_yellow_crosses from '../images/wcstCards/3-yellow-cross.png';
import four_blue_circles from '../images/wcstCards/4-blue-circle.png';
import { answerBoardEmitter } from '../modules/answerBoardEmitter.js';
import './AnswerBoard.css';
import './AnswerBoardWCSTView.css';
import './PopupStyle.css';

export default function AnswerBoardWCSTView(props) {

  // 选择的答案是要存在model里面的
  const submitHandler = useCallback((card) => {
    console.log("submitting card: ", card);
    answerBoardEmitter.emit('answer', card);
  }, []);

  return (
    <div className="answer-board-inline-form-wrapper" style={{ flex: 'inherit', width: '98%' }}>
      <div className='answerBoard-wcst'>
        <Row >
          <Col><img onClick={() => submitHandler("4-blue-circle")} src={four_blue_circles} className='answerBoard-wcst-img'></img></Col>
          <Col><img onClick={() => submitHandler("3-yellow-cross")} src={three_yellow_crosses} className='answerBoard-wcst-img'></img></Col>
          <Col><img onClick={() => submitHandler("2-green-star")} src={two_green_stars} className='answerBoard-wcst-img'></img></Col>
          <Col><img onClick={() => submitHandler("1-red-triangle")} src={one_red_triangle} className='answerBoard-wcst-img'></img></Col>
        </Row>
      </div>
    </div>
  )
}