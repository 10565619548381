// 生成威斯康辛卡片顺序
export default function wcstCardOrder() {
  // let number = ['1', '2', '3', '4'];
  // let color = ['blue', 'green', 'red', 'yellow'];
  // let form = [ 'circle', 'cross', 'star', 'triangle'];

  // let cardOrder = [];

  // number.map((numItem, numIndex) => {
  //   color.map((colorItem, colorIndex) => {
  //     form.map((formItem, formIndex) => {
  //       cardOrder.push(numItem + colorItem + formItem);
  //     });
  //   });
  // })

  let cardOrder = [
    '2-green-cross',
    '1-blue-circle',
    '3-red-star',
    '4-yellow-triangle',
    '2-red-cross',
    '1-green-circle',
    '4-blue-triangle',
    '3-yellow-star',
    '1-red-circle',
    '2-yellow-cross',
    '3-blue-star',
    '4-green-triangle',
    '2-blue-cross',
    '3-green-star',
    '4-red-triangle',
    '1-yellow-circle',
    '3-red-triangle',
    '1-blue-cross',
    '2-green-star',
    '4-yellow-circle',
    '2-red-star',
    '1-green-cross',
    '4-blue-circle',
    '3-yellow-triangle',
    '1-red-cross',
    '2-yellow-star',
    '4-green-circle',
    '3-blue-triangle',
    '1-yellow-cross',
    '2-blue-star',
    '3-green-triangle',
    '4-red-circle',
    '2-green-triangle',
    '1-blue-star',
    '4-yellow-cross',
    '3-red-circle',
    '1-green-star',
    '2-red-triangle',
    '3-yellow-circle',
    '4-blue-cross',
    '1-red-star',
    '4-green-cross',
    '2-yellow-triangle',
    '3-blue-circle',
    '1-yellow-star',
    '2-blue-triangle',
    '4-red-cross',
    '3-green-circle',
    '1-blue-triangle',
    '3-red-cross',
    '2-green-circle',
    '4-yellow-star',
    '2-red-circle',
    '3-yellow-cross',
    '1-green-triangle',
    '4-blue-star',
    '1-red-triangle',
    '4-green-star',
    '2-yellow-circle',
    '3-blue-cross',
    '1-yellow-triangle',
    '3-green-cross',
    '2-blue-circle',
    '4-red-star',
    '1-blue-circle',
    '2-green-cross',
    '4-yellow-triangle',
    '3-red-star',
    '1-green-circle',
    '2-red-cross',
    '4-blue-triangle',
    '3-yellow-star',
    '4-green-triangle',
    '1-red-circle',
    '3-blue-star',
    '2-yellow-cross',
    '4-red-triangle',
    '2-blue-cross',
    '3-green-star',
    '1-yellow-circle',
    '2-green-star',
    '3-red-triangle',
    '1-blue-cross',
    '4-yellow-circle',
    '1-green-cross',
    '3-yellow-triangle',
    '2-red-star',
    '4-blue-circle',
    '1-red-cross',
    '2-yellow-star',
    '4-green-circle',
    '3-blue-triangle',
    '1-yellow-cross',
    '2-blue-star',
    '3-green-triangle',
    '4-red-circle',
    '1-blue-star',
    '2-green-triangle',
    '4-yellow-cross',
    '3-red-circle',
    '4-blue-cross',
    '2-red-triangle',
    '1-green-star',
    '3-yellow-circle',
    '1-red-star',
    '3-blue-circle',
    '2-yellow-triangle',
    '4-green-cross',
    '1-yellow-star',
    '4-red-cross',
    '2-blue-triangle',
    '3-green-circle',
    '1-blue-triangle',
    '2-green-circle',
    '3-red-cross',
    '4-yellow-star',
    '1-green-triangle',
    '4-blue-star',
    '2-red-circle',
    '3-yellow-cross',
    '1-red-triangle',
    '2-yellow-circle',
    '4-green-star',
    '3-blue-cross',
    '4-red-star',
    '1-yellow-triangle',
    '2-blue-circle',
    '3-green-cross',
  ];

  return cardOrder
}