// import _ from 'lodash';
const EventEmitter = require('events');
class AnswerBoardEmitter extends EventEmitter {
  //   constructor(props) {
  //     super(props);
  //     this.debouncedEmit = _.debounce(
  //       (eventName, data) => {
  //         console.log('~~~EMIT');
  //         super.emit(eventName, data);
  //       },
  //       300,
  //       { leading: true }
  //     );
  //   }
  //   emit(eventName, data) {
  //     console.log(`EMIT222`, eventName, data);
  //     this.debouncedEmit(eventName, data);
  //   }
}
export const answerBoardEmitter = new AnswerBoardEmitter();

/**
 *
 * eventNames:
 *  answer
 *  submit
 *  set-answer-board
 *
 *
 */
