// 精总，3个量表，可自定义选择至少一个
// 排列组合，可根据所选量表，确定对应的sceneId
// sceneId 之前已生成
// emotion sleep stress 
export const fromToSceneId = {
  // default 默认是精总
  default: {
    'emotion': '36589a7ea450446',
    'sleep': 'f02c7a68422e4d5',
    'stress': '580a04df3b2c443',
    'emotion-sleep': 'feab8a25d2164cc',
    'emotion-stress': 'd8916536450b453',
    'sleep-stress': '9c29c95c6a5d491',
    'emotion-sleep-stress': 'ba8275fcfaec492',
  },
  baoshijie: {
    'emotion': 'f853c9c041d44cb',
    'sleep': '6ed7e3ff86ee4b2',
    'stress': 'c52b5927778e4b1',
    'emotion-sleep': 'e5f86595837442f',
    'emotion-stress': 'c581ced8c3fb454',
    'sleep-stress': '02bf75a06445495',
    'emotion-sleep-stress': 'a7833bb5f835456',
  }
}

export const organizationIdToFormDesc = {
  default: '近日，抗击新冠肺炎疫情的工作面临着新的挑战。广大的医护人员正在经历一个艰难的时期，面对高强度的工作和繁杂的信息流，长时间受到压力的冲击，会影响我们的心理状态，打乱我们工作和生活的节奏。保持情绪稳定、睡眠良好可以帮助我们更快地度过压力时期。心理健康自测可以帮助您了解自身的心理情况，从情绪、压力、睡眠三个维度，系统评估您目前的状态。',
  baoshijie: '面对高强度的工作和繁杂的信息流，长时间受到压力的冲击，会影响我们的心理状态，打乱我们工作和生活的节奏。保持情绪稳定、睡眠良好可以帮助我们更快地度过压力时期。心理健康自测可以帮助您了解自身的心理情况，从情绪、压力、睡眠三个维度，系统评估您目前的状态。'
}
