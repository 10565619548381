import QRCode from 'qrcode';

function getQrCodeCanvas(url) {
  return new Promise(function (resolve, reject) {
    let canvas = document.createElement('canvas');
    canvas.width = 800;
    canvas.height = 800;
    QRCode.toCanvas(canvas, url, function (error) {
      if (error) {
        reject(error);
        return;
      }
      //   document.body.append(canvas);
      //   var imgBase64 = canvas.toDataURL('image/png');
      setTimeout(() => {
        resolve(canvas);
      }, 2000);
      //   resolve(imgBase64);
      //   resolve(canvas);
    });
  });
}

function getEchartBase64(echartId) {
  return new Promise(function (resolve, reject) {
    if (window.echartInstance && window.echartInstance[echartId]) {
      try {
        // const echartBase64 = window.echartInstance[echartId]
        //   .getEchartsInstance()
        //   .getDataURL();

        // let dataXmlString = window.echartInstance[echartId]
        //   .getEchartsInstance()
        //   .getDataURL();

        // dataXmlString = dataXmlString.replace(
        //   'data:image/svg+xml;charset=UTF-8,',
        //   ''
        // );

        // var img1 = new Image();
        // var svg = new Blob([dataXmlString], { type: 'image/svg+xml' });
        // var url = window.URL.createObjectURL(svg);
        // console.log('!!!!url', url);
        // img1.onload = function() {
        //   resolve(img1);
        //   window.URL.revokeObjectURL(url);
        // };
        // img1.src = url;

        // return;

        const imageBase64 = window.echartInstance[echartId]
          .getEchartsInstance()
          .getDataURL();

        console.log(imageBase64);

        let img = new Image();
        img.onload = () => {
          resolve(img);
        };
        img.src = imageBase64;
      } catch (e) {
        console.error(e);
        reject(e);
      }
    } else {
      resolve(null);
    }
  });
}

// options = {
//     backgroundImage:"url",
//     addOnList:[],
//     shareQRCodeUrl:""
// }

async function drawImage(ctx, options) {
  return new Promise((resolve, reject) => {
    const { height, width, value, position } = options;
    const image = new Image(height, width);
    image.crossOrigin = 'anonymous';
    image.onload = function () {
      ctx.drawImage(image, position[0], position[1], width, height);
      resolve();
    };
    image.src = value;
  })
}

export function getDynamicCanvasContent(options) {
  const { backgroundImage = null, addOnList = [], shareQRCodeUrl } = options;

  return new Promise((resolve, reject) => {
    let canvas = document.createElement('canvas');

    const ctx = canvas.getContext('2d');
    const rootTid = localStorage.getItem('rootTid');
    console.log('rootTid: ', rootTid);
    var image = new Image();
    image.crossOrigin = 'anonymous';
    image.onload = async function () {
      // draw image...
      canvas.width = image.width;
      canvas.height = image.height;
      ctx.drawImage(image, 0, 0);

      // draw addons
      for (let i = 0; i < addOnList.length; i++) {
        const addOnItem = addOnList[i];
        if (addOnItem.type === 'shareQrCode') {
          if (!shareQRCodeUrl) {
            shareQRCodeUrl = window.__shareUrl || window.location.href;
          }
          let qrcodeDom = await getQrCodeCanvas(shareQRCodeUrl);
          const { height, width, position } = addOnItem;
          ctx.drawImage(qrcodeDom, position[0], position[1], width, height);
          console.log('!qrcodeDom', qrcodeDom, width, height);

          // ctx.drawImage(qrcodeDom, 290, 675, 170, 170);
        } else if (addOnItem.type === 'text') {
          // addOnItem.fontSize = '150px';
          // console.log(
          //   isNaN(parseFloat(addOnItem.fontSize)),
          //   parseFloat(addOnItem.fontSize)
          // );
          // if (isNaN(parseFloat(addOnItem.fontSize))) {
          //   alert('!!!');
          //   ctx.font = `${addOnItem.fontSize} ${addOnItem.font}`;
          // } else {
          //   ctx.font = `${addOnItem.fontSize}px ${addOnItem.font}`;
          // }
          ctx.font = `${addOnItem.fontSize} Arial`;
          ctx.fillStyle = addOnItem.color || '#2979ff';
          ctx.textAlign = addOnItem.textAlign || 'center';
          const { position } = addOnItem;
          ctx.fillText(addOnItem.value, position[0], position[1]);
          // ctx.fillText(addOnItem.value, 700, 1180);
        } else if (addOnItem.type === 'gauge_pointer') {
          // return;
          let {
            startAngle,
            endAngle,
            position,
            length = 90,
            value = 50,
            maxValue = 100,
            minValue = 0,
            color = 'white'
          } = addOnItem;
          // draw the pointer

          const score = value;
          const [POINTER_X, POINTER_Y] = position;
          // const [POINTER_X, POINTER_Y] = [500, 700];

          // draw the pointer
          const pointer = new Path2D();

          // length = length < 90 ? 90 : length;
          pointer.moveTo(POINTER_X + 0, POINTER_Y - (length / 9) * 2);
          pointer.lineTo(POINTER_X + length / 9, POINTER_Y + 0);
          pointer.lineTo(POINTER_X + 0, POINTER_Y + length);
          pointer.lineTo(POINTER_X - length / 9, POINTER_Y + 0);
          pointer.lineTo(POINTER_X - 0, POINTER_Y - (length / 9) * 2);

          // rotate the pointer to the start point
          ctx.translate(POINTER_X, POINTER_Y); // first, translate the axis to the center of the pointer
          ctx.rotate((startAngle / 180) * Math.PI); // rotate to the start position

          const FULL_ANGLE = endAngle - startAngle;
          const FULL_SCORE = maxValue - minValue;

          ctx.rotate((((score / FULL_SCORE) * FULL_ANGLE) / 180) * Math.PI); // rotate to the score position
          //   ctx.stroke(pointer);

          ctx.translate(-POINTER_X, -POINTER_Y);
          ctx.fillStyle = color;
          ctx.fill(pointer);

          // reset the axis
          ctx.translate(POINTER_X, POINTER_Y);
          ctx.rotate(-(((score / FULL_SCORE) * FULL_ANGLE) / 180) * Math.PI); // rotate to the score position
          ctx.rotate(-(startAngle / 180) * Math.PI); // rotate to the start position
          ctx.translate(-POINTER_X, -POINTER_Y);
        } else if (addOnItem.type === 'echart') {
          const {
            height = 630,
            width = 1000,
            position = [0, 600],
            chartId
          } = addOnItem;
          let chartDom = await getEchartBase64(chartId);
          ctx.drawImage(chartDom, position[0], position[1], width, height);
          // console.log('!qrcodeDom', qrcodeDom, width, height);
        } else if (addOnItem.type === 'image') {
          await drawImage(ctx, addOnItem);
        }
      }

      // await Promise.all(
      //   addOnList.map(async addOnItem => {

      //   })
      // );

      //   //put qrcode onto it
      //   let qrcodeDom = document.getElementById('share_test_modal_qrcode');
      //   ctx.drawImage(qrcodeDom, 293, 675, 165, 165);

      //write rootTid below qr code
      ctx.font = '12px Arial';
      ctx.fillStyle = 'white';
      ctx.textAlign = 'left';
      ctx.fillText(rootTid, 10, canvas.height - 20);

      // export image
      var imgBase64 = canvas.toDataURL('image/png');
      resolve(imgBase64);
    };
    // image.src = '///image.siuvo.com.cn/wechat/nCovShareTestNew.jpeg';
    image.src = backgroundImage;
  });
}
