import { createPatientEvent } from '../modules/shufu.js';
import CONFIG from 'GlobalConfigFile';
import { getCurrentUserId, getSessionId, getSceneId, getDeviceId } from '../utils/localStorage.js';

export function submitReceiveMsgEvent(params) {
  const closePatientEvent = CONFIG.closePatientEvent; // 配置是否关闭数据打点
  console.log('**submitReceiveMsgEvent**closePatientEvent*******', closePatientEvent)
  if (!closePatientEvent) {
    createPatientEvent(params);
  }
}