import React, { useEffect, useState } from 'react';
import { Button } from 'framework7-react';

export default function CountDownButton(props) {
  const [countdown, setCountdown] = useState(0);

  const { onClick, countdownFrom } = props;

  // Count down every second
  useEffect(() => {
    if (countdown > 0) {
      setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    }
  });

  const _onClick = () => {
    if (countdown > 0) {
      return;
    } else {
      try {
        onClick();
        setCountdown(countdownFrom);
      } catch (e) {}
    }
  };

  return (
    <Button
      {...props}
      onClick={_onClick}
      color={props.disabled || countdown > 0 ? 'gray' : ''}
    >
      {countdown > 0 ? `请稍候(${countdown})` : props.children}
    </Button>
  );
}

CountDownButton.defaultProps = {
  onClick: () => {
    console.warn('onClick is not defined');
  },
  countdownFrom: 60,
  disabled: false
};
