import React, { useState, useEffect } from 'react';
import { answerBoardEmitter } from '../modules/answerBoardEmitter.js';
import './InlineObjectTypeAnswer.css';
import TemplateQuestion from './TemplateQuestion.js';
import parseLanguage from '../utils/parseLanguage.js';


InlineObjectTypeAnswer.defaultProps = {
    question: null,
    renderActionButtons: () => console.warn("No function for renderActionButtons found")
}

export default function InlineObjectTypeAnswer(props) {
    const { questionMessageObject = {} /*questionId*/ } = props.question;

    const [answer, setAnswer] = useState({});

    let questionKeys =
        props.willAskArray || Object.keys(questionMessageObject.properties);
    const defaultAnswer = questionKeys.reduce((currAns, questionKey) => Object.assign({}, currAns, { [questionKey]: 'SKIPPED' }), {});

    useEffect(() => {
        if (
            !questionMessageObject.manualSubmit && questionKeys.every(key => {
                return !!answer[key];
            })
        ) {
            // todo submit
            answerBoardEmitter.emit('answer', answer);
        }
    });

    function renderSingleEnum(questionKey) {
        const question = questionMessageObject.properties[questionKey];
        return (
            <div
                style={{
                    flex: 1,
                    // padding: 5,
                    marginLeft: 5,
                    marginRight: 5,
                    height: '100%',
                    overflowY: 'auto'
                    // backgroundColor: '#f6f6f6',
                    // borderRadius: 5
                }}
                key={questionKey}
            >
                <div style={{ textAlign: 'center' }}>{question.title_zh}</div>
                <div>
                    {Object.keys(question.enum).map((enumKey, index) => {
                        return (
                            <div key={enumKey} style={{ textAlign: 'center' }}>
                                <button
                                    className={[
                                        'shufu-answer-button',
                                        answer[questionKey] === enumKey
                                            ? 'shufu-answer-button-selected'
                                            : ''
                                    ].join(' ')}
                                    onClick={() => {
                                        setAnswer({
                                            ...answer,
                                            [questionKey]: enumKey
                                        });
                                    }}
                                >
                                    {parseLanguage(question.enum[enumKey] || enumKey)}
                                </button>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    function renderQuestion(questionKey) {
        const question = questionMessageObject.properties[questionKey];
        if (question.type === "string" && question.enum) {
            return renderSingleEnum(questionKey);
        } else if (question.type === "string" && question.viewType === 'template') {
            const onChange = (stringValue, objectValue) => {
                console.log("string questionKey: ", questionKey, stringValue);
                if (stringValue == null || stringValue.length === 0)
                    setAnswer({ ...answer, [questionKey]: 'SKIPPED' });
                else
                    setAnswer({ ...answer, [questionKey]: stringValue });
            }
            return <TemplateQuestion key={questionKey} onChange={onChange} templateKey={questionKey} templateSchema={question.templateSchema} templateList={question.templateList} />;
        } else if (question.type === "object" && question.viewType === 'template') {
            const onChange = (stringValue, objectValue) => {
                console.log("object questionKey: ", questionKey, objectValue);
                if (stringValue == null || stringValue.length === 0 || objectValue == null || typeof objectValue != 'object' || Object.keys(objectValue).length == 0)
                    setAnswer({ ...answer, [questionKey]: 'SKIPPED' });
                else
                    setAnswer({ ...answer, [questionKey]: objectValue });
            }
            return <TemplateQuestion key={questionKey} onChange={onChange} templateKey={questionKey} templateSchema={question.properties} templateList={question.templateList} />;
        } else {
            return <div>Current question type and config is not support in InlineObjectTypeAnswer</div>
        }
    }

    return (
        <div>
            <div
                className={questionMessageObject.style === 'column' ? 'object-type-answer-column' : 'object-type-answer-default'}
            >
                {questionKeys.map(questionKey => {
                    return renderQuestion(questionKey);
                })}
            </div>
            {questionMessageObject.manualSubmit && props.renderActionButtons ?
                props.renderActionButtons({
                    skip: { onClick: () => answerBoardEmitter.emit('answer', defaultAnswer) },
                    submit: { onClick: () => answerBoardEmitter.emit('answer', Object.assign({}, defaultAnswer, answer)) }
                }) : null}
        </div>
    );
}