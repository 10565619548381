import io from 'socket.io-client';
import CONFIG from 'GlobalConfigFile';

export default class shuaiCloud {
  constructor(props) {
    console.log('shuaiCloudProps', props);
    const token = encodeURIComponent(props.appAuthToken);
    const server = props.server;
    const socketConfig = {
      path: CONFIG.shuaicloudPath,
      // query: {
      //   token,
      //   server
      // },
      transports: ['websocket', 'polling']
    }
    // this.socket = io(`${CONFIG.shuaicloudUrl}/user?token=${token}&server=${server}`, { transports: ['websocket', 'polling'] });
    this.socket = io(`${CONFIG.shuaicloudUrl}/user?token=${token}&server=${server}`, socketConfig);
    this.on = this.on.bind(this);

    this.on('ERROR_LOGIN', props.onInitFailure);
    this.on('message', props.onMessage);
    this.on('connect', props.onConnect);
  }

  on(eventName, cb) {
    if (typeof cb === 'function') this.socket.on(eventName, cb);
  }

  sendMessage(message) {
    console.log(message);
  }
}
