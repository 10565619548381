import React, { useState, useEffect, useRef, useCallback } from 'react';

import { Input, f7 } from 'framework7-react';

import _ from 'lodash';



AutoComplete.defaultProps = {
    enums: {},
    order: null,
    onChange: function () {
        console.warn('AutoComplete onChange not implemented');
    },
    key: '',
    type: "single"
};
export default function AutoComplete(props) {
    const { id, enums, order, style, onChange } = props;

    const autocompleteDropdownSimple = useRef(null);
    const [value, setValue] = useState(null);
    const onChangeRef = useRef();

    onChangeRef.current = onChange;




    const enumValueToKeyMap = _.invert(enums);



    const onValueChange = useCallback((value) => {
        setValue(value);
        onChangeRef.current(value);
    }, []);

    useEffect(() => {
        if (!autocompleteDropdownSimple.current) {
            console.log("create f7 autocomplete, id=", id);
            autocompleteDropdownSimple.current = f7.autocomplete.create({
                inputEl: `#autocomplete-dropdown-${id}`,
                openIn: 'dropdown',
                expandInput: true,
                source(query, render) {
                    try {
                        const results = order.filter(enumKey => enums[enumKey].includes(query)).map(enumKey => enums[enumKey]);
                        render(results);
                    } catch (e) {
                        console.warn(`autocomplete error, id=${id}, error=`, e);
                    }
                },
                on: {
                    change: function (value) {
                        onValueChange(enumValueToKeyMap[value[0]]);
                    }
                }
            });
        }

    }, [enumValueToKeyMap, enums, id, onValueChange, order])

    useEffect(() => {
        return () => {
            if (autocompleteDropdownSimple.current) {
                console.log("cleanup autocomplete");
                autocompleteDropdownSimple.current.destroy();
            }
        }
    }, [])

    return (
        <div style={{ ...style }}>
            <Input
                style={{ color: value != null ? undefined : "gray", marginTop: 10, backgroundColor: "white", padding: "5px 0px 5px 0px" }}
                type="text"
                onChange={(e) => { if (enumValueToKeyMap[e] !== value) { onValueChange(enumValueToKeyMap[e]); } }}
                placeholder="请输入"
                inputId={`autocomplete-dropdown-${id}`}
            />
        </div>
    );
}
