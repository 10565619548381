// import CONFIG from './config';
import CONFIG from 'GlobalConfigFile';
import axios from 'axios';
// import cookie from 'react-cookies';
import { getToken } from '../utils/localStorage.js';


const API_BASE_URL = CONFIG.backend.shufuLocal
  ? CONFIG.SHUFU.local.url
  // : CONFIG.SHUFU.remote.url;
  : CONFIG.getRemoteConfig().url;

console.log('getRemoteUrl---------------->', `${CONFIG.getRemoteConfig().url}`);

// const API_BASE_URL = 'http://apiv2.siuvo.com:7099/api/1.2/rest';

// const API_BASE_URL = "https://sit.siuvo.cn/romeo/api/1.2/rest/"

// return a Promise
function _request(options, isRawUrl = false) {
  // let _token = cookie.load('appAuthToken');
  const url = !isRawUrl ? (API_BASE_URL + options.url) : options.url;
  let _token = getToken();
  let _options = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    method: 'GET',
    withCredentials: !isRawUrl, // 外部url 允许跨域
    data: {},
    ...options,
    url
  };
  if (_token) {
    _options.headers['Authorization'] = `Bearer ${_token}`;
  }
  let startTime = Date.now();
  return axios
    .request(_options)
    .then(res => {
      if (res.status === 200 || res.status === 201) {
        __bl &&
          __bl.api &&
          __bl.api(options.name, true, Date.now() - startTime, res.status);
        return res.data;
      } else {
        throw new Error(`${res.data.errorMessage}: ${res.data.errorCode}`);
      }
    })
    .catch(error => {
      if (
        error.response &&
        error.response.data &&
        error.response.data.errorMessage
      ) {
        __bl &&
          __bl.api &&
          __bl.api(
            options.name,
            false,
            Date.now() - startTime,
            error.response.status,
            `[${error.message}] (${error.response.data.errorCode}) ${error.response.data.errorMessage}`
          );

        // alert(
        //   `[${error.message}] (${error.response.data.errorCode}) ${error.response.data.errorMessage}`
        // );
        if (options.isThrowErrorToPage) {
          throw new Error(error.response.data.errorMessage);
        }
        console.log(error)
      }
      __bl &&
        __bl.api &&
        __bl.api(
          options.name,
          false,
          Date.now() - startTime,
          error.response && error.response.status,
          `[${error.message}]`
        );
      if (options.isThrowErrorToPage) {
        throw new Error(error);
      }
    });
}

// URL 参数拼接，直接遍历对象，若为value为空，则不会拼接
function _getQuerystr(params) {
  if (!params) return '';
  const queryStr = Object.keys(params)
    .reduce((ary, key) => {
      if (key && params[key]) {
        ary.push(encodeURIComponent(key) + '=' + encodeURIComponent(params[key]));
      }
      return ary;
    }, [])
    .join('&');
  return '?' + queryStr;
}

// export async function login(options) {
//   const password = encodeURIComponent(options.password);
//   const url = `/user/login?username=${options.username}&password=${password}`;
//   return await _request({
//     method: 'POST',
//     url
//     //   'Access-Control-Request-Headers':'authorization,content-type'
//   })
//     .then(res => {
//       if (res.status === 200 || res.status === 201) {
//         return res.data;
//       } else {
//         throw `${res.data.errorMessage}: ${res.data.errorCode}`;
//       }
//     })
//     .catch(error => {
//       throw error;
//     });
// }

// export async function getForm(formId) {
//   const url = `/spa/startService/${formId}`;
//   return await _request({
//     method: 'POST',
//     url
//   })
//     .then(res => {
//       if (res.status === 200 || res.status === 201) {
//         return res.data;
//       } else {
//         throw `${res.data.errorMessage}: ${res.data.errorCode}`;
//       }
//     })
//     .catch(error => {
//       throw error;
//     });
// }

// export async function startService(serviceItemId) {
//   const url = `/spa/startService/${serviceItemId}`;
//   return await _request({
//     method: 'POST',
//     url
//   })
//     .then(res => {
//       if (res.status === 200 || res.status === 201) {
//         return res.data;
//       } else {
//         throw `${res.data.errorMessage}: ${res.data.errorCode}`;
//       }
//     })
//     .catch(error => {
//       throw error;
//     });
// }

// export async function processAndNextStep(serviceInstanceId, jsonSchema) {
//   const url = `/spa/processAndNextStep/${serviceInstanceId}`;
//   return await _request({
//     method: 'POST',
//     url,
//     data: jsonSchema
//   })
//     .then(res => {
//       if (res.status === 200 || res.status === 201) {
//         return res.data;
//       } else {
//         throw `${res.data.errorMessage}: ${res.data.errorCode}`;
//       }
//     })
//     .catch(error => {
//       throw error;
//     });
// }

// export async function register(username, password, cellphone, userType) {
//   const url = `/user/signup`;
//   return await _request({
//     method: 'POST',
//     url,
//     data: {
//       username,
//       password,
//       cellphone,
//       userType
//     }
//   })
//     .then(res => {
//       if (res.status === 200 || res.status === 201) {
//         return res.data;
//       } else {
//         throw `${res.data.errorMessage}: ${res.data.errorCode}`;
//       }
//     })
//     .catch(error => {
//       throw error;
//     });
// }

export function getUploadImageUrl() {
  return `${CONFIG.getRemoteConfig().host}/juliet/support/upload/image`;
}

export async function queryMessage({
  userId,
  startTime,
  direction = 'NEW_TO_OLD',
  limit = 20,
  serviceInstanceId
}) {
  // return new Promise((resolve, reject) => {
  //   setTimeout(() => {
  //     // resolve(require('./mock/messageList.json'));
  //     resolve([]);
  //   }, 400);
  // });

  const url = `/messages/`;
  return await _request({
    name: 'queryMessage',
    method: 'GET',
    url,
    params: {
      visibleTo: userId,
      startTime,
      direction,
      size: limit,
      serviceInstanceId
    }
  });
}


export async function queryReport(argument) {
  const url = `/report`;
  return await _request({
    name: 'queryReport',
    method: 'GET',
    url,
    params: {
      ...argument
    }
  });
}

export async function getJsonFormById(jsonFormId) {
  const url = `/form/jsonform/${jsonFormId}`;
  return await _request({
    name: 'getJsonFormById',
    method: 'GET',
    url
  });
}

export async function getJsonFormProcessedById(jsonFormId, fromJsonFormDataId, userId) {
  const url = `/form/jsonformProcessed/${jsonFormId}?fromJsonFormDataId=${fromJsonFormDataId}&userId=${userId}`
  return await _request({
    name: 'getJsonFormProcessedById',
    method: 'GET',
    url
  });
}

export async function getJsonFormDataById(jsonFormDataId) {
  const url = `/form/jsonformdata/${jsonFormDataId}`;
  return await _request({
    name: 'getJsonFormDataById',
    method: 'GET',
    url
  });
}

export async function getAllComplaintsByTag(category, avSchemaIdSuffix, subcategories) {
  let url = `/medicalRecord/allChiefComplaintsByTag/${category}`;
  if (avSchemaIdSuffix || subcategories) url += `?`;
  if (avSchemaIdSuffix) url += `avSchemaIdSuffix=${avSchemaIdSuffix}&`;
  if (subcategories && subcategories.length > 0) url += `subcategories=${encodeURI(subcategories.join(","))}`;
  return await _request({
    name: 'getAllComplaintsByTag',
    method: 'GET',
    url
  });
}

export async function replyToForm(body) {
  return await _request({
    name: 'replyToForm',
    method: 'POST',
    url: `/messages/reply-to-form`,
    data: body
  });
}

export async function __DEMO__RestartDaoZhen() {
  return await _request({
    name: '__DEMO__RestartDaoZhen',
    method: 'POST',
    url: `/service/instance/restartWithEncounter/d06c44b5-c79c-4ecb-9a49-35e83c05ba24`,
    data: {
      taskVariables: '{}',
      wfVariables: '{}'
    }
  });
}

export async function __DEMO__RestartXinlixue() {
  return await _request({
    name: '__DEMO__RestartXinlixue',
    method: 'POST',
    url: `/service/instance/restartWithEncounter/b92248aa-140a-415f-8cbf-809faef510bb`,
    data: {
      taskVariables: '{}',
      wfVariables: '{}'
    }
  });
}

export function getQrcodeSceneData(sceneId) {
  return _request({
    name: 'getQrcodeSceneData',
    method: 'GET',
    url: `/user/wechatQr/${sceneId}`
  });
}

export function getServiceItem(serviceItemId) {
  return _request({
    name: 'getServiceItem',
    method: 'GET',
    url: `/service/item/by-id/${serviceItemId}`
  });
}

export function getServiceInstance(serviceInstanceId) {
  return _request({
    name: 'getServiceInstance',
    method: 'GET',
    url: `/service/instance/by-id/${serviceInstanceId}`
  });
}

// export function getServiceItemStartInfo(serviceItemId) {
//   let url = `/service/item/${serviceItemId}/startInfo`;
//   return _request({
//     method: 'GET',
//     url: url
//   })
//     .then(res => {
//       // alert('!!!');
//       if (res.status === 200 || res.status === 201) {
//         return res.data;
//       } else {
//         console.log(res.data);
//         throw new Error(`${res.data.errorMessage}: ${res.data.errorCode}`);
//       }
//     })
//     .catch(error => {
//       // console;
//       throw new Error(error);
//     });
// }

export function restartServiceInstance(serviceInstanceId) {
  const url = `/service/instance/restartServiceInstance/${serviceInstanceId}`;

  return _request({
    name: 'restartServiceInstance',
    method: 'POST',
    url
  });
}

export function startServiceItemWithCheckEncounter(option) {
  const {
    serviceItemId,
    customerId,
    wfVariables,
    name,
    tags,
    encounterId,
    forceStartNew,
    sceneId,
    tid
  } = option;
  const serverParam = {
    wfVariables: wfVariables ? wfVariables : '{}',
    taskVariables: '{}',
    tags: tags || []
  };
  let url = `/service/instance/startServiceCheckEncounterAndRestart/${serviceItemId}?customerId=${customerId}&instanceName=${name}`;
  if (encounterId) {
    url += `&encounterId=${encounterId}`;
  }
  if (forceStartNew) {
    url += `&forceStartNew=true`;
  }
  if (sceneId) {
    url += `&sceneId=${sceneId}`;
  }
  if (tid) {
    url += `&trackingId=${tid}`;
  }

  return _request({
    name: 'startServiceItemWithCheckEncounter',
    method: 'POST',
    url,
    data: serverParam
  });
}

// 开启流程
export function startServiceBySceneId(params) {
  let url = `/service/instance/startServiceBySceneId`

  return _request({
    name: 'startServiceBySceneId',
    method: 'POST',
    url,
    data: params
  });
}

export function sendVerificationCodeToCellphone(cellphone) {
  // return _request({
  //   name: 'sendVerificationCodeToCellphone',
  //   method: 'POST',
  //   url: `/user/send-verification-code-for-phone/${cellphone}`
  // });
  return _request({
    name: 'sendVerificationCodeToCellphone',
    method: 'GET',
    url: `/user/sendVerificationCodeWithAliCloud`,
    params: {
      cellphone
    }
  });
}

export function verifyCodeReturnUsersOrRegister(
  cellphone,
  verificationCode,
  name
) {
  return _request({
    // name: 'verifyCodeReturnUsersOrRegister',
    // method: 'POST',
    // url: `/user/verifyCodeReturnUsersOrRegister`,
    // data: {
    //   cellphone,
    //   verificationCode,
    //   name
    // }
    name: 'verifyCodeReturnUsersOrRegister',
    method: 'POST',
    url: `/user/verifyCodeReturnUsersOrRegisterWithRedis`,
    data: {
      cellphone,
      verificationCode,
      name
    },
    isThrowErrorToPage: true
  });
}

export function loginWithUserIdAndShufuToken(userId, shufuToken) {
  return _request({
    name: 'loginWithUserIdAndShufuToken',
    method: 'POST',
    url: `/user/loginWithUserIdAndShufuToken`,
    data: {
      userId,
      shufuToken
    }
  });
}

export function registerWithCellphoneAndShufuToken(cellphone, shufuToken) {
  return _request({
    name: 'registerWithCellphoneAndShufuToken',
    method: 'POST',
    url: `/user/registerWithCellphoneAndShufuToken`,
    data: {
      cellphone,
      shufuToken
    }
  });
}

// 扫码接口
export function createUserAndStartServiceInternal(dataInfo) {
  const { userId, organizationId, sceneId, encounterIdentifier = '', patientIdentifier = '', patientName = '', wardOrReg = '', ricn = '' } = dataInfo;
  let url = `/user/createUserAndStartServiceInternal?organizationId=${organizationId}`
  let postData = {};
  if (sceneId) url += `&sceneId=${sceneId}`;
  if (encounterIdentifier) url += `&encounterIdentifier=${encounterIdentifier}`;
  if (patientIdentifier) url += `&patientIdentifier=${patientIdentifier}`;
  if (patientName) postData.patientName = patientName;
  if (wardOrReg) postData.wardOrReg = wardOrReg;
  if (ricn) postData.ricn = ricn;
  if (userId) postData.userId = userId;
  return _request({
    name: 'createUserAndStartServiceInternal',
    method: 'POST',
    url,
    data: postData
  });
}

export function getAllSymptoms(symptomVersion = "emr") {
  return _request({
    name: 'getAllSymptoms',
    method: 'GET',
    url: `/medicalRecord/getAllSymptoms?symptomVersion=${symptomVersion}`
  });
}

export function verifyLandingOrganizationIdAndDataEnc(organizationId, dataEnc) {
  return _request({
    isThrowErrorToPage: true,
    name: 'verifyLandingOrganizationIdAndDataEnc',
    method: 'GET',
    url: `/user/createUserAndStartService?organizationId=${organizationId}&dataEnc=${dataEnc}`
    // data: {
    //   dataEnc,
    //   organizationId
    // }
  });
}

export function verifyUserLandingOrganizationIdAndUserData(
  organizationId,
  identifier,
  name
) {
  let url = `/user/loginWithUserIdAndOrganizationId?organizationId=${organizationId}&identifier=${identifier}`;
  if (name) {
    url += `&name=${name}`;
  }
  return _request({
    name: 'verifyUserLandingOrganizationIdAndUserData',
    method: 'GET',
    url
    // data: {
    //   dataEnc,
    //   organizationId
    // }
  });
}

export function getSceneDataBySceneId(sceneId) {
  return _request({
    name: 'getSceneDataBySceneId',
    method: 'GET',
    url: `/user/getWechatQrInfoBySceneIds?sceneIds=${sceneId}`
    // data: {
    //   dataEnc,
    //   organizationId
    // }
  });
}

export function getSceneDataBySceneIdAndTimestamp(sceneId, qrTimestamp) {
  let url = `/user/getWechatQrInfoBySceneId?sceneId=${sceneId}`;
  if (qrTimestamp) url += `&qrTimestamp=${qrTimestamp}`;
  return _request({
    name: 'getSceneDataBySceneId',
    method: 'GET',
    url
  });
}

export function getReportData(reportId) {
  return _request({
    name: 'getReportData',
    method: 'GET',
    url: `/report/info/${reportId}`
  });
}

export function getWechatSign({
  appId,
  timestamp,
  nonceStr,
  url = 'https://h5.siuvo.cn',
  sceneId
}) {
  return _request({
    name: 'getWechatSign',
    method: 'POST',
    url: `/wechat/jsApiTicketAndShareInformation`,

    data: {
      appId,
      timestamp,
      nonceStr,
      url,
      sceneId
    }
  });
}

export function sendTrackInfo({ tid, from, newTid, sceneId }) {
  return _request({
    name: 'sendTrackInfo',
    method: 'PUT',
    url: `/trackInfo`,
    data: {
      fromId: tid,
      channel: from,
      toId: newTid,
      sceneId
    }
  });
}

export function wechatLoginWithWebAuthCode({ code, scope }) {
  return _request({
    name: 'wechatLoginWithWebAuthCode',
    method: 'POST',
    url: `/wechat/wechatLoginWithWebAuthCode?code=${code}&scope=${scope}`
  });
}

// 定时向服务器发送用户做题的时间
export async function startSendActionLogs(body) {
  return await _request({
    name: 'startSendActionLogs',
    method: 'PUT',
    url: `/formActionLog/bulk`,
    data: body,
    isThrowErrorToPage: false
  });
}

// 删除用户
export async function deactivateUser(userId) {
  return await _request({
    name: 'deactivateUser',
    method: 'DELETE',
    url: `/user/deactivateUser/${userId}`
  });
}

// 用户上传错误报告
export function createErrorReport(errorData) {
  return _request({
    name: 'createErrorReport',
    method: 'POST',
    url: `/errorReport/create`,
    data: errorData
  });
}

export async function createPatientEvent(params) {
  return await _request({
    name: 'createPatientEvent',
    method: 'POST',
    url: `/event/create`,
    data: params,
    isThrowErrorToPage: false
  });
}

// 查询历史报告
export async function queryReportLink({ customerId, status, pageNo, pageSize }) {
  const url = `/report/link/?status=${status}&reportType=form&customerId=${customerId}&from=${pageNo}&size=${pageSize}`;
  return await _request({
    name: 'queryReportLink',
    method: 'GET',
    url,
  });
}

// 患者通过身份证查询病历
export async function getSDMHCPatientList(identityId) {
  const url = `/hospital/patient/getSDMHCPatientList?ricn=${identityId}`;
  return await _request({
    name: 'getSDMHCPatientList',
    method: 'GET',
    url,
  })
}
// 患者通过身份证查询病历
// export async function getPatientList(identityId, organizationId) {
export async function getPatientList(params) {
  // const url = `/hospital/patient/getPatientList?ricn=${identityId}&organizationId=${organizationId}`;
  const url = `/hospital/patient/getPatientList${_getQuerystr(params)}`;
  return await _request({
    name: 'getSDMHCPatientList',
    method: 'GET',
    url,
  })
}

// 患者 拿到患者token连接socket
export async function getRegisterWithRicn(params) {
  const url = `/user/registerWithRicn`;
  return await _request({
    name: 'getRegisterWithRicn',
    method: 'POST',
    url,
    data: params,
  })
}

// 患者随访信息登记
export async function createPatientFollowup(params) {
  const url = `/SDMHC/patientFollowup/createByPatient`;
  return await _request({
    name: 'createPatientFollowup',
    method: 'POST',
    url,
    data: params,
  })
}

// 患者提交问卷作答结果
export async function createPatientAnswer(params) {
  const url = `/SDMHC/patientFollowup/answerByPatient`;
  return await _request({
    name: 'createPatientAnswer',
    method: 'POST',
    url,
    data: params,
  })
}

// 通过id(流水号)查询随访记录
export async function searchPatientFollowupInfoId(patientFollowupInfoId) {
  const url = `/SDMHC/patientFollowup/info/${patientFollowupInfoId}`;
  return await _request({
    name: 'searchPatientFollowupInfoId',
    method: 'GET',
    url,
    isThrowErrorToPage: true
  })
}

// 【牙防所】查询用户
export async function getYafangsuoPatientList(params) {
  const url = `/hospital/patient/getYafangsuoPatientList${_getQuerystr(params)}`;
  return await _request({
    name: 'getYafangsuoPatientList',
    method: 'GET',
    url,
  })
}

// 【牙防所】创建注册用户
export async function registerWithHospitalUser(params) {
  const url = `/user/registerWithHospitalUser`;
  return await _request({
    name: 'registerWithHospitalUser',
    method: 'POST',
    url,
    data: params,
  })
}

/**
 * nlp解析
 * @param {*} text 
 * @param {*} labels 
 * @returns 
*/
export async function getNlpMatch(text, labels) {
  
  const url = 'https://nlp-api.siuvo.cn/classify_text';
  
  const requestOptions = {
    name: 'getNlpMatch',
    method: 'POST',
    url,
    data: { text, labels }
  };
  
  return await _request(requestOptions, true);
}

// nlp解析
export async function getNlpResponse(data) {
  const url = 'https://nlp-api.siuvo.cn/get_nlp_response';
  const requestOptions = {
    name: 'getNlpResponse',
    method: 'POST',
    url,
    data,
  };
  return await _request(requestOptions, true);
}


// 根据用户查询token
export async function getTokenByUserId(params) {
  const url = `/user/getTokenByUserId${_getQuerystr(params)}`;
  return await _request({
    name: 'getTokenByUserId',
    method: 'GET',
    url,
  })
}
// 根据用户输入信息查询wechatQr
export async function wechaQRList(params) {
  const url = `/user/wechatQR/list${_getQuerystr(params)}`;
  return await _request({
    name: 'wechaQR-list',
    method: 'GET',
    url,
  })
}

//新增接口，校验用户信息 cellphone
export async function getUserProfile(params) {
  const url = `/user/profile${_getQuerystr(params)}`;
  return await _request({
    name: 'wechaQR-list',
    method: 'GET',
    url,
  })
}

// 新增接口，联通产线修复，历史数据查询
// @QueryParam("formId") String formId, @QueryParam("userId") String userId, @QueryParam("organizationId") String organizationId, @QueryParam("serviceInstanceId") String serviceInstanceId
export async function getExistByFormId(params) {
  const url = `/form/jsonformdata/existByFormId${_getQuerystr(params)}`;
  return await _request({
    name: 'existByFormId',
    method: 'GET',
    url,
  })
}
