import { AccordionContent, Actions, ActionsButton, ActionsGroup, Block, BlockFooter, Button, Icon, Link, List, ListItem, LoginScreenTitle, Message, Messages, Navbar, NavRight, Page, Popup, Preloader } from 'framework7-react';
import moment from 'moment';
import React from 'react';
import AnswerBoard from '../../components/AnswerBoard.js';
import Canvas2File from '../../components/Canvas2File.js';
import MessageBlock from '../../components/MessageBlock.js';
import ReportBugs from '../../components/ReportBugs.js';
import robot from '../../images/robot.png';
let html2canvas = require('html2canvas');

import {
 getQrcodeSceneData, getServiceInstance, getServiceItem, queryMessage, restartServiceInstance, startServiceItemWithCheckEncounter, startServiceBySceneId, getTokenByUserId
} from '../../modules/shufu.js';
import { submitReceiveMsgEvent } from '../../components/SubmitReceiveMsgEvent.js'
import { getCurrentUserId, getSceneId, getServiceInstanceId, getShowHistoryReportButton, setServiceInstanceId, setServiceItemId, getPatientInfo, setCurrentUserId, setToken } from '../../utils/localStorage.js';
import mergeServiceItemAndQrCodeOptions from '../../utils/mergeServiceItemAndQrCodeOptions';

import { answerBoardEmitter } from '../../modules/answerBoardEmitter.js';
import { messageEventEmitter } from '../../modules/shuaicloud/index.js';
import './chat.css';

import _ from 'lodash';

// import { __DEMO__RestartDaoZhen } from '../../modules/shufu.js';

import getParameterByName from '../../utils/getParameterByName';

import CONFIG from 'GlobalConfigFile';
import { withTranslation } from 'react-i18next';
import { initShuaiCloud as initShuaiCloudFromClientInstance } from '../../modules/shuaicloud/clientInstance';
import { getAnnouncement, getDeviceId, getSessionId } from '../../utils/localStorage.js';

const faqList = [
  { question: '收不到验证码？', answer: '请先检查手机号是否正确；其次检查手机是否有信号，如果还是未能解决，请联系医生或者护士导检台' },
  { question: '没有挂号信息？', answer: '请询问医生或者护士导检台，确认个人挂号信息是否正确' },
  { question: '页面无法加载？', answer: '请先检查网络，然后刷新页面重试' },
  { question: '误操作退出后如何重新登录?', answer: '重新扫码，使用原手机号登录，选择之前未完成的患者账号，即可继续操作。' },
  { question: '无法提交？', answer: '请先检查网络，然后刷新页面重试' },
  { question: '如何刷新页面？', answer: '请点击屏幕右上角的三个点“…”按钮，找到“刷新”按钮并点击刷新，或者点击页面右侧的帮助反馈下的刷新按钮刷新页面' },
  { question: '如何调整字体大小？', answer: '请点击屏幕右上角的三个点“…”按钮，找到调整字体功能' },
]

function filterMessageList(messageList) {
  return messageList.filter(message => {
    let messageJson = JSON.parse(message.message.data);
    return (
      messageJson._lcattrs.type === 'text' ||
      messageJson._lcattrs.type === 'jsonSchema'
    );
  });
}
class ChatForHis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messageList: [],
      answerBoardStyle: {},
      isLoadingHistoryMessage: false,
      showTyping: false,
      noMore: false,
      sceneId: null,
      sceneData: null,
      serviceInstanceId: getServiceInstanceId(),
      questionNumber: -1,
      totalQuestions: -1,
      uploadBugPopupOpen: false,
      bugReportFile: null,
      showBugReportButton: false,
      faqOpen: false,
      announcement: getAnnouncement(),
      showHistoryReportButton: getShowHistoryReportButton(),
      formVariables: {},
    };
    this.updatePopupStatus = this.updatePopupStatus.bind(this);

    this._debouncedHandler = null;
    this.debouncedOnScroll = function (event) {
      if (this._debouncedHandler) {
        clearTimeout(this._debouncedHandler);
        this._debouncedHandler = null;
      }
      let target = event.target;
      let scrollTop = target.scrollTop;
      if (target.id !== 'message_list_wrapper') {
        return;
      }
      this._debouncedHandler = setTimeout(() => {
        this.onScroll(scrollTop);
        this._debouncedHandler = null;
      }, 200);
    };

    this.messagesComponent = React.createRef();
    let defaultTitle = '';
    if (getParameterByName('lang') === 'en') {
      defaultTitle = 'Siuvo';
    }
    this.title = getParameterByName('title') || defaultTitle;
    this.jumpToStaticForm = props.jumpToStaticForm || getParameterByName('jumpToStaticForm');
    if (this.title === 'NONE') this.title = defaultTitle;
    this.noRestart = props.noRestart || getParameterByName('noRestart') || false;
    this.sceneId = props.sceneId || getParameterByName('sceneId');
    this.userId = props.userId || getParameterByName('userId') || getCurrentUserId();
  }

  // 修改上传bug弹出框状态
  updatePopupStatus(popupStatus) {
    this.setState({
      uploadBugPopupOpen: popupStatus
    })
  }

  async getMessages(before) {
    // return [];
    if (!this.state.serviceInstanceId) {
      return [];
    }
    return queryMessage({
      userId: getCurrentUserId(),
      startTime: before || null,
      limit: 3,
      serviceInstanceId: this.state.serviceInstanceId
    });
  }

  onReceiveMessage(fullMessage) {
    console.log('fullMessage.message', fullMessage.message);
    const messageData = JSON.parse(_.get(fullMessage.message, 'data', '{}'));
    console.log('onReceiveMessage', messageData);
    try {
      let formId = '';
      if (messageData._lcattrs && messageData._lcattrs.type !== 'text') {
        const message_lctext = JSON.parse(_.get(messageData, '_lctext', '{}'));
        formId = _.get(message_lctext, ['schema', 'shufuFormId'], 'default');
      }
      const parsedFormId = formId ? formId.replace(/\.\d{13}$/, '') : formId;
      // 数据打点
      const params = {
        name: 'onReceiveMessage/' + parsedFormId,
        source: 'browser',
        userId: getCurrentUserId(),
        deviceId: getDeviceId(),
        sessionId: getSessionId(),
        occurOn: new Date().getTime(),
        env: CONFIG.server,
        userAgent: navigator.userAgent,
        props: {
          sceneId: getSceneId(),
          serviceInstanceId: fullMessage.serviceInstanceId,
          messageId: fullMessage.id,
          formId: formId
        },
      }
      submitReceiveMsgEvent(params);
    } catch (e) {
      console.log(e)
    }

    if (this.state.serviceInstanceId && (fullMessage.serviceInstanceId !== this.state.serviceInstanceId)) return;
    let currentMessageList = this.state.messageList;
    currentMessageList.push(fullMessage);
    console.log('MessageListPUSH', currentMessageList);
    currentMessageList = currentMessageList.sort((ma, mb) => {
      return (
        moment(ma.createdTime).valueOf() - moment(mb.createdTime).valueOf()
      );
      // return (
      //   new Date(ma.createdTime).getTime() - new Date(mb.createdTime).getTime()
      // );
    });
    currentMessageList = filterMessageList(currentMessageList);

    // calculate if need to scroll to bottom
    var messageListWrapper = document.getElementById('message_list_wrapper');
    let needScoll = false;
    if (
      messageListWrapper &&
      messageListWrapper.clientHeight + messageListWrapper.scrollTop >=
      messageListWrapper.scrollHeight - 20
    ) {
      needScoll = true;
    }
    this.setState({ messageList: currentMessageList }, () => {
      if (needScoll) {
        // console.log("scroll to top chat.js onReceiveMessage: ", messageListWrapper.scrollTop);
        messageListWrapper.scrollTop = messageListWrapper.scrollHeight;
      }
    });
  }

  // 新的开启服务流程的接口
  async startServiceWithSceneId(sceneId) {
    let sceneData = await getQrcodeSceneData(sceneId);
    sceneData = sceneData.sceneData;
    if (!this.title && sceneData.title) {
      this.title = sceneData.title
    }
    if (!this.title && sceneData.title) {
      this.title = sceneData.title
    }
    let tags = JSON.parse(sceneData.tags || '[]');
    tags.push(`tid=${window.__tid || null}`);
    tags.push(`rootTid=${window.__rootTid || null}`);
    let options = {
      referralCode: sceneData.rc || '',
      newServiceItemId: sceneData.sid || undefined,
      newServiceInstanceName: sceneData.sn || undefined,
      wfVariables: sceneData.wfVariables || '{}',
      startedWithUserId: sceneData.startedWithUserId || undefined,
      organizationId: sceneData.organizationId || undefined,
      tags: JSON.stringify(tags)
    };

    if (options.newServiceItemId) {
      setServiceItemId(options.newServiceItemId);
      let serviceItem = await getServiceItem(options.newServiceItemId);

      let {
        wfVariables
      } = mergeServiceItemAndQrCodeOptions(serviceItem, options);
      const providerId = getParameterByName('providerId');
      let newServiceInstance = await startServiceBySceneId({
        // serviceItemId: options.newServiceItemId,
        customerId: getCurrentUserId(),
        // wfVariables: JSON.stringify(wfVariables),
        // name: options.newServiceInstanceName || serviceItem.name,
        sceneId,
        trackingId: window.__tid || null,
        patientInfoMap: getPatientInfo(),
        room: providerId,
        patientInfoFrom: 'inWechatQrInfo'
      });
      const serviceInstanceId = newServiceInstance.serviceInstance &&
        newServiceInstance.serviceInstance.id;
      setServiceInstanceId(serviceInstanceId);
      const formVariables = JSON.parse(_.get(newServiceInstance, ["serverParam", "variables", "formVariables"], '{}'));

      this.setState({
        sceneData,
        formVariables,
        serviceInstanceId
      });

      let chatPageBackgroundImageUrl = _.get(sceneData, 'chatPageBackgroundImageUrl', ''),
        messageListWrapperDom = document.getElementsByClassName('messageListWrapper')[0];
      if (chatPageBackgroundImageUrl && messageListWrapperDom) messageListWrapperDom.style.backgroundImage = 'url(' + chatPageBackgroundImageUrl + ')'
      // 机器人聊天框背景
      let styleSheet = document.styleSheets[0];
      let chatBubbleRobotStyle = _.get(sceneData, 'chatBubbleRobotStyle', '');
      if (chatBubbleRobotStyle) styleSheet.insertRule('.shufu-text-message-bubble.message-received .message-bubble {' + chatBubbleRobotStyle + '}', 0);
      // 机器人头像
      let robotAvatarImageUrl = _.get(sceneData, 'robotAvatarImageUrl', '');
      if (robotAvatarImageUrl) styleSheet.insertRule('.shufu-text-message-bubble .message-avatar { background-image: url("' + robotAvatarImageUrl + '")!important}', 0);
      // 复选标记背景色
      const themeColor = _.get(sceneData, 'themeColor', '');
      if (themeColor) styleSheet.insertRule('.multiple-choice-checkmark {background-color: ' + themeColor + '!important}', 0);
      // 用户聊天框背景
      const chatBubbleCustomerStyle = _.get(sceneData, 'chatBubbleCustomerStyle', '');
      styleSheet.insertRule('.message-sent .message-bubble {' + chatBubbleCustomerStyle + '}', 0);

      const accentColor = _.get(sceneData, 'accentColor', '');
      if (accentColor) styleSheet.insertRule('.shufu-text-fold {color: ' + accentColor + '!important}', 0);
    }
  }

  async tryStartNewServiceInstance(sceneId) {
    if (sceneId) {
      await this.startServiceWithSceneId(sceneId);
    }
  }

  async retrieveMessagesAndUpdateMessagesList(tryNum = 1) {
    try {
      const retrievedMessages = await this.getMessages();

      let messageList = _.uniqBy(retrievedMessages.concat(this.state.messageList), 'id');

      messageList = filterMessageList(messageList);
      if (messageList.length <= 0) {
        this.setState({ noMore: true });
      }
      messageList.sort((ma, mb) => {
        return (
          moment(ma.createdTime).valueOf() - moment(mb.createdTime).valueOf()
        );
      });

      this.setState({ messageList: messageList }, () => {
        // after set the message, scroll to the bottom
        setTimeout(() => {
          var objDiv = document.getElementById('message_list_wrapper');
          objDiv.scrollTop = objDiv.scrollHeight;
        }, 20);
      });

      if (messageList.length === 0 && tryNum < 3) {
        this.setState({ showTyping: true });
        setTimeout(async () => await this.retrieveMessagesAndUpdateMessagesList(tryNum + 1), 1000);
      } else {
        this.setState({ showTyping: false });
      }

      return messageList;
    } catch (e) {
      console.error(e);
      self.$f7.dialog.alert(this.props.t('server_busy'));
    }
  }

  // 组件挂载后（插入 DOM 树中）立即调用。
  async componentDidMount() {
    const self = this;
    let sceneId = this.sceneId;
    let userId = this.userId;
    setCurrentUserId(userId);
    this.setState({ sceneId });
    setTimeout(() => {
      this.setState({ showBugReportButton: true })
    }, 2000);

    // set message handler
    messageEventEmitter.on('message', messageText => {
      const fullMessage = JSON.parse(messageText);
      this.onReceiveMessage(fullMessage);
      setTimeout(() => {
        this.setState({ showTyping: false });
      }, 100);

      // if (this.messagesComponent) {
      //   setTimeout(() => {
      //     this.messagesComponent.current.hideTyping();
      //   }, 200);
      // }
    });

    // fake typing bubble
    answerBoardEmitter.on('submit', () => {
      setTimeout(() => {
        this.setState({ showTyping: true });
        let ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('iphone') > 0 || ua.indexOf('ipad') > 0 || true) {
          setTimeout(() => {
            var messageListWrapper = document.getElementById(
              'message_list_wrapper'
            );
            if (messageListWrapper) {
              console.log("scroll to top chat.js fake typing bubble: ", messageListWrapper.scrollTop);
              messageListWrapper.scrollTop = messageListWrapper.scrollHeight;
            }
          }, 50);
        }
      }, 250);

      setTimeout(() => {
        this.setState({ showTyping: false });
      }, 50000);
      return;
    });

    // 问题产生渲染进度条
    answerBoardEmitter.on('set-answer-board', questionMessageObject => {
      let questionNumber = parseInt(_.get(questionMessageObject, 'question.questionMessageObject.questionNumber', -1));
      let totalQuestions = parseInt(_.get(questionMessageObject, 'question.questionMessageObject.totalQuestions', -1));
      this.setState({
        questionNumber,
        totalQuestions
      });
    });

    // 是否显示showTyping
    answerBoardEmitter.on('show-typing', (val) => {
      let { isShow, autoDisapper } = val;
      // 默认两秒后消失，调用nlp接口
      if (!autoDisapper) {
        this.setState({ showTyping: isShow });
      }
      else {
        if (isShow === true) {
          this.setState({ showTyping: true });
        }
        setTimeout(() => this.setState({ showTyping: false }), 3500);
      }
    });

    self.$f7.preloader.show();
    try {
      // 根据userId获取token
      let params = {
        userId
      };
      const userData = await getTokenByUserId(params);
      const { token } = userData;
      setToken(token);
      await initShuaiCloudFromClientInstance();
    } catch (e) {
      console.error(e);
      self.$f7.dialog.alert(this.props.t('server_busy'));
    }

    if (!this.noRestart) {
      try {
        await this.tryStartNewServiceInstance(sceneId);
      } catch (e) {
        console.error(e);
        self.$f7.dialog.alert(this.props.t('server_busy'));
      }
    }

    await this.retrieveMessagesAndUpdateMessagesList();

    if (this.state.serviceInstanceId) {
      const serviceInstance = await getServiceInstance(this.state.serviceInstanceId);
      const formVariables = JSON.parse(_.get(serviceInstance, ["serverParam", "variables", "formVariables"], '{}'));
      this.setState({
        formVariables
      })
    }

    self.$f7.preloader.hide();

  }

  // 组件更新后会被立即调用
  async componentDidUpdate() {
    const urlSceneId = getParameterByName('sceneId');
    const currentPath = $$f7.view.current.router.currentRoute.path;
    if (!this.noRestart) {
      if (
        currentPath === '/chat/' &&
        urlSceneId &&
        urlSceneId !== this.state.sceneId
      ) {
        console.log('STARTING NEW SERVICE!!');
        self = this;
        self.$f7.preloader.show();
        try {
          this.setState({ sceneId: urlSceneId });
          await this.tryStartNewServiceInstance(urlSceneId);
        } catch (e) {
          console.error(e);
          self.$f7.dialog.alert(this.props.t('server_busy'));
        }
        console.log('spinner ends');
        self.$f7.preloader.hide();
      }
    }
  }

  async onScroll(scrollTop) {
    if (this.state.noMore) {
      return;
    }
    if (this.state.messageList.length > 0 && scrollTop < 200) {
      let messageList = await this.getMessages(
        moment(this.state.messageList[0].createdTime).valueOf()
      );

      console.log('PREVIOUS MESSAGES', messageList);
      messageList = filterMessageList(messageList);

      if (messageList.length <= 0) {
        this.setState({ noMore: true });
        return;
      }
      messageList.sort((ma, mb) => {
        return (
          moment(ma.createdTime).valueOf() - moment(mb.createdTime).valueOf()
        );
      });
      this.setState({
        messageList: _.uniqBy(messageList.concat(this.state.messageList), 'id')
      });
    }
  }

  async restartServiceInstance(serviceInstanceId) {
    console.log("RESTARTING");
    const newServiceInstance = await restartServiceInstance(serviceInstanceId);
    setServiceInstanceId(newServiceInstance.id);
    const formVariables = JSON.parse(_.get(newServiceInstance, ["serverParam", "variables", "formVariables"], '{}'));

    this.setState({ serviceInstanceId: newServiceInstance.id, formVariables }, () => this.retrieveMessagesAndUpdateMessagesList());
  }

  renderJumpToStaticFormLoader() {
    if (this.jumpToStaticForm) {
      return (
        <div style={{ position: "absolute", "backgroundColor": "white", top: 0, right: 0, height: "100%", width: "100%", zIndex: 10 }}>
          <Page noToolbar noNavbar noSwipeback loginScreen className="landing-page">
            <LoginScreenTitle>{title}</LoginScreenTitle>

            <List>
              <div style={{ textAlign: 'center', marginBottom: 100, marginTop: 100 }}>
                <Preloader size={80} color="#2979ff"></Preloader>
              </div>
            </List>

            <List>
              <BlockFooter>
                初始化中，请稍候
                <br />
                <br />
                加载中...
              </BlockFooter>
            </List>
          </Page>
        </div>
      )
    }
  }

  renderHistoryBtn(showHistoryReportButton) {
    return <>
      {
        showHistoryReportButton ?
          <NavRight >
            <Link onClick={() => {
              this.props.f7router.navigate('/scaleIntroductionWithReport/historyReport/');
            }}>
              <div className='chat-title-right'>历史报告</div>
              <Icon className='chat-title-right-icon' f7="chevron_right" ></Icon>
            </Link>
          </NavRight> : null
      }
    </>
  }

  renderMessageBlock() {
    const waitTip = this.props.t('please_wait');
    return (
      <>
        {
          this.state.messageList.map((message, index) => {
            return (
              <MessageBlock
                key={index}
                fullMessage={message}
                userId={getCurrentUserId()}
                sceneData={this.state.sceneData}
                formVariables={this.state.formVariables}
                isLastMessage={
                  index === this.state.messageList.length - 1
                }
              ></MessageBlock>
            );
          })
        }
        {/* showTyping 是否显示message正在loading的样式 */}
        {this.state.showTyping ? (
          <Message
            typing={true}
            type="received"
            key="TYPING_MESSAGE"
            className="shufu-text-message-bubble"
            style={{ marginBottom: 5, marginTop: 5 }}
            header={waitTip}
            avatar={_.get(this.state.sceneData, 'robotAvatarImageUrl', '') || robot}
          ></Message>
        ) : null}
      </>
    )
  }

  renderAnswerBoard() {
    return (
      <>
        <AnswerBoard
          jumpToStaticForm={this.jumpToStaticForm}
          message={this.state.messageList[this.state.messageList.length - 1]}
        />
      </>
    )
  }

  render() {
    const { questionNumber, totalQuestions, showBugReportButton, announcement, showHistoryReportButton } = this.state;
    let waitTip = this.props.t('please_wait');
    if (getParameterByName('lang') === 'en') {
      waitTip = 'Please Wait...';
    }
    return (
      <Page
        name="chat-page"
      // infiniteTop
      // onInfinite={() => {
      //   console.log('onInfinite');
      // }}
      >
        {(!this.title) ? <>
          {
            showBugReportButton ? <Navbar >
              {this.renderHistoryBtn(showHistoryReportButton)}
            </Navbar> : null
          }
        </> : <Navbar title={this.title}>
          {this.renderHistoryBtn(showHistoryReportButton)}
        </Navbar>}
        <div className="chatContainer" style={{ paddingTop: ((questionNumber != -1 || totalQuestions != -1) && this.title) ? 40 : 0 }}>
          <div className={this.title ? 'answer-board-progress-bar-content' : 'answer-board-progress-bar-content-no-title'} style={{ display: (questionNumber != -1 || totalQuestions != -1) ? 'inline-block' : 'none' }}>
            <div className="answer-board-progress-bar">
              <div className="answer-board-progress-bar-line">
                <div className="bar-line-bg"></div>
                <div className="bar-line-progress"
                  style={{
                    width: `${(questionNumber / totalQuestions) * 100}%`
                  }}
                ></div>
              </div>
              <div className={'answer-board-progress-bar-num'}>
                <font color='#2979ff'>{questionNumber}</font> / {totalQuestions}
              </div>
            </div>
          </div>
          <div style={{ display: announcement ? 'block' : 'none', padding: 8, boxShadow: "0px 0px 30px 0px rgb(160 160 160 / 40%)", zIndex: 100 }}>
            {announcement}
          </div>
          {/* <Button
            className="upload-bug-report-button"
            actionsOpen="#helperAction"
            style={{ display: showBugReportButton ? 'block' : 'none' }}
          >{this.props.t('uploadBug')}</Button> */}
          {getParameterByName('lang') !== 'de' ? <Button
            className="upload-bug-report-button"
            actionsOpen="#helperAction"
            style={{ display: showBugReportButton ? 'block' : 'none' }}
          >
            <Icon f7="bars" size="40px"></Icon>
          </Button> : null}
          <Actions id="helperAction">
            <ActionsGroup>
              <ActionsButton onClick={() => {
                window.$$f7.dialog.confirm('请问您确定刷新页面吗？', '提示', () => {
                  window.location.reload();
                });
              }
              }
              >刷新界面</ActionsButton>
              <ActionsButton
                onClick={() => {
                  this.setState({ faqOpen: true })
                }}
              >常见问题</ActionsButton>
              <ActionsButton
                onClick={() => {
                  let bugReportCanvasDom = document.getElementById("bugReportCanvas");
                  if (bugReportCanvasDom != null) bugReportCanvasDom.parentNode.removeChild(bugReportCanvasDom);
                  let _this = this;
                  html2canvas(document.body).then(canvas => {
                    canvas.setAttribute("id", "bugReportCanvas");
                    document.body.appendChild(canvas);
                    let canvasDom = document.getElementById("bugReportCanvas");
                    Canvas2File(canvasDom).then(bugReportFile => {
                      _this.setState({
                        bugReportFile,
                        uploadBugPopupOpen: true
                      });
                    });
                  });
                  // 如果系统不支持html2canvas则让用户自己上传截图
                  setTimeout(() => {
                    if (!_this.state.uploadBugPopupOpen) {
                      window.$$f7.dialog.alert(this.props.t('screenshotFail'), false, { cssClass: 'dialog-text-left' });
                      _this.setState({
                        bugReportFile: null,
                        uploadBugPopupOpen: true
                      });
                    }
                  }, 1500);
                }
                }
              >帮助反馈</ActionsButton>
              {
                !CONFIG.WIDESCREEN &&
                <ActionsButton onClick={() => {
                  window.$$f7.dialog.confirm('请问您确定想重新开始吗？', '提示', () => {
                    answerBoardEmitter.emit('set-answer-board', { type: "empty" })
                    const serviceInstanceId = this.state.serviceInstanceId;
                    this.setState({ messageList: [], showTyping: true, serviceInstanceId: null }, () => this.restartServiceInstance(serviceInstanceId));
                  });
                }}
                >
                  重新开始
                </ActionsButton>
              }
            </ActionsGroup>
          </Actions>
          <Popup
            className="faq-popup-swipe"
            opened={this.state.faqOpen}
            swipeToClose
          >
            <Page>
              <Navbar title="FAQ">
                <NavRight>
                  <Link popupClose>关闭</Link>
                </NavRight>
              </Navbar>
              <List accordionList>
                {
                  faqList.map((item, index) => {
                    return (
                      <ListItem key={index} accordionItem title={item.question}>
                        <AccordionContent>
                          <Block>
                            <p>{item.answer}</p>
                          </Block>
                        </AccordionContent>
                      </ListItem>
                    );
                  })
                }
              </List>
            </Page>
          </Popup>
          <Popup
            className="demo-popup"
            opened={this.state.uploadBugPopupOpen}
            onPopupClosed={() => {
              this.setState({ uploadBugPopupOpen: false })
            }}
          >
            {this.state.uploadBugPopupOpen ? (
              <div>
                <Navbar title={this.props.t('uploadBug')}>
                  <NavRight>
                    <Link popupClose>{this.props.t('close')}</Link>
                  </NavRight>
                </Navbar>
                <ReportBugs updatePopupStatus={this.updatePopupStatus} bugReportFile={this.state.bugReportFile}></ReportBugs>
              </div>
            ) : null}
          </Popup>
          <div className="messageListWrapper">
            <div
              className="massageListInnerWrapper"
              id="message_list_wrapper"
              onScroll={this.debouncedOnScroll.bind(this)}
            >
              <Messages
                style={{ minHeight: 'unset', marginBottom: 20, justifyContent: 'flex-end' }}
                // ref={el => {
                //   this.messagesComponent = el;
                // }}
                ref={this.messagesComponent}
              >
                {
                  this.renderMessageBlock()
                }
              </Messages>
            </div>
          </div>
          {
            this.renderAnswerBoard()
          }
        </div>
        {this.renderJumpToStaticFormLoader()}
      </Page>
    );
  }
}
export default withTranslation()(ChatForHis);
