import React, { Component } from 'react';
import 'framework7/css/framework7.bundle.min.css';
import { App, View } from 'framework7-react';
import routes from './routes.js';
import getParameterByName from './utils/getParameterByName';
import { getWechatSign, sendTrackInfo } from './modules/shufu.js';
import i18n from './i18n';
import _ from 'lodash';
import { wxEventEmitter } from './modules/wxEventEmitter.js';
import { WX_EVENT } from './modules/event.js';
import ClientMonitor from 'skywalking-client-js';
import CONFIG from 'GlobalConfigFile';
import { setSessionId, setDeviceId, setSceneId } from './utils/localStorage.js';
import generateUUID from './utils/generateUuid.js';
import isWeixinBrowser from './utils/isWeixinBrowser';
import isValidWechatLoginUrl from './utils/isValidWechatLoginUrl';


const shortid = require('shortid');
const f7params = {
  // Array with app routes
  routes,
  // App Name
  name: "",
  // App id
  id: 'com.myapp.test',
  // ...
  theme: 'ios',

  dialog: {
    buttonOk: i18n.t('ok'),
    buttonCancel: i18n.t('cancel')
  }
};


function getIOSVersion(ua) {
  ua = ua || navigator.userAgent;
  var match = ua.match(/OS ((\d+_?){2,3})\s/i);
  return match ? match[1].replace(/_/g, '.') : 'unknown';
}

function checkVersion() {
  const iosVsn = getIOSVersion().split('.');
  return +iosVsn[0] == 11 && +iosVsn[1] >= 0 && +iosVsn[1] < 3;
}

export const isIphonex = () => {
  if (typeof window !== 'undefined' && window) {
    return (
      /iphone/gi.test(window.navigator.userAgent) && window.screen.height >= 812
    );
  }
  return false;
};

export default class H5App extends Component {
  constructor(props) {
    super(props);
    this.myFramework7Ref = React.createRef();
  }

  async sendTrackInfoAndStoreRootTid({ tid, from, newTid, sceneId }) {
    const { ancestorIds } = await sendTrackInfo({ tid, from, newTid, sceneId });
    const rootTid = ancestorIds.length > 0 ? ancestorIds[0] : tid;
    window.__tid = newTid;
    window.__rootTid = rootTid;
    localStorage.setItem('rootTid', rootTid);
  }

  async initializeWechatJsApi(shareUrl, sceneId) {
    const appId = CONFIG.APPID;
    const timestamp = Math.floor(Date.now() / 1000);
    const nonceStr = `${Math.floor(Math.random() * 10000000)}_${Math.floor(
      Math.random() * 10000000
    )}`;
    // alert('url: ' + window.location.href.split('#')[0]);

    // let signature = 'a118f757d71a7214793aa5e8899f78dd046c8a88';
    try {
      let {
        signature,
        title,
        description,
        imgUrl
      } = await getWechatSign({
        appId,
        timestamp,
        nonceStr,
        sceneId,
        url: window.location.href.split('#')[0]
      });
      console.log({ appId, timestamp, nonceStr, signature });

      // alert('SIGN: ' + signature);
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: appId, // 必填，公众号的唯一标识
        timestamp: timestamp, // 必填，生成签名的时间戳·
        nonceStr: nonceStr, // 必填，生成签名的随机串
        signature: signature, // 必填，签名
        jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', 'scanQRCode'] // 必填，需要使用的JS接口列表
      });

      wx.ready(function () {
        console.log('Wechat Ready');
        console.info('Wechat Ready');
        wxEventEmitter.emit(WX_EVENT.WX_SIGNATURE_STATUS, 'success');


        let url = shareUrl;
        wx.updateAppMessageShareData({
          title: title || '舒辅智慧', // 分享标题
          desc:
            description ||
            '新冠病毒来了，莫惊慌，了解自己，降低风险，寻求帮助，沉着应对，保持健康', // 分享描述
          link: url + "&from=messageshare", // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl:
            imgUrl || 'https://image.siuvo.com.cn/wechat/wechat_share.jpeg', // 分享图标
          success: function () {
            // 设置成功
            console.log('updateAppMessageShareData success');
          }
        });

        wx.updateTimelineShareData({
          title: title || '舒辅智慧', // 分享标题
          link: url + "&from=timelineshare", // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl:
            imgUrl || 'https://image.siuvo.com.cn/wechat/wechat_share.jpeg', // 分享图标
          success: function () {
            // 设置成功
            console.log('updateTimelineShareData');
          }
        });
      });
      wx.error(function (res) {
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
        console.error('!!!!!ERROR', res);
        wxEventEmitter.emit(WX_EVENT.WX_SIGNATURE_STATUS, 'failed');
      });
    } catch (e) {
      wxEventEmitter.emit(WX_EVENT.WX_SIGNATURE_STATUS, 'failed');
      console.error("failed to initialize wechat jsapi: ", e);
      // alert(e.message);
    }
  }

  async setUpTrackingAndWechatApi() {
    // return;
    let newTid = shortid.generate();
    let sceneId = getParameterByName('sceneId');
    let nobody = getParameterByName('nobody');
    let title = getParameterByName('title');
    let tid = getParameterByName('tid');
    let from = getParameterByName('from');
    let lang = getParameterByName('lang');
    let hideShare = getParameterByName('hideShare');
    let backgroundImageUrl = getParameterByName('backgroundImageUrl');
    let redirectState = getParameterByName('state');

    //do nothing if this webpage is about to go through wechat login.
    //After the wechat login, redirectState will have a value.
    if (nobody && isWeixinBrowser() && isValidWechatLoginUrl() && !redirectState) {
      return;
    }

    let shareUrl = `${window.location.protocol}//${window.location.host.replace(
      /:.+$/,
      ''
    )}/welcome/?tid=${newTid}`;

    if (sceneId) {
      shareUrl += `&sceneId=${sceneId}`;
    }
    if (nobody) {
      shareUrl += `&nobody=${nobody}`;
    }
    if (lang) {
      shareUrl += `&lang=${lang}`;
    }

    if (hideShare) {
      shareUrl += `&hideShare=${hideShare}`;
    }

    if (title) {
      shareUrl += `&title=${encodeURIComponent(title)}`;
    }

    if (backgroundImageUrl) {
      shareUrl += `&backgroundImageUrl=${encodeURIComponent(backgroundImageUrl)}`;
    }

    // call api to send track info;
    try {
      await this.sendTrackInfoAndStoreRootTid({ tid, from, newTid, sceneId });
    } catch (e) {
      // do nothing
    }

    window.__shareUrl = shareUrl;

    // const appId = 'wx04ac68f0c2bb796d'; // sandbox
    // // const appId = 'wx8457bf764fcc834b'; // real
    if (CONFIG.supportWechatJsApi) {
      this.initializeWechatJsApi(shareUrl, sceneId);
    }
  }

  componentDidMount() {
    // 打点sessionId,sceneId生成并保存
    setSceneId(getParameterByName('sceneId'))
    setSessionId(generateUUID());
    setDeviceId(generateUUID());
    if (CONFIG.isOpenSkywalking) {
      ClientMonitor.register({
        collector: CONFIG.skywalkingUrl,
        service: CONFIG.getRemoteConfig().server + '-' + 'liaoyuan-h5',
        pagePath: location.href,
        serviceVersion: 'v1.0.0',
        enableSPA: true,
        useFmp: true
      });
      ClientMonitor.setPerformance({
        collector: CONFIG.skywalkingUrl,
        service: CONFIG.getRemoteConfig().server + '-' + 'liaoyuan-h5',
        pagePath: location.href,
        serviceVersion: 'v1.0.0',
        enableSPA: true,
        useFmp: true
      });
    }
    const siuvoLoaderElement = document.getElementById('siuvo-loader');

    if (siuvoLoaderElement) {
      siuvoLoaderElement.parentNode.removeChild(siuvoLoaderElement);
    }

    // set $f7 to window to make it accessible to all components
    window.$$f7 = this.myFramework7Ref.current.$f7;

    // add ali session id to all alert info
    let _alertFunction = window.$$f7.dialog.alert;
    /**
     * 
     * @param {*} message 显示提示的文字
     * @param {*} addSid 是否显示错误代码
     * @param {*} parameter 添加到alert中的参数，如cssClass
     * @param  {...any} args 回调函数等
     */
    window.$$f7.dialog.alert = function (message, addSid = true, parameter, ...args) {
      if (!_.isEmpty(parameter)) {
        window.$$f7.dialog.create({
          text: message,
          buttons: [{ text: '确认' }],
          verticalButtons: true,
          ...parameter,
          on: {
            closed: function () {
              if (typeof args[0] === 'function') {
                args[0]();
              }
            }
          }
        }).open();
      } else {
        if (addSid) {
          _alertFunction(`${message}\n(${__bl.session || '..'})`, ...args);
        } else {
          _alertFunction(message, ...args);
        }
      }
    };

    /* ------hard code smhc redirect------- */
    const tid = getParameterByName('tid');
    if (tid === 'Nfzmu_hph' || tid === 'X5h3Flcu') {
      let newUrl = window.location.href;
      if (newUrl.match('add71cbe50834bf')) {
        newUrl = newUrl.replace('add71cbe50834bf', '21feb68de040470');
        window.location.replace(newUrl);
      }
    }

    /* ---- end hard code smhc redirect------ */

    /* ----  shenwan redirect ----- (2.18.2019)*/
    if (window.location.href.match('h5.demo.siuvo.cn/#!/userLanding')) {
      let newUrl = window.location.href;
      console.log(newUrl);
      newUrl = newUrl.replace(
        'http://h5.demo.siuvo.cn/#!/userLanding',
        'https://h5.siuvo.cn/#!/userLanding/'
      );
      newUrl += '&tid=jFKTRyW4';
      window.location.replace(newUrl);
    }

    // //for local testing
    // if (window.location.href.match('localhost:3000/#!/userLanding')) {
    //   let newUrl = window.location.href;
    //   console.log(newUrl);
    //   newUrl = newUrl.replace('http://localhost:3000/#!/userLanding', 'https://h5.siuvo.cn/userLanding');
    //   newUrl += "&tid=jFKTRyW4"
    //   window.location.replace(newUrl);
    // }

    /* ---- end shenwan redirect ------ */

    /* ------hard code baoxiulan + biGuiYuan redirect (2.18.2019)------- */
    const sceneId = getParameterByName('sceneId');
    if (tid === '72sQhF1Q') {
      //currently they're using an incorrect tid that's already associated with yunnan

      //baoxiulan
      if (sceneId === '3d66fd3bdb0243a') {
        let newUrl = window.location.href;
        newUrl = newUrl.replace('72sQhF1Q', 'T5gFkqU0');
        window.location.replace(newUrl);
      }

      //biGuiYuan
      if (sceneId === 'f6dc261ad0e9431') {
        let newUrl = window.location.href;
        newUrl = newUrl.replace('72sQhF1Q', 'vGQ1hz2j');
        window.location.replace(newUrl);
      }
    }

    /* ---- end hard code baoxiulan + biGuiYuan redirect------ */

    /** ------ hard code for xinhua hospital to add removeLogo parameter */

    if (!window.location.href.match('removeLogo') && (sceneId === '035bdaa1680649d' || sceneId === 'a7a2a8e1b8ca49e')) {
      let newUrl = window.location.href;
      newUrl = newUrl.concat('&removeLogo=true');
      window.location.replace(newUrl);
    }

    /* ---- end hard code xinhua------ */

    /* ----- NOTE: there is a hardcoded nginx redirect on es2 for drNi, 0a21efada4b0431 => 0a21efada4b0431_redirect ----- (cece - 2020.09.06)*/



    if (isIphonex()) {
      document.body.style.paddingBottom = '34px';
      document.body.style.boxSizing = 'border-box';
      document.body.style.transition = 'all 0.2s linear';
    }

    // 软键盘弹出
    document.body.addEventListener('focusin', event => {
      let target = event.target;
      if (event.target.tagName.toLowerCase() !== 'input') {
        return;
      }

      if (isIphonex()) {
        document.body.style.paddingBottom = '0px';
        document.body.style.boxSizing = 'border-box';
      }

      setTimeout(() => {
        // scroll to bottom
        var messageListWrapper = document.getElementById(
          'message_list_wrapper'
        );
        if (messageListWrapper) {
          messageListWrapper.scrollTop = messageListWrapper.scrollHeight;
        }

        //https://github.com/nieyt/input-show/blob/master/src/index.js
        let ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('iphone') > 0 || ua.indexOf('ipad') > 0) {
          if (target && messageListWrapper) {
            // only scroll if there are messageListWrapper
            const isSpecVersion = checkVersion();
            if (!isSpecVersion) {
              target.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'nearest'
              });
              // target.scrollIntoView(true); //元素的顶端与可视区域的顶端对齐
            }
          }
        }
      }, 500);
    });

    //软键盘收起的事件处理
    document.body.addEventListener('focusout', event => {
      // in some case, like the keyboard going out or something,
      if (event.target.tagName.toLowerCase() !== 'input') {
        return;
      }
      if (isIphonex()) {
        document.body.style.paddingBottom = '34px';
        document.body.style.boxSizing = 'border-box';
      }
      setTimeout(() => {
        // scroll to bottom
        var messageListWrapper = document.getElementById(
          'message_list_wrapper'
        );
        if (messageListWrapper) {
          messageListWrapper.scrollTop = messageListWrapper.scrollHeight;
        }
      }, 300);

      // workaround for ios wechat keyboard bug
      let ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf('iphone') > 0 || ua.indexOf('ipad') > 0) {
        setTimeout(() => {
          document.body.scrollTop = document.body.scrollHeight;
          // document.body.scrollTop = document.body.scrollTop;
          //滚动到顶部
          window.scrollTo(0, 0);
          //滚动到底部
          window.scrollTo(0, document.documentElement.clientHeight);
        }, 10);
      }
    });

    this.setUpTrackingAndWechatApi();
  }

  render() {
    // tell React that we want to associate the <input> ref
    // with the `textInput` that we created in the constructor
    return (
      <App params={f7params} ref={this.myFramework7Ref}>
        {/* <Statusbar /> */}

        {/* initial page is specified in routes.js */}
        <View
          main
          url="/"
          pushState
          pushStateOnLoad={true}
        // stackPages={false}
        ></View>
      </App>
    );
  }
}
