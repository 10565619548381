// 徐牙防的患者病历列表
import React, { useState, useCallback } from 'react';
import {
  Page,
  Navbar,
  NavLeft,
  Link,
  Icon,
  Block,
  NavTitle,
  List,
  ListItem,
  BlockFooter,
  Button,
  Preloader
} from 'framework7-react';
import 'framework7-icons';
import _ from 'lodash';
import { registerWithHospitalUser } from '../../modules/shufu.js';
import getParameterByName from '../../utils/getParameterByName';
import {
  setToken,
  setCurrentUserId,
  getCurrentUserId,
  setAnnouncement,
  setPatientInfo,
  removeAnnouncement,
  setShowHistoryReportButton
} from '../../utils/localStorage.js';
import { initShuaiCloud as initShuaiCloudFromClientInstance } from '../../modules/shuaicloud/clientInstance';

export default function MedicalRecordListXuyafang(props) {
  const { medicalRocordList, idCardType, organizationId } = props;
  const [loading, setLoading] = useState(false);

  const sceneId = getParameterByName('sceneId');
  let chatUrl = '/chat/';

  function renderMedicalRocordList() {

    return (
      medicalRocordList && medicalRocordList.length > 0
        ? (
          <>
            <List mediaList>
              {
                medicalRocordList.map((item, index) => {
                  return (
                    <ListItem
                      key={index}
                      title={`姓名:${item.name ? item.name : '暂无'}`}
                      subtitle={`挂号序号:${item.encounterIdentifier ? item.encounterIdentifier : '暂无'}`}
                      after={`医生:${item.doctor ? item.doctor : '暂无'}`}
                      text={`科室:${item.department ? item.department : '暂无'}`}
                      link="#"
                      onClick={() => {
                        setLoading(true);
                        handleStartServiceCheckEncounterAndRestart(item);
                      }}
                    >
                    </ListItem>
                  )
                })
              }
            </List>
          </>
        )
        : (
          <>
            <List mediaList>
              <ListItem>暂无数据</ListItem>
            </List>
          </>
        )
    )
  }

  const _registerWithHospitalUser = useCallback(async (params) => {
    let res = await registerWithHospitalUser(params);
    console.log('res', res);
    // 拿到token，连接socket
    const token = _.get(res, ['token']);
    const userid = _.get(res, ['userid']);
    setCurrentUserId(userid);
    setToken(token);
    if (token) {
      await initShuaiCloudFromClientInstance();
    }
  }, []);

  const handleStartServiceCheckEncounterAndRestart = useCallback(async (patientInfo) => {
    const { sceneId, idCard, params } = patientInfo;
    await _registerWithHospitalUser({
      organizationId,
      idCard,
      idCardType,
      params
    });
    setPatientInfo(patientInfo);
    setLoading(false);
    props.f7router.navigate(`/chat/?sceneId=${sceneId}`, {
      reloadCurrent: true,
    });
  }, [props.f7router, _registerWithHospitalUser, idCardType, organizationId]);

  return (
    <Page
      noToolbar
      noSwipeback
    >
      <Navbar>
        <NavLeft backLink='返回' backLinkUrl='/loginWithCertificate' sliding>
        </NavLeft>
        <NavTitle>病历列表</NavTitle>
      </Navbar>
      {
        renderMedicalRocordList()
      }
      {/* <Block>
        <BlockFooter>
          xxxxxxxxxxxxx
        </BlockFooter>
      </Block> */}
      {
        loading
          ? <div
            style={{
              width: '100%',
              display: 'flex',
              margin: 20,
              justifyContent: 'center'
            }}
          >
            <Preloader size={30} />
          </div>
          : null
      }
    </Page>
  )
}