import React, { useState, useEffect } from 'react';

import { Input, Icon, Link, Actions, ActionsButton, List, ListItem } from 'framework7-react';

import i18n from '../i18n';


InputWithSuffix.defaultProps = {
  suffixList: null,
  questionKey: null,
  params: {},
  model: null,
  style: {},
  onChange: function () {
    console.warn('InputWithSuffix onChange not implemented');
  }
};
export default function InputWithSuffix(props) {
  const [inputString, setInputString] = useState('');
  const [suffixString, setSuffixString] = useState('');

  const { suffixList, questionKey, params, onChange, model, style } = props;

  useEffect(() => {
    let suffixArray = suffixList;
    if (suffixArray && typeof suffixArray === 'string') {
      suffixArray = [suffixArray];
    }
    if (suffixArray && suffixArray.length) {
      setSuffixString(suffixList[0]);
    }
  }, [suffixList]);

  function getValueFromModel(model) {
    if (model == null) return '';
    if (model === 'SKIPPED') return '';
    const modelStr = `${model}`;
    if (suffixString) {
      const modelSuffix = modelStr.substring(modelStr.length - suffixString.length, modelStr.length);
      if (modelSuffix === suffixString) return model.substring(0, model.length - suffixString.length);
    }
    return model;
  }

  function renderInput() {
    return (
      <div style={{
        padding: 2,
        fontSize: 16,
        flex: 1
      }}>
        <Input
          {...props}
          // maxlength={50}
          onChange={event => {
            // console.log('props', props)
            const input = event.target.value;
            if (props.inputType === 'number') {
              const reg = /^\d*(\.\d*)?$/;
              // console.log('value；', reg.test(input))
              if (!reg.test(input)) return
            }
            if (input && input.length > 50) {
              window.$$f7.dialog.alert(i18n.t('maxlength_error_message'), false);
              return;
            }
            setInputString(input);
            if (input && input.length > 0)
              onChange(input + suffixString, params);
            else
              onChange(undefined, params);
          }}
          value={getValueFromModel(model)}
        ></Input>
      </div>
    );
  }

  function renderSuffixSelector() {
    let suffixArray = suffixList;
    if (!suffixArray) {
      return null;
    }
    if (suffixArray && typeof suffixArray === 'string') {
      suffixArray = [suffixArray];
    }

    const actionsKey = `suffix-actions-menu-${questionKey.replace(/\W/g, '')}`;
    if (suffixArray.length > 1) {
      return (
        <div style={{ background: '#2979ff', borderRadius: '15px', padding: '3px 10px', alignSelf: "center"  }}>
          <Link actionsOpen={`.${actionsKey}`} style={{ color: '#fff' }}>
            {suffixString}

            <Icon
              f7="chevron_down"
              size="14px"
              color="blue"
              style={{ marginLeft: 5, color: '#fff' }}
            ></Icon>
          </Link>

          <Actions className={actionsKey}>
            {suffixArray.map(suffix => {
              return (
                <ActionsButton
                  key={`${suffix}`}
                  style={{ backgroundColor: "#fff" }}
                  title={suffix}
                  onClick={() => {
                    setSuffixString(suffix);
                    onChange(inputString + suffix, params);
                  }}
                >{suffix}</ActionsButton>
              );
            })}
          </Actions>
        </div>
      );
    } else {
      return <div style={{ color: '#2979ff', alignSelf: "center" }}>{suffixString}</div>;
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row', ...style }}>
      {renderInput()}
      {renderSuffixSelector()}
    </div>
  );
}
