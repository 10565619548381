import React, { useState, useEffect, useCallback } from 'react';
import { Message, Popup } from 'framework7-react';
import './MessageBlock.css';
import { getJsonFormById } from '../modules/shufu.js';
// import _ from 'lodash';
import { answerBoardEmitter } from '../modules/answerBoardEmitter.js';
import robot from '../images/robot.png';
// import DemoChooseItemAndViewInvoicePopup from './DemoChooseItemAndViewInvoicePopup.js';
MessageBlockDemoForm.defaultProps = {
  shufuFormId: null
};

export default function MessageBlockDemoForm(props) {
  const [form, setForm] = useState({});
  const [formId, setFormId] = useState(null);
  const [popupOpen, setPopupOpen] = useState(false);
  useEffect(() => {
    async function getForm(formId) {
      try {
        let jsonForm = await getJsonFormById(formId);
        let form = JSON.parse(jsonForm.form);
        console.log('MessageBlockDemoForm-->getForm-jsonForm：', jsonForm)
        console.log('MessageBlockDemoForm-->getForm-form：', form)
        setForm(form);
        answerBoardEmitter.emit('set-answer-board', {
          type: 'demoChooseItemsAndViewInvoiceForm',
          form: form
        });
      } catch (e) {
        // do nothing
      }
    }
    if (props.shufuFormId) {
      setFormId(props.shufuFormId);
      getForm(props.shufuFormId);
    }
  }, [props.shufuFormId]);

  const submitFormHandler = useCallback(
    async () => {
      window.$$f7.dialog.alert('预约成功!', false);
      console.log('已点击预约');
    },
    []
  );

  // add submit form emitter
  useEffect(() => {
    answerBoardEmitter.on('submit', submitFormHandler);

    return () => {
      answerBoardEmitter.removeListener('submit', submitFormHandler);
    };
  }, [submitFormHandler]);

  let askMessage = (
    <Message
      key={`${props.messageId}_ask`}
      className="shufu-text-message-bubble"
      style={{ marginBottom: 5, marginTop: 5 }}
      type="received"
      avatar={robot}
      // textHeader={'bbb'}
      text={form.schema && form.schema.shufuFormSubject_zh}
    ></Message>
  );
  // TODO get schema, and display more message

  // let answerMessage =
  //   (
  //     <Message
  //       key={`${props.messageId}_answer`}
  //       className="shufu-text-message-bubble"
  //       style={{ marginBottom: 5, marginTop: 5 }}
  //       type="sent"
  //       onClick={() => {
  //         setPopupOpen(true);
  //       }}
  //     >
  //       <span style={{ borderBottom: '1px solid white' }}>点击查看作答</span>
  //       <Popup
  //         className="static-form-popup"
  //         opened={popupOpen}
  //         onPopupClosed={() => {
  //           setPopupOpen(false);
  //         }}
  //       >
  //         <DemoChooseItemAndViewInvoicePopup
  //           schema={form && form.schema}
  //           submitHandler={submitFormHandler}
  //         ></DemoChooseItemAndViewInvoicePopup>
  //       </Popup>
  //     </Message>
  //   );
  return [askMessage];
}
