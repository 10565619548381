import { Message } from 'framework7-react';
import React from 'react';
import i18n from '../i18n.js';
import './MessageBlock.css';
import parseLanguage from '../utils/parseLanguage';
import handleTraditionalChinese from '../utils/handleTraditionalChinese'
import moment from 'moment'
import _ from 'lodash'


AnswerMessageBubble.defaultProps = {
    onEdit: () => null,
    canEdit: false,
    messageId: null,
    message: {
        _id: null,
        text: null
    },
    customerAvatarImageUrl: null,
    chatBubbleCustomerStyle: ''
};

function parseAnswerText(text) {
    if (text === 'SKIPPED') return i18n.t('skipped');
    return parseLanguage(text).replace(/^__OTHER__/, '');
}

const getImageHtmlStr = (imageUrl) => `<img src="${imageUrl}" style="width:100%;" />`;

export function convertAnswerValToAnswerText(answerVal, question) {
    let text = '';

    if (Array.isArray(answerVal)) {
        // alert('WA');
        if (question.viewType === 'uploadImage') {
            if (answerVal.includes('SKIPPED')) return i18n.t('skipped');
            text = `<div>${answerVal.map(imageUrl => getImageHtmlStr(imageUrl))}</div>`;
        } else {
            text = answerVal.map(answer => parseAnswerText(answer)).join(', ');
        }
    } else if (question.viewType === 'date') {
        if (answerVal === 'SKIPPED') {
            text = i18n.t('skipped');
        } else {
            const dateUnit = _.get(question, 'dateUnit', '');
            const dateFormat = (dateUnit === 'YEAR')
                ? "YYYY"
                : (
                    (dateUnit === 'MONTH')
                        ? "YYYY-MM"
                        : "YYYY-MM-DD"
                )
            const selectDate = moment(answerVal);
            text = selectDate.format(dateFormat);
        }
    } else {
        text = parseAnswerText(answerVal);
        // text=answerVal;
    }

    return handleTraditionalChinese(text);
}

export function createAnswerMessage(currentQuestionId, answerKey, answerVal, question) {

    return {
        _id: `${currentQuestionId}_answer`,
        text: convertAnswerValToAnswerText(answerVal, question),
        type: 'answer', //! !!! THE LIB REQUIRES THIS KEY AND VALUE TO DETERMINE THE MESSAGE TYPE
        questionId: currentQuestionId,
        answer: answerKey,
        questionModel: question.model, //! !!! THE LIB REQUIRES THIS KEY AND VALUE TO DETERMINE THE QUESTION MODEL
        viewType: question.viewType,
        // avatarUrl: myavatarUrl,
        direction: 'sent'
    };
}


export function AnswerMessageBubble(props) {
    // console.log('AnswerMessageBubble------------------------');
    const { onEdit, canEdit, messageId, message: { _id, text }, customerAvatarImageUrl, chatBubbleCustomerStyle } = props;

    // let styleSheet = document.styleSheets[0];
    // styleSheet.insertRule('.message-sent .message-bubble {' + chatBubbleCustomerStyle + '}', 0);
    return (<div className='shufu-message-sent-content'>
        <div
            className="can-edit-tag"
            slot="content-end"
            style={{
                maxHeight: 100,
                visibility: canEdit ? 'visible' : 'hidden'
            }}
            onClick={onEdit}
        >
            {i18n.t('click_to_change_answer')}
        </div>
        <Message
            key={`${messageId}_${_id}`}
            className='shufu-text-message-bubble'
            style={{ marginBottom: 5, marginTop: 5 }}
            type="sent"
            text={<div dangerouslySetInnerHTML={{__html: text}}/>}
            avatar={customerAvatarImageUrl}
        >
        </Message>
    </div>);
}
