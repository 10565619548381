import React, { useState, useEffect, useCallback } from 'react';
import { Message, Popup } from 'framework7-react';
import './MessageBlock.css';
import { getJsonFormById, getJsonFormProcessedById, getJsonFormDataById, replyToForm } from '../modules/shufu.js';
import _ from 'lodash';
import { answerBoardEmitter } from '../modules/answerBoardEmitter.js';
import robot from '../images/robot.png';
import StaticFormPopup from './StaticFormPopup.js';
import { submitPatientEvent } from './SubmitPatientEvent.js'

MessageBlockStaticForm.defaultProps = {
  messageJson: {},
  isLastMessage: false,
  messageId: Date.now(),
  message: {}
};

// function parseLanguage(text, language) {
//   const str = text ? `${text}` : text;
//   if (str && str.includes('|~|')) {
//     const [text_zh, text_en] = str.split('|~|');
//     return language && language === 'en' ? text_en : text_zh;
//   } else {
//     return str;
//   }
// }

export default function MessageBlockStaticForm(props) {
  // const [symptomObject, setSymptomObject] = useState({});
  const [model, setModel] = useState(null); // this one stores newly answered result, for rendering result
  const [form, setForm] = useState(null);
  const [formId, setFormId] = useState(null);
  const [popupOpen, setPopupOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { userId, messageJson, isLastMessage, message, messageId } = props;

  useEffect(() => {
    async function getForm(formId, model, fromJsonFormDataId) {
      try {
        const jsonForm = fromJsonFormDataId ? await getJsonFormProcessedById(formId, fromJsonFormDataId, userId) : await getJsonFormById(formId);

        let form = JSON.parse(jsonForm.form);
        setForm(form);
        if (isLastMessage) {
          answerBoardEmitter.emit('set-answer-board', {
            type: 'staticForm',
            form,
            model
          });
        }
      } catch (e) {
        // do nothing
      }
    }
    let _form = JSON.parse(messageJson._lctext || '{}');
    let partSchema = _form.schema || {};
    let shufuFormId = partSchema.shufuFormId;
    const fromJsonFormDataId = partSchema.fromJsonFormDataId;
    if (shufuFormId) {
      if (formId !== shufuFormId) {
        setFormId(shufuFormId);
        getForm(shufuFormId, _form.model, fromJsonFormDataId);
      }
    }
  }, [formId, messageJson._lctext, isLastMessage, userId]);

  const submitFormHandler = useCallback(
    async model => {
      // let model = {
      //   symptoms
      // };

      // console.log(props.messageJson);
      let answerObject = JSON.parse(messageJson._lctext || '{}');
      answerObject.model = model;
      // return;

      const text = JSON.stringify(answerObject);

      let body = {
        from: message.from,
        to: message.to,
        'msg-id': message['msg-id'],
        timestamp: new Date(message.timestamp).getTime(),
        data: {
          _lctext: text,
          _lcattrs: {
            ...messageJson._lcattrs,
            processId: answerObject.interaction.processId, // FIXME workaround here
            businessKey: answerObject.interaction.businessKey // FIXME workaround here
          },
          _lctype: -1 // <-- not sure what's this..., just use -1 by default
        }
      };
      setModel(model);
      setIsSubmitted(true);
      // scroll to the end
      setTimeout(() => {
        var messageListWrapper = document.getElementById(
          'message_list_wrapper'
        );
        if (messageListWrapper) {
          // console.log("scroll to top MessageBlockStaticForm: ", messageListWrapper.scrollTop);
          messageListWrapper.scrollTop = messageListWrapper.scrollHeight;
        }
      }, 500);
      // return;
      const EventParams = {
        formId,
        endTime: new Date().getTime(),
        messageId: messageId,
        serviceInstanceId: _.get(props, 'messageJson._lcattrs.ssid', '')
      }
      submitPatientEvent(EventParams, 'onReplyToForm');
      try {
        const res = await replyToForm(body);
        if (!res) {
          submitPatientEvent(EventParams, 'onReplyToFormFailed');
        }
      } catch (e) {
        console.log("error: ", e);
      }
    },
    [formId, message, messageJson._lcattrs, messageJson._lctext, messageId, props]
  );

  // add submit form emitter
  useEffect(() => {
    if (isLastMessage) {
      answerBoardEmitter.on('submit', submitFormHandler);
    }

    return () => {
      answerBoardEmitter.removeListener('submit', submitFormHandler);
    };
  }, [submitFormHandler, isLastMessage]);

  let _form = JSON.parse(messageJson._lctext || '{}');
  const isProcessSuccess = messageJson._lcattrs.isProcessSuccess;
  if (_form.model && !model) {
    setModel(_form.model);
  }

  let askMessage = (
    <Message
      key={`${messageId}_ask`}
      className="shufu-text-message-bubble"
      style={{ marginBottom: 5, marginTop: 5 }}
      type="received"
      avatar={robot}
      // textHeader={'bbb'}
      text={_form.schema && _form.schema.shufuFormSubject_zh}
    ></Message>
  );
  // TODO get schema, and display more message

  let answerMessage =
    isProcessSuccess || isSubmitted ? (
      <Message
        key={`${messageId}_answer`}
        className="shufu-text-message-bubble"
        style={{ marginBottom: 5, marginTop: 5 }}
        type="sent"
        onClick={() => {
          setPopupOpen(true);
        }}
      >
        <span style={{ borderBottom: '1px solid white' }}>点击查看作答</span>
        <Popup
          className="static-form-popup"
          opened={popupOpen}
          onPopupClosed={() => {
            setPopupOpen(false);
          }}
        >
          <StaticFormPopup
            schema={form && form.schema}
            model={model}
            submitHandler={null}
            canSubmit={false}
          ></StaticFormPopup>
        </Popup>
      </Message>
    ) : null;
  return [askMessage, answerMessage];
}
