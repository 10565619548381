import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import './DemoChooseItemAndViewInvoicePopup.css';
import { Link, Popover, Checkbox, Page, Navbar, NavLeft, NavTitle, Icon } from 'framework7-react';
import intro from '../images/intro.png';

export default function DemoChooseItemAndViewInvoicePopup(props) {
  const [order, setOrder] = useState([]);
  const [forms, setForms] = useState({});
  const [labTests, setLabTests] = useState({});
  const [sumPrice, setSumPrice] = useState(0);
  const [processChoose, setProcessChoose] = useState(true);

  useEffect(() => {
    let _forms = _.get(props, ['schema', 'properties', 'forms'], {});
    _forms.items = _.get(_forms, 'items', []).map(formsItem => {
      formsItem.checked = false;
      return formsItem;
    })
    let _labTests = _.get(props, ['schema', 'properties', 'labTests'], {});
    _labTests.items = _.get(_labTests, 'items', []).map(labTestsItem => {
      labTestsItem.checked = false;
      return labTestsItem;
    })
    // console.error(props);
    // console.error(_forms);
    // console.error(_labTests);
    setOrder(_.get(props, 'order', []));
    setForms(_forms);
    setLabTests(_labTests);
  }, [props]);

  // 计算总金额
  function calculatePrice() {
    let price = 0;
    forms.items.map(form => {
      if (form.checked) price += parseFloat(form.price);
    })
    labTests.items.map(labTest => {
      if (labTest.checked) price += parseFloat(labTest.price);
    })
    setSumPrice(price);
  }

  function setCheckboxClick(form, type) {
    if (type === 'forms') {
      let newForms = { ...forms };
      newForms.items.map(newForm => {
        if (newForm.id === form.id) newForm.checked = !newForm.checked;
        return newForm;
      });
      setForms(newForms);
    } else {
      let newLabTests = { ...labTests };
      newLabTests.items.map(newLabTest => {
        if (newLabTest.id === form.id) newLabTest.checked = !newLabTest.checked;
        return newLabTest;
      });
      setLabTests(newLabTests);
    }
  }

  // 选择项目页面
  function renderItem(formInfo, type) {
    return <div className='form-info'>
      <div className='form-info-title'>{formInfo.title_zh}</div>
      {_.get(formInfo, 'items', []).map((form, index) => {
        return <div key={form.id}>
          <div style={{ display: 'flex', lineHeight: '30px' }}>
            <div style={{ flex: 1 }}>
              <Checkbox
                value={form.id}
                key={formInfo.title_zh}
                checked={
                  form.checked
                }
                onChange={() => {
                  setCheckboxClick(form, type);
                }}
              ></Checkbox>
            </div>
            <div className='form-name' style={{ textAlign: 'left' }} onClick={() => {
              setCheckboxClick(form, type);
            }}>{form.name}</div>
            <div style={{ flex: 2, textAlign: 'right' }} onClick={() => {
              setCheckboxClick(form, type);
            }}>￥{form.price.toFixed(2)}</div>
            <div style={{ flex: 1, textAlign: 'right' }}>
              <Link style={{ flex: '1' }} popoverOpen={".popover-info" + index + type}>
                <img src={intro} style={{ width: 20, height: 20, margin: '5px 0' }} />
              </Link>
              <Popover className={"popover-info" + index + type} >
                <div style={{ padding: 20 }}>{form.information}</div>
              </Popover>
            </div>
          </div>
        </div>
      })}
    </div>
  }

  // 渲染已选择项目页面
  function renderSelectItem(type) {
    const formInfos = type === 'forms' ? { ...forms } : { ...labTests }
    return <div className='form-info' style={{ display: 'flex' }}>
      <div style={{ padding: '0 10px', flex: 1 }}>{formInfos.title_zh}</div>
      <div style={{ flex: 3 }}>
        {formInfos.items.map((formInfo, formIndex) => {
          if (formInfo.checked) {
            return <div style={{ display: 'flex' }} key={formIndex}>
              <div style={{ flex: 3 }}>{formInfo.name}</div>
              <div style={{ flex: 1 }}>￥{formInfo.price.toFixed(2)}</div>
            </div>
          } else {
            return null;
          }
        })}
      </div>
    </div>
  }

  // 加载所有表单
  function renderAllItem() {
    if (order.length > 0) {
      if (order[0] === 'forms' && order[1] === 'labTests') {
        return <div>
          {renderItem(forms, 'forms')}
          {renderItem(labTests, 'labTests')}
        </div>
      }
    }
    return <div>
      {renderItem(labTests, 'labTests')}
      {renderItem(forms, 'forms')}
    </div>
  }

  return (
    <Page key={props.symptom && props.symptom.id}>
      <Navbar>
        <NavLeft>
          {processChoose ? (<Link popupClose>
            <Icon icon="icon-back"></Icon> <span> 返回</span>
          </Link>) : (<Link onClick={() => { setProcessChoose(true) }}>
            <Icon icon="icon-back"></Icon> <span> 返回</span>
          </Link>)}
        </NavLeft>
        <NavTitle>{_.get(props, 'schema.title_zh')}</NavTitle>
      </Navbar>
      <div>
        {processChoose ? (
          <div>
            <div className='content-div'>
              <div className='nav-title'>{props.schema.instructions_zh}</div>
              {renderAllItem()}
            </div>
            <div className='btn-div'>
              <div className='text-button'></div>
              <div className='submit-button' onClick={() => {
                calculatePrice();
                setProcessChoose(false);
              }}>下一步</div>
            </div>
          </div>
        ) : (
            <div>
              <div className='content-div'>
                <div className='nav-title'>项目信息</div>
                {renderSelectItem('forms')}
                {renderSelectItem('labTests')}
              </div>
              <div className='btn-div'>
                <div className='text-button'>需付金额：{sumPrice.toFixed(2)}元</div>
                <div className='submit-button' onClick={() => {
                  props.submitHandler();
                }}>提交</div>
              </div>
            </div>
          )}
      </div>
    </Page >
  )
}