import React, { useState, useEffect, useCallback } from 'react';
import { answerBoardEmitter } from '../modules/answerBoardEmitter.js';
import './AnswerBoard.css';
import SymptomDetailPopup from './SymptomDetailPopup.js';
import CategorySymptomPopup from './CategorySymptomPopup.js';
import { getAllComplaintsByTag } from '../modules/shufu.js';
import _ from 'lodash';

import { Popup, Preloader } from 'framework7-react';

AnswerBoardCategorySearch.defaultProps = {
  category: '',
  avSchemaIdSuffix: null,
  buttonText: "查看所有症状 >",
  title: "选择症状",
  subcategories: null,
  defaultSymptomsTags: null,
  removeCategoryPage: false,
  limit: 1
};
export default function AnswerBoardCategorySearch(props) {
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [defaultSymptomsList, setDefaultSymptomsList] = useState([]);
  const [fullSymptomList, setFullSymptomList] = useState([]);
  const [popupOpen, setPopupOpen] = useState(false);
  const [fullSymptomPopupOpen, setFullSymptomPopupOpen] = useState(false);
  const [selectedSymptom, setSelectedSymptom] = useState(null);
  const [selectedSymptoms, setSelectedSymptoms] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const { title, buttonText, category, avSchemaIdSuffix, subcategories, defaultSymptomsTags, removeCategoryPage, limit } = props;

  const submitHandler = useCallback(async (symptoms = []) => {
    setSubmitting(true);
    setPopupOpen(false);
    setFullSymptomPopupOpen(false);
    answerBoardEmitter.emit('submit', symptoms);
  }, []);

  useEffect(() => {
    setLoading(true);
    async function init() {
      try {
        let symptomList = await getAllComplaintsByTag(category, avSchemaIdSuffix, subcategories);
        // todo get default recommaned symptoms
        let defaultSymptomsList = [];
        const chiefComplaintsByTagMap = _.keyBy(symptomList, 'tagName');
        if (defaultSymptomsTags) defaultSymptomsList = _.uniqBy(defaultSymptomsTags.reduce((curr, defaultSymptomsTag) => curr.concat(_.get(chiefComplaintsByTagMap, [defaultSymptomsTag, "chiefComplaints"], [])), []), "id");
        setLoading(false);
        setDefaultSymptomsList(defaultSymptomsList);
        setFullSymptomList(symptomList);
        setTimeout(() => {
          var messageListWrapper = document.getElementById(
            'message_list_wrapper'
          );
          if (messageListWrapper) {
            // console.log("scroll to top AnswerBoardCategorySearch ", messageListWrapper.scrollTop);
            messageListWrapper.scrollTop = messageListWrapper.scrollHeight;
          }
        }, 500);

      } catch (e) {
        // alert(e)
      }
    }

    init();
  }, [category, avSchemaIdSuffix, subcategories, defaultSymptomsTags]);

  const addSymptom = useCallback((symptom) => {
    const newSelectedSymptoms = selectedSymptoms.filter(selectedSymptom => selectedSymptom.id !== symptom.id).concat([symptom]);
    setSelectedSymptoms(newSelectedSymptoms);
  }, [selectedSymptoms])

  const deleteSymptom = useCallback((symptom) => {
    const newSelectedSymptoms = selectedSymptoms.filter(selectedSymptom => selectedSymptom.id !== symptom.id);
    setSelectedSymptoms(newSelectedSymptoms);
  }, [selectedSymptoms])

  // render part
  if (loading) {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          margin: 20,
          justifyContent: 'center'
        }}
      >
        <Preloader size={30} />
      </div>
    );
  } else {
    return (
      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <div>
          <Popup
            className="demo-popup"
            opened={popupOpen}
            onPopupClosed={() => {
              setPopupOpen(false);
            }}
          >
            <SymptomDetailPopup
              symptom={selectedSymptom}
              selectedCategory={selectedCategory}
              submitHandler={(symptom) => {
                if (limit === 1) {
                  window.$$f7.dialog.confirm('请检查您的选择，确认提交后将不得修改。是否确认提交？', '提示', () => {
                    submitHandler([symptom]);
                  });
                  return;
                }
                addSymptom(symptom);
                setPopupOpen(false);
                setFullSymptomPopupOpen(true);
              }}
            ></SymptomDetailPopup>
          </Popup>
          <Popup
            className="demo-popup"
            opened={fullSymptomPopupOpen}
            onPopupClosed={() => {
              setFullSymptomPopupOpen(false);
            }}
          >
            <CategorySymptomPopup
              isOpen={fullSymptomPopupOpen}
              fullSymptomList={fullSymptomList}
              selectedSymptoms={selectedSymptoms}
              onSubmitHandler={submitHandler}
              limit={limit}
              onSelectHandler={(symptom, selectedCategory) => {
                if (selectedSymptoms.some(selectedSymptom => selectedSymptom.id === symptom.id)) {
                  deleteSymptom(symptom);
                  return;
                }
                if (selectedSymptoms.length === limit) {
                  window.$$f7.dialog.alert(`请最多选择${limit}个症状`, false);
                  return;
                }

                if (!symptom.attributeValueSchema || Object.keys(symptom.attributeValueSchema).length === 0) {
                  if (limit === 1) {
                    window.$$f7.dialog.confirm('请检查您的选择，确认提交后将不得修改。是否确认提交？', '提示', () => {
                      symptom.attributeValueModel = {};
                      submitHandler([symptom]);
                      setFullSymptomPopupOpen(false);
                    })
                    return;
                  }
                  addSymptom(symptom);
                  setFullSymptomPopupOpen(false);
                  return;
                }
                if (limit === 1)
                  setFullSymptomPopupOpen(false);
                setSelectedSymptom(symptom);
                setSelectedCategory(selectedCategory)
                setTimeout(() => {
                  setPopupOpen(true);
                }, 10);
              }}
              title={title}
            ></CategorySymptomPopup>
          </Popup>
        </div>
        {submitting ? (
          <Preloader size={48} color="#2979ff"></Preloader>
        ) : (
          <div
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            {defaultSymptomsList.length > 0 ? <div className="answer-board-answer-button-wrapper-left">
              {defaultSymptomsList.map(symptom => {
                return (
                  <div
                    style={{ position: 'relative' }}
                    className="shufu-answer-button-div"
                    key={`${category}.${symptom.id}`}
                  >
                    <div
                      className={symptom.iconUrl ? "shufu-answer-button button-min-width-auto" : "shufu-answer-button"}
                      key={`${category}.${symptom.id}`}
                      onClick={() => {
                        if (!symptom.attributeValueSchema || Object.keys(symptom.attributeValueSchema).length === 0) {
                          window.$$f7.dialog.confirm('请检查您的选择，确认提交后将不得修改。是否确认提交？', '提示', () => {
                            symptom.attributeValueModel = {};
                            submitHandler([symptom]);
                          })
                          return;
                        }
                        setPopupOpen(true);
                        setSelectedSymptom(symptom);
                      }}
                    >
                      <div>
                        {symptom.iconUrl ? (
                          <img src={symptom.iconUrl} style={{ height: 18, position: 'relative', top: 3, marginRight: 5 }}></img>
                        ) : null}
                        {symptom.synonyms_zh[0]}
                      </div>
                    </div>
                    {symptom.description_zh ? <span
                      className="shufu-answer-button-information-icon"
                      onClick={() => window.$$f7.dialog.alert(symptom.description_zh, false, { cssClass: 'dialog-text-left' })
                      }>?</span> : null}
                  </div>
                );
              })}
            </div> : null}

            {!removeCategoryPage ? <div className="answer-board-action-button-wrapper">
              <div
                className="inline-form-submit-button"
                onClick={() => {
                  setFullSymptomPopupOpen(true);
                }}
              >
                {buttonText}
              </div>
            </div> : null}
          </div>
        )}
      </div>
    );
  }

  // todo should get suggest diseases;
}
