import React, { useState, useEffect } from 'react';
import {
    Page,
    Navbar,
    NavLeft,
    Link,
    Icon,
    Block,
    Card,
    NavTitle,
    Input,
    List,
    ListItem,
    Button
} from 'framework7-react';

RelatedQuestionsPopup.defaultProps = {
    relatedQuestions: []
};

export default function RelatedQuestionsPopup(props) {

    const getListItem = (relatedQuestion) => {
        return (
            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottomWidth: "thin",
                borderColor: "#f9f9f9",
                margin: "0 0 1em",
                borderRadius: "0.5em",
                background: "white",
            }}>
                <div style={{
                    width: "75%",
                    padding: "1.2em"
                }}>{relatedQuestion.title}</div>
                

                <div style={{
                    width: "25%",
                    padding: "0.4em",
                    paddingLeft: "1.2em",
                    borderLeft: "2px dashed #aaa",
                    alignText: "right"
                }}>{relatedQuestion.answer}</div>
            </div>
        );
    }

    if (props.relatedQuestions) {
        return (
            <Page key={props.relatedQuestions}>
                <Navbar>
                    <NavLeft>
                        <Link popupClose>
                            <Icon icon="icon-back"></Icon> <span> 返回</span>
                        </Link>
                    </NavLeft>
                    <NavTitle>已答症状</NavTitle>
                </Navbar>
                <Block>
                    <div style={{
                        color: "#282828",
                        fontSize: "1.2em",
                    }}>
                        {props.relatedQuestions.map((relatedQuestion) => getListItem(relatedQuestion))}
                    </div>
                </Block>
            </Page >
        );
    } else {
        return null;
    }
}
