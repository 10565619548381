import React, { useState, useEffect, useRef, useCallback } from 'react';
import _ from 'lodash';
import parseLanguage from '../utils/parseLanguage.js';
import isAnswerValid from '../utils/isAnswerValid.js';
import DatePicker from 'react-mobile-datepicker';
import moment from 'moment';
import './StaticFormObjectArrayList.css';
import Upload from './FileUpload/upload/Upload.js';
import i18n from '../i18n';


import { Icon, Input, Button, Checkbox } from 'framework7-react';
import AutoComplete from './AutoComplete.js';
import InputWithSuffix from './InputWithSuffix.js';
StaticFormObjectArrayList.defaultProps = {
  schema: {
    //   order: [],
    properties: {},
    items: {},
    title_zh: 'Default表格'
  },
  onChange: function () {
    console.warn('onChange not defined');
  },
  model: [],
  canSubmit: true
};

//https://stackoverflow.com/questions/39821320/recursively-collect-values-for-property-using-lodash
_.mixin({
  toPairsDeep: obj =>
    _.flatMap(_.toPairs(obj), ([k, v]) =>
      _.isObjectLike(v) ? _.toPairsDeep(v) : [[k, v]]
    )
});

function getFlattenedValue(obj) {
  return _(obj)
    .toPairsDeep()
    .map(1)
    .value();
}

export default function StaticFormObjectArrayList(props) {
  const [model, setModel] = useState([
    {
      __key: Date.now()
    }
  ]);


  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const onModelChange = useCallback(
    model => {
      if (props.canSubmit) {
        model.forEach(modelItem => {
          let questionKeyList =
            props.schema.items.order ||
            Object.keys(props.schema.items.properties);
          const __ok = questionKeyList.every(questionKey => {
            const question = props.schema.items.properties[questionKey];
            if (!question) {
              return true;
            }
            if (!question.isRequired) {
              return true;
            }

            const shouldAsk = checkAskIf(question.askedif, modelItem);
            if (!shouldAsk) return true;
            return isAnswerValid(modelItem[questionKey]);

          });
          modelItem.__ok = __ok;
        });
        setModel(model);
        props.onChange(model);
      }
    },
    [setModel, props]
  );

  function checkAskIf(askedif = [], model) {
    let flattenAnswers = getFlattenedValue(model);
    if (askedif.length === 0) {
      return true;
    }
    return (
      askedif.filter(anserKey => flattenAnswers.includes(anserKey)).length > 0
    );
  }

  function renderSingleChoiceOtherEnum(arrayIndex, questionSchema, questionKey) {
    const placeholder = parseLanguage(questionSchema.enum["__OTHER__"]);
    const questionPath = `${arrayIndex}.${questionKey}`;
    return (
      <div
        style={{ marginTop: 5 }}
        key={`${questionPath}.__OTHER__`}
      >
        <Checkbox
          name={questionPath}
          value={"__OTHER__"}
          key={`${questionPath}.__OTHER__`}
          style={{ marginRight: 20, width: '100%', display: 'flex', marginTop: 5 }}
          readonly={!props.canSubmit}
          // must add !! before match, to convert null to false. checked={null} will cause bug.
          checked={
            !!(
              (props.canSubmit ? model : props.model)[arrayIndex][
              questionKey
              ] || ''
            ).match(/^__OTHER__/)
          }
          onChange={e => {

            if (!props.canSubmit) {
              return;
            }
            const { name, value } = e.target;
            let newModel = [...model];
            if (newModel[arrayIndex][questionKey] && newModel[arrayIndex][questionKey].includes('__OTHER__'))
              delete newModel[arrayIndex][questionKey]
            else
              newModel[arrayIndex][questionKey] = '__OTHER__';
            // setModel(newModel);
            onModelChange(newModel);
          }}
        >
          <div
            style={{
              fontSize: 16,
              paddingLeft: 20,
              width: '100%'
            }}
          >
            <Input
              type={questionSchema.otherEnumViewType === 'paragraph' ? 'textarea' : 'text'}
              // maxlength={50}
              placeholder={placeholder}
              style={{ background: 'white', padding: 5 }}
              readonly={!props.canSubmit}
              value={(() => {
                let _model = props.canSubmit ? model : props.model;
                return (_model[arrayIndex][questionKey] || '').match(
                  /^__OTHER__/
                )
                  ? (_model[arrayIndex][questionKey] || '').replace(
                    /^__OTHER__/,
                    ''
                  )
                  : '';
              })()}
              onChange={e => {
                const input = e.target.value;
                if (!props.canSubmit) {
                  return;
                }
                if (input && input.length > 50) {
                  window.$$f7.dialog.alert(i18n.t('maxlength_error_message'), false);
                  return;
                }
                let newModel = [...model];
                newModel[arrayIndex][questionKey] =
                  '__OTHER__' + input;
                // setModel(newModel);
                onModelChange(newModel);
              }}
              onFocus={() => {
                if (!props.canSubmit) {
                  return;
                }
                let newModel = [...model];
                if (newModel[arrayIndex][questionKey] && !newModel[arrayIndex][questionKey].match(/^__OTHER__/))
                  newModel[arrayIndex][questionKey] = '__OTHER__';
                // setModel(newModel);
                onModelChange(newModel);
              }}
            ></Input>
          </div>
        </Checkbox>
      </div>
    );
  }

  function renderSingleChoiceEnum(arrayIndex, questionSchema, questionKey, enumKey) {
    const questionPath = `${arrayIndex}.${questionKey}`;
    const enumPath = `${questionPath}.${enumKey}`;
    return (
      <div
        style={{ flex: 1, marginTop: 5 }}
        key={enumPath}
      >
        <Checkbox
          name={questionPath}
          value={enumKey}
          key={enumPath}
          style={{ marginRight: 20, width: '100%', display: 'flex', marginTop: 5 }}
          readonly={!props.canSubmit}
          checked={
            props.canSubmit
              ? model[arrayIndex][questionKey] === enumKey
              : props.model[arrayIndex][questionKey] === enumKey
          }
          onChange={e => {
            if (!props.canSubmit) {
              return;
            }
            const { name, value } = e.target;
            let newModel = [...model];
            if (newModel[arrayIndex][questionKey] === value)
              delete newModel[arrayIndex][questionKey]
            else
              newModel[arrayIndex][questionKey] = value;
            // setModel(newModel);
            onModelChange(newModel);
          }}
        >
          <div
            style={{
              fontSize: 16,
              paddingLeft: 20,
              paddingRight: 20,
              minWidth: 40
            }}
          >
            {parseLanguage(questionSchema.enum[enumKey])}
          </div>
        </Checkbox>
      </div>
    );
  }

  function renderSingleChoiceEnums(arrayIndex, questionSchema, questionKey) {

    const { order, enum: enums, viewType } = questionSchema;
    const enumOrder = order || Object.keys(enums);
    const flexDirection = enumOrder.includes('__OTHER__') ? "column" : questionSchema.flexDirection ? questionSchema.flexDirection : "row";

    const questionPath = `${arrayIndex}-${questionKey}`;
    if (viewType === "search") {
      return <AutoComplete id={questionPath} type="single" enums={enums} order={enumOrder} onChange={(value) => {
        let newModel = [...model];
        newModel[arrayIndex][questionKey] = value;
        onModelChange(newModel);
      }} />
    }

    return <div
      style={{
        display: 'flex',
        flexDirection,
        marginTop: 5
      }}
    >
      {enumOrder.map(enumKey => {
        if (enumKey === '__OTHER__') {
          return renderSingleChoiceOtherEnum(arrayIndex, questionSchema, questionKey);
        } else {
          return renderSingleChoiceEnum(arrayIndex, questionSchema, questionKey, enumKey);
        }
      })}
    </div>
  }

  function renderSingleChoice(
    questionSchema,
    modelItem,
    questionKey,
    arrayIndex
  ) {
    const { title_zh: title, isRequired } = questionSchema;

    const answer = _.get(modelItem, questionKey);


    const questionPath = `${arrayIndex}.${questionKey}`;

    return (
      <div key={questionPath} style={{ margin: 5, padding: 5 }}>
        {title ? (
          <div className="static-form-object-array-section-title">
            {title}
            {(answer == null || answer.replace('__OTHER__', '').length == 0) && isRequired ? (
              <span className="is-required-asterisk">(*)</span>
            ) : null}
          </div>
        ) : null}

        {renderSingleChoiceEnums(arrayIndex, questionSchema, questionKey)}
      </div>
    );
  }

  function renderMultipleChoice(
    questionSchema,
    modelItem,
    questionKey,
    arrayIndex
  ) {
    const { title_zh, isRequired, items: { enum: enums, order, otherEnumViewType } } = questionSchema;
    const enumOrder = order || Object.keys(enums);
    const flexDirection = enumOrder.includes('__OTHER__') ? "column" : questionSchema.flexDirection ? questionSchema.flexDirection : "row";
    const answer = _.get(modelItem, questionKey);

    return (
      <div key={questionKey} style={{ margin: 5, padding: 5 }}>
        {title_zh ? (
          <div className="static-form-object-array-section-title">
            {title_zh}
            {(answer == null || answer.map(answerItem => answerItem.replace('__OTHER__', '')).length == 0) && isRequired ? (
              <span className="is-required-asterisk">(*)</span>
            ) : null}
          </div>
        ) : null}
        <div
          style={{
            display: 'flex',
            flexDirection,
            marginTop: 5
          }}
        >
          {enumOrder.map(answerKey => {
            if (answerKey === '__OTHER__') {
              const placeholder = parseLanguage(enums[answerKey]) || "请输入";
              return (
                <div
                  style={{ marginTop: 5 }}
                  key={`${questionKey}.${answerKey}`}
                >
                  <Checkbox
                    name={questionKey + arrayIndex}
                    value={answerKey}
                    key={`${questionKey}.${answerKey}`}
                    style={{ marginRight: 20, width: '100%', display: 'flex', marginTop: 5 }}
                    readonly={!props.canSubmit}
                    className='square-border-checkbox'
                    // must add !! before match, to convert null to false. checked={null} will cause bug.
                    checked={
                      !!(
                        (props.canSubmit ? model : props.model)[arrayIndex][
                        questionKey
                        ] || []
                      ).some(answerItem => answerItem.match(/^__OTHER__/))
                    }
                    onChange={e => {

                      if (!props.canSubmit) {
                        return;
                      }
                      // const { name, value } = e.target;
                      const newModel = [...model];
                      const answer = newModel[arrayIndex][questionKey] || [];
                      const currOtherIndex = answer.findIndex(answerItem => answerItem.includes('__OTHER__'));
                      if (currOtherIndex >= 0) {
                        answer.splice(currOtherIndex, 1);
                      }
                      else {
                        answer.push('__OTHER__');
                      }
                      newModel[arrayIndex][questionKey] = answer;
                      onModelChange(newModel);
                    }}
                  >
                    <div
                      style={{
                        fontSize: 16,
                        paddingLeft: 20,
                        width: '100%'
                      }}
                    >
                      <Input
                        type={otherEnumViewType === 'paragraph' ? 'textarea' : 'text'}
                        // maxlength={50}
                        placeholder={placeholder}
                        style={{ background: 'white', padding: 5 }}
                        readonly={!props.canSubmit}
                        value={(() => {
                          let _model = props.canSubmit ? model : props.model;
                          const answer = _model[arrayIndex][questionKey] || [];
                          const currOtherIndex = answer.findIndex(answerItem => answerItem.match(/^__OTHER__/));
                          return currOtherIndex >= 0
                            ? (answer[currOtherIndex]).replace(
                              /^__OTHER__/,
                              ''
                            )
                            : '';
                        })()}
                        onChange={e => {
                          if (!props.canSubmit) {
                            return;
                          }
                          const input = e.target.value;
                          if (input && input.length > 50) {
                            window.$$f7.dialog.alert(i18n.t('maxlength_error_message'), false);
                            return;
                          }

                          const newModel = [...model];
                          const answer = newModel[arrayIndex][questionKey] || [];
                          const currOtherIndex = answer.findIndex(answerItem => answerItem.includes('__OTHER__'));
                          if (currOtherIndex >= 0) {
                            answer.splice(currOtherIndex, 1);
                          }
                          answer.push('__OTHER__' + input);
                          newModel[arrayIndex][questionKey] = answer;
                          // setModel(newModel);
                          onModelChange(newModel);
                        }}
                        onFocus={() => {
                          if (!props.canSubmit) {
                            return;
                          }
                          const newModel = [...model];
                          const answer = newModel[arrayIndex][questionKey] || [];
                          const currOtherIndex = answer.findIndex(answerItem => answerItem.includes('__OTHER__'));
                          if (currOtherIndex < 0)
                            answer.push('__OTHER__');
                          newModel[arrayIndex][questionKey] = answer;
                          // setModel(newModel);
                          onModelChange(newModel);
                        }}
                      ></Input>
                    </div>
                  </Checkbox>
                </div>
              );
            } else {
              return (
                <div
                  style={{ flex: 1, marginTop: 5 }}
                  key={`${questionKey}.${answerKey}`}
                >
                  <Checkbox
                    name={questionKey + arrayIndex}
                    value={answerKey}
                    key={`${questionKey}.${answerKey}`}
                    style={{ marginRight: 20, width: '100%', display: 'flex', marginTop: 5 }}
                    readonly={!props.canSubmit}
                    className='square-border-checkbox'
                    checked={
                      props.canSubmit
                        ? model[arrayIndex][questionKey] && model[arrayIndex][questionKey].includes(answerKey)
                        : props.model[arrayIndex][questionKey] && props.model[arrayIndex][questionKey].includes(answerKey)
                    }
                    onChange={e => {
                      if (!props.canSubmit) {
                        return;
                      }
                      const { name, value } = e.target;
                      const newModel = [...model];
                      const answer = newModel[arrayIndex][questionKey] || [];
                      const currAnswerKeyIndex = answer.findIndex(answerItem => answerItem === value);
                      if (currAnswerKeyIndex >= 0)
                        answer.splice(currAnswerKeyIndex, 1);
                      else
                        answer.push(value);
                      newModel[arrayIndex][questionKey] = answer;
                      // setModel(newModel);
                      onModelChange(newModel);
                    }}
                  >
                    <div
                      style={{
                        fontSize: 16,
                        paddingLeft: 20,
                        paddingRight: 20,
                        minWidth: 40
                      }}
                    >
                      {parseLanguage(enums[answerKey])}
                    </div>
                  </Checkbox>
                </div>
              );
            }
          })}
        </div>
      </div>
    );
  }

  function renderString(questionSchema, modelItem, questionKey, arrayIndex) {
    const { title_zh: title, isRequired, type, suffix } = questionSchema;
    const inputType = type === "number" ? "number" : "textarea";
    const placeholder = questionSchema.placeholder || type === "number" ? "请输入" : "请输入（本部分限输入50字以内）";
    const answer = _.get(modelItem, questionKey);
    const numberStyle = { background: 'white', padding: "2px 8px 2px 8px" };
    const textareaStyle = { background: 'white', width: "100%" };
    const style = type === "number" ? numberStyle : textareaStyle;
    return (
      <div key={questionKey} style={{ margin: 5, padding: 5 }}>
        {title ? (
          <div className="static-form-object-array-section-title">
            {title}
            {(answer == null || answer.length == 0) && isRequired ? (
              <span className="is-required-asterisk">(*)</span>
            ) : null}
          </div>
        ) : null}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 5
          }}
        >
          <InputWithSuffix
            resizable
            questionKey={questionKey}
            type={inputType}
            suffixList={suffix}
            placeholder={placeholder}
            inputmode={type === 'number' ? 'decimal' : 'text'}
            pattern={type === 'number' ? '\d*' : ''}
            style={style}
            disabled={!props.canSubmit}
            model={props.model[arrayIndex] && props.model[arrayIndex][questionKey]}
            onChange={input => {
              let newModel = [...model];
              newModel[arrayIndex][questionKey] = input;
              onModelChange(newModel);
            }}
          ></InputWithSuffix>
        </div>
      </div>
    );
  }

  function renderDatePicker(
    questionSchema,
    modelItem,
    questionKey,
    arrayIndex
  ) {
    const title = questionSchema.title_zh;
    return (
      <div key={questionKey} style={{ margin: 5, padding: 5 }}>
        {title ? (
          <div className="static-form-object-array-section-title">
            {title}
            {_.get(modelItem, questionKey) && questionSchema.isRequired ? (
              <span className="is-required-asterisk">(*)</span>
            ) : null}
          </div>
        ) : null}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 5
          }}
        >
          <Input
            type="datepicker"
            // key={questionId}
            placeholder="点击选择日期"
            readonly={!props.canSubmit}
            inputStyle={{ backgroundColor: "#fff", padding: 5 }}
            calendarParams={{
              openIn: 'customModal',
              header: true,
              footer: true,
              toolbarCloseText: '确定',
              headerPlaceholder: '请选择日期',
              // value:
              //   defaultValue || questionMessageObject.defaultDate
              //     ? [defaultValue || questionMessageObject.defaultDate]
              //     : undefined,
              // minDate: questionMessageObject.minDate,
              // maxDate:
              //   questionMessageObject.maxDate === 'TODAY'
              //     ? new Date()
              //     : questionMessageObject.maxDate,
              locale: 'en-US', // to remove '日',
              monthNames: [
                '一月',
                '二月',
                '三月',
                '四月',
                '五月',
                '六月',
                '七月',
                '八月',
                '九月',
                '十月',
                '十一月',
                '十二月'
              ],
              monthNamesShort: [
                '一',
                '二',
                '三',
                '四',
                '五',
                '六',
                '七',
                '八',
                '九',
                '十',
                '十一',
                '十二'
              ],
              dayNames: [
                '周日',
                '周一',
                '周二',
                '周三',
                '周四',
                '周五',
                '周六'
              ],
              dayNamesShort: ['日', '一', '二', '三', '四', '五', '六'],
              dateFormat: 'yyyy-mm-dd',
              on: {
                change: (ccalendar, value) => {
                  if (!props.canSubmit) {
                    return;
                  }
                  // setInputString(new Date(value[0]).getTime());
                  let newModel = [...model];
                  newModel[arrayIndex][questionKey] = new Date(
                    value[0]
                  ).getTime();
                  // setModel(newModel);
                  onModelChange(newModel);
                }
              }
            }}
            disabled={!props.canSubmit}
            value={
              props.canSubmit
                ? undefined
                : [new Date(props.model[arrayIndex][questionKey])]
            }
            // placeholder={questionMessageObject.title_zh}
            className="static-form-object-array-list-date-input"
          ></Input>
        </div>
      </div>
    );
  }

  function renderItem(modelItem, index) {
    let order =
      props.schema.items.order || Object.keys(props.schema.items.properties);
    return (
      <div
        key={modelItem.__key}
        style={{
          background: '#f7f7f7',
          padding: 10,
          marginBottom: 20,
          position: 'relative'
        }}
      >
        {props.canSubmit ? (
          <div
            style={{ position: 'absolute', top: -15, right: -15 }}
            onClick={() => {
              model.splice(index, 1);
              console.log(index, model);
              // setModel([...model]);
              onModelChange([...model]);
            }}
          >
            <Icon
              f7="multiply_circle_fill"
              size={30}
              style={{ color: '#e20000' }}
            ></Icon>
          </div>
        ) : null}

        {order.map(questionKey => {
          let question = props.schema.items.properties[questionKey];
          if (!question) {
            return null;
          }

          let shouldAsk = checkAskIf(question.askedif, modelItem);
          if (!shouldAsk) {
            if (modelItem[questionKey] !== undefined) {
              model[index][questionKey] = undefined;
              // setModel([...model]);
              onModelChange([...model]);
            }
            return null;
          }
          if (question.type === 'string') {
            // if the type is string
            if (question.enum) {
              // if there's enum, it's single choice question
              return renderSingleChoice(
                question,
                modelItem,
                questionKey,
                index
              );
            } else {
              return renderString(question, modelItem, questionKey, index);
            }
          }
          if (question.type === 'object') {
            if (question.viewType === 'dateRange') {
            }
          }
          if (question.type === 'number') {
            if (question.viewType === 'date') {
              return renderDatePicker(question, modelItem, questionKey, index);
            } else {
              return renderString(question, modelItem, questionKey, index);
            }
          }
          if (question.type === 'array') {
            if (question.viewType === 'uploadImage')
              return renderImageUpload(question, modelItem, questionKey, index);
            if (question.items && question.items.enum)
              return renderMultipleChoice(
                question,
                modelItem,
                questionKey,
                index
              );
          }

        })}
      </div>
    );
  }

  function renderImageUpload(questionSchema, modelItem, questionKey, arrayIndex) {
    const title = questionSchema.title_zh;
    return (
      <div key={questionKey} style={{ margin: 5, padding: 5 }}>
        {title ? (
          <div className="static-form-object-array-section-title">
            {title}
            {questionSchema.isRequired ? (
              <span className="is-required-asterisk">(*)</span>
            ) : null}
          </div>
        ) : null}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 5
          }}
        >
          <Upload
            onImageUrlsChange={(imageUrls) => {
              let newModel = [...model];
              newModel[arrayIndex][questionKey] = imageUrls;
              // setModel(newModel);
              onModelChange(newModel);
            }}
            disabled={!props.canSubmit}
            imageUrls={modelItem[questionKey]}
          />
        </div>
      </div>
    );
  }
  function renderAddItemButton() {
    if (props.canSubmit) {
      return (
        <Button
          onClick={() => {
            model.push({ __key: Date.now() });
            // setModel([...model]);
            onModelChange([...model]);
          }}
        >
          添加更多
        </Button>
      );
    } else {
      return null;
    }
  }

  return (
    <div>
      <div>
        {(props.canSubmit ? model : props.model).map((modelItem, index) => {
          return renderItem(modelItem, index);
        })}
      </div>
      <div>{renderAddItemButton()}</div>
    </div>
  );

}
