import React, { useState, useCallback, useEffect } from 'react';
import './report.css';

import getParameterByName from '../../utils/getParameterByName';
var Chinese = require('chinese-s2t');

const ReportTitle = function(props) {
  const report = props.report;
  if (!report) {
    return null;
  }

  let title = report.name;

  if (getParameterByName('lang') === 'tc') {
    title = Chinese.s2t(title);
  }
  return (
    <div className="report-title-container">
      <div className="report-title-text">{title}</div>
    </div>
  );
};
ReportTitle.defaultProps = {
  report: null
};
export default ReportTitle;
