function createDynamicManifest () {
  // 创建一个动态生成的manifest.json字符串
  const dynamicManifest = JSON.stringify({
    "short_name": "舒辅智慧",
    "name": "舒辅智慧",
    "icons": [
      {
        "src": window.location.origin + "/logo/logo.png",
        "sizes": "64x64 32x32 24x24 16x16"
      },
      {
        "src": window.location.origin + "/logo/logo192.png",
        "type": "image/png",
        "sizes": "192x192"
      },
      {
        "src": window.location.origin + "/logo/logo512.png",
        "type": "image/png",
        "sizes": "512x512"
      }
    ],
    "start_url": window.location.pathname + window.location.search, // 当前浏览器的路由和参数
    "display": "standalone",
    "background_color": "#ffffff",
    "theme_color": "#000000",
  });

  // 创建一个<link>元素来添加动态生成的manifest.json到头部
  const manifestLink = document.createElement("link");
  manifestLink.rel = "manifest";
  manifestLink.href = "data:application/json," + encodeURIComponent(dynamicManifest);


  document.head.appendChild(manifestLink);
}
export default createDynamicManifest;