import {
    Button,
    List,
    ListInput,
    Popup
} from 'framework7-react';

import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import CountDownButton from './CountDownButton.js';
import ChooseUserListPopup from './ChooseUserListPopup.js';

import {
    sendVerificationCodeToCellphone,
    verifyCodeReturnUsersOrRegister,
    loginWithUserIdAndShufuToken,
    registerWithCellphoneAndShufuToken,
    deactivateUser
} from '../modules/shufu';

import { initUser } from '../pages/welcome/welcome';
import { set } from 'lodash';

CellphoneLogin.defaultProps = {
    needName: false,
    organizationId: null,
    onLogin: () => { console.log("no onLogin function passed in") },
    onCheckCellPhone: () => { return {checkPass: true, msg: ''}}
};

/**
 * React component for logging in with cellphone and verification code.
 * @param {*} props
 * @param {function} props.onLogin (required) callback function for successful login with one parameter: userId
 * @param {Boolean} props.needName (optional) if true, will collect name when logging in
 * @param {String} organizationId (optional) organizationId of user
 */
export default function CellphoneLogin(props) {
    const { needName, organizationId, onLogin, onCheckCellPhone} = props;

    const { t, i18n } = useTranslation();

    const [cellphone, setCellphone] = useState('');
    const [code, setCode] = useState('');
    const [name, setName] = useState(undefined);
    const [chooseUserListPopupOpen, setChooseUserListPopupOpen] = useState(false);

    const [tempShufuToken, setTempShufuToken] = useState(null);
    const [userInfoList, setUserInfoList] = useState([]);

    const sendCellphoneCode = useCallback(async () => {
        window.$$f7.preloader.show();
        try {
            let result = await sendVerificationCodeToCellphone(cellphone);
            if (result.error) {
                window.$$f7.dialog.alert(result.error);
            }
        } catch (e) {
            console.error(e);
            window.$$f7.dialog.alert(t('server_busy'));
        }
        window.$$f7.preloader.hide();
    }, [cellphone, t]);

    const loginWithCellphoneCode = useCallback(
        async (_cellphone, _code) => {
            if (_cellphone && _code) {
                // do nothing
            } else {
                if (!cellphone || !code) {
                    alert('请输入手机和验证码');
                    return;
                }

                if (needName && !name) {
                    alert('请输入姓名');
                    return;
                }
            }

            window.$$f7.preloader.show();

            try {
                let result = await verifyCodeReturnUsersOrRegister(
                    _cellphone || cellphone,
                    _code || code,
                    name
                );
                if (result.status === 'USERS_EXIST') {
                    window.$$f7.preloader.hide();
                    setChooseUserListPopupOpen(true);
                    setTempShufuToken(result.shufuToken);
                    setUserInfoList(result.data.userInfo);
                } else if (result.status === 'NO_USER_EXISTS') {
                    const userId = result.data.userId || result.data.userid; // there's maybe a typo from backend
                    const token = result.data.token;
                    await initUser(userId, organizationId, token);
                    onLogin(userId);
                }
            } catch (err) {
                window.$$f7.preloader.hide();
                console.error(err);
                window.$$f7.dialog.alert(t('incorrect_code'));
            }
        },
        [cellphone, code, name, needName, onLogin, organizationId, t]
    );

    const onSelectUser = async _userId => {
        window.$$f7.preloader.show();
        try {

            const result = await loginWithUserIdAndShufuToken(_userId, tempShufuToken);
            const { token, userid: userId } = result;
            setChooseUserListPopupOpen(false);
            await initUser(userId, organizationId, token);
            onLogin(userId);

        } catch (err) {
            window.$$f7.preloader.hide();
            window.$$f7.dialog.alert(err.message);
        }
    };

    const onDeleteUser = async _userId => {
        window.$$f7.preloader.show();
        try {
            await deactivateUser(_userId);
            window.$$f7.preloader.hide();
        } catch (err) {
            window.$$f7.preloader.hide();
            window.$$f7.dialog.alert(err.message);
        }
    };

    const onCreateNewUser = async () => {
        window.$$f7.preloader.show();
        try {
            let result = await registerWithCellphoneAndShufuToken(
                cellphone,
                tempShufuToken
            );
            let { token, userid: userId } = result;
            setChooseUserListPopupOpen(false);
            await initUser(userId, organizationId, token);
            console.log('INIT COMPLETE');
            onLogin(userId)
        } catch (err) {
            console.error(err);
            window.$$f7.preloader.hide();
            window.$$f7.dialog.alert(t('server_busy'));
        }
    };

    // 当输入框有焦点时触发函数,给登录按钮增加scrollIntoView
    const onFocus =() => {
        let loginButton = document.querySelector('#loginButton');
        document.hasFocus() && setTimeout(() => {
            loginButton.scrollIntoView(false)
        }, 300)
    }

    return (
        <div>
            <List form>
                <ListInput
                    label="手机号"
                    type="text"
                    placeholder="请输入您的手机号"
                    value={cellphone}
                    onInput={e => {
                        setCellphone(e.target.value);
                    }}
                    onFocus={onFocus}
                >
                    <CountDownButton
                        outline
                        slot="inner-end"
                        disabled={!!!cellphone}
                        style={{
                            width: 100,
                            marginRight: 10,
                            position: 'absolute',
                            right: 10,
                            bottom: 10
                        }}
                        onClick={async () => {
                            if (!cellphone) {
                                window.$$f7.dialog.alert('请输入手机号');
                                throw new Error('empty cellphone');
                            }
                            const res = await onCheckCellPhone(cellphone);
                            const {checkPass, msg} = res;
                            if (checkPass) {
                                sendCellphoneCode();
                            } else {
                                window.$$f7.dialog.alert(msg);
                            }
                            
                        }}
                    >
                        获取验证码
                    </CountDownButton>
                </ListInput>

                <ListInput
                    id='verifyCode'
                    label="验证码"
                    type="text"
                    placeholder="请输入验证码"
                    value={code}
                    maxlength={4}
                    onInput={e => {
                        setCode(e.target.value);
                    }}
                    onFocus={onFocus}
                />

                {needName ? (
                    <ListInput
                        label="姓名"
                        type="text"
                        placeholder="请输入姓名"
                        value={name}
                        onInput={e => {
                            setName(e.target.value);
                        }}
                        onFocus={onFocus}
                    />
                ) : null}
            </List>

            <Button
                id='loginButton'
                raised
                fill
                large
                style={cellphone && code ? {} : { background: '#bfbfbf' }}
                className='welcome-login-button'
                onClick={() => {
                    loginWithCellphoneCode();
                }}
            >
                登录
            </Button>

            <Popup
                opened={chooseUserListPopupOpen}
                onPopupClosed={() => {
                    setChooseUserListPopupOpen(false);
                }}
                id='chooseUserListPopup'
            >
                <ChooseUserListPopup
                    userList={userInfoList}
                    onSelectUser={onSelectUser}
                    onDeleteUser={onDeleteUser}
                    onCreateNewUser={onCreateNewUser}
                ></ChooseUserListPopup>
            </Popup>
        </div>
    );
}
