import { Popup } from 'framework7-react';
import React, { useCallback, useState } from 'react';
import { answerBoardEmitter } from '../modules/answerBoardEmitter.js';
import './AnswerBoard.css';
import './AnswerBoardSelectBodyPartForm.css';
import './PopupStyle.css';
import SelectBodyPartFormPopup from './SelectBodyPartFormPopup.js';
import SelectBodyJointFormPopup from './SelectBodyJointFormPopup.js';


AnswerBoardSelectBodyPartForm.defaultProps = {
  content: {
    schema: {}
  }
};
export default function AnswerBoardSelectBodyPartForm(props) {
  const [popupOpen, setPopupOpen] = useState(false);

  const { content: { schema } } = props;

  const submitHandler = useCallback((model) => {
    console.log('model:', model)
    answerBoardEmitter.emit('submit', model);
  }, []);

  return (
    <div className="answer-board-action-button-wrapper">
      <div style={{ flex: 1, height: '100%', display: 'flex' }}>
        <div className="inline-form-submit-button" style={{ flex: 2, backgroundColor: '#fff', color: '#2979ff' }}
          onClick={() => {
            setPopupOpen(true);
          }}
        >
          {schema.buttonText_zh || '请点击此处填写'}
        </div>
      </div>

      <Popup
        className="static-form-popup popup-pc-style"
        opened={popupOpen}
        onPopupClosed={() => {
          setPopupOpen(false);
        }}
      >
        {
          props.bodyType === 'joint' ?
          <SelectBodyJointFormPopup
            schema={schema}
            model={{}}
            submitHandler={(model) => { submitHandler(model); setPopupOpen(false); }}
            canSubmit={true}
          />:
          <SelectBodyPartFormPopup
            schema={schema}
            model={{}}
            submitHandler={(model) => { submitHandler(model); setPopupOpen(false); }}
            canSubmit={true}
          />
        }
      </Popup>
    </div>
  );
}
