import React, { useState, useEffect, useCallback } from 'react';
import { answerBoardEmitter } from '../modules/answerBoardEmitter.js';
import './AnswerBoard.css';
import SymptomDetailPopup from './SymptomDetailPopup.js';
import SymptomSearchPopup from './SymptomSearchPopup.js';
import { getAllSymptoms } from '../modules/shufu.js';
// import { getAllComplaintsByTag } from '../modules/shufu.js';
import _ from 'lodash';

import { Popup, Preloader, Input, Button } from 'framework7-react';
import checkmark from '../images/checkmark.png';
import i18n from '../i18n';


AnswerBoardSymptomSearch.defaultProps = {
  content: {}
};
export default function AnswerBoardSymptomSearch(props) {
  // console.log("AnswerBoardSymptomSearch props: ", props);
  // console.log('props.content', props.content);

  // return <div>[AnswerBoardSymptomSearch]</div>;
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [defaultSymptomList, setDefaultSymptomList] = useState([]);
  const [fullSymptomListAndCategory, setFullSymptomListAndCategory] = useState(
    {}
  );
  const [popupOpen, setPopupOpen] = useState(false);
  const [fullSymptomPopupOpen, setFullSymptomPopupOpen] = useState(false);
  const [symptomDetailPopupOpen, setSymptomDetailPopupOpen] = useState(false);
  const [selectedSymptomMap, setSelectedSymptomMap] = useState({});
  const [selectedSymptom, setSelectedSymptom] = useState(null);
  const [isCustomSymptomInputVisible, setIsCustomSymptomInputVisible] = useState(false);
  const [customSymptomName, setCustomSymptomName] = useState('');

  const { content: { symptomVersion, defaultSymptomsTags, allowAvSchemaPopup, removeSearchPage, sort, limit, allowCustomSymptomInDefault, allowNoneOfTheAboveInDefault } } = props


  const submitHandler = useCallback(() => {
    setSubmitting(true);
    // setPopupOpen(false);

    if (customSymptomName) {
      const customSymptom = {
        id: `#${customSymptomName}_${symptomVersion}`,
        displayName_en: customSymptomName,
        displayName_zh: customSymptomName,
        doctorDisplayName_zh: customSymptomName,
        symptomVersion,
        categories: [],
        ddxCategories: []
      };
      answerBoardEmitter.emit('submit', [customSymptom]);
      return;
    }


    let symptoms = Object.keys(selectedSymptomMap).map(
      sk => {
        if (sk.startsWith('#') && sk !== "#noneOfTheAbove") //custom symptom
          return null;
        return selectedSymptomMap[sk]
      }
    ).filter(symptom => symptom);

    answerBoardEmitter.emit('submit', symptoms);
  }, [customSymptomName, selectedSymptomMap, symptomVersion]);

  // try to get setmptoms
  useEffect(() => {
    setLoading(true);
    async function init() {
      try {
        // let symptomList = await getAllComplaintsByTag(props.category);
        // todo get default recommaned symptoms

        // let result = require('../modules/mock/psychiatry_symptoms.json');

        let result = await getAllSymptoms(symptomVersion);

        // let defaultSymptomList =
        //   _.keyBy(symptomList, 'tagName')['全身'] || symptomList[0];


        let defaultSymptomList = result.symptoms.slice(0, 10);

        if (defaultSymptomsTags) defaultSymptomList = result.symptoms.filter((symptom) => symptom.tags.some(tag => defaultSymptomsTags.includes(tag)));
        if (sort && sort === 'createdTime') defaultSymptomList.sort((a, b) => a.createdTime - b.createdTime);
        if (allowCustomSymptomInDefault) defaultSymptomList.push({ id: "CUSTOM_SYMPTOM" });
        if (allowNoneOfTheAboveInDefault) defaultSymptomList.push(
          {
            id: "#noneOfTheAbove",
            displayName_zh: "没有以上症状",
            doctorDisplayName_zh: "没有以上症状",
            displayName_en: "none of the above",
            symptomVersion,
            categories: [],
            ddxCategories: []
          }
        );
        // console.log("defaultSymptoms: ", defaultSymptomList);

        setLoading(false);
        setDefaultSymptomList(defaultSymptomList);
        setFullSymptomListAndCategory(result);
        setTimeout(() => {
          var messageListWrapper = document.getElementById(
            'message_list_wrapper'
          );
          if (messageListWrapper) {
            // console.log("scroll to top AnswerBoardSymptomSearch ", messageListWrapper.scrollTop);
            messageListWrapper.scrollTop = messageListWrapper.scrollHeight;
          }
        }, 500);

      } catch (e) {
        // alert error
      }
    }

    init();
  }, [allowNoneOfTheAboveInDefault, allowCustomSymptomInDefault, defaultSymptomsTags, sort, symptomVersion]);


  const onSymptomClick = (symptom) => {
    if (selectedSymptomMap[symptom.id]) {
      console.log("deleting selected symptom: ", symptom.id);
      delete selectedSymptomMap[symptom.id];
      setSelectedSymptomMap({ ...selectedSymptomMap });
    } else {
      if (limit && Object.keys(selectedSymptomMap).length === limit) {
        window.$$f7.dialog.alert(`请最多选择${limit}个症状`, false);
      } else {
        setSelectedSymptom(symptom);
        if (allowAvSchemaPopup && symptom.attributeValueSchema && Object.keys(symptom.attributeValueSchema).length > 0)
          setSymptomDetailPopupOpen(true);
        else
          onAddSymptom(symptom);
      }
    }
  }

  const onAddSymptom = (symptom) => {
    console.log("Adding symptom: ", symptom);
    if (symptom.id === '#noneOfTheAbove') {
      setSelectedSymptomMap({ [symptom.id]: symptom });
    } else {
      selectedSymptomMap[symptom.id] = symptom;
      if (selectedSymptomMap['#noneOfTheAbove']) delete selectedSymptomMap['#noneOfTheAbove'];
      setSelectedSymptomMap({ ...selectedSymptomMap });
    }
    setSymptomDetailPopupOpen(false);
  }

  const renderCustomSymptomInput = () => {
    return (
      <div style={{ margin: 15, fontSize: 17 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
          <div >
            请填写来就诊的主要症状。
          </div>
          <Button style={{ marginTop: -5, fontSize: 20 }} onClick={() => { setCustomSymptomName(''); setIsCustomSymptomInputVisible(false); }}>X</Button>
        </div>
        <Input
          // maxlength={50}
          resizable
          type="textarea"
          placeholder="请输入（本部分限输入50字以内）"
          value={customSymptomName}
          style={{ background: '#f8f8f8', padding: 10 }}
          onChange={e => {
            const input = e.target.value;
            if (input && input.length > 50) {
              window.$$f7.dialog.alert(i18n.t('maxlength_error_message'), false);
              return;
            }
            setCustomSymptomName(input);
          }}
        ></Input>
      </div >)
  }

  const renderDefaultSymptomList = () => {
    return (
      <div className="answer-board-answer-button-wrapper-left" >
        {
          defaultSymptomList.map(symptom => {
            if (symptom.id === "CUSTOM_SYMPTOM") {
              if (Object.keys(selectedSymptomMap).length !== 0)
                return null;
              else {
                return (<div style={{ position: 'relative', marginTop: 5 }}>
                  <button
                    style={{ border: '2px dashed #2979ff', backgroundColor: '#F9F9F9' }}
                    className={'shufu-answer-button'}
                    key={`${props.category}.CUSTOM_SYMPTOM`}
                    onClick={() => setIsCustomSymptomInputVisible(true)}
                  >
                    以上症状均不符合
                        </button>
                </div>);
              }
            }
            if (symptom.id === '#noneOfTheAbove') {
              return (<div style={{ position: 'relative', marginTop: 5 }}>
                <button
                  style={{ border: '2px dashed #2979ff', backgroundColor: '#F9F9F9' }}
                  className={[
                    'shufu-answer-button',
                    selectedSymptomMap[symptom.id]
                      ? 'shufu-answer-button-selected'
                      : ''
                  ].join(' ')}
                  key={`${props.category}.NONE_OF_THE_ABOVE`}
                  onClick={() => onSymptomClick(symptom)}
                >
                  {symptom.displayName_zh}
                </button>
              </div>);
            }
            return (
              <div style={{ position: 'relative', marginTop: 5 }}>
                {
                  selectedSymptomMap[symptom.id] ? (<div className='multiple-choice-checkmark' style={{ top: -5 }}>
                    <img src={checkmark} style={{ width: '80%', height: '100%' }}></img>
                  </div>) : null
                }
                <button
                  // className="shufu-answer-button"
                  className={[
                    'shufu-answer-button',
                    selectedSymptomMap[symptom.id]
                      ? 'shufu-answer-button-selected'
                      : ''
                  ].join(' ')}
                  key={`${props.category}.${symptom.id}`}
                  onClick={() => onSymptomClick(symptom)}
                >
                  {symptom.displayName_zh}
                </button>
                {symptom.description_zh ? <span
                  className="shufu-answer-button-information-icon"
                  onClick={() => window.$$f7.dialog.alert(symptom.description_zh, false, { cssClass: 'dialog-text-left' })
                  }>?</span> : null}
              </div>
            );
          })
        }
      </div >)
  }

  // render part
  if (loading) {
    return (
      < div
        style={{
          width: '100%',
          display: 'flex',
          margin: 20,
          justifyContent: 'center'
        }
        }
      >
        <Preloader size={30} />
      </div >
    )
  } else {
    const confirmSuffix = Object.keys(selectedSymptomMap).length > 0 ? `（${Object.keys(selectedSymptomMap).length}）` : '';
    return (
      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <div>
          <Popup
            className="demo-popup"
            opened={fullSymptomPopupOpen}
            onPopupClosed={() => {
              setFullSymptomPopupOpen(false);
            }}
          >
            <SymptomSearchPopup
              fullSymptomList={_.uniqBy(
                fullSymptomListAndCategory.symptoms,
                s => s.id
              )}
              categoryList={fullSymptomListAndCategory.order}
              selectedSymptomMap={selectedSymptomMap}
              onSelectHandler={symptom => {
                onSymptomClick(symptom);
              }}
              onSubmitHandler={() => {
                //TODO submit;
                if (Object.keys(selectedSymptomMap).length) {
                  window.$$f7.dialog.confirm('请检查您的选择，确认提交后将不得修改。是否确认提交？', '提示', () => {
                    submitHandler();
                    setFullSymptomPopupOpen(false);
                  })
                } else {
                  window.$$f7.dialog.alert('请选择至少一项', false);
                }
              }}
            ></SymptomSearchPopup>
          </Popup>
          <Popup
            className="demo-popup"
            opened={symptomDetailPopupOpen}
            onPopupClosed={() => {
              setSymptomDetailPopupOpen(false);
            }}
          >
            <SymptomDetailPopup
              symptom={selectedSymptom}
              submitHandler={onAddSymptom}
            ></SymptomDetailPopup>
          </Popup>
        </div>
        {submitting ? (
          <Preloader size={48} color="#2979ff"></Preloader>
        ) : (
          <div
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            { !removeSearchPage
              ? <div className="symptom-search-show-more-container">
                <span
                  className="symptom-search-show-more"
                  onClick={() => {
                    setFullSymptomPopupOpen(true);
                  }}
                >
                  查看更多症状 &gt;
              </span>
              </div> : null}
            {isCustomSymptomInputVisible ? renderCustomSymptomInput() : renderDefaultSymptomList()}

            <div className="answer-board-action-button-wrapper">
              {Object.keys(selectedSymptomMap).length || customSymptomName
                ? <div
                  className="inline-form-submit-button"
                  onClick={() => {
                    if (Object.keys(selectedSymptomMap).length || customSymptomName) {
                      window.$$f7.dialog.confirm('请检查您的选择，确认提交后将不得修改。是否确认提交？', '提示', () => {
                        submitHandler();
                      })
                    } else {
                      window.$$f7.dialog.alert('请选择至少一项', false);
                    }
                  }}
                >
                  提交{confirmSuffix}
                </div>
                : <div
                  className="inline-form-submit-button inline-form-submit-button-disabled"
                >
                  提交
                </div>
              }
            </div>
          </div>
        )}
      </div>
    );
  }

  // todo should get suggest diseases;
}
