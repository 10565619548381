import { Message, Popup } from 'framework7-react';
import React, { useState } from 'react';
import robot from '../images/robot.png';
import robot_normal from '../images/robot_normal.png';
import robot_smile from '../images/robot_smile.png';
import './MessageBlock.css';
import RelatedQuestionsPopup from './RelatedQuestionsPopup';
import TextToSpeechMessage from './TextToSpeechMessage';
import parseLanguage from '../utils/parseLanguage';
import handleTraditionalChinese from '../utils/handleTraditionalChinese';
import FullScreenImagesPopUp from './FullScreenImagesPopUp';

QuestionMessageBubble.defaultProps = {
    messageId: null,
    message:
    {
        _id: null,
        relatedQuestionsList: null,
        type: null,
        badge: null,
        questionNumber: -1,
        text: null,
        information: null,
        imageUrl: null
    },
    schema: {},
    showTextToSpeech: false,
    robotAvatarImageUrl: robot
};

function createRelatedQuestionsList(questionsToDisplay, messageObject, model) {
    return questionsToDisplay.map(question => {
        const path = question.path;

        const displayAnswers = JSON.parse(question.displayAnswers) || [];
        const questionTitle = messageObject[path] && messageObject[path].title_zh;

        const [sectionKey, questionKey] = path.split("/")
        const userAnswer = model[sectionKey] && model[sectionKey][questionKey];
        if (!userAnswer) {
            return null
        }
        const needAdd = displayAnswers.some((da) => {
            return userAnswer.match(new RegExp(`${da}$`))
        })
        if (needAdd) {
            return {
                title: questionTitle,
                answer: parseLanguage(messageObject[path] && messageObject[path].enum[userAnswer])
            }

        } else {
            return null
        }


    }).filter(q => !!q)
}

export function createQuestionMessage(
    questionId,
    messageObject,
    language = 'zh',
    model
) {
    // console.log("CREATE QUESTION MESSAGE", {questionId, messageObject, language, model})
    const question = messageObject[questionId];
    const { information_zh: information, questionNumber, type, questionsToDisplay, imageUrl, badge, viewType, requireConfirmInstructions } = question;
    const titleSwitch = `title_${language}`;
    let title =
        question[titleSwitch] || question.title;

    const relatedQuestionsList = questionsToDisplay ? createRelatedQuestionsList(questionsToDisplay, messageObject, model) : null;

    return {
        _id: `${questionId}`,
        direction: 'received',
        questionId,
        text: handleTraditionalChinese(title),
        information: handleTraditionalChinese(information),
        questionNumber,
        type,
        badge,
        requireConfirmInstructions,
        viewType,
        // isHighlighted: messageObject[questionId].isHighlighted || false,
        relatedQuestionsList,
        imageUrl
    };
}

/** 
 * determine whether to show robot based on messageType and which robot to show
 */
const getRobotImage = (messageType) => {
    if (messageType === 'instructions' || messageType === 'progressMessage') {
        return <img src={Math.random() > 0.5 ? robot_normal : robot_smile} style={{ display: 'block', width: '20%', margin: '0 auto' }}></img>
    }
    return null;
}

export function QuestionMessageBubble(props) {
    const [relatedQuestionsPopupOpen, setRelatedQuestionsPopupOpen] = useState(false);
    const [fullImagePopupOpen, setFullImagePopupOpen] = useState(false);

    const { message: { relatedQuestionsList, type, badge, questionNumber, text, _id, information, imageUrl, viewType }, messageId, schema, showTextToSpeech, robotAvatarImageUrl } = props;
    const relatedQuestionsPopup = relatedQuestionsList ? <Popup
        className="demo-popup"
        opened={relatedQuestionsPopupOpen}
        onPopupClosed={() => {
            setRelatedQuestionsPopupOpen(false);
        }}
    >
        <RelatedQuestionsPopup relatedQuestions={relatedQuestionsList} />
    </Popup> : null;

    const relatedQuestionsButton = relatedQuestionsList ? <div style={{ marginTop: "1em", color: "#2979FF", textDecorationLine: "underline" }} onClick={() => setRelatedQuestionsPopupOpen(true)}>请点击查看这些已答症状</div> : null;
    const informationButton = information ?
        <div
            style={{
                position: "absolute",
                top: -12,
                left: 70,
                backgroundColor: "#2979FF",
                padding: "1px 7px",
                fontWeight: "bold",
                color: "white",
                borderRadius: 15
            }}
            onClick={() => window.$$f7.dialog.alert(information, false, { cssClass: 'dialog-text-left' })
            }>?</div>
        : null;
    let messageHtml = text;
    // 判断是否有题号
    const hasQuestionNumber = !schema.schema.hideQuestionNumber && questionNumber;
    // 题号过滤，小于9的前面默认加0
    let questionHintText = '';
    if (hasQuestionNumber) questionHintText = questionNumber > 9 ? questionNumber : ('0' + questionNumber);
    if (type === 'instructions') questionHintText = badge;
    if (type === 'array' && viewType !== 'uploadImage') messageHtml += '<b>（可多选）</b>';

    const messageContent = <div><div className='shufu-text-message-bubble-question-number' style={{ backgroundColor: hasQuestionNumber ? '#ffb921' : '#2979ff', display: questionHintText ? 'inline-block' : 'none' }}>{questionHintText}</div>
        {informationButton}
        {
            messageHtml ? (
                <div
                    dangerouslySetInnerHTML={{ __html: messageHtml }}
                >
                </div>
            ) : null
        }
        {
            imageUrl ? (
                <>
                    <img style={{ width: '100%' }} onClick={() => setFullImagePopupOpen(true)} src={imageUrl}></img>
                    <FullScreenImagesPopUp
                        imageUrl={imageUrl}
                        fullImagePopupOpen={fullImagePopupOpen}
                        onPopClose={() => {
                            setFullImagePopupOpen(false);
                        }}
                    />
                </>
            ) : null
        }
        {relatedQuestionsButton}
    </div>

    if (showTextToSpeech === 'true') {
        const speechText = (type === 'array' && viewType !== 'uploadImage') ? text + '（可多选）' : text;
        return (
            <div>
                {getRobotImage(type)}
                <TextToSpeechMessage
                    key={`${messageId}_${_id}`}
                    messageType={type}
                    speechText={speechText}
                >
                    {messageContent}
                </TextToSpeechMessage>
                {relatedQuestionsPopup}
            </div>
        );
    } else {
        return (
            <div>
                {getRobotImage(type)}
                <Message
                    key={`${messageId}_${_id}`}
                    className={type === 'instructions' ? 'shufu-text-message-bubble-instructions' : (imageUrl ? 'shufu-text-message-bubble shufu-text-message-bubble-image' : 'shufu-text-message-bubble')}
                    type="received"
                    avatar={robotAvatarImageUrl}
                >
                    {messageContent}
                </Message>
                {relatedQuestionsPopup}
            </div>
        );
    }
}

