import React from 'react';
import ReactDOM from 'react-dom';

///polyfill modules
import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11';
// import 'core-js';

// Import F7 Bundle
import Framework7 from 'framework7/framework7-lite.esm.js';
import Dialog from 'framework7/components/dialog/dialog';
import Popup from 'framework7/components/popup/popup';
import Preloader from 'framework7/components/preloader/preloader';
import Messages from 'framework7/components/messages/messages';
import View from 'framework7/components/view/view';
import Input from 'framework7/components/input/input';
import Searchbar from 'framework7/components/searchbar/searchbar';
import Popover from 'framework7/components/popover/popover';
import Calendar from 'framework7/components/calendar/calendar';
import Tabs from 'framework7/components/tabs/tabs';
import Actions from 'framework7/components/actions/actions';
import Accordion from 'framework7/components/accordion/accordion';
import InfiniteScroll from 'framework7/components/infinite-scroll/infinite-scroll';
import AutoComplete from 'framework7/components/autocomplete/autocomplete';

// Import F7-React Plugin
import Framework7React from 'framework7-react';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';
import createDynamicManifest from './utils/createDynamicManifest';

// Init F7-React Plugin
Framework7.use([Actions, Tabs, Calendar, Popover, Searchbar, View, Input, Messages, Preloader, Popup, Dialog, Framework7React, Accordion, InfiniteScroll, AutoComplete]);

if (process.env.NODE_ENV === 'development') {
  const VConsole = require('vconsole');
  var vConsole = new VConsole();
}
// 动态创建manifest---pwa使用
createDynamicManifest();

ReactDOM.render(<App />, document.getElementById('root'));

window.onresize = function () {
  document.body.style.height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight + 'px'
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
