import shuaiCloud from './shuaicloud';
import { getToken, getServer } from '../../utils/localStorage.js';

let shuaiCloudInstance = null;

export const initClient = function(props) {
  const appAuthToken = getToken();
  const server = getServer();
  const client = new shuaiCloud({
    appAuthToken: props.appAuthToken || appAuthToken,
    server,
    onInitFailure: props.onInitFailure || onInitFailure,
    onMessage: props.onMessage || onMessage,
    onConnect: props.onConnect || onConnect
  });
  shuaiCloudInstance = client;
  return shuaiCloudInstance;
};

function onInitFailure(e) {
  console.log(e);
  // wx.showModal({
  //     title: 'websocket错误',
  //     content: e.error,
  //     showCancel: false
  // })
}

function onMessage(e) {
  console.log(`DEFAULT onMessage`, e);
}
function onConnect(e) {
  console.log(`DEFAULT onConnect`, e);
}
export function getClient() {
  return shuaiCloudInstance;
}

const EventEmitter = require('events');
class ShuaiCloudMessageEmitter extends EventEmitter {}
export const messageEventEmitter = new ShuaiCloudMessageEmitter();

// module.exports = {
//   initClient,
//   getClient,
//   messageEventEmitter: myShuaiCloudMessageEmitter
// };
