import React, { Component } from 'react';
import {
  Page,
  Card,
} from 'framework7-react';
import './chooseSale.css';
import attention from '../../images/attention.jpg';
import burnout from '../../images/burnout.jpg';
import backbone from '../../images/backbone.jpeg';
import healthy from '../../images/healthy.jpg';
import marriage from '../../images/marriage.jpg';
import sleep from '../../images/sleep.jpg';
import pressure from '../../images/pressure.jpg';
import coronavirus_fever from '../../images/coronavirus_fever.jpeg';
import coronavirus from '../../images/coronavirus.jpeg';
import getParameterByName from '../../utils/getParameterByName';
import getOrganizationConfig from '../../utils/getOrganizationConfig';

class ChooseScales extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(sceneId) {
    this.props.f7router.navigate(
      `/scaleIntroduction/?title=${getParameterByName(
        'title'
      )}&sceneId=${sceneId}`
    );
  }

  handleLesson() {
    this.props.f7router.navigate('/lessonIntroduction/');
  }

  componentDidMount() {
    window.$$f7.preloader.hide();
  }

  renderPage() {
    return (
      <div style={{ padding: 10 }}>
        {/* <LoginScreenTitle>心理健康关爱系列测评</LoginScreenTitle> */}
        <div className="ul-reportButton">
          <button
            className="historyReportButton"
            onClick={() => {
              // self.$f7.dialog.confirm(text, title);
              this.props.f7router.navigate('/historyReport/');
            }}
          >
            <p className="historyReportButtonTip">历史测试报告</p>
          </button>
        </div>

        <Card className="card">
          <button className="scaleButtons" onClick={() => this.handleLesson()}>
            <img className="scaleImage" src={backbone}></img>
            <p className="scaleButtonTips">脊椎健康养护教程</p>
          </button>
        </Card>

        <Card>
          <button
            className="scaleButtons"
            onClick={() =>
              this.handleClick(getOrganizationConfig().sleep.sceneId)
            }
          >
            <img className="scaleImage" src={sleep}></img>
            <p className="scaleButtonTips">睡眠质量测评</p>
          </button>
        </Card>
        <Card>
          <button
            className="scaleButtons"
            onClick={() =>
              this.handleClick(getOrganizationConfig().healthy.sceneId)
            }
          >
            <img src={healthy} className="scaleImage"></img>
            <p className="scaleButtonTips">健康状况测评</p>
          </button>
        </Card>

        <Card>
          <button
            className="scaleButtons"
            onClick={() =>
              this.handleClick(getOrganizationConfig().burnout.sceneId)
            }
          >
            <img className="scaleImage" src={burnout}></img>
            <p className="scaleButtonTips">职业倦怠测评</p>
          </button>
        </Card>

        <Card>
          <button
            className="scaleButtons"
            onClick={() =>
              this.handleClick(getOrganizationConfig().pressure.sceneId)
            }
          >
            <img className="scaleImage" src={pressure}></img>
            <p className="scaleButtonTips">压力状态测评</p>
          </button>
        </Card>

        <Card>
          <button
            className="scaleButtons"
            onClick={() =>
              this.handleClick(getOrganizationConfig().marriage.sceneId)
            }
          >
            <img className="scaleImage" src={marriage}></img>
            <p className="scaleButtonTips">婚姻关系测评</p>
          </button>
        </Card>

        <Card>
          <button
            className="scaleButtons"
            onClick={() =>
              this.handleClick(getOrganizationConfig().attention.sceneId)
            }
          >
            <img className="scaleImage" src={attention}></img>
            <p className="scaleButtonTips">儿童注意力测评</p>
          </button>
        </Card>

        <Card>
          <button
            className="scaleButtons"
            onClick={() =>
              this.handleClick(getOrganizationConfig().coronavirus.sceneId)
            }
          >
            <img className="scaleImage" src={coronavirus}></img>
            <p className="scaleButtonTips">新型冠状病毒相关情绪自我评估</p>
          </button>
        </Card>

        <Card>
          <button
            className="scaleButtons"
            onClick={() =>
              this.handleClick(
                getOrganizationConfig().coronavirus_fever.sceneId
              )
            }
          >
            <img className="scaleImage" src={coronavirus_fever}></img>
            <p className="scaleButtonTips">新冠病毒风险评估及防控攻略</p>
          </button>
        </Card>
      </div>
    );
  }

  render() {
    return (
      <Page
        className="scalesSection"
        noToolbar
        noNavbar
        noSwipeback
        loginScreen
      >
        {this.renderPage()}

        <div
          style={{ width: '100%', marginTop: 10, height: 50 }}
          onClick={() => {
            let pass = window.prompt('请输入代码');
            if (pass === 'siuvo') {
              window.location.href =
                'https://app.siuvo.cn/liveInfo/1872930c-89c5-4618-a561-b5912e92b373';
            }
          }}
        ></div>
      </Page>
    );
  }
}
export default ChooseScales;
