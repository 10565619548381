import React, { useState, useEffect, useCallback } from 'react';
import { answerBoardEmitter } from '../modules/answerBoardEmitter.js';
import { Popup } from 'framework7-react';
// import parseLanguage from '../utils/parseLanguage.js';
import './AnswerBoard.css';
import './PopupStyle.css';
import DemoChooseItemAndViewInvoicePopup from './DemoChooseItemAndViewInvoicePopup.js';
export default function AnswerBoardDemoForm(props) {
  const [popupOpen, setPopupOpen] = useState(false);

  const submitHandler = useCallback(() => {
    window.$$f7.dialog.confirm('请检查您的选择，确认提交后将不得修改。是否确认预约？', '提示', () => {
      answerBoardEmitter.emit('submit');
      setPopupOpen(false);
    })
  }, []);

  return (
    <div className="answer-board-action-button-wrapper">
      <div style={{ flex: 1, height: '100%', display: 'flex' }}>
        <div className="inline-form-submit-button" style={{ flex: 2, backgroundColor: '#fff', color: '#2979ff' }}
          onClick={() => {
            setPopupOpen(true);
          }}
        >
          {props.content.schema.buttonText_zh || '请点击此处填写'}
        </div>
      </div>

      <Popup
        className="static-form-popup popup-pc-style"
        opened={popupOpen}
        onPopupClosed={() => {
          setPopupOpen(false);
        }}
      >
        <DemoChooseItemAndViewInvoicePopup
          schema={props.content.schema}
          submitHandler={submitHandler}
        ></DemoChooseItemAndViewInvoicePopup>
      </Popup>
    </div>
  );
}
