import React, { Component } from "react";
import { Page, Preloader } from "framework7-react";
import backbone from "../../images/backbone.jpeg";
import getOrganizationConfig from '../../utils/getOrganizationConfig';

class LessonIntroduction extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const title = "脊椎健康养护教程";
    return (
      <Page
        className="scaleIntroductionSection"
        noToolbar
        noNavbar
        noSwipeback
        loginScreen
      >
        <div>
          <img className="scaleImg" src={backbone} alt="image"></img>
        </div>
        <div className='contentSection'>
          <p className="scaleTitle">{title}</p>
          <p className="scaleIntroduction">“健康来自好的姿势”，脊柱是人的支柱和栋梁，号称人的“第二生命线”。<br /><br />
            近年来，随着电子产品的快速普及，不良的使用姿势正无形中损害着我们的脊柱，脊柱问题逐渐演化为学生党、上班族的“文明病”。<br /><br />
            合理适当的脊柱养护将帮助我们以更好的精神面貌投入日常生活，赶快点击下方按钮开启“脊柱养护”之旅吧。
          </p>
        </div>
        <div className='buttonSection'>
          <button
            className="startTestButton"
            onClick={()=> {
              location.href = getOrganizationConfig().backbone.url;
              // window.$$f7.preloader.show();
            }}
          >
            <span className='buttonTip'>开始测试</span>
          </button>
        </div>
      </Page>
    );
  }
}
export default LessonIntroduction;
