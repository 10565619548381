import React, { useState, useEffect } from 'react';
import { Page, Navbar, NavRight, Link, Icon, Checkbox, List, ListItem, BlockTitle } from 'framework7-react';
import './scaleIntroductionWithReport.css';
import getParameterByName from '../../utils/getParameterByName';

import { useTranslation } from 'react-i18next';
import { initUser } from '../welcome/welcome';
import { initShuaiCloud as initShuaiCloudFromClientInstance } from '../../modules/shuaicloud/clientInstance';
import robot_icon from '../../images/robot_icon.png';
import { getCurrentUserId, setServiceInstanceId, getOrganizationId } from '../../utils/localStorage.js';

import {
  verifyCodeReturnUsersOrRegister,
  startServiceBySceneId
} from '../../modules/shufu';
import CONFIG from 'GlobalConfigFile';
import introImage from '../../images/smhc/smhc-scale-intro.png';
import {fromToSceneId, organizationIdToFormDesc} from './formSceneId';

const ScaleIntroduction = function (props) {
  const { t, i18n } = useTranslation();
  // 已选量表list
  const [formListSelected, setFormListSelected] = useState(['emotion', 'sleep', 'stress']);
  let organizationId;
  if (getOrganizationId() === 'null'){
    organizationId = 'default';
  } else {
    organizationId = getOrganizationId();
  }
  const descContent = organizationIdToFormDesc[organizationId];
  const formList = [
    {
      title: '压力评估（10题）',
      value: 'stress'
    },
    {
      title: '睡眠评估（8题）',
      value: 'sleep'
    },
    {
      title: '情绪评估（16题）',
      value: 'emotion'
    },
  ]

  useEffect(() => {

    async function initShuaiCloud() {
      try {
        await initShuaiCloudFromClientInstance();
        window.$$f7.preloader.hide();
      } catch (e) {
        console.err(e);
        window.$$f7.preloader.hide();
      }
    }

    if (sessionStorage.getItem('token')) {
      initShuaiCloud();
    } else {
      console.error('sessionStorage get token is null!')
    }
  }, [t])

  /**
   * 链接中没有sceneId，在选择form获取sceneId后开启流程
   */
  async function handleStart () {
    try {
      if(formListSelected.length > 0) {
        const formKey = formListSelected.sort().join('-');
        const sceneId = fromToSceneId[organizationId][formKey];
        const chatUrl = `/chat/?sceneId=${sceneId}&noRestart=true`
        window.$$f7.preloader.show();
        let newServiceInstance = await startServiceBySceneId({
          customerId: getCurrentUserId(),
          sceneId,
          trackingId: window.__tid || null
        });
        const serviceInstanceId = newServiceInstance.serviceInstance &&
            newServiceInstance.serviceInstance.id;
        setServiceInstanceId(serviceInstanceId);
        window.$$f7.preloader.hide();
        props.f7router.navigate(chatUrl, {
          reloadCurrent: true,
        });
      } else {
        window.$$f7.dialog.alert('请您至少选择一种量表测试', false, { cssClass: 'dialog-text-left' })
      }
    } catch (e) {
      window.$$f7.preloader.hide();
      console.error(" err", e);
    }
  }

  // form选择
  function  onFormChange (e) {
    const value = e.target.value;
    if (e.target.checked) {
      formListSelected.push(value);
    } else {
      formListSelected.splice(formListSelected.indexOf(value), 1);
    }
    setFormListSelected([...formListSelected]);
  };

   return (
    <Page
      className="scaleIntroductionSection"
      noToolbar
      noSwipeback
    >
      <Navbar>
        <NavRight >
            <Link  onClick={() => {
              props.f7router.navigate('/scaleIntroductionWithReport/historyReport/');
            }}>
              <div>历史报告</div>
              <Icon f7="chevron_right"></Icon>
            </Link>
          </NavRight>
      </Navbar> 
      <div className="pageContainer">
        <div
          style={{
            flex: 1,
            overflowY: 'auto'
          }}
        >
          <div>
            <img className="scaleImg" src={introImage}></img>
          </div>
          <div style={{ position: 'relative', textAlign: 'right' }}>
            <img style={{ zIndex: -1, width: 60, }} src={robot_icon}></img>
            <div
              style={{
                color: '#414141',
                textAlign: 'left',
                padding: 10,
                color: '#414141',
                textAlign: 'left',
                textIndent: 30,
                fontSize: 16
              }}
            >
              {descContent}
            </div>
          </div>
          <BlockTitle style={{textAlign: 'left'}}>您可以选择以下量表：</BlockTitle>
          <List>
            {
              formList.map((item, index) => {
                return (
                  <ListItem 
                    key={index}
                    defaultChecked
                    checkbox 
                    title={item.title} 
                    name="form-checkbox" 
                    value={item.value}
                    onChange={onFormChange}
                  />
                )
              })
            }
          </List>
        </div>
        <div className="actionButton">
          <button
            className="startTestButton"
            onClick={handleStart}
          >
            <span className="buttonTip">{t("start_test")}</span>
          </button>
        </div>
      </div>
    </Page>
  );
};

export default ScaleIntroduction;
