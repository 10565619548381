import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { Input, Icon, Link, Actions, ActionsButton } from 'framework7-react';


function getRenderItemList(templateList, templateSchema) {

  let _string = templateList.join('');
  let _renderItemList = [];
  let regString = /{(.+?)}/;
  let tryCount = 0;

  while (_string.length) {
    tryCount++;
    if (tryCount > 1000) {
      return {
        error: `try compile loop more than 1000 times`
      };
    }
    let match = _string.match(regString);
    //   console.log('=== found match', match);

    if (!match) {
      // no match, this is the last constant string
      _renderItemList.push({
        case: 'CONSTANT_STRING',
        value: _string
      });
      break;
    }

    let matchIndex = match.index; //2
    let matchString = match[0]; //${severity}
    let matchGroupString = match[1]; //severity; the variable name

    if (matchIndex > 0) {
      // There are some constant string at the beginning
      _renderItemList.push({
        case: 'CONSTANT_STRING',
        value: _string.substr(0, matchIndex)
      });
      _string = _string.substr(matchIndex);
      continue;
    } else {
      let varName = matchGroupString;
      if (templateSchema[varName]) {
        _renderItemList.push({
          case: 'AVSCHEMA_VALUE',
          value: varName
        });
      } else {
        _renderItemList.push({
          case: 'ERROR',
          value: `${varName} not found in schema`
        });
      }
    }
    _string = _string.substr(matchString.length);
  }
  return _renderItemList
}

function getResultString(model, templateSchema, renderItemList) {
  console.log('template value: ', model);
  if (model === 'SKIPPED') return '';

  let result = '';

  for (const renderItem of renderItemList) {
    if (renderItem.case === 'CONSTANT_STRING') {
      result += renderItem.value;
    } else if (renderItem.case === 'AVSCHEMA_VALUE') {
      const questionKey = renderItem.value;
      const subSchema = templateSchema[questionKey];
      const subModel = model[questionKey];
      if (subModel == null || subModel.length === 0) {
        return '';
      }

      if (subSchema.enum) {
        result += subSchema.enum[subModel] != null ? subSchema.enum[subModel] : '--';
      } else {
        result += subModel;
      }
    }
  }
  return result;
}

export function getTemplateResultString(model, templateSchema, templateList) {
  const renderItemList = getRenderItemList(templateList, templateSchema);
  return getResultString(model, templateSchema, renderItemList);
}


export default function TemplateQuestion(props) {
  const [renderItemList, setRenderItemList] = useState([]);

  const [model, setModel] = useState({});

  const [warning, setWarning] = useState(null);

  const { onChange, templateKey, templateList, templateSchema, templateWarningExpression, templateWarningText, style } = props;

  useEffect(() => {
    for (const templateKey in templateSchema) {
      const defaultValue = templateSchema[templateKey].defaultValue;
      if (defaultValue && model[templateKey] == null) {
        setModel(Object.assign({ [templateKey]: defaultValue }, model))
      }
    }
  }, [templateSchema, model])

  useEffect(() => {
    const schemaKeys = Object.keys(templateSchema);
    const fnStr = "return " + `${templateWarningExpression}`;
    try {
      const warningFn = new Function(...schemaKeys, fnStr);
      const varValues = schemaKeys.map(key => model[key]);
      if (varValues.some((val) => val == null)) return; //don't check for warning if not all values are filled in

      const showWarning = warningFn(...varValues);
      if (showWarning && warning !== templateWarningText) setWarning(templateWarningText);
      if (!showWarning && warning) setWarning(null);
    } catch (e) {
      console.error("warningFn execution failed: ", e);
    }


  }, [templateWarningExpression, templateWarningText, templateSchema, model, warning])


  useEffect(() => {
    const _renderItemList = getRenderItemList(templateList, templateSchema);
    setRenderItemList(_renderItemList);
  }, [templateList, templateSchema, setRenderItemList]);

  if (!templateList) {
    return null;
  }

  function _getResultString(_model) {
    if (!_model) {
      _model = model;
    }
    return getResultString(_model, templateSchema, renderItemList);
  }

  function renderEnum(schema, key) {
    const suffixString = schema.enum[model[key]] || schema.enum[schema.defaultValue];

    const valueArray = (schema.order || Object.keys(schema.enum)).map(
      k => schema.enum[k]
    );
    return (
      <div key={`${templateKey}.${key}.${suffixString}`} style={{ display: 'inline-block', margin: '0 15px 0 5px' }}>
        <Link actionsOpen={`.suffix-actions-menu-${templateKey}-${key}`}>
          {suffixString}

          <Icon
            f7="chevron_down"
            size="14px"
            color="blue"
            style={{ marginLeft: 5 }}
          ></Icon>
        </Link>

        <Actions className={`suffix-actions-menu-${templateKey}-${key}`}>
          {(schema.order || Object.keys(schema.enum)).map(answerKey => {
            return (
              <ActionsButton
                key={`${templateKey}.${key}.${answerKey}`}
                style={{ backgroundColor: "#fff" }}
                title={schema.enum[answerKey]}
                onClick={() => {
                  let newModel = {
                    ...model,
                    [key]: answerKey
                  };
                  setModel(newModel);
                  onChange(_getResultString(newModel), newModel);
                }}
              >{schema.enum[answerKey]}</ActionsButton>
            );
          })}
        </Actions>
      </div>
    );
  }

  function renderInput(schema, key) {
    const typeProps = schema.type === 'number' ? { type: 'number', pattern: "\\d*" } : { type: 'text' }
    return (
      <div key={key}
        style={{
          backgroundColor: '#f0f0f0', display: 'inline-block', padding: 10, borderRadius: '5px'
        }}>
        <Input
          maxlength={50}
          {...typeProps}
          placeholder="请输入"
          {...props}
          style={{ width: 50 }}
          onChange={event => {
            const value = event.target.value;
            const parsedValue = schema.type === 'number' && value != null && value.length > 0 ? parseFloat(value) : value;
            let newModel = {
              ...model,
              [key]: parsedValue
            };
            setModel(newModel);
            onChange(_getResultString(newModel), newModel);
          }}
        ></Input>
      </div>
    );
  }

  function renderVariableItem(renderItem) {
    const schemaKey = renderItem.value;
    const schema = _.get(templateSchema, schemaKey, {});
    if (schema.type === 'string' && schema.enum) {
      return renderEnum(schema, schemaKey);
    } else {
      return renderInput(schema, schemaKey);
    }
  }


  return (
    <div>
      <div style={{ ...style }}>
        {renderItemList.map(renderItem => {
          if (renderItem.case === 'CONSTANT_STRING') {
            return <span key={renderItem.value} style={{ fontWeight: 'bold', marginRight: 5 }}>{renderItem.value}</span>;
          } else if (renderItem.case === 'ERROR') {
            return <span style={{ color: 'red' }}>${renderItem.value}</span>;
          } else {
            return renderVariableItem(renderItem);
          }
        })}
      </div>
      {warning ? <div
        style={{
          textAlign: "right",
          color: "#ffb92e",
          fontWeight: "bold",
          margin: "0px 5px 5px 0px"
        }}>
        ***{warning}***
      </div> : null}
    </div>
  );
}

TemplateQuestion.defaultProps = {
  templateSchema: {},
  templateList: [],
  onChange: function () {
    console.warn('TemplateQuestion.onChange not defined');
  },
  style: {},
  templateKey: null
};
