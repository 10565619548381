// 扫码等级页面
import React, { useEffect, useState } from 'react';
import './register.css';
import {
  Input,
  Block,
  Button,
  Page
} from 'framework7-react';
import { createPatientFollowup } from '../../modules/shufu';

function Register(props) {
  console.log('props', props);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');

  async function submitRegisterInfo() {
    if (!name) {
      return window.$$f7.dialog.alert('请输入姓名', false);
    }
    if (!phone) {
      return window.$$f7.dialog.alert('请输入手机号', false);
    }
    let params = {
      patientName: name,
      cellphone: phone,
    }
    let res = await createPatientFollowup(params);
    if (!res.errorCode) {
      props.f7router.navigate('/follow-up/successfulRegister');
    }
  }
  
  return (
    <Page
      noToolbar
      noNavbar
      noSwipeback
    // loginScreen
    className='register-page'
    >
      <div className="register">
        <div className='register-title'>
          山东省精神卫生中心随访系统
        </div>
        <div className='register-article'>
          <span>您好，为帮助医生更快更全面地</span><span className='color-blue'>了解您的病情，提升您的就医体验。</span><span>您的复诊医师在门诊时需要这些信息，请按您的</span>
          <span className='color-blue'>实际情况和真实想法在负责诊前</span><span>完成问卷。系统将在您就诊后的</span><span className='color-blue'>12天</span><span>以短信形式给您发送问卷，请注意查收并</span>
          <span className='color-blue'>认真填写。</span>
        </div>
        <div className='register-input'>
          <div className='input-desc'>请输入您的姓名和手机号:</div>
            <div className='input-item'>
              <div style={{ width: '60px' }}>姓名：</div>
              <Input 
                placeholder="请输入您的姓名" 
                outline
                clearButton
                floatingLabel
                type="text"
                maxlength={11}
                inputStyle={{
                  height: '30px',
                }}
                onChange={(e) => {
                  setName(e.target.value);
                }
                }
              />
            </div>
            <br />
            <div className='input-item'>
              <div style={{ width: '60px' }}>手机号：</div>
              <Input placeholder="请输入您的手机号" 
                outline
                floatingLabel
                clearButton
                type="text"
                maxlength={11}
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
                inputStyle={{
                  height: '30px',
                }}
              />
            </div>
        </div>
        <Block>
        <Button
          large
          raised
          fill
          round
          onClick={() => {
            submitRegisterInfo()
          }}
        >
          提交
        </Button>
      </Block>
      </div>
    </Page>
  )
}

export default Register;