import React, { useState, useCallback, useEffect } from 'react';
import './report.css';
import getParameterByName from '../../utils/getParameterByName';
var Chinese = require('chinese-s2t');

const getImageModal = (imgUrl, modalImgUrl, styleString, index) => {
    const modalId = `modal-background-${index}`;
    return `
        <img
            src="${imgUrl}"
            onclick="console.log('click image');
            document.getElementById('${modalId}').style['display'] = 'flex';
            document.getElementById('${modalId}').onclick=function(e) {
                console.log('click background', e.target);
                if (e.target.className && e.target.className.match(/modal-background/)) {
                     document.getElementById('${modalId}').style['display'] = 'none';
                }
                console.log(e.target.className);};"
            style="${styleString}"
        >
        </img>
        <div
            id="modal-background-${index}"
            class="modal-background"
            onclick=""
        >
            <div
                class="modal-image"
            >
                <img
                    src=${modalImgUrl || imgUrl}
                    style="width:100%;display:block"
                ></img>

            </div>
        </div>`;
}

const ReportRichTextContent = function (props) {
    const content = props.content;
    const noHref = getParameterByName('noHref') === 'true';

    if (!content) {
        return null;
    }


    const parseImagePopup = (content) => {
        const imagePopupRegex = /IMAGE_POPUP_{([\s\S]+?)}/g;
        const imagePopupMatches = [...content.matchAll(imagePopupRegex)];
        console.log(imagePopupMatches);
        let currIndex = 0;
        let parsedContent = '';
        let index = 0;
        for (const match of imagePopupMatches) {
            index++;
            const paramString = match[1];
            const [imgUrl, modalImgUrl, styleString] = paramString.split("|");
            const matchIndex = match.index;
            const matchLength = match[0].length;
            parsedContent += content.substring(currIndex, matchIndex);
            parsedContent += getImageModal(imgUrl, modalImgUrl, styleString, index);
            currIndex = matchIndex + matchLength;
        }
        parsedContent += content.substring(currIndex, content.length);
        return parsedContent;
    }

    const parseContent = (content) => {
        //handle newline
        content = content.replace(/\\n/g, '<br/>');
        content = parseImagePopup(content);
        return content;
    }

    let htmlString =
        parseContent(content) ||
        `<p style="text-align:center;"><span style="font-size:20px"><span style="color:#003ba5">我觉得可以吗？</span></span></p><p><span style="font-size:14px">我觉得不可以，<span style="color:#f32784">你为什么觉得不可以</span>？我觉得主要是这几点：</span></p><ul><li><span style="font-size:14px">这个不可以</span></li><li><span style="font-size:14px">那个不可以</span></li><li><span style="font-size:14px">全部都不可以</span></li><li><span style="font-size:14px">到底可以不可以？</span></li></ul><p></p><div class="media-wrap image-wrap align-center" style="text-align:center"><img src="https://upload.wikimedia.org/wikipedia/commons/6/6c/An_expression_about_wiki_BY_FHH440.jpg" width="200px" height="200px" style="width:200px;height:200px"/></div><p></p>`;
    if (getParameterByName('lang') === 'tc') {
        htmlString = Chinese.s2t(htmlString);
    }

    return (
        <div
            dangerouslySetInnerHTML={{
                __html: noHref
                    ? htmlString.replace(
                        /<a /g,
                        "<a class='external' style='display:none' "
                    )
                    : htmlString.replace(/<a /g, "<a class='external' ")
            }}
        ></div>
    );
};
ReportRichTextContent.defaultProps = {
    content: null,
};
export default ReportRichTextContent;
