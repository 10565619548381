import React, { useState, useEffect } from 'react';
import {
  Page,
  Navbar,
  NavLeft,
  NavRight,
  Link,
  Icon,
  Subnavbar,
  NavTitle,
  Searchbar,
  Toolbar,
  Tabs,
  Tab,
  Button
} from 'framework7-react';
// import _ from 'lodash';
// import parseLanguage from '../utils/parseLanguage.js';
import './SymptomSearchPopup.css';

SymptomSearchPopup.defaultProps = {
  fullSymptomList: [],
  categoryList: [],
  selectedSymptomMap: {},
  onSelectHandler: function () {
    console.warn('submitHandler not defined');
  },
  onSubmitHandler: function () {
    console.warn('submitHandler not defined');
  }
};

export default function SymptomSearchPopup(props) {
  const [fullSymptomList, setFullSymptomList] = useState(props.fullSymptomList);
  const [suggestSymptomList, setSuggestSymptomList] = useState(null);
  useEffect(() => {
    setFullSymptomList(props.fullSymptomList);
    // setSuggestSymptomList(
    //   props.fullSymptomList && props.fullSymptomList.slice(0, 5)
    // );
  }, [props.fullSymptomList]);
  console.log({ fullSymptomList: props.fullSymptomList });

  function renderSelected() {
    const selectedKeyList = Object.keys(props.selectedSymptomMap);
    return (
      <div
        className="symptom-search-popup-selected-container"
        style={{
          maxHeight: selectedKeyList.length === 0 ? 0 : 150
          // minHeight: 50
        }}
      >
        {/* <div className="symptom-search-popup-max-select-tip">最多选择3个</div> */}
        <div className="symptom-search-popup-selected-items-container">
          {selectedKeyList.map(key => {
            const symptom = props.selectedSymptomMap[key];
            return (
              <div
                className="symptom-search-popup-selected-items"
                key={symptom.id}
              >
                {symptom.displayName_zh}
                <div
                  className="symptom-search-popup-selected-items-delete-button"
                  onClick={() => {
                    props.onSelectHandler(symptom);
                    setSuggestSymptomList(null);
                  }}
                >
                  <Icon
                    f7="multiply_circle_fill"
                    size={20}
                    style={{ color: '#e20000' }}
                  ></Icon>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  function renderSuggest() {
    if (!suggestSymptomList) {
      return null;
    }
    return (
      <div className="symptom-search-popup-suggest-container">
        <div className="symptom-search-popup-sugggest-title-wrapper">
          <span className="symptom-search-popup-sugggest-title-text">相关症状</span>
        </div>
        <div
          style={{
            overflowX: 'scroll',
            whiteSpace: 'nowrap',
            paddingBottom: 5
          }}
        >
          {suggestSymptomList.map((symptom, index) => {
            return (
              <div key={`${symptom.id}`} style={{ display: 'inline-block' }}>
                <button
                  // className="shufu-answer-button"
                  className={[
                    'shufu-symptom-search-button',
                    props.selectedSymptomMap[symptom.id]
                      ? 'shufu-symptom-search-button-selected'
                      : ''
                  ].join(' ')}
                  style={{ maxWidth: '44vw', whiteSpace: 'normal' }}
                  key={`${symptom.id}`}
                  onClick={() => {
                    // console.log(symptom);
                    // setPopupOpen(true);
                    if (!props.selectedSymptomMap[symptom.id]) {
                      const category = (symptom.categories || [])[0];
                      const suggest = props.fullSymptomList
                        .filter(
                          s =>
                            s.categories.includes(category) &&
                            !props.selectedSymptomMap[s.id]
                        )
                        .sort(
                          (s1, s2) => -s1.popularityScore + s2.popularityScore
                        );
                      setSuggestSymptomList(suggest);
                    }

                    props.onSelectHandler(symptom);
                  }}
                >
                  {symptom.displayName_zh}
                  {/* {symptom.displayName_zh.substring(
                        0,
                        (index * index) % 3 == 0 ? (index % 2 == 0 ? 7 : 4) : 3
                      )} */}
                </button>
                {symptom.description_zh ? <span
                  className="shufu-answer-button-information-icon"
                  onClick={() => window.$$f7.dialog.alert(symptom.description_zh, false, { cssClass: 'dialog-text-left' })
                  }>?</span> : null}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  function renderTabs() {
    const tabs = ['全部', ...props.categoryList];
    return (
      <div className="symptom-search-popup-tabs-container">
        <Toolbar
          className="symptom-search-popup-category-tabbar"
          tabbar
          scrollable
          position={'top'}
          style={{ minHeight: 44, top: 0 }}
        >
          {tabs.map((tab, index) => (
            <Link key={tab} tabLink={`#tab-${tab}`} tabLinkActive={index === 0}>
              {tab}
            </Link>
          ))}
        </Toolbar>
        <Tabs>
          {tabs.map((tab, index) => {
            let symptomList =
              tab === '全部'
                ? fullSymptomList /*.sort()*/
                : fullSymptomList.filter(symptom =>
                  (symptom.tags || []).includes(tab)
                );
            return (
              <Tab
                key={tab}
                id={`tab-${tab}`}
                className="page-content"
                tabActive={index === 0}
              >
                <div
                  className='symptom-search-add-more'
                >
                  以下症状都没有？
                    <Button
                    style={{ display: 'inline-block' }}
                    onClick={() => {
                      window.$$f7.dialog.prompt(
                        '(20字以内)',
                        '请输入您的症状',
                        value => {
                          if (value) {
                            const symptom = {
                              id: `#${value}`,
                              displayName_en: value,
                              displayName_zh: value,
                              categories: [],
                              ddxCategories: []
                            };
                            props.onSelectHandler(symptom);
                          }
                        }
                      );
                    }}
                  >
                    点击添加
                    </Button>
                </div>
                <div style={{
                  display: "flex",
                  flexWrap: "wrap"
                }}>
                  {symptomList.map((symptom, index) => {
                    return (
                      <div>
                        <button
                          // className="shufu-answer-button"
                          className={[
                            'shufu-symptom-search-button',
                            props.selectedSymptomMap[symptom.id]
                              ? 'shufu-symptom-search-button-selected'
                              : ''
                          ].join(' ')}
                          key={`${symptom.id}`}
                          onClick={() => {
                            // console.log(symptom);
                            // setPopupOpen(true);
                            if (!props.selectedSymptomMap[symptom.id]) {
                              let category = (symptom.categories || [])[0];

                              let suggest = props.fullSymptomList
                                .filter(
                                  s =>
                                    s.categories.includes(category) &&
                                    !props.selectedSymptomMap[s.id] && s.id !== symptom.id
                                )
                                .sort(
                                  (s1, s2) =>
                                    -s1.popularityScore + s2.popularityScore
                                )
                                .slice(0, 5);

                              setSuggestSymptomList(suggest);
                            }
                            props.onSelectHandler(symptom);
                          }}
                        >
                          {symptom.displayName_zh}
                          {/* {symptom.displayName_zh.substring(
                        0,
                        (index * index) % 3 == 0 ? (index % 2 == 0 ? 7 : 4) : 3
                      )} */}
                        </button>
                        {symptom.description_zh ? <span
                          className="shufu-answer-button-information-icon"
                          onClick={() => window.$$f7.dialog.alert(symptom.description_zh, false, { cssClass: 'dialog-text-left' })
                          }>?</span> : null}
                      </div>

                    );
                  })}
                </div>
              </Tab>
            );
          })}
        </Tabs>
      </div>
    );
  }

  return (
    <Page style={{ overflowY: 'hidden' }}>
      <Navbar>
        <NavLeft>
          <Link popupClose>
            <Icon icon="icon-back"></Icon> <span> 返回</span>
          </Link>
        </NavLeft>
        <NavTitle>精中智能预问诊系统</NavTitle>
        <NavRight>
          <Link onClick={props.onSubmitHandler}>
            <span className='symptom-search-submit-button'> 提交</span>
          </Link>
        </NavRight>
        <Subnavbar inner={false}>
          <Searchbar
            placeholder="搜索"
            disableButtonText="取消"
            onChange={e => {
              const value = e.target.value;
              const filteredList = props.fullSymptomList.filter(s =>
                (s.displayName_zh || '').includes(value)
              );
              setFullSymptomList(filteredList);
            }}
            onSearchbarClear={(searchbar, previousQuery) => {
              setFullSymptomList(props.fullSymptomList);
            }}
            onSearchbarDisable={() => {
              setFullSymptomList(props.fullSymptomList);
            }}
          ></Searchbar>
        </Subnavbar>
      </Navbar>
      <div
        style={{
          height: '100%',
          width: '100%',
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          boxSizing: 'border-box',
          overflowY: 'hidden',
          paddingTop: 44 // search bar and navbar
          // marginTop: -44
        }}
      >
        {renderSelected()}
        {renderSuggest()}
        {renderTabs()}
      </div>
    </Page>
  );
}
