import { Icon, Link, Navbar, NavLeft, NavRight, Page, Popup } from 'framework7-react';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { ReactComponent as SelectBodyPart } from '../images/selected_body_parts.svg';
import { ReactComponent as SelectBodyPartBack } from '../images/selected_body_parts_back.svg';
import './AnswerBoard.css';
import './AnswerBoardSelectBodyPartForm.css';
import './PopupStyle.css';
import StaticFormPopup from './StaticFormPopup.js';

// import DemoChooseItemAndViewInvoicePopup from './DemoChooseItemAndViewInvoicePopup.js';

SelectBodyPartFormPopup.defaultProps = {
    schema: {},
    model: {},
    submitHandler: null,
    canSubmit: true
};
export default function SelectBodyPartFormPopup(props) {
    const [staticPopupOpen, setStaticPopupOpen] = useState(false);
    const [model, setModel] = useState(props.model);
    const [bodySide, setBodySide] = useState('front');
    const [bodyPart, setBodyPart] = useState(null);

    const { schema, submitHandler, canSubmit } = props;

    useEffect(() => {
        const selectedBodyParts = Object.keys(model[bodySide] || []);
        for (const selectedBodyPart of selectedBodyParts) {
            const bodyPartSvgs = document.querySelectorAll(`[id='${selectedBodyPart}']`);
            for (const bodyPartSvg of bodyPartSvgs) {
                if (bodyPartSvg) {
                    bodyPartSvg.setAttribute("fill", "#ffcc66");
                } else {
                    console.warn("failed to find bodyPart: ", selectedBodyPart);
                }
            }
        }
    }, [model, bodySide])

    function checkModel() {
        return Object.keys(model).length > 0;
    }

    function confirmToSubmit() {
        if (checkModel()) {
            // 增加确认弹框，二次确认后提交
            window.$$f7.dialog.confirm('请检查您的选择，确认提交后将不得修改。是否确认提交？', '提示', () => {
                submitHandler(model);
            })
        } else {
            window.$$f7.dialog.alert("请至少选择一个部位。", false);
        }
    }

    return (
        <Page>
            <Navbar>
                <NavLeft>
                    <Link popupClose>
                        <Icon icon="icon-back"></Icon> <span> 返回</span>
                    </Link>
                </NavLeft>
                {/* <NavTitle>{_.get(props, 'schema.title_zh')}</NavTitle> */}
                {canSubmit ? <NavRight>
                    <Link
                        onClick={() => {
                            confirmToSubmit()
                        }}
                    >
                        <span> 提交</span>
                    </Link>
                </NavRight> : null}
            </Navbar>
            <div style={{ padding: 20 }}>
                <div style={{ float: 'right', borderRadius: 5 }} onClick={() => setBodySide(bodySide === 'front' ? 'back' : 'front')}>
                    <span style={{borderRadius: "10px 0px 0px 10px"}} className={bodySide === 'front' ? 'highlight' : 'no-highlight'}>正面</span>
                    <span style={{borderRadius: "0px 10px 10px 0px"}} className={bodySide === 'front' ? 'no-highlight' : 'highlight'}>背面</span>
                </div>
                <div style={{ clear: 'both' }}>
                </div>
                <div className='body-content'>{schema.title_zh || ''}</div>
                {bodySide === 'front' ? (
                    <div className='body-part'>
                        <SelectBodyPart
                            onClick={e => {
                                if (!e.target.id) return;
                                if (canSubmit || _.get(model, [bodySide, e.target.id])) {
                                    setBodyPart(e.target.id);
                                    setStaticPopupOpen(true);
                                }
                            }}
                        >
                        </SelectBodyPart>
                    </div>
                ) : (
                    <div className='body-part'>
                        <SelectBodyPartBack
                            onClick={e => {
                                if (!e.target.id) return;
                                if (canSubmit || _.get(model, [bodySide, e.target.id])) {
                                    setBodyPart(e.target.id);
                                    setStaticPopupOpen(true);
                                }
                            }}
                        >
                        </SelectBodyPartBack>
                    </div>
                )}
            </div>
            <Popup
                className="static-form-popup"
                opened={staticPopupOpen}
                onPopupClosed={() => {
                    setStaticPopupOpen(false);
                }}
            >
                <StaticFormPopup
                    key={`static-form-${bodySide}-${bodyPart}`}
                    schema={_.get(schema, ['properties', bodySide, 'properties', bodyPart], {})}
                    model={_.get(model, [bodySide, bodyPart], {})}
                    submitHandler={bodyPartModel => {
                        const newModel = _.cloneDeep(model);
                        _.set(newModel, [bodySide, bodyPart], bodyPartModel);
                        setModel(newModel);
                        setStaticPopupOpen(false);
                    }}
                    canSubmit={canSubmit}
                    requireConfirm={false}
                ></StaticFormPopup>
            </Popup>
        </Page>
    );
}
