import {
  Page
} from 'framework7-react';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isIphonex } from '../../App.js';
import CellphoneLogin from '../../components/CellphoneLogin';
import robot_smile from '../../images/robot_smile.png';
import shufu_logo from '../../images/shufu-logo.png';
import { initShuaiCloud as initShuaiCloudFromClientInstance } from '../../modules/shuaicloud/clientInstance';
import {
  getSceneDataBySceneIdAndTimestamp,
  getServiceItem,
  startServiceItemWithCheckEncounter,
  verifyCodeReturnUsersOrRegister,
  wechatLoginWithWebAuthCode,
  getUserProfile
} from '../../modules/shufu';
import getParameterByName from '../../utils/getParameterByName';
import isWeixinBrowser from '../../utils/isWeixinBrowser';
import isValidWechatLoginUrl from '../../utils/isValidWechatLoginUrl';
import {
  getCurrentUserId,
  setCurrentUserId,
  setOrganizationId,
  setServiceInstanceId,
  setServiceItemId,
  setToken,
  setAnnouncement,
  removeAnnouncement,
  setShowHistoryReportButton
} from '../../utils/localStorage.js';
import mergeServiceItemAndQrCodeOptions from '../../utils/mergeServiceItemAndQrCodeOptions';
import './welcome.css';

import CONFIG from 'GlobalConfigFile';
// 引入本地配置文件，判断是否显示历史报告
import {SHOW_HISTROY_CONFIG} from '../../constant';


export async function initUser(userId, organizationId, token) {
  console.log({ userId, token });
  async function initShuaiCloud() {
    await initShuaiCloudFromClientInstance();
  }

  setCurrentUserId(userId);
  setOrganizationId(organizationId);
  setToken(token);
  await initShuaiCloud();
}


function sleep(ms) {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, ms);
  });
}

const Welcome = function (props) {
  const [title, setTitle] = useState(document.title || getParameterByName('title') || '');
  const [isValid, setIsValid] = useState(true);
  const { t, i18n } = useTranslation();

  const removeLogo = getParameterByName('removeLogo');
  const backgroundImageUrl = getParameterByName('backgroundImageUrl') || 'default';

  const sceneId = getParameterByName('sceneId');
  const qrTimestamp = getParameterByName('qrTimestamp');
  const toPage = getParameterByName('toPage');
  const excludeTitleInInstructions = getParameterByName('excludeTitleInInstructions');
  let chatUrl = '/chat/';

  if (toPage) {
    chatUrl = `/${toPage}/`;
  }

  if (typeof sceneId !== 'undefined' && sceneId !== null && sceneId !== '') {
    chatUrl = `${chatUrl}?sceneId=${sceneId}&title=${title}`;
  } else if (typeof title !== 'undefined' && title !== null && title !== '') {
    chatUrl = `${chatUrl}?title=${title}`;
  }

  console.log(chatUrl);
  // 根据url参数判断要不要显示FormTitle
  if(excludeTitleInInstructions) {
    chatUrl += `&excludeTitleInInstructions=${excludeTitleInInstructions}`
  }

  const [needName] = useState(getParameterByName('needName') === 'true');

  const [nobody] = useState(getParameterByName('nobody') === 'true');
  const [organizationId, setOrganizationId] = useState(getParameterByName('organizationId'));

  const [introductionImageUrl, setIntroductionImageUrl] = useState(undefined);
  const [sceneData, setSceneData] = useState(null);

  const startServiceInstanceWithSceneData = useCallback(async (sceneData) => {

    const tags = JSON.parse(sceneData.tags || '[]');
    tags.push(`tid=${window.__tid || null}`);
    tags.push(`rootTid=${window.__rootTid || null}`);
    let options = {
      referralCode: sceneData.rc || '',
      newServiceItemId: sceneData.sid || undefined,
      newServiceInstanceName: sceneData.sn || undefined,
      wfVariables: sceneData.wfVariables || '{}',
      startedWithUserId: sceneData.startedWithUserId || undefined,
      organizationId: sceneData.organizationId || undefined,
      tags: JSON.stringify(tags)
    };
    
    if (options.newServiceItemId) {
      setServiceItemId(options.newServiceItemId);
      let serviceItem = await getServiceItem(options.newServiceItemId);
      // 获取service item 中的providerId
      const providerId = _.get(serviceItem, ['provider', 'id'], '');
      const showHistoryReportButtonFromLocal = !!SHOW_HISTROY_CONFIG[providerId]
      
      const {
        // needShowServiceTerm,
        wfVariables
        // startInfo
      } = mergeServiceItemAndQrCodeOptions(serviceItem, options);
      
      let newServiceInstance = await startServiceItemWithCheckEncounter({
        serviceItemId: options.newServiceItemId,
        customerId: getCurrentUserId(),
        wfVariables: JSON.stringify(wfVariables),
        name: options.newServiceInstanceName || serviceItem.name,
        tags: JSON.parse(options.tags || '[]') || [],
        // encounterId,
        // forceStartNew,
        // forceStartNew: true,
        sceneId,
        tid: window.__tid || null
      });
      const uiConfig = _.get(newServiceInstance, ['serviceInstance', 'uiConfig']) || '{}';
      const announcement = _.get(JSON.parse(uiConfig), 'announcement', "");
      const showHistoryReportButton = _.get(JSON.parse(uiConfig), 'showHistoryReportButton', "") || showHistoryReportButtonFromLocal;
      console.log('newServiceInstance', newServiceInstance);
      setShowHistoryReportButton(showHistoryReportButton);
      if (announcement) {
        setAnnouncement(announcement);
      } else {
        removeAnnouncement();
      }
      const serviceInstanceId = newServiceInstance.serviceInstance &&
        newServiceInstance.serviceInstance.id;
      setServiceInstanceId(serviceInstanceId);
      console.log('sceneData--------------');

      let chatPageBackgroundImageUrl = _.get(sceneData, 'chatPageBackgroundImageUrl', ''),
        messageListWrapperDom = document.getElementsByClassName('messageListWrapper')[0];
      if (chatPageBackgroundImageUrl && messageListWrapperDom) messageListWrapperDom.style.backgroundImage = 'url(' + chatPageBackgroundImageUrl + ')'
      // 机器人聊天框背景
      let styleSheet = document.styleSheets[0];
      let chatBubbleRobotStyle = _.get(sceneData, 'chatBubbleRobotStyle', '');
      if (chatBubbleRobotStyle) styleSheet.insertRule('.shufu-text-message-bubble.message-received .message-bubble {' + chatBubbleRobotStyle + '}', 0);
      // 机器人头像
      let robotAvatarImageUrl = _.get(sceneData, 'robotAvatarImageUrl', '');
      if (robotAvatarImageUrl) styleSheet.insertRule('.shufu-text-message-bubble .message-avatar { background-image: url("' + robotAvatarImageUrl + '")!important}', 0);
      // 复选标记背景色
      const themeColor = _.get(sceneData, 'themeColor', '');
      if (themeColor) styleSheet.insertRule('.multiple-choice-checkmark {background-color: ' + themeColor + '!important}', 0);
      // 用户聊天框背景
      const chatBubbleCustomerStyle = _.get(sceneData, 'chatBubbleCustomerStyle', '');
      styleSheet.insertRule('.message-sent .message-bubble {' + chatBubbleCustomerStyle + '}', 0);

      const accentColor = _.get(sceneData, 'accentColor', '');
      if (accentColor) styleSheet.insertRule('.shufu-text-fold {color: ' + accentColor + '!important}', 0);
    }
  }, [sceneId]);

  const onLogin = useCallback((sceneData) => {
    console.log("LOGIN SCENEDATA: ", sceneData);
    window.$$f7.preloader.show();
    setTimeout(async () => {
      console.log('SET TIMEOUT COMPLETE');
      // do not dismiss loading if need to navigate to chat page
      // window.$$f7.preloader.hide();
      await startServiceInstanceWithSceneData(sceneData);
      props.f7router.navigate(chatUrl + "&noRestart=true", {
        reloadCurrent: true,
        props: {
          noRestart: true
        }
        // pushState: false
      });
    }, 100)
  }, [chatUrl, props.f7router, startServiceInstanceWithSceneData]);


  const loginWithCellphoneCode = useCallback(
    async (_cellphone, _code, sceneData) => {
      if (_cellphone && _code) {
        // do nothing
      } else {
        if (!_cellphone || !_code) {
          alert('请输入手机和验证码');
          return;
        }
      }

      window.$$f7.preloader.show();

      try {
        let result = await verifyCodeReturnUsersOrRegister(
          _cellphone,
          _code,
          null
        );
        if (result.status === 'NO_USER_EXISTS') {
          const userId = result.data.userId || result.data.userid; // there's maybe a typo from backend
          const token = result.data.token;
          await initUser(userId, organizationId, token);

          if (!introductionImageUrl) {
            onLogin(sceneData);
          } else {
            window.$$f7.preloader.hide();
          }
        } else {
          console.error("unexpected login result status: ", result);
        }
      } catch (err) {
        window.$$f7.preloader.hide();
        console.error(err);
        window.$$f7.dialog.alert(t('incorrect_code'));
      }
    },
    [organizationId, introductionImageUrl, onLogin, t]
  );

  const checkAutomaticLogin = useCallback((sceneData) => {
    async function tryAutomaticLogin(sceneData) {
      //delete workaround bc it breaks shenwan's product - cece 2.18.2019

      // workaround for go back problem - zhebin
      // if (sessionStorage.getItem('token')) {
      // sessionStorage.removeItem('token');
      // history.back();
      // return;
      // }

      console.log('NOBODY', getParameterByName('nobody'));
      if (nobody) {
        // only wechat browser and h5.siuvo.cn uses wechat login
        if (isWeixinBrowser() && isValidWechatLoginUrl()) {
          let code = getParameterByName('code');
          let redirectState = getParameterByName('state');

          // const appId = 'wx04ac68f0c2bb796d'; // sandbox
          const appId = CONFIG.APPID;
          // const appId = 'wx8457bf764fcc834b'; // real
          const state = 'wechat_login_tried';
          const scope = 'snsapi_base';
          const currentURL = window.location.href;
          // const currentURL = 'https://sit.siuvo.cn';

          console.log('!!!!CODECODE', { code, redirectState });
          if (code && redirectState === 'wechat_login_tried') {
            // try to use code to login
            window.$$f7.preloader.show();

            console.log('!!!!', { code, redirectState });

            try {
              let result = await wechatLoginWithWebAuthCode({ code, scope });
              const userId = result.data.userId || result.data.userid; // there's maybe a typo from backend
              const token = result.data.token;
              await initUser(userId, organizationId, token);
              if (!introductionImageUrl) {
                onLogin(sceneData);
              } else {
                window.$$f7.preloader.hide();
              }

              console.log(result);
              return;
            } catch (err) {
              window.$$f7.dialog.alert('微信登录失败');
              window.$$f7.preloader.hide();
              console.log(err);
            }
          } else if (!code && redirectState === 'wechat_login_tried') {
            // do nothing
          } else {
            // try to use wechat login

            let jumpUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${encodeURIComponent(
              currentURL
            )}&response_type=code&scope=${scope}&state=${state}#wechat_redirect`;
            try {
              window.location.replace(jumpUrl); // try to use wechat login
              return;
            } catch (e) {
              alert(e);
            }
          }
        }
        // if it's not wechat browser, or wechat login failed, use original login
        loginWithCellphoneCode(
          `GG_${Date.now()}_${Math.floor(Math.random() * 100)}`,
          '9999',
          sceneData
        );
      }
    }

    if (introductionImageUrl === undefined) {
      return;
    }
    tryAutomaticLogin(sceneData);
  }, [introductionImageUrl, nobody, loginWithCellphoneCode, organizationId, onLogin]);

  // 新增手机号校验--联通使用
  async function onCheckCellPhone(cellphone) {
    if (organizationId === 'liantong') {
      try {
        // 获取用户列表
        const userProfile = await getUserProfile({cellphone, organizationIds: organizationId})
        // 如果用户存在，则可以正常使用
        if (userProfile && userProfile.length > 0) {
          return {
            checkPass: true,
            msg: ''
          }
        }
        return {
          checkPass: false,
          msg: '您的账号不存在'
        }
      } catch (error) {
        console.log('getUserProfile: error', error)
        return {
          checkPass: false,
          msg: '您的账号不存在'
        }
      }
    }
    return {
      checkPass: true,
      msg: ''
    }
  }

  useEffect(() => {
    async function tryGetSceneData() {
      if (!sceneId) {
        return;
      }
      const sceneResponse = await getSceneDataBySceneIdAndTimestamp(
        sceneId,
        qrTimestamp
      );
      if (sceneResponse.status === 'expired') {
        setIsValid(false);
      }
      if (sceneResponse.error) {
        setIsValid(false);
      }
      const sceneResult = sceneResponse.wechatQrInfo;
      let serviceItem = await getServiceItem(sceneResult.sceneData.sid);
      // const organizationId = sceneResult.organization.id
      // 原始接口数据中，wechatQr中没有organizationId---废弃
      // const organizationId = _.get(sceneResult, ['organization', 'id'], '')
      const organizationId = _.get(serviceItem, ['organizationId'], '')
      organizationId && setOrganizationId(organizationId);
      if (sceneResult) {
        setSceneData(sceneResult.sceneData);
        checkAutomaticLogin(sceneResult.sceneData);
      }

      if (_.get(sceneResult, ['sceneData', 'title'], '') === '儿童保健') {
        props.f7router.navigate('/childCareWelcome/', {
          reloadCurrent: true
        });
      }
      const _introductionImageUrl =
        (sceneResult && sceneResult['introductionImageUrl']) ||
        (sceneResult &&
          sceneResult['sceneData'] &&
          sceneResult['sceneData']['introductionImageUrl']) ||
        getParameterByName('introductionImageUrl');
      if (_introductionImageUrl) {
        console.log(
          'setIntroductionImageUrl(_introductionImageUrl)',
          _introductionImageUrl
        );
        setIntroductionImageUrl(_introductionImageUrl);

        setTimeout(() => {
          console.log('introductionImageUrl', introductionImageUrl);
        }, 1000);
      } else {
        console.log('setIntroductionImageUrl(null)');
        setIntroductionImageUrl(null);
      }
    }
    tryGetSceneData();

  }, [sceneId, introductionImageUrl, qrTimestamp, props.f7router, checkAutomaticLogin]);


  // useEffect(() => {
  //   async function tryGetSceneData() {
  //     if (!sceneId) {
  //       return;
  //     }
  //     const sceneResponse = await getSceneDataBySceneIdAndTimestamp(
  //       sceneId,
  //       qrTimestamp
  //     );
  //     if (sceneResponse.status === 'expired') {
  //       setIsValid(false);
  //     }
  //     if (sceneResponse.error) {
  //       setIsValid(false);
  //     }
  //     const sceneResult = sceneResponse.wechatQrInfo;
  //     // const organizationId = sceneResult.organization.id
  //     const organizationId = _.get(sceneResult, ['organization', 'id'], '')
  //     organizationId && setOrganizationId(organizationId);
  //     if (sceneResult) {
  //       setSceneData(sceneResult.sceneData);
  //       checkAutomaticLogin(sceneResult.sceneData);
  //     }

  //     if (_.get(sceneResult, ['sceneData', 'title'], '') === '儿童保健') {
  //       props.f7router.navigate('/childCareWelcome/', {
  //         reloadCurrent: true
  //       });
  //     }
  //     const _introductionImageUrl =
  //       (sceneResult && sceneResult['introductionImageUrl']) ||
  //       (sceneResult &&
  //         sceneResult['sceneData'] &&
  //         sceneResult['sceneData']['introductionImageUrl']) ||
  //       getParameterByName('introductionImageUrl');
  //     if (_introductionImageUrl) {
  //       console.log(
  //         'setIntroductionImageUrl(_introductionImageUrl)',
  //         _introductionImageUrl
  //       );
  //       setIntroductionImageUrl(_introductionImageUrl);

  //       setTimeout(() => {
  //         console.log('introductionImageUrl', introductionImageUrl);
  //       }, 1000);
  //     } else {
  //       console.log('setIntroductionImageUrl(null)');
  //       setIntroductionImageUrl(null);
  //     }
  //   }
  //   tryGetSceneData();

  // }, [sceneId, introductionImageUrl, qrTimestamp, props.f7router, checkAutomaticLogin]);


  if (!isValid) {
    return (
      <Page noToolbar noNavbar noSwipeback loginScreen>
        <div className="welcome-container">
          <div className="welcome-title">
            {title || '舒辅智慧'}
            <br />
            对不起，该链接已失效！
          </div>
        </div>
      </Page>
    );
  }

  if (nobody) {
    //seems like only weixin browser handles the additional padding at the bottom of iphoneX correctly, this is a workaround for other browsers
    const marginBottom = isIphonex() && !isWeixinBrowser() ? 150 : 34;
    const startButton = (
      <div
        style={{
          width: '100%',
          paddingTop: 10,
          paddingBottom: 10,
          textAlign: 'center',
          backgroundColor: '#2979ff',
          color: 'white',
          marginBottom
        }}
        onClick={() => {
          window.$$f7.preloader.show();
          onLogin(sceneData);
        }}
      >
        开 始
      </div>
    );

    const getBackgroundImage = () => {
      if (introductionImageUrl) {
        return 'unset';
      }
      if (removeLogo) {
        return "url('///image.siuvo.com.cn/wechat/h5TitleBackgroundImage_noLogo.jpg')";
      }
      if (backgroundImageUrl === 'mentalHealthNoLogo') {
        return "url('///image.siuvo.com.cn/wechat/h5TitleBackgroundImage_mentalHealth_noLogo.jpeg')";
      }
      if (backgroundImageUrl === 'duiyang') {
        return "url('///image.siuvo.com.cn/DuiYang/duiyangIntroduction.png')";
      }
      return undefined;
    }

    const getBackgroundColor = () => {
      if (introductionImageUrl) return 'white';
      if (backgroundImageUrl === 'duiyang') return '#EBD1A5';

      return undefined;
    }

    const getTitle = (title) => {
      if (backgroundImageUrl === 'duiyang') return ' ';
      return title;
    }


    return (
      <Page noToolbar noNavbar noSwipeback loginScreen>
        <div
          className="welcome-container"
          style={{
            backgroundImage: getBackgroundImage(),
            backgroundColor: getBackgroundColor(),
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          {introductionImageUrl === null ? (
            <div className="welcome-title">{getTitle(title) || t('shufu_zhihui')}</div>
          ) : (
            [
              <div style={{ flex: 1, overflowY: 'scroll' }}>
                <img
                  src={introductionImageUrl}
                  style={{
                    width: '100%'
                  }}
                ></img>
              </div>,
              //racing condition between getting the wechatQrInfo and rendering the startButton.
              //This condition makes sure that the imageUrl exists before rendering the button
              introductionImageUrl ? startButton : null
            ]
          )}
        </div>
      </Page>
    );
  }
  return (
    <Page noToolbar noNavbar noSwipeback loginScreen className='welcome-container-body'>
      {
        organizationId === 'chunni' ? <div className='welcome-words welcome-words-text'>春泥心理</div> : <img className='welcome-shufu-logo' src={shufu_logo}></img>
      }
      <div className='welcome-words'>
        <span className='welcome-words-text'>欢迎！</span>
        <img className='welcome-words-image' src={robot_smile}></img>
      </div>
      {/* <LoginScreenTitle>{title || t('shufu_zhihui')}</LoginScreenTitle> */}

      <CellphoneLogin organizationId={organizationId} needName={needName} onLogin={() => onLogin(sceneData)} onCheckCellPhone={onCheckCellPhone}/>
      {
        organizationId !== 'chunni' && <div style={{ textAlign: 'center', fontSize: '14px', color: '#8c8c8c' }}>
          Powered by Siuvo.Inc
        </div>
      }
    </Page>
  );
};

Welcome.defaultProps = {

};
export default Welcome;
