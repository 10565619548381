export default function isAnswerValid(answer) {
    if (answer == null) return false;
    if (typeof answer === 'string') return answer.replace('__OTHER__', '').length > 0;
    if (Array.isArray(answer)) {
        if (answer.length === 0) return false;

        if (typeof answer[0] === 'string') {
            const otherAnswer = answer.find(answerItem => answerItem.includes('__OTHER__'));
            if (otherAnswer) {
                return otherAnswer.replace("__OTHER__", '').length > 0;
            }
        }
    }
    return true;
}