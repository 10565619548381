export default function Canvas2File(test) {
  return new Promise((resolve) => {
    test.toBlob(function (blobObj) {
      let file = new File([blobObj],
        new Date().getTime() + '.png',
        { type: 'image/png' });
      resolve(file);
    });
  });
}
