import React, { useState, useEffect, useCallback } from 'react';
import { Message } from 'framework7-react';
import './MessageBlock.css';
import {
  // getJsonFormById,
  replyToForm
  // getAllComplaintsByTag
} from '../modules/shufu.js';
import _ from 'lodash';
import { answerBoardEmitter } from '../modules/answerBoardEmitter.js';
import robot from '../images/robot.png';
import { submitPatientEvent } from './SubmitPatientEvent.js'
MessageBlockSymptomSearch.defaultProps = {
  messageJson: {},
  isLastMessage: false,
  messageId: Date.now(),
  message: {}
};

// function parseLanguage(text, language) {
//   const str = text ? `${text}` : text;
//   if (str && str.includes('|~|')) {
//     const [text_zh, text_en] = str.split('|~|');
//     return language && language === 'en' ? text_en : text_zh;
//   } else {
//     return str;
//   }
// }

export default function MessageBlockSymptomSearch(props) {
  // const [schema, setSchema] = useState(null);
  // const [symptomObject, setSymptomObject] = useState({});
  const form = JSON.parse(props.messageJson._lctext || '{}');
  const [model, setModel] = useState(null); // this one stores newly answered result, for rendering result
  const [_hasSetAnswerBoard, setHasSetAnswerBoard] = useState(false);
  useEffect(() => {
    if (props.isLastMessage && !_hasSetAnswerBoard) {
      setHasSetAnswerBoard(true);
      answerBoardEmitter.emit('set-answer-board', {
        type: 'symptomSearch',
        symptomVersion: form.schema.symptomVersion,
        defaultSymptomsTags: form.schema.defaultSymptomsTags ? form.schema.defaultSymptomsTags : form.schema.defaultSymptomsTag ? [form.schema.defaultSymptomsTag] : undefined,
        allowAvSchemaPopup: form.schema.allowAvSchemaPopup,
        removeSearchPage: form.schema.removeSearchPage,
        limit: form.schema.limit,
        sort: form.schema.sort,
        allowCustomSymptomInDefault: form.schema.allowCustomSymptomInDefault,
        allowNoneOfTheAboveInDefault: form.schema.allowNoneOfTheAboveInDefault
      });
    }
  }, [form, props.isLastMessage, props.messageJson, _hasSetAnswerBoard]);

  const submitFormHandler = useCallback(
    async symptoms => {
      let model = {
        symptoms
      };

      // console.log(props.messageJson);
      let answerObject = JSON.parse(props.messageJson._lctext || '{}');
      answerObject.model = model;
      // return;

      const messageId = props.messageId;
      console.log(messageId);

      const text = JSON.stringify(answerObject);

      let body = {
        from: props.message.from,
        to: props.message.to,
        'msg-id': props.message['msg-id'],
        timestamp: new Date(props.message.timestamp).getTime(),
        data: {
          _lctext: text,
          _lcattrs: {
            ...props.messageJson._lcattrs,
            processId: answerObject.interaction.processId, // FIXME workaround here
            businessKey: answerObject.interaction.businessKey // FIXME workaround here
          },
          _lctype: -1 // <-- not sure what's this..., just use -1 by default
        }
      };
      setModel(model);
      // scroll to the end
      setTimeout(() => {
        var messageListWrapper = document.getElementById(
          'message_list_wrapper'
        );
        if (messageListWrapper) {
          // console.log("scroll to top MessageBlockSymptomSearch ", messageListWrapper.scrollTop);
          messageListWrapper.scrollTop = messageListWrapper.scrollHeight;
        }
      }, 500);
      // return;
      const EventParams = {
        formId: _.get(form, ['schema', 'shufuFormId'], ''),
        endTime: new Date().getTime(),
        messageId: messageId,
        serviceInstanceId: _.get(props, 'messageJson._lcattrs.ssid', '')
      }
      submitPatientEvent(EventParams, 'onReplyToForm');
      try {
        const res = await replyToForm(body);
        if (!res) {
          submitPatientEvent(EventParams, 'onReplyToFormFailed');
        }
      } catch (e) {
        console.log("error: ", e);
      }
    },
    [form, props]
  );

  // add submit form emitter
  useEffect(() => {
    if (props.isLastMessage) {
      answerBoardEmitter.on('submit', submitFormHandler);
    }

    return () => {
      answerBoardEmitter.removeListener('submit', submitFormHandler);
    };
  }, [submitFormHandler, props.isLastMessage]);

  const isProcessSuccess = props.messageJson._lcattrs.isProcessSuccess;
  const message = form.schema && (form.schema.instructions_zh + (form.schema.limit ? `<b>（最多选${form.schema.limit}个）</b>` : ''));
  let askMessage = (
    <Message
      key={`${props.messageId}_ask`}
      className="shufu-text-message-bubble"
      style={{ marginBottom: 5, marginTop: 5 }}
      type="received"
      avatar={robot}
    >
      <div dangerouslySetInnerHTML={{ __html: message }} />
    </Message>
  );
  // TODO get schema, and display more message

  let answerMessage =
    isProcessSuccess || model ? (
      <Message
        key={`${props.messageId}_answer`}
        className="shufu-text-message-bubble"
        style={{ marginBottom: 5, marginTop: 5 }}
        type="sent"
      >
        {((model || form.model || {})['symptoms'] || [])
          .map(symptom => {
            // console.log(symptom);
            let title = symptom.displayName_zh;
            return title;
            // let id = symptom.id;
            // let symptomModel = symptom.attributeValueModel;
            // let schema = _.get(symptomObject, `${id}.attributeValueSchema`);

            // let text = Object.keys(symptomModel)
            //   .map(questionKey => {
            //     let questionTitle = schema[questionKey].title_zh;
            //     let questionType = schema[questionKey].type;
            //     if (questionType === 'string' && schema[questionKey].enum) {
            //       questionType = 'enum';
            //     }
            //     let questionText;
            //     if (questionType === 'string') {
            //       questionText = symptomModel[questionKey];
            //     } else if (questionType === 'enum') {
            //       questionText = parseLanguage(
            //         schema[questionKey].enum[symptomModel[questionKey]]
            //       );
            //     } else if (questionType === 'array') {
            //       questionText = symptomModel[questionKey]
            //         .map(answerKey => {
            //           return parseLanguage(
            //             schema[questionKey].items.enum[answerKey]
            //           );
            //         })
            //         .join('、');
            //     }
            //     return `${questionTitle}：${questionText}`;
            //   })
            //   .join('，\n');
            // return `${title}\n${text}`;
          })
          .join('；\n')}
      </Message>
    ) : null;
  return [askMessage, answerMessage];
}
