import React, { useState, useEffect, useCallback, useRef } from 'react';
import { queryReportLink } from '../../../modules/shufu.js';
import { getCurrentUserId, getOrganizationId } from '../../../utils/localStorage.js';
import { Page, Icon, Navbar, NavLeft, NavTitle } from 'framework7-react';
import './historyReport.css'
import moment from 'moment';

function HistoryReport(props) {
  const pageSize = 15;
  const reportStatus = 'completeAndSigned';
  const customerId = getCurrentUserId();
  const organizationId = getOrganizationId();
  const [reportList, setReportList] = useState([]);
  const [hasMoreItem, setHasMoreItem] = useState(true);
  const allowInfiniteRef = useRef();
  const pageNoRef = useRef(0);
  const reportListRef = useRef(reportList);


  const getReports = useCallback(async (customerId, status, pageNo, pageSize) => {
    const result = await queryReportLink({
      customerId,
      status,
      pageNo,
      pageSize
    });
    return result;
  }, [])

  const updateStatusAndData = useCallback((list) => {
    const length = list.length;
    if (length < pageSize) {
      allowInfiniteRef.current = false;
      setHasMoreItem(false);
    } else {
      allowInfiniteRef.current = true;
      setHasMoreItem(true);
    }
    reportListRef.current = [...reportListRef.current, ...list]
    setReportList([...reportListRef.current]);
  }, [])

  const loadMore = useCallback(() => {
    async function loadData() {
      if (!allowInfiniteRef.current) return;
      allowInfiniteRef.current = false;
      pageNoRef.current += pageSize;
      const result = await getReports(customerId, reportStatus, pageNoRef.current, pageSize);
      // 暂时过滤掉lisa.followUp.JSSforUnicom和lisa.followUp.Q12这两个量表，因为这两个报告没有结论
      const filterResult = result.filter(item => item.formId !== 'lisa.followUp.JSSforUnicom' && item.formId !== 'lisa.followUp.Q12');
      updateStatusAndData(filterResult);
    }
    loadData();
  }, [customerId, getReports, updateStatusAndData])

  useEffect(() => {
    const infiniteScrollDom = document.getElementsByClassName('infinite-scroll-content')[0];
    infiniteScrollDom.addEventListener('infinite', () => {
      setTimeout(() => {
        loadMore();
      }, 500)
    })
  }, [loadMore])

  useEffect(() => {
    async function fetchData() {
      try {
        const result = await getReports(customerId, reportStatus, pageNoRef.current, pageSize);
        const filterResult = result.filter(item => item.formId !== 'lisa.followUp.JSSforUnicom' && item.formId !== 'lisa.followUp.Q12');
        console.log('filterResult:', filterResult);
        updateStatusAndData(filterResult);
      } catch (e) {
        console.error('getReports:', e)
      }
    }
    fetchData();

  }, [customerId, organizationId, getReports, updateStatusAndData])

  return (
    <Page
      className="scaleIntroductionSectionWiReport"
      noToolbar
      // noNavbar
      noSwipeback
    // loginScreen
    >
      <Navbar>
        <NavLeft backLink="返回"></NavLeft>
        <NavTitle>测评报告</NavTitle>
      </Navbar>
      <div className="page-content infinite-scroll-content">
        <div className="report-item">
          {
            reportList && reportList.length ? (
              <>
                {reportList.map((item, index) => {
                  return (
                    <div className="card" key={index} onClick={() => {
                      window.open(
                        item.link,
                        '_self'
                      );
                    }}>
                      <div className="card-footer">
                        <div>
                          <div className='report-name'>{item['displayName_zh']}</div>
                          <div className='report-create-time'>{moment(item.reportCreatedTime).format('YYYY年MM月DD日')}</div>
                        </div>
                        <Icon f7="chevron_right"></Icon>
                      </div>
                    </div>
                  )
                })}
              </>
            ) : <div className="empty-report">暂无历史测评报告</div>
          }
        </div>
        {
          hasMoreItem && <div className="preloader infinite-scroll-preloader"></div>
        }
      </div>
    </Page>
  )
}

export default HistoryReport;