import React, { useState, useEffect, useCallback } from 'react';
import ReactPlayer from 'react-player'
import { answerBoardEmitter } from '../modules/answerBoardEmitter.js';
import parseLanguage from '../utils/parseLanguage.js';
import './AnswerBoard.css';
import {
  Input,
  Link,
  Actions,
  ActionsButton,
  Icon
} from 'framework7-react';
import { getCurrentUserInfo } from '../utils/localStorage.js';
import TemplateQuestion from './TemplateQuestion.js';
import Upload from './FileUpload/upload/Upload.js';

var Chinese = require('chinese-s2t');
import getParameterByName from '../utils/getParameterByName';
import { useTranslation } from 'react-i18next';

import moment from 'moment';
import InlineObjectTypeAnswer from './InlineObjectTypeAnswer'
import FullScreenImagesPopUp from './FullScreenImagesPopUp'

import checkmark from '../images/checkmark.png';
import fullScreen from '../images/full-screen.png';
import _, { indexOf } from 'lodash';

AnswerBoardInlineForm.defaultProps = {
  question: {
    questionId: '',
    questionMessageObject: {}
  }
};
export default function AnswerBoardInlineForm(props) {
  const [selectedKeys, setSelectedKeys] = useState({}); //for multiple
  const [selectedKeysOrder, setSelectedKeysOrder] = useState([]); //for multiple

  const [imageUrls, setImageUrls] = useState([]);

  const [inputString, setInputString] = useState('');

  const [isInputValid, setIsInputValid] = useState(true);

  const [suffixString, setSuffixString] = useState('');

  const [loading, setLoading] = useState(false);

  const [canAnswer, setCanAnswer] = useState(true);

  const [autofocus, setAutofocus] = useState(true);

  const [isOtherInputFocus, setIsOtherInputFocus] = useState(false);

  const [dateFormat, setDateFormat] = useState(false);

  const [questionDefaultValue, setQuestionDefaultValue] = useState('');

  // 图片全屏展示
  const [fullImagePopupOpen, setFullImagePopupOpen] = useState(false);
  const [fullscreenImageUrl, setFullscreenImageUrl] = useState('');

  const { question: { questionId, questionMessageObject = {} }, submitProps: { autoSubmit, submitDescriptionText, submitButtonText } } = props;
  const { t, i18n } = useTranslation();

  const isTc = getParameterByName('lang') === 'tc';

  const clearInputStringHandler = () => {
    setInputString('');
  };

  const MobileDetect = require('mobile-detect');
  const userAgentInfo = new MobileDetect(navigator.userAgent);

  useEffect(() => {
    answerBoardEmitter.on('clear-input-string', clearInputStringHandler);
    return () => answerBoardEmitter.removeListener('clear-input-string', clearInputStringHandler);
  }, []);

  // 日期格式的回答添加监听回车事件
  function addDateClickEnterEvent(dateUnit) {
    document.onkeydown = function (event) {
      let e = event || window.event;
      if (e && e.keyCode == 13 && event.target.nodeName === 'INPUT') {
        let dateData = checkDateFormat(dateUnit);
        if (dateData) {
          setInputString(dateData);
          handleSubmit(dateData);
          setDateFormat(false);
        } else {
          window.$$f7.dialog.alert(i18n.t('date_error_message'), false);
        }
      }
    }
  }

  // 监听选择题的数字键盘键入
  function addNumberClickEvent(type, keyData, showSubmitButton) {
    document.onkeydown = function (event) {
      const index = parseInt(event.key);
      const key = keyData[index - 1];
      if (index && key || key == '0') {
        if (type === 'single') {
          onSingleEnumClick(key, showSubmitButton);
        }
        else if (type === 'multiple') {
          onMultipleEnumClickEvent(key)
        }
      }
    }
  }

  // 根据填写的年月日判断，假如填写完年自动跳转到月
  function autoJumpDate() {
    const domYear = document.getElementById('datepickerYear');
    const domMonth = document.getElementById('datepickerMonth');
    const domDate = document.getElementById('datepickerDate');
    /**
     * 年份输入4位后自动跳转到月份
     * 月份2月到12月自动跳转到日期
     * 因为1月份用户写了一个1之后可能会有10月11月12月的可能，而2月及以后会直接跳转
     */
    if (domYear && domYear.getElementsByTagName('input')[0].value.length === 4) {
      const domMonthValue = domMonth.getElementsByTagName('input')[0].value;
      if (!domMonthValue) {
        domMonth.getElementsByTagName('input')[0].focus();
      }
      if (domMonth && (parseInt(domMonthValue) >= 2 && parseInt(domMonthValue) <= 12)) {
        if (domDate && domDate.getElementsByTagName('input') && domDate.getElementsByTagName('input')[0]) {
          domDate.getElementsByTagName('input')[0].focus();
        }
      }
    }
  }

  useEffect(() => {
    let ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('iphone') > 0 || ua.indexOf('ipad') > 0) {
      setAutofocus(false);
    }
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [props.question]);


  useEffect(() => {
    if (!questionMessageObject.requireConfirmInstructions && (questionMessageObject.type === 'instructions' || questionMessageObject.type === 'lastMessage' || questionMessageObject.type === 'progressMessage')) {
      setTimeout(() => {
        answerBoardEmitter.emit('answer', 0, props.question.questionId); // automatically continue if message is instruction or lastMessage or progressMessage
      }, 500);
      return;
    }
    // 连续的instructions消息加上气泡
    if (questionMessageObject.type === "boolean" && questionMessageObject.viewType === "instructions") {
      answerBoardEmitter.emit('show-typing', { isShow: true, autoDisapper: true });
      setTimeout(() => {
        answerBoardEmitter.emit('answer', true, props.question.questionId); // automatically answer instructions message
      }, 3500);
    }
  }, [questionMessageObject.type, props.question.questionMessageObject, questionMessageObject.requireConfirmInstructions, props.question.questionId, questionMessageObject.viewType]);

  useEffect(() => {
    setCanAnswer(true);
    setSelectedKeys({});
    setSelectedKeysOrder([]);
  }, [props.question.questionId]);

  // 设置defaultValue值
  useEffect(() => {
    let defaultValue = _.get(questionMessageObject, 'defaultValue', '');
    if (questionMessageObject.type === "array" && Array.isArray(defaultValue)) {
      const tmpDefaultValue = {};
      defaultValue.forEach(everyQuestionDefaultValue => { tmpDefaultValue[everyQuestionDefaultValue] = true; });
      if (tmpDefaultValue.length > 0) {
        setSelectedKeys(tmpDefaultValue);
        setSelectedKeysOrder(defaultValue);
      }
    }
    else if (questionMessageObject.enum && defaultValue) {
      setSelectedKeys({ [defaultValue]: true });
    }
    else if (typeof defaultValue === 'string' || typeof defaultValue === "number") {
      setInputString(defaultValue);
    }

    setQuestionDefaultValue(defaultValue);
  }, [questionMessageObject]);

  useEffect(() => {
    let suffixArray = questionMessageObject.suffix;
    if (!suffixArray) {
      setSuffixString('');
      return;
    }
    if (typeof suffixArray === 'string') {
      suffixArray = [suffixArray];
    }
    setSuffixString(suffixArray[0]);
  }, [questionMessageObject.suffix, props.question.questionId]);

  useEffect(() => {
    let _input = inputString;
    if (_input == undefined || _input === 'undefined' || _input.length === 0) {
      setIsInputValid(true);
      return;
    }
    const _inputWithSuffixString = suffixString ? _input + suffixString : _input;

    if (questionMessageObject.validator) {
      let reg = new RegExp(questionMessageObject.validator, 'g');
      if (!reg.test(_input) && !reg.test(_inputWithSuffixString)) {
        setIsInputValid(false);
        return;
      }
    }

    if (questionMessageObject.parsedValidatorExpression) {
      const parsedInput = questionMessageObject.type === 'number' ? parseFloat(_input) : _input;
      const fnStr = "return " + `${questionMessageObject.parsedValidatorExpression}`;
      const validatorFn = new Function('answer', ...questionMessageObject.varNames, fnStr);
      if (!validatorFn(parsedInput, ...questionMessageObject.varValues)) {
        setIsInputValid(false);
        return;
      }
    }

    setIsInputValid(true);

  }, [inputString, questionMessageObject.parsedValidatorExpression, questionMessageObject.type, questionMessageObject.validator, questionMessageObject.varNames, questionMessageObject.varValues, suffixString])


  const handleSubmit = useCallback(
    inputParam => {
      let _input = inputParam || inputString;

      if (_input == undefined || _input === 'undefined') {
        window.$$f7.dialog.alert(
          '请填写此题。',
          false
        );
        return;
      }

      const _inputWithSuffixString = suffixString ? _input + suffixString : _input;

      if (questionMessageObject.validator) {
        let reg = new RegExp(questionMessageObject.validator, 'g');
        if (!reg.test(_input) && !reg.test(_inputWithSuffixString)) {
          window.$$f7.dialog.alert(
            questionMessageObject.validationErrorMessage || '输入内容格式错误',
            false
          );
          return;
        }
      }

      if (questionMessageObject.parsedValidatorExpression) {
        const parsedInput = questionMessageObject.type === 'number' ? parseFloat(_input) : _input;
        const fnStr = "return " + `${questionMessageObject.parsedValidatorExpression}`;
        const validatorFn = new Function('answer', ...questionMessageObject.varNames, fnStr);
        if (!validatorFn(parsedInput, ...questionMessageObject.varValues)) {
          window.$$f7.dialog.alert(
            questionMessageObject.validationErrorMessage || '输入内容格式错误',
            false
          );
          return;
        }
      }

      if (_inputWithSuffixString) _input = _inputWithSuffixString
      console.log('emit answer', _input);
      answerBoardEmitter.emit('answer', _input);
      const inputElement = document.getElementsByClassName(
        'answer-board-inline-form-input input-with-value'
      );

      let input = inputElement && inputElement[0];
      if (input) {
        input.value = '';
      }

      setInputString('');
      setSuffixString('');
    }, [inputString,
    questionMessageObject,
    suffixString]
  )

  // 填空格式的回答添加监听回车事件
  useEffect(() => {
    document.onkeydown = function (event) {
      let e = event || window.event;
      if (e && e.keyCode == 13) {
        if (questionMessageObject.type === "number" || (questionMessageObject.type === "string" && !questionMessageObject.enum)) {
          handleSubmit();
        }
        // 键入回车键时，自动确认
        if (questionId === 'SUBMIT') {
          if (!loading) {
            answerBoardEmitter.emit('submit');
            setLoading(true);
          }
        }
      }
    }
  }, [inputString, questionMessageObject.enum, questionMessageObject.type, handleSubmit, loading, questionId]);

  // 确认提交的按钮监听提交
  useEffect(() => {
    if (questionId === 'SUBMIT' && autoSubmit) {
      if (!loading) {
        answerBoardEmitter.emit('submit');
        setLoading(true);
      }
    }
  }, [autoSubmit, loading, questionId])

  function renderStatusBar() {
    return (
      // <div
      //   className="progress-bar"
      //   style={{
      //     width: `${(questionMessageObject.questionNumber /
      //       questionMessageObject.totalQuestions) *
      //       100}vw`
      //   }}
      // ></div>
      <div className='answer-board-progress-bar-content'>
        <div className="answer-board-progress-bar">
          <div className="answer-board-progress-bar-line">
            <div className="bar-line-bg"></div>
            <div className="bar-line-progress"
              style={{
                width: `${(questionMessageObject.questionNumber /
                  questionMessageObject.totalQuestions) *
                  100}%`
              }}
            ></div>
          </div>
          <div className={'answer-board-progress-bar-num'}>
            <font color='#2979ff'>{questionMessageObject.questionNumber}</font> / {questionMessageObject.totalQuestions}
          </div>
        </div>
      </div>
    );
  }

  // render progress bar
  // useEffect(() => {
  //   let node = document.createElement('div');
  //   node.setAttribute('id', 'form_progress_bar');
  //   node.setAttribute(
  //     'style',
  //     `height:3px;
  //      position:absolute;
  //      left:0;
  //      /*top:44px;*/
  //      bottom:0;
  //      z-index:10000;
  //      background-color:#2979ff;
  //      border-bottom:1px solid white;
  //      width:`
  //   );

  //   document.body.appendChild(node);
  //   return () => {
  //     document.getElementById('form_progress_bar').remove();
  //   };
  // });

  function renderSuffixSelector() {
    if (!questionMessageObject.suffix) {
      return null;
    }

    let suffixArray = questionMessageObject.suffix;
    if (typeof suffixArray === 'string') {
      suffixArray = [suffixArray];
    }

    if (suffixArray.length > 1) {
      return (
        <div style={{ background: '#2979ff', borderRadius: '15px', padding: '3px 10px', marginRight: '-10px' }}>
          <Link actionsOpen={`.suffix-actions-menu`} style={{ color: '#fff' }}>
            {suffixString}

            <Icon
              f7="chevron_down"
              size="20px"
              style={{ marginLeft: 5, color: '#fff' }}
            ></Icon>
          </Link>

          <Actions className={`suffix-actions-menu`}>
            {suffixArray.map(suffix => {
              return (
                <ActionsButton
                  key={`${suffix}`}
                  style={{ backgroundColor: "#fff" }}
                  title={`${suffix}`}
                  onClick={() => {
                    setSuffixString(suffix);
                  }}
                >{`${suffix}`}</ActionsButton>
              );
            })}
          </Actions>
        </div>
      );
    } else {
      return <div style={{ color: '#2979ff' }}>{suffixString}</div>;
    }
  }

  function getEnumDisplay(key, index) {
    // 手机端不显示题目答案序号：index
    let indexText = '';
    const mobile = userAgentInfo.mobile();
    if (!mobile) {
      indexText += (index + 1 + '、')
    }

    const enumValue = questionMessageObject.enum[key] || key;
    const parsedLanguageEnum = isTc ? Chinese.s2t(parseLanguage(enumValue)) : parseLanguage(enumValue);
    return (
      <span style={{ textAlign: 'left', display: 'inline-block' }} dangerouslySetInnerHTML={{ __html: `${indexText} ${parsedLanguageEnum}` }}>
      </span>
    )
  }

  function renderTextEnumButton(key, index, onClick) {
    const showSelectionOrder = _.get(questionMessageObject, "showSelectionOrder");
    const checkmarkContent = (showSelectionOrder && (key !== 'noneOfTheAbove')) ? selectedKeysOrder.indexOf(key) + 1 : <img src={checkmark} style={{ width: '80%', height: '100%' }}></img>;

    const information = _.get(questionMessageObject, ['enumInfo', key, 'information_zh']);
    const iconQuestion = _.get(questionMessageObject, ['enumInfo', key, 'iconUrl'], null);
    const enumRows = questionMessageObject.enumRows;
    let shufuAnswerButtonDiv = '';
    if (questionMessageObject.enumOrder.length === 8) {
      shufuAnswerButtonDiv = 'shufu-answer-button-div-eight';
    } else {
      shufuAnswerButtonDiv = 'shufu-answer-button-div';
    }
    if (enumRows) {
      shufuAnswerButtonDiv += ' shufu-answer-button-div-enum-rows'
    }
    return (
      <div
        // float: _.get(questionMessageObject, 'align', false) ? 'none' : 'left'
        style={{ position: 'relative' }}
        key={`${props.question.questionId}.${key}`}
        className={shufuAnswerButtonDiv}
      >
        <div
          style={key === 'noneOfTheAbove' ? { border: '2px dashed #2979ff', backgroundColor: '#F9F9F9' } : {}}
          key={`${props.question.questionId}.${key}`}
          className={[
            enumRows ? 'shufu-answer-button-enum-rows' : 'shufu-answer-button',
            selectedKeys[key] ? 'shufu-answer-button-selected' : ''
          ].join(' ')}
          onClick={() => { onClick(key) }}
        >
          {
            iconQuestion ? (<img className='question-icon' src={iconQuestion}></img>) : null
          }
          {getEnumDisplay(key, index)}
        </div>
        {
          selectedKeys[key] ? (<div className='multiple-choice-checkmark'>
            {checkmarkContent}
          </div>) : null
        }

        {
          information ?
            <span
              style={{
                backgroundColor: "#2979FF",
                padding: "2px 7px",
                fontWeight: "bold",
                color: "white",
                borderRadius: 15,
              }}
              onClick={() => window.$$f7.dialog.alert(information, false, { cssClass: 'dialog-text-left' })
              }>?</span> : null
        }
      </div>
    )
  }


  function renderImageEnumButton(key, index, imageUrl, onClick) {
    return (
      <div style={{ width: '50%' }} key={`${props.question.questionId}.${key}`}>
        <div
          className={[
            'shufu-answer-button', 'shufu-image-answer-button',
            selectedKeys[key] ? 'shufu-answer-button-selected' : ''
          ].join(' ')}
          onClick={() => { onClick(key) }}
        >
          <div className="shufu-image-answer-content">
            <div className="shufu-image-answer-full-screen-icon-content" onClick={(e) => {
              setFullImagePopupOpen(true);
              setFullscreenImageUrl(imageUrl)
              // 阻止点击事件冒泡
              e.stopPropagation();
            }}>
              <Icon className="shufu-image-answer-full-screen-icon" f7="arrow_up_left_arrow_down_right" size="20" />
            </div>
            <img src={imageUrl} className={'shufu-answer-image'} />
          </div>
          {getEnumDisplay(key, index)}
        </div>
      </div>
    );
  }

  function renderEnumButton(key, index, onClick) {
    const imageUrl = _.get(questionMessageObject, ['enumInfo', key, 'imageUrl']);
    return imageUrl ? renderImageEnumButton(key, index, imageUrl, onClick) : renderTextEnumButton(key, index, onClick);
  }

  // 根据不同的文本设置不同的展示方法
  function setCustomDialog(skipCallbackFunction) {
    const skipWarning = _.get(questionMessageObject, 'skipWarning');
    const skipConfirm = _.get(questionMessageObject, 'skipConfirm');
    const skipCancel = _.get(questionMessageObject, 'skipCancel');
    if (skipWarning) {
      if (skipConfirm && skipCancel) {
        window.$$f7.dialog.create({
          title: t('tips'),
          text: skipWarning || t('skipConfirm'),
          buttons: [
            {
              text: skipConfirm,
              onClick: () => {
                skipCallbackFunction();
              }
            },
            {
              text: skipCancel
            },
          ],
          cssClass: 'dialog-text-left',
          verticalButtons: true,
        }).open();
      } else {
        window.$$f7.dialog.confirm(skipWarning || t('skipConfirm'), t('tips'), () => {
          skipCallbackFunction();
        })
      }
    } else {
      skipCallbackFunction();
    }
  }

  function renderActionButtons({ skip, submit }) {
    let selectedKeysLength = Object.keys(selectedKeys).filter((key) => selectedKeys[key]).length;
    if (inputString.replace(/^__OTHER__/, '')) selectedKeysLength += 1;
    const confirmSuffix = (submit && submit.confirmSuffixOverride) != null ? submit.confirmSuffixOverride : selectedKeysLength > 0 ? `（${selectedKeysLength}）` : '';
    if ((!skip || !questionMessageObject.allowSkip) && !submit) return null;
    const submitButton = submit
      ? submit.disabled
        ? <div
          className="inline-form-submit-button  inline-form-submit-button-disabled"
          style={{ flex: 2 }}
        >
          确定{confirmSuffix}
        </div>
        : <div
          className="inline-form-submit-button"
          style={{ flex: 2 }}
          onClick={submit.onClick}
        >
          确定{confirmSuffix}
        </div>
      : null;

    return (<div className="answer-board-action-button-wrapper">
      <div style={{ flex: 1, height: '100%', display: 'flex' }}>
        {skip && questionMessageObject.allowSkip ? <div
          className={skip.className ? skip.className : "inline-form-skip-button"}
          style={{ flex: 1 }}
          onClick={
            () => {
              setCustomDialog(function () {
                skip.onClick();
              }
              );
            }
          }
        >
          {t('skip')}
        </div> : null}
        {submitButton}
      </div>
    </div>);
  }

  function renderInstructions() {
    if (questionMessageObject.instructions_zh)
      return <div style={{ margin: "10px 15px 5px 15px", lineHeight: "1.7em" }}>{questionMessageObject.instructions_zh}</div>;
  }

  function renderSingleEnumInput(key, index) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: 20,
          marginBottom: 20,
          alignItems: 'center'
        }}
      >
        <div
          style={{
            flex: 1,
            padding: 5
          }}
        >
          <div
            className="answer-board-inline-form-input-container"
          >
            <Input
              defaultValue={questionDefaultValue}
              // maxlength={50}
              id="answer_board_inline_form_input"
              key={questionId}
              type="text"
              placeholder={
                questionMessageObject.enum[key] || '请输入'
              }
              clearButton
              wrap={false}
              className="answer-board-inline-form-input"
              inputStyle={{}}
              onInput={event => {
                // console.log('onInput', event);
              }}
              value={inputString.replace(/^__OTHER__/, '')}
              onChange={event => {
                const input = event.target.value;
                if (input && input.length > 50) {
                  window.$$f7.dialog.alert(i18n.t('maxlength_error_message'), false);
                  return;
                }
                setInputString('__OTHER__' + input);
              }}
              onKeyUp={event => {
                console.log('onKeyUp', event);
                // if (event.key === 'Enter') {
                //   handleSubmit();
                // }
              }}
              onKeyDown={event => {
                console.log('onKeyDown', event);
              }}
              onKeyPress={event => {
                console.log('onKeyPress', event);
              }}
              onFocus={event => {
                setIsOtherInputFocus(true);
              }}
              onBlur={event => {
                requestAnimationFrame(() => setIsOtherInputFocus(false));
              }}
            />
          </div>
        </div>
        {inputString ? (
          <div
            className="answer-board-inline-form-input-submit-button"
            onClick={() => handleSubmit()}
          >
            {t('send')}
          </div>
        ) : null}
      </div>
    );
  }



  const onSingleEnumClick = (key, showSubmitButton) => {
    if (canAnswer) {
      if (!showSubmitButton) {
        setCanAnswer(false);
        setTimeout(() => {
          answerBoardEmitter.emit('answer', key);
        }, 10);
      } else {
        setSelectedKeys({ [key]: true });
      }
    }
  };

  function renderSingleChoice() {
    let order =
      questionMessageObject.enumOrder || questionMessageObject.order || Object.keys(questionMessageObject.enum);
    const enumRows = questionMessageObject.enumRows;
    const videoUrl = questionMessageObject.videoUrl;
    const showSubmitButton = questionDefaultValue;
    const onSkip = () => {
      if (canAnswer) {
        setCanAnswer(false);
        setTimeout(() => {
          answerBoardEmitter.emit('answer', 'SKIPPED');
        }, 10);
      }
    }
    const onSubmit = () => {
      if (canAnswer) {
        setCanAnswer(false);
        const answer = Object.keys(selectedKeys).filter(key => selectedKeys[key])[0];
        setTimeout(() => {
          answerBoardEmitter.emit('answer', answer);
        }, 10);
      }
    }

    const currentSelectedKeys = Object.keys(selectedKeys).filter(key => selectedKeys[key]);

    const submitConfig = showSubmitButton ? { onClick: onSubmit, disabled: currentSelectedKeys.length === 0, confirmSuffixOverride: '' } : null;

    addNumberClickEvent('single', order, showSubmitButton);

    let increaseLen = 0;
    return (
      enumRows ?
        (
          enumRows.map((rows, i) => {
            if (i > 0) {
              increaseLen = enumRows[i - 1].length - 1;
            }
            return (<div style={{ display: 'flex' }} key={i}>
              {rows.map((key, index) => {
                if (key === '__OTHER__') {
                  return renderSingleEnumInput(key, index + i + increaseLen);
                } else {
                  if (isOtherInputFocus) {
                    return null;
                  }
                  return renderEnumButton(key, index + i + increaseLen, () => onSingleEnumClick(key, showSubmitButton));
                }
              })}
            </div>)
          })
        ) :
        (<div
          style={{
            // height: '100%', 
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <div className={questionMessageAlign ? "answer-board-answer-button-wrapper" : "answer-board-answer-button-wrapper-left"}>
            {videoUrl ? renderVideo(videoUrl) : null}
            <div
              style={{ maxHeight: '100%', width: '100%', display: questionMessageAlign ? 'inline' : 'flex', flexWrap: questionMessageAlign ? 'nowrap' : 'wrap' }}
            >
              {order.map((key, index) => {

                if (key === '__OTHER__') {
                  return renderSingleEnumInput(key, index);
                } else {
                  if (isOtherInputFocus) {
                    return null;
                  }
                  return renderEnumButton(key, index, () => onSingleEnumClick(key, showSubmitButton));
                }
              })}
            </div>
          </div>
          {renderActionButtons({
            skip: { onClick: onSkip, className: "inline-form-submit-button" },
            submit: submitConfig
          })}
        </div>
        )
    );
  }

  function renderVideo(videoUrl) {
    return <ReactPlayer
      controls={true}
      style={{
        zIndex: 10000
      }}
      className='react-player'
      url={videoUrl}
      width='100%'
      height='40vh'
    />
  }

  function renderStringInput() {
    let defaultValue;
    if (questionId === 'generalInfo/name') {
      //名字
      let userInfo = getCurrentUserInfo();
      defaultValue = userInfo.name;
      if (!inputString && defaultValue && inputString !== defaultValue) {
        setInputString(defaultValue);
      }
    }
    const buttonExists = inputString || questionMessageObject.allowSkip;
    const maxLength = questionMessageObject.maxLength || 50;
    const maxLengthErrorMessage = questionMessageObject.maxLengthErrorMessage || i18n.t('maxlength_error_message');
    const inputMaxWidth = buttonExists ? '85%' : '90%';
    return (
      <div
        style={{
          // height: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <div style={{ flex: 1, padding: 5, maxWidth: inputMaxWidth }}>
          <div
            className={isInputValid ? "answer-board-inline-form-input-container" : "answer-board-inline-form-input-container  answer-board-inline-form-input-container-invalid"}
          >
            <Input
              defaultValue={questionDefaultValue || defaultValue}
              // maxlength={50}
              id="answer_board_inline_form_input"
              key={questionId}
              type="text"
              placeholder={t('please_input')}
              clearButton
              wrap={false}
              className="answer-board-inline-form-input"
              inputStyle={{}}
              value={inputString}
              autofocus={autofocus}
              onInput={event => {
                // console.log('onInput', event);
              }}
              onChange={event => {
                const input = event.target.value;
                if (input && input.length > maxLength) {
                  window.$$f7.dialog.alert(maxLengthErrorMessage, false);
                  return;
                }
                setInputString(input);
              }}
              onKeyUp={event => {
                console.log('onKeyUp', event);
                // if (event.key === 'Enter') {
                //   handleSubmit();
                // }
              }}
              onKeyDown={event => {
                console.log('onKeyDown', event);
              }}
              onKeyPress={event => {
                console.log('onKeyPress', event);
              }}
            />
            <div>{renderSuffixSelector()}</div>
          </div>
        </div>
        {inputString ? (
          <div
            style={{
              width: 55,
              height: 30,
              borderRadius: 15,
              margin: 5,
              backgroundColor: '#2979ff',
              color: 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            onClick={_.debounce(function () {
              handleSubmit();
            }, 200)}
          >
            {t('send')}
          </div>
        ) : questionMessageObject.allowSkip ? (
          <div
            style={{
              minWidth: 55,
              height: 30,
              borderRadius: 15,
              margin: 5,
              backgroundColor: 'white',
              color: 'red',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            onClick={() => {
              setCustomDialog(function () {
                answerBoardEmitter.emit('answer', 'SKIPPED');
              });
            }}
          >
            {t('skip')}
          </div>
        ) : null}
      </div>
    );
  }

  function renderUploadImage() {
    return (
      <div>
        <div style={{ padding: 10 }}>
          <Upload
            onImageUrlsChange={(imageUrls) => {
              setImageUrls(imageUrls);
            }}
            disabled={false}
          />
        </div>
        {renderActionButtons({
          skip: { onClick: () => { answerBoardEmitter.emit('answer', ['SKIPPED']); } },
          submit: { onClick: () => answerBoardEmitter.emit('answer', imageUrls), disabled: imageUrls.length === 0 }
        })}
      </div>)
  }

  function renderTemplate() {
    const parsedQuestionId = questionId.replace(/\//g, '_');
    return (
      <div>
        <div>
          <TemplateQuestion
            key={parsedQuestionId}
            style={{ display: 'flex', padding: 20, justifyContent: 'center', alignItems: 'center' }}
            onChange={(val) => setInputString(val)}
            templateKey={parsedQuestionId}
            templateSchema={questionMessageObject.templateSchema}
            templateList={questionMessageObject.templateList}
            templateWarningExpression={questionMessageObject.templateWarningExpression}
            templateWarningText={questionMessageObject.templateWarningText}
          />
        </div>
        {renderActionButtons({
          skip: { onClick: () => { answerBoardEmitter.emit('answer', 'SKIPPED'); setInputString(''); } },
          submit: {
            disabled: !!!inputString,
            onClick: () => { handleSubmit() }
          }
        })}
      </div>)
  }

  function getMultipleChoiceAnswer() {
    const answer = questionMessageObject.enumOrder.filter((enumKey) => selectedKeys[enumKey]);
    if (questionMessageObject.showSelectionOrder)
      answer.sort((a, b) => selectedKeysOrder.indexOf(a) - selectedKeysOrder.indexOf(b));
    if (inputString.replace(/^__OTHER__/, ''))
      answer.push(inputString);
    return answer;
  }

  const onMultipleEnumClickEvent = (key) => {
    if (key === 'noneOfTheAbove') {
      setSelectedKeys({
        noneOfTheAbove: true
      });
      setSelectedKeysOrder(["noneOfTheAbove"]);
      setInputString('');
      return;
    }
    const isKeySelected = !!!selectedKeys[key];
    setSelectedKeys({
      ...selectedKeys,
      [key]: isKeySelected,
      noneOfTheAbove: false
    });
    if (isKeySelected)
      setSelectedKeysOrder(selectedKeysOrder.filter(selectedKey => selectedKey !== 'noneOfTheAbove').concat(key))
    else
      setSelectedKeysOrder(selectedKeysOrder.filter(selectedKey => selectedKey !== key))
  }

  function renderMultipleChoice(questionMessageAlign) {
    const answer = getMultipleChoiceAnswer();
    const onSkip = () => {
      answerBoardEmitter.emit('answer', ['SKIPPED']);
      setSelectedKeys({});
      setSelectedKeysOrder([]);
      setInputString('');
    };
    const onSubmit = () => {
      answerBoardEmitter.emit('answer', answer);
      setSelectedKeys({});
      setSelectedKeysOrder([]);
      setInputString('');
    };
    addNumberClickEvent('multiple', questionMessageObject.enumOrder)
    return (
      <div
        style={{
          // height: '100%',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <FullScreenImagesPopUp
          imageUrl={fullscreenImageUrl}
          fullImagePopupOpen={fullImagePopupOpen}
          onPopClose={() => {
            setFullImagePopupOpen(false);
          }}
        />
        <div
          className={questionMessageAlign ? "answer-board-answer-button-wrapper" : "answer-board-answer-button-wrapper-left"}
        >
          {questionMessageObject.enumOrder.map((key, index) => {
            if (key === '__OTHER__') {
              return (
                <div
                  key={key}
                  style={{
                    padding: 5,
                    width: '30em'
                  }}
                >
                  <div
                    className="answer-board-inline-form-input-container"
                  >
                    <Input
                      defaultValue={questionDefaultValue}
                      // maxlength={50}
                      id="answer_board_inline_form_input"
                      key={questionId}
                      type="text"
                      placeholder={
                        questionMessageObject.enum[key] || '请输入'
                      }
                      clearButton
                      wrap={false}
                      className="answer-board-inline-form-input"
                      inputStyle={{}}
                      onInput={event => {
                        // console.log('onInput', event);
                      }}
                      value={inputString.replace(/^__OTHER__/, '')}
                      onChange={event => {
                        const input = event.target.value;
                        if (input && input.length > 50) {
                          window.$$f7.dialog.alert(i18n.t('maxlength_error_message'), false);
                          return;
                        }
                        setInputString('__OTHER__' + input);
                        if (selectedKeys.noneOfTheAbove) {
                          setSelectedKeys({
                            ...selectedKeys,
                            noneOfTheAbove: false
                          });
                          setSelectedKeysOrder(selectedKeysOrder.filter(selectedKey => selectedKey !== 'noneOfTheAbove'));
                        }
                      }}
                      onKeyUp={event => {
                        console.log('onKeyUp', event);
                        // if (event.key === 'Enter') {
                        //   handleSubmit();
                        // }
                      }}
                      onKeyDown={event => {
                        console.log('onKeyDown', event);
                      }}
                      onKeyPress={event => {
                        console.log('onKeyPress', event);
                      }}
                      onFocus={event => {
                        setIsOtherInputFocus(true);
                      }}
                      onBlur={event => {
                        setIsOtherInputFocus(false);
                      }}
                    />
                  </div>
                </div>
              );
            } else {
              if (isOtherInputFocus) {
                return null;
              }
              return renderEnumButton(key, index, onMultipleEnumClickEvent);
            }
          })}
        </div>
        {renderActionButtons({ skip: { onClick: onSkip }, submit: { onClick: onSubmit, disabled: answer.length === 0 } })}
      </div>
    );
  }

  function renderDateInput(dateUnit) {
    setTimeout(() => {
      var messageListWrapper = document.getElementById(
        'message_list_wrapper'
      );
      messageListWrapper.scrollTop = messageListWrapper.scrollHeight;
    }, 500);
    let defaultYear = null;
    let defaultMonth = null;
    let defaultDay = null;
    if (questionDefaultValue != null) {
      defaultYear = moment(questionDefaultValue).year();
      defaultMonth = moment(questionDefaultValue).month() + 1;
      defaultDay = moment(questionDefaultValue).date();
    }

    addDateClickEnterEvent(dateUnit);
    return (
      <div
        style={{
          // height: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <div style={{ flex: 1, padding: 5 }}>
          <div
            style={{
              display: 'flex',
            }}
          >
            <div className="answer-date-inputs-div">
              <Input
                id="datepickerYear"
                type="tel"
                maxlength={4}
                className="answer-board-inline-form-input"
                autofocus={autofocus}
                placeholder={i18n.t('date_year')}
                defaultValue={defaultYear || undefined}
                onChange={() => { checkDateFormat(dateUnit); autoJumpDate(); }}
              />
            </div>
            {
              dateUnit === 'YEAR'
                ? null
                : (<>
                  <div className="answer-date-inputs-text">
                    /
                  </div>
                  <div className="answer-date-inputs-div">
                    <Input
                      id="datepickerMonth"
                      type="tel"
                      maxlength={2}
                      className="answer-board-inline-form-input"
                      placeholder={i18n.t('date_month')}
                      defaultValue={defaultMonth || undefined}
                      onChange={() => { checkDateFormat(dateUnit); autoJumpDate(); }}
                    />
                  </div>
                </>)
            }
            {
              (dateUnit === 'YEAR' || dateUnit === 'MONTH')
                ? null
                : (<>
                  <div className="answer-date-inputs-text">
                    /
                  </div>
                  <div className="answer-date-inputs-div">
                    <Input
                      id="datepickerDate"
                      type="tel"
                      maxlength={2}
                      className="answer-board-inline-form-input"
                      placeholder={i18n.t('date_date')}
                      defaultValue={defaultDay || undefined}
                      onChange={() => { checkDateFormat(dateUnit); autoJumpDate(); }}
                    />
                  </div>
                </>)
            }
            {dateFormat || !questionMessageObject.allowSkip ? (
              <div
                style={{
                  width: 50,
                  height: 30,
                  borderRadius: 15,
                  margin: 3,
                  backgroundColor: '#2979ff',
                  color: 'white',
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                  lineHeight: '30px',
                  textAlign: 'center'
                }}
                onClick={() => {
                  const dateData = checkDateFormat(dateUnit);
                  if (dateData) {
                    setInputString(dateData);
                    handleSubmit(dateData);
                    setDateFormat(false);
                  } else {
                    window.$$f7.dialog.alert(i18n.t('date_error_message'), false);
                  }
                }}
              >
                {i18n.t('ok')}
              </div>
            ) : (
              <div
                style={{
                  width: 50,
                  height: 30,
                  borderRadius: 15,
                  margin: 3,
                  backgroundColor: 'red',
                  color: 'white',
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                  lineHeight: '30px',
                  textAlign: 'center'
                }}
                onClick={() => {
                  setCustomDialog(function () {
                    answerBoardEmitter.emit('answer', 'SKIPPED');
                    setSelectedKeys({});
                    setSelectedKeysOrder([]);
                    setInputString('');
                  });
                }}
              >
                {i18n.t('skip')}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  // 限制时间
  function limitDatePickerYear(datepickerDate) {
    const timeInput = moment(datepickerDate).valueOf();
    const timeLimitStart = moment('1900-01-01').valueOf();
    const timeLimitEnd = moment().valueOf();
    const result = timeInput > timeLimitStart && timeInput < timeLimitEnd;
    return result;
  }

  // 验证年月格式 ---此方法，校验和取值公用，调用也是多次，后期需要重写优化
  function checkDateFormat(dateUnit) {
    let datepickerYear = document.getElementById('datepickerYear') ?
      document.getElementById('datepickerYear').getElementsByTagName('input')[0].value : '';
    let datepickerMonth = document.getElementById('datepickerMonth') ?
      document.getElementById('datepickerMonth').getElementsByTagName('input')[0].value : '';
    let datepickerDate = document.getElementById('datepickerDate') ?
      document.getElementById('datepickerDate').getElementsByTagName('input')[0].value : '';
    // 验证输入的数字格式
    if (datepickerYear && parseInt(datepickerYear) != datepickerYear) {
      return false;
    }
    if (datepickerMonth && parseInt(datepickerMonth) != datepickerMonth) {
      return false;
    }
    if (datepickerDate && parseInt(datepickerDate) != datepickerDate) {
      return false;
    }
    // 根据dateUnit来判断需要用户填写的年月日格式
    let checkDatePicker = true;
    if (dateUnit === 'YEAR') {
      checkDatePicker = datepickerYear.length === 4;
      datepickerMonth = '01';
      datepickerDate = '01';
    } else if (dateUnit === 'MONTH') {
      checkDatePicker = datepickerYear.length === 4 && (parseInt(datepickerMonth) >= 1 && parseInt(datepickerMonth) <= 12);
      datepickerMonth = ('0' + datepickerMonth).slice(-2);
      datepickerDate = '01';
    } else {
      checkDatePicker = datepickerYear.length === 4 &&
        (parseInt(datepickerMonth) >= 1 && parseInt(datepickerMonth) <= 12) &&
        (parseInt(datepickerDate) >= 1 && parseInt(datepickerDate) <= 31);
      datepickerMonth = ('0' + datepickerMonth).slice(-2);
      datepickerDate = ('0' + datepickerDate).slice(-2);
    }
    const datepickerData = datepickerYear + '-' + datepickerMonth + '-' + datepickerDate;
    if (checkDatePicker && limitDatePickerYear(datepickerData)) {
      setDateFormat(true);
      return moment(datepickerData).valueOf();
    } else {
      setDateFormat(false);
      return false;
    }
  }

  function renderNumberInput() {

    const maxLength = questionMessageObject.maxLength || 50;
    const maxLengthErrorMessage = questionMessageObject.maxLengthErrorMessage || i18n.t('maxlength_error_message');

    return (
      <div
        style={{
          // height: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <div style={{ flex: 1, padding: 5 }}>
          <div
            className={isInputValid ? "answer-board-inline-form-input-container" : "answer-board-inline-form-input-container  answer-board-inline-form-input-container-invalid"}
          >
            <Input
              defaultValue={questionDefaultValue}
              id="answer_board_inline_form_input"
              key={questionId}
              type="number"
              inputmode="decimal"
              pattern="\d*"
              // type="text"
              placeholder={t('please_input')}
              clearButton
              wrap={false}
              className="answer-board-inline-form-input"
              inputStyle={{}}
              autofocus={autofocus}
              value={inputString}
              onChange={event => {
                const input = event.target.value;
                if (input && input.length > maxLength) {
                  window.$$f7.dialog.alert(maxLengthErrorMessage, false);
                  return;
                }
                setInputString(input);
              }}
            />
            <div style={{ flex: 'none' }}>{renderSuffixSelector()}</div>
          </div>
        </div>
        {inputString ? (
          <div
            className="answer-board-inline-form-input-submit-button"
            onClick={() => {
              if (inputString.includes('e')) {
                window.$$f7.dialog.alert('输入的数字中不能包含e', false);
              } else {
                handleSubmit();
              }
            }}
          >
            {t('send')}
          </div>
        ) : questionMessageObject.allowSkip ? (
          <div
            style={{
              minWidth: 50,
              height: 30,
              borderRadius: 15,
              margin: 5,
              backgroundColor: 'white',
              color: 'red',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            onClick={() => {
              setCustomDialog(function () {
                answerBoardEmitter.emit('answer', 'SKIPPED');
              });
            }}
          >
            {t('skip')}
          </div>
        ) : null}
      </div>
    );
  }

  function renderSubmitBoard() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ textAlign: 'center', padding: 20 }}>
          <div dangerouslySetInnerHTML={{ __html: submitDescriptionText || t('submit_text') }}></div>
        </div>
        <div className="answer-board-action-button-wrapper">
          {loading ? null : <div
            className="inline-form-submit-button"
            onClick={_.debounce(function () {
              if (loading) {
                return;
              }
              answerBoardEmitter.emit('submit');
              setLoading(true);
              // answerBoardEmitter.emit('answer', {
              //   key,
              //   value: questionMessageObject.enum[key]
              // });
            }, 200)}
          >{submitButtonText || t('submit')}</div>}
        </div>
      </div>
    );
  }

  function renderObjectQuestion() {
    return (
      <div>
        {renderInstructions()}
        <InlineObjectTypeAnswer
          key={props.question.questionId}
          question={props.question}
          willAskArray={props.question.willAskArray}
          renderActionButtons={renderActionButtons}
        />
      </div>
    );
  }

  const questionMessageAlign = _.get(questionMessageObject, 'align', false) === 'center';
  if (questionMessageObject.type === 'array') {
    if (questionMessageObject.viewType === 'uploadImage') {
      return renderUploadImage();
    }
    else if (questionMessageObject.enumOrder) {
      return renderMultipleChoice(questionMessageAlign);
    } else {
      return (
        <button
          onClick={() => {
            answerBoardEmitter.emit('answer', []);
          }}
        >
          跳过不支持的题目
        </button>
      );
    }
  } else if (questionMessageObject.type === 'string') {
    if (questionMessageObject.enum) {
      return renderSingleChoice();
    } else if (questionMessageObject.viewType === 'template') {
      return renderTemplate();
    } else {
      return renderStringInput();
    }
  } else if (questionMessageObject.type === "boolean") {
    if (questionMessageObject.viewType === "instructions") {
      return null;
    }
  } else if (questionMessageObject.type === 'instructions' || questionMessageObject.type === 'lastMessage' || questionMessageObject.type === 'progressMessage') {
    if (questionMessageObject.requireConfirmInstructions) {
      return renderSingleChoice();
    }
    console.log('automatically skip instruction & lastMessage & progressMessage', props);
    return null;
  } else if (questionMessageObject.type === 'number') {
    if (questionMessageObject.viewType === 'date') {
      return renderDateInput(_.get(questionMessageObject, 'dateUnit', ''));
    } else {
      return renderNumberInput();
    }
  } else if (questionMessageObject.type === 'object') {
    return renderObjectQuestion();
  } else if (props.question.questionId === 'SUBMIT') {
    return renderSubmitBoard();
  } else {
    return <div>不支持的题目类型</div>;
  }
}