import { Message } from 'framework7-react';
import _ from 'lodash';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import robot from '../images/robot.png';
import { answerBoardEmitter } from '../modules/answerBoardEmitter.js';
import { getJsonFormById, replyToForm, startSendActionLogs, createPatientEvent, getNlpMatch, getNlpResponse, getExistByFormId } from '../modules/shufu.js';
import ShufuFormChat from '../modules/ShufuFormChat.js';
import {
  deleteFormStatus, getFormStatus, getSceneId, getServiceItemId, getShowTextToSpeech, saveFormStatus, getOrganizationId, getCurrentUserId,
} from '../utils/localStorage.js';
import { AnswerMessageBubble, createAnswerMessage } from './AnswerMessageBubble';
import './MessageBlock.css';
import { createQuestionMessage, QuestionMessageBubble } from './QuestionMessageBubble';
import CONFIG from 'GlobalConfigFile';
import { submitPatientEvent } from './SubmitPatientEvent.js';
import { parseIntentions } from '../utils/utils.js';

MessageBlockInlineForm.defaultProps = {
  messageJson: {},
  isLastMessage: false,
  messageId: Date.now(),
  message: {},
  robotAvatarImageUrl: null,
  customerAvatarImageUrl: null
};

function emitCurrentQuestionToAnswerBoard(
  questionId,
  questionMessageObject,
  submitProps,
  willAskArray = []
) {
  answerBoardEmitter.emit('clear-input-string');
  answerBoardEmitter.emit('set-answer-board', {
    type: 'inlineFormQuestion',
    submitProps,
    question: {
      questionId,
      questionMessageObject,
      willAskArray
    },
  });
}
let answerDataList = []; // 每隔一段时间需要传到后端的题目计时列表
const blankAnswerData = {
  formId: '',
  messageId: '',
  serviceInstanceId: '',
  businessKey: '',
  action: '',
  path: '',
  startTime: '',
  endTime: ''
};
let answerData = Object.assign({}, blankAnswerData);

export default function MessageBlockInlineForm(props) {
  const isLastMessageRef = useRef();
  const [schema, setSchema] = useState(null);
  const [formState, setFormState] = useState(null);
  const [shufuFormChat, setShufuFormChat] = useState(null);
  const [canEdit, setCanEdit] = useState(true); // for unfinished form, use this flag to determine if it can be edited
  const [formId, setFormId] = useState(null);
  const serviceItemId = getServiceItemId();
  const showTextToSpeech = getShowTextToSpeech();
  let form = JSON.parse(props.messageJson._lctext || '{}');
  let model = form.model || {};
  const [sendActionLogsIntervalId, setSendActionLogsIntervalId] = useState(null);
  // 新增，联通prod bug fix add
  const messageIdRef = useRef();


  const submitProps = {
    autoSubmit: schema && schema.schema.autoSubmit,
    submitButtonText: schema && schema.schema.submitButtonText,
    submitDescriptionText: schema && schema.schema.submitDescriptionText
  }

  // 设置定时器
  useEffect(() => {
    isLastMessageRef.current = props.isLastMessage;
    if (props.isLastMessage) {
      const intervalId = setInterval(() => {
        sendActionLogs();
      }, 15000);
      setSendActionLogsIntervalId(intervalId);
    }
  }, [props.isLastMessage]);

  useEffect(() => {
    if (!props.isLastMessage && sendActionLogsIntervalId) {
      clearInterval(sendActionLogsIntervalId);
      setSendActionLogsIntervalId(null);
    }
  }, [props.isLastMessage, sendActionLogsIntervalId])

  // 向服务器发送做题时间的log（当本地存储的数组为非空时发送）
  function sendActionLogs() {
    if (answerDataList.length !== 0) {
      const actionLogsToSend = _.cloneDeep(answerDataList);
      answerDataList = [];
      startSendActionLogs(actionLogsToSend);
    }
  }

  const answerQuestionHandler = useCallback(
    async (answer, questionId) => {
      if (questionId && questionId !== formState.currentQuestionId) {
        console.error("mismatch question and answer", answer, questionId, formState.currentQuestionId);
        return;
      }
      // 点击答案结束计时统计时间s
      const doneQuestion = () => {
        answerData.formId = formId ? formId : '';
        answerData.endTime = new Date().getTime();
        answerData.answer = JSON.stringify(answer);
        answerDataList.push(answerData);
      }
      doneQuestion();
      // 提交打点数据
      submitPatientEvent(answerData, 'onAnswerQuestion')
      answerData = Object.assign({}, blankAnswerData);
      const currentQuestion = formState.messageObject[formState.currentQuestionId];
      // 拿到当前questionId的messageObject
      const currentQuestionMessageObject = formState.messageObject[formState.currentQuestionId];
      const [sectionKey, questionKey] = formState.currentQuestionId.split('/');
      let newFormState = {};
      // 获取意图，用于NLP解析
      const intentions = currentQuestion.intentions;
      let matchIntention;
      let response;
      let nlp_path;
      let nlp_response;
      if (intentions) {
        const intentionsValues = Object.keys(intentions).map(key => intentions[key]);
        const defaultIntention = currentQuestion.defaultIntention;
        try {
          const nlpResult = await getNlpMatch(answer, intentionsValues);
          matchIntention = parseIntentions(intentions, nlpResult, defaultIntention);
        } catch (e) {
          matchIntention = { mappedIntention: defaultIntention } // 使用默认意图初始化
        }
      };
      if (currentQuestionMessageObject.getNLPResponse === true) {
        const params = {
          rawText: answer,
          key: `${formId}_${sectionKey}_${questionKey}`,
        };
        answerBoardEmitter.emit('show-typing', { isShow: true, autoDisapper: false });
        const resResponse = await getNlpResponse(params);
        response = resResponse.response;
        nlp_path = resResponse.resResponse;
        nlp_response = { response, nlp_path }
      };
      newFormState = {
        ...formState,
        ...shufuFormChat.getNewState(
          answer,
          formState.messageObject[formState.currentQuestionId],
          null,
          matchIntention,
          nlp_response
        )
      };
      saveFormStatus(props.messageId, newFormState);
      setFormState(newFormState);
      answerBoardEmitter.emit('show-typing', { isShow: false, autoDisapper: false });
      emitCurrentQuestionToAnswerBoard(
        newFormState.currentQuestionId,
        newFormState.messageObject[newFormState.currentQuestionId],
        submitProps,
        newFormState.willAskArray
      );
      setTimeout(() => {
        var messageListWrapper = document.getElementById(
          'message_list_wrapper'
        );
        if (messageListWrapper) {
          // console.log("scroll to top MessageBlockInlineForm: ", messageListWrapper.scrollTop);
          messageListWrapper.scrollTop = messageListWrapper.scrollHeight;
        }
      }, 10);
    },
    [formState, shufuFormChat, props.messageId, submitProps, formId]
  );


  const submitFormHandler = useCallback(async () => {
    sendActionLogs();
    let answerObject = shufuFormChat.convertSubmitModel();
    let messageJson = props.messageJson;
    answerObject.interaction.isReply = true;
    const messageId = props.messageId;

    const text = JSON.stringify(answerObject);

    let body = {
      from: props.message.from,
      to: props.message.to,
      'msg-id': props.message['msg-id'],
      timestamp: new Date(props.message.timestamp).getTime(),
      data: {
        _lctext: text,
        _lcattrs: {
          ...messageJson._lcattrs,
          processId: answerObject.interaction.processId, // FIXME workaround here
          businessKey: answerObject.interaction.businessKey // FIXME workaround here
        },
        _lctype: -1 // <-- not sure what's this..., just use -1 by default
      }
    };
    const EventParams = {
      formId,
      endTime: new Date().getTime(),
      messageId: messageId,
      serviceInstanceId: _.get(props, 'messageJson._lcattrs.ssid', '')
    }
    // 数据打点
    submitPatientEvent(EventParams, 'onReplyToForm');
    // window.$$f7.dialog.preloader('提交中');
    setCanEdit(false);
    try {
      const res = await replyToForm(body);
      if (res) {
        deleteFormStatus(messageId);
      } else {
        submitPatientEvent(EventParams, 'onReplyToFormFailed');
        // 提交失败，但是socket推了下一张form，默认此次提交失败不影响流程，流程继续
        if (isLastMessageRef.current) {
          const currentState = shufuFormChat.getInitialState();
          emitCurrentQuestionToAnswerBoard(
            currentState.currentQuestionId,
            currentState.messageObject[currentState.currentQuestionId],
            { ...submitProps, autoSubmit: false, submitDescriptionText: "对不起，提交失败，请重试" },
            currentState.willAskArray
          );
        }
      }
    } catch (e) {
      console.log("error: ", e);
      //alert error
    }
    // window.$$f7.dialog.close();

    console.log({ messageJson, answerObject });

    // console.log({ messageJson, answerObject });
  }, [shufuFormChat, props, formId, submitProps]);
  // To get schema
  useEffect(() => {
    const organizationId = getOrganizationId();
    async function getForm(formId) {
      try {
        let oldModelData = null;
        let jsonForm = await getJsonFormById(formId);
        if (organizationId === 'liantong') {
          const params = {
            formId,
            userId: getCurrentUserId(),
            organizationId,
            serviceInstanceId: _.get(props, 'messageJson._lcattrs.ssid', '')
          }
          const res = await getExistByFormId(params);
          // 获取历史存在的jsonFormData
          if (res.status === '1001') {
            // 只有msgid，使用msgId从local中取cahe form status
            const {messageId} = res;
            messageIdRef.current = messageId;
          } else if (res.status === '1002'){
            // 返回的是jsonformdata, 使用后端保存的老的做完的数据
            oldModelData = res.jsonformdata.modelData;
          }
          let form = JSON.parse(jsonForm.form);
          let _schema = form;
          let result = _.merge(_schema, _form);
          if (oldModelData) {
            // merge后，如果老的数据存在，则使用老的答案，直接跳转到提交
            result.model = JSON.parse(oldModelData)
          }
          setSchema(result);
        } else {
          let form = JSON.parse(jsonForm.form);
          let _schema = form;
          let result = _.merge(_schema, _form);
          setSchema(result);
        }
      } catch (e) {
        // do nothing
      }
    }

    let _form = JSON.parse(props.messageJson._lctext || '{}');
    let partSchema = _form.schema || {};
    let shufuFormId = partSchema.shufuFormId;

    if (shufuFormId && !schema && shufuFormId !== formId) {
      setFormId(shufuFormId);
      getForm(shufuFormId);
    }
  }, [props.messageJson, schema, formId, props]);

  const startQuestion = useCallback(
    // 问题出现开始计时，修改答案时action = 'MODIFY_ANSWER'
    (detail, action = 'ANSWER_QUESTION') => {
      answerData = {
        formId: formId ? formId : '',
        messageId: _.get(props, 'messageId', ''),
        serviceInstanceId: _.get(props, 'messageJson._lcattrs.ssid', ''),
        businessKey: _.get(props, 'messageJson._lcattrs.businessKey', ''),
        action,
        path: action === 'ANSWER_QUESTION' ? _.get(detail, 'question.questionId', '') : detail,
        questionNumber: action === 'ANSWER_QUESTION' ? _.get(detail, 'question.questionMessageObject.questionNumber', '') : '',
        startTime: new Date().getTime(),
        endTime: '',
        serviceItemId
      }
    }, [formId, props, serviceItemId])

  // add answer question emitter
  useEffect(() => {
    if (props.isLastMessage) {
      answerBoardEmitter.on('answer', answerQuestionHandler);
      answerBoardEmitter.on('submit', submitFormHandler);
      answerBoardEmitter.on('set-answer-board', startQuestion);
    }

    return () => {
      answerBoardEmitter.removeListener('answer', answerQuestionHandler);
      answerBoardEmitter.removeListener('submit', submitFormHandler);
      answerBoardEmitter.removeListener('set-answer-board', startQuestion);
    };
  }, [props, answerQuestionHandler, submitFormHandler, startQuestion]);

  useEffect(() => {

    if (schema && !formState) {
      // need to populate ShufuFormChat
      // console.log('YYYYYYYYYYYYYY,need to populate ShufuFormChat', schema);

      let shufuFormChat = new ShufuFormChat({
        newMessage: schema,
        language: 'zh',
        formVariables: props.formVariables,
        createQuestionMessage: createQuestionMessage,
        createAnswerMessage: createAnswerMessage,
        isReplyProcessedSuccess: () => {
          return false;
        },
        excludeTitleInInstructions: window.excludeTitleInInstructions
      });
      setShufuFormChat(shufuFormChat);

      let cachedFormState = getFormStatus(props.messageId);
      let formStatus;
      if (cachedFormState) {
        formStatus = cachedFormState;
        shufuFormChat.setInitalState(cachedFormState);
      } else {
        const oldCaheFormState = messageIdRef.current && getFormStatus(messageIdRef.current);
        if (oldCaheFormState) {
          formStatus = oldCaheFormState
        } else {
          formStatus = shufuFormChat.getInitialState();
        }
      }

      setFormState(formStatus);

      if (props.isLastMessage) {
        emitCurrentQuestionToAnswerBoard(
          formStatus.currentQuestionId,
          formStatus.messageObject[formStatus.currentQuestionId],
          submitProps,
          formStatus.willAskArray
        );
      }
    }
  }, [schema, formState, props.messageId, props.isLastMessage, submitProps, props.formVariables]);

  const isProcessSuccess = props.messageJson._lcattrs.isProcessSuccess;
  if (!isProcessSuccess /*\&& props.isLastMessage*/) {
    // if the schema is loading:
    if (!schema || !formState) {
      return null;
      // return [
      //   <Message
      //     key={`${props.messageId}_loading1`}
      //     className="shufu-text-message-bubble"
      //     style={{ marginBottom: 5, marginTop: 5 }}
      //     type="received"
      //     avatar="http://image.siuvo.com.cn/wechat/board.png"
      //   >
      //     LOADING1...
      //   </Message>
      // ];
    }
    return (formState.messagesShown || [])
      .map(message => {
        if (message.direction === 'received') {
          return <QuestionMessageBubble
            key={`${props.messageId}_${message._id}_outer`}
            message={message}
            messageId={props.messageId}
            schema={schema}
            showTextToSpeech={showTextToSpeech}
            robotAvatarImageUrl={props.robotAvatarImageUrl || robot}
          />
        } else {
          const onEdit = () => {
            let newFormState = {
              ...formState,
              ...shufuFormChat.editAnswer(message)
            };
            saveFormStatus(props.messageId, newFormState);
            setFormState(newFormState);
            emitCurrentQuestionToAnswerBoard(
              newFormState.currentQuestionId,
              newFormState.messageObject[newFormState.currentQuestionId],
              submitProps,
              newFormState.willAskArray
            );
            startQuestion(_.get(newFormState, 'currentQuestionId', ''), 'MODIFY_ANSWER');
          };
          return <AnswerMessageBubble
            key={`${props.messageId}_${message._id}_answer_outer`}
            message={message}
            messageId={props.messageId}
            canEdit={canEdit}
            onEdit={onEdit}
            chatBubbleCustomerStyle={props.chatBubbleCustomerStyle}
            customerAvatarImageUrl={props.customerAvatarImageUrl}
          />;
        }
      })
      .reverse();
  } else if (isProcessSuccess) {
    // need to display the result
    // if the schema is loading:
    if (!schema || !formState) {
      return [
        <Message
          key={`${props.messageId}_loading1`}
          className="shufu-text-message-bubble"
          type="received"
          robotAvatarImageUrl={props.robotAvatarImageUrl || robot}
        >
          数据载入中...
        </Message>,
        <Message
          key={`${props.messageId}_loading2`}
          className="shufu-text-message-bubble"
          type="sent"
        >
          数据载入中...
        </Message>
      ];
    }
    const result = [];

    formState.wholeOrder.forEach(questionId => {
      const [sectionKey, questionKey] = questionId.split('/');
      const answer = _.get(model, [sectionKey, questionKey], null);
      if (answer === null) {
        return;
      }
      const question = formState.messageObject[questionId];
      const questionMessage = createQuestionMessage(questionId, formState.messageObject, 'zh', model);
      result.push(<QuestionMessageBubble
        key={`${props.messageId}_${questionMessage._id}_outer`}
        message={questionMessage}
        messageId={props.messageId}
        schema={schema}
        showTextToSpeech={showTextToSpeech}
        robotAvatarImageUrl={props.robotAvatarImageUrl || robot}
      />);

      if (question.viewType !== 'instructions') {
        const answerMessage = createAnswerMessage(questionId, answer, shufuFormChat.getAnswerVal(question, answer), question);
        result.push(<AnswerMessageBubble
          key={`${props.messageId}_${answerMessage._id}_answer_outer`}
          message={answerMessage}
          messageId={props.messageId}
          canEdit={false}
          chatBubbleCustomerStyle={props.chatBubbleCustomerStyle}
          customerAvatarImageUrl={props.customerAvatarImageUrl}
        />);
      }
    });
    return result;
  } else {
    console.log('MESSAGE discarded', props.messageJson);
    return null;
  }
}
