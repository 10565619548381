// 山东精神卫生中心问卷
import React, { useCallback, useEffect, useState } from 'react';
import './questionnaire.css';
import {
  Col,
  Input,
  Row,
  Radio,
  Checkbox,
  Button,
  Block,
  Page

} from 'framework7-react';
import _ from 'lodash';
import ToastComponent from 'framework7/components/toast/toast';
import { createPatientAnswer, searchPatientFollowupInfoId } from '../../modules/shufu';

const noSatisfactionReason = {
  type: 'text',
  require: true,
  question: '不满意原因',
  questionKey: 'noSatisfactionReason',
  added: true
};

const outpatientRehabilitationIntention = {
  type: 'checkbox',
  require: true,
  question: '您有意向门诊治疗的项目是',
  questionKey: 'outpatientRehabilitationIntention',
  added: true,
  radioChoice: [
    {
      choice: 'MECT治疗',
      value: 'MECT治疗',
    },
    {
      choice: '中医治疗',
      value: '中医治疗',
    },
    {
      choice: '经颅磁',
      value: '经颅磁',
    },
    {
      choice: '生物反馈',
      value: '生物反馈',
    },
    {
      choice: '沙盘治疗',
      value: '沙盘治疗',
    },
    {
      choice: '家庭治疗',
      value: '家庭治疗',
    },
    {
      choice: '团体治疗',
      value: '团体治疗',
    },
    {
      choice: '其他',
      value: '其他',
    },
  ],
};

const questionList = [
  {
    type: 'text',
    require: true,
    question: '姓名',
    questionKey: 'name',
  },
  {
    type: 'radio',
    require: true,
    question: '性别',
    questionKey: 'gender',
    radioChoice: [
      {
        choice: '男',
        value: 'male',
      },
      {
        choice: '女',
        value: 'female'
      },
    ]
  },
  {
    type: 'text',
    require: true,
    question: '年龄',
    questionKey: 'age',
  },
  {
    type: 'text',
    require: false,
    question: '诊断',
    questionKey: 'diagnose',
  },
  {
    type: 'text',
    require: false,
    question: '用药情况',
    questionKey: 'drugUsage',
  },
  {
    type: 'radio',
    require: true,
    question: '本次就诊是否满意',
    questionKey: 'satisfaction',
    askif: true,
    radioChoice: [
      {
        choice: '非常满意',
        value: 'greatSatisfaction'
      },
      {
        choice: '比较满意',
        value: 'moreSatisfaction',
      },
      {
        choice: '一般',
        value: 'lessSatisfaction',
      },
      {
        choice: '不满意',
        value: 'noSatisfaction',
        other: noSatisfactionReason
      },
    ]
  },
  {
    type: 'radio',
    require: true,
    question: '目前病情如何',
    questionKey: 'condition',
    radioChoice: [
      {
        choice: '波动',
        value: 'fluctuate',
      },
      {
        choice: '平稳',
        value: 'stable',
      },
      {
        choice: '改善',
        value: 'improve',
      },
      {
        choice: '复发',
        value: 'reappear',
      },
    ]
  },
  {
    type: 'radio',
    require: false,
    question: '是否用药治疗',
    questionKey: 'medicate',
    radioChoice: [
      {
        choice: '是',
        value: 'true',
      },
      {
        choice: '否',
        value: 'false',
      },
    ]
  },
  {
    type: 'radio',
    require: false,
    question: '回家后是否坚持服药',
    questionKey: 'medicationAdherence',
    radioChoice: [
      {
        choice: '是',
        value: 'true',
      },
      {
        choice: '否',
        value: 'false',
      },
    ]
  },
  {
    type: 'radio',
    require: false,
    question: '用药后病情是否有所改善',
    questionKey: 'imporedAfterMedication',
    radioChoice: [
      {
        choice: '是',
        value: 'true',
      },
      {
        choice: '否',
        value: 'false',
      },
    ]
  },
  {
    type: 'text',
    require: false,
    question: '用药后什么感觉',
    questionKey: 'feelAfterMedication',
  },
  {
    type: 'radio',
    require: true,
    question: '是否有门诊定期复诊计划',
    questionKey: 'subsequentVisit',
    radioChoice: [
      {
        choice: '有计划',
        value: 'true',
      },
      {
        choice: '暂无',
        value: 'false',
      },
    ]
  },
  {
    type: 'radio',
    require: false,
    question: '是否需要预约专家',
    questionKey: 'reserveExpert',
    radioChoice: [
      {
        choice: '是',
        value: 'true',
      },
      {
        choice: '否',
        value: 'false',
      },
    ],
    desc: '目前门诊康复治疗有：MECT治疗 中医治疗 经颅磁 生物反馈 沙盘治疗 家庭治疗 团体治疗',
  },
  {
    type: 'radio',
    require: false,
    question: '是否有门诊康复治疗意向',
    questionKey: 'outpatientRehabilitation',
    askif: true,
    radioChoice: [
      {
        choice: '是',
        value: 'true',
        other: outpatientRehabilitationIntention
      },
      {
        choice: '否',
        value: 'false',
      },
    ]
  }
];

function Questionnaire(props) {
  const [renderQuestionList, setRenderQuestionList] = useState(questionList);
  const [sendData, setSendDatd] = useState({
    // 姓名
    name: '',
    // 性别
    gender: '',
    // 年龄
    age: '',
    // 诊断
    diagnose: '',
    // 用药情况
    drugUsage: '',
    // 本次就诊是否满意
    satisfaction: '',
    // 目前病情如何
    condition: '',
    // 是否用药治疗
    medicate: '',
    // 回家后是否坚持服药
    medicationAdherence: '',
    // 用药后病情是否有所改善
    imporedAfterMedication: '',
    // 用药后什么感觉
    feelAfterMedication: '',
    // 是否有门诊定期复诊计划
    subsequentVisit: '',
    // 是否需要预约专家
    reserveExpert: '',
    // 是否有门诊康复治疗意向
    outpatientRehabilitation: '',
    // 不满意原因
    noSatisfactionReason: '',
    // 您有意向门诊治疗的项目是
    outpatientRehabilitationIntention: [],
  });

  const { patientFollowupInfoId } = props.$f7route.query || '';

  const _searchPatientFollowupInfoId = useCallback(async (followUpId) => {
    try {
      if (!followUpId) {
        window.$$f7.dialog.alert('该问卷无效', false, {}, () => {
          // 异常处理
          window.open("about:blank", "_self")
          window.close();
        });
      }
      const res = await searchPatientFollowupInfoId(followUpId);
      if (res && res.answerInfo) {
        window.$$f7.dialog.alert('该问卷已经回答过', false, {}, () => {
          props.f7router.navigate('/follow-up/successfulRegister');
        });
      }
    } catch (e) {
      window.$$f7.dialog.alert('该问卷无效', false, {}, () => {
        // 异常处理
        window.open("about:blank", "_self")
        window.close();
      });
    }
  }, [props.f7router])

  useEffect(() => {
    _searchPatientFollowupInfoId(patientFollowupInfoId)
  }, [_searchPatientFollowupInfoId, patientFollowupInfoId])

  /**
   * 
   * @param {*} require   是否为必填项
   * @param {*} index     题目序号
   * @param {*} question  问题类型
   */
  // 输入文本类型的题目
  function renderQuestionInput(item, index) {
    const { require, question, questionKey } = item;

    return (
      <div key={index}>
        <Row>
          <Col width="100">
            <div className='question_row'>
              {
                require === true
                  ? <span className='question_star'>*</span>
                  : <></>
              }
              <span className='question_index'>{index < 9 ? `0${index + 1}` : `${index + 1}`}</span>
              <span className='question'>{question}</span>
            </div>
          </Col>
          <Col width="100">
            <Input
              style={{
                padding: '7px 2px'
              }}
              outline
              floatingLabel
              type="text"
              placeholder="请输入"
              clearButton
              name={questionKey}
              value={sendData[questionKey]}
              onChange={(val) => {
                const { value, name } = val.target;
                setSendDatd({
                  ...sendData,
                  [name]: value,
                });
              }}
            />
          </Col>
        </Row>
      </div>
    )
  }

  function renderQuestionRadio(item, index) {
    const { require, question, radioChoice, questionKey, askif } = item;

    return (
      <div key={index}>
        <Row style={{
          display: 'flex',
          flexDirection: 'column',
        }}>
          <Col width="100">
            <div className='question_row'>
              <span className='question_star'>
                {
                  require === true
                    ? <span className='question_star'>*</span>
                    : <></>
                }
              </span>
              <span className='question_index'>{index < 9 ? `0${index + 1}` : `${index + 1}`}</span>
              <span className='question'>{question}</span>
            </div>
          </Col>
          {
            radioChoice.map((chioseItem, chioseIndex) => {
              return (
                <Col width="100" medium="50" style={{ marginTop: '5px' }} key={chioseIndex}>
                  <Radio
                    name={questionKey}
                    value={chioseItem.choice}
                    onChange={(val) => {
                      const { value, name } = val.target;
                      let questionTemp = _.cloneDeep(renderQuestionList);
                      const nextItemIsAdded = renderQuestionList[index + 1] && renderQuestionList[index + 1].added;
                      if (askif && chioseItem.other && !nextItemIsAdded) {
                        questionTemp.splice(index + 1, 0, chioseItem.other);
                        setRenderQuestionList(questionTemp)
                      }
                      else if (askif && !chioseItem.other && nextItemIsAdded) {
                        questionTemp.splice(index + 1, 1);
                        setRenderQuestionList(questionTemp)
                      }
                      setSendDatd({
                        ...sendData,
                        [name]: value,
                      });
                    }}
                  />&nbsp;&nbsp; {chioseItem.choice}
                </Col>
              )
            })
          }
        </Row>
      </div>
    )
  }

  function renderQuestionCheckbox(item, index) {
    const { require, question, radioChoice, questionKey } = item;

    return (
      <div key={index}>
        <Row style={{
          display: 'flex',
          flexDirection: 'column',
        }}>
          <Col width="100">
            <div className='question_row'>
              <span className='question_star'>
                {
                  require === true
                    ? <span className='question_star'>*</span>
                    : <></>
                }
              </span>
              <span className='question_index'>{index < 9 ? `0${index + 1}` : `${index + 1}`}</span>
              <span className='question'>{question}</span>
            </div>
          </Col>
          {
            radioChoice.map((chioseItem, chioseIndex) => {
              return (
                <Col width="100" medium="50" style={{ marginTop: '5px' }} key={chioseIndex}>
                  <Checkbox
                    name={chioseItem.choice}
                    value={chioseItem.choice}
                    checked={sendData.outpatientRehabilitationIntention.indexOf(chioseItem.value) >= 0}
                    onChange={(val) => {
                      const { value, name, checked } = val.target;
                      // 判断是选中还是取消
                      if (checked) {
                        // checkedList.push(value);
                        sendData.outpatientRehabilitationIntention.splice(sendData.outpatientRehabilitationIntention.length, 0, value)
                      }
                      else {
                        // 取消选项把该值从数组中移除
                        sendData.outpatientRehabilitationIntention.splice(sendData.outpatientRehabilitationIntention.indexOf(value), 1)
                      }
                      setSendDatd({
                        ...sendData,
                        outpatientRehabilitationIntention: sendData.outpatientRehabilitationIntention
                      })
                    }}
                  />&nbsp;&nbsp; {chioseItem.choice}
                </Col>
              )
            })
          }
        </Row>
      </div>
    )
  }

  /**
   * 根据不同的题目类型渲染不同的组件
   */
  function matchType(item, index) {
    let type = item.type;
    switch (type) {
      case 'text':
        return renderQuestionInput(item, index)
      case 'radio':
        return renderQuestionRadio(item, index)
      case 'checkbox':
        return renderQuestionCheckbox(item, index)
    }
  }

  // 校验必填项，确认提交
  async function confirmToSubmit() {
    // 是否提交接口
    let isPass = true;
    for (let sendDataKey in sendData) {
      const sendDataItem = sendData[sendDataKey];
      renderQuestionList.map((questionItem, questionIndex) => {
        // 问题列表的key和回答内容的key相等时，判断是否为必填项
        if (questionItem.questionKey === sendDataKey && questionItem.require === true) {
          if (sendDataItem === '' || sendDataItem.length === 0) {
            isPass = false;
            return window.$$f7.dialog.alert('请输入' + questionItem.question, false);
          }
        }
      })
    }
    // 提交
    let params = {
      // 随访记录id
      patientFollowupInfoId: patientFollowupInfoId,
      ...sendData,
    }
    if (isPass) {
      // 提交接口
      let res = await createPatientAnswer(params);
      if (!res.errorCode) {
        props.f7router.navigate('/follow-up/successfulRegister');
      }
    }
  }

  return (
    <Page
      noToolbar
      noNavbar
      noSwipeback
      // loginScreen
      className='questionire-page'
    >
      <div className='page-questionnaire'>
        <h1 className='title'>
          山东精神卫生中心问卷
        </h1>
        <div className='desc'>
          为了解您的就诊反馈和后续就诊需求，希望您能抽出几分钟时间，完成本问卷。您的感受和建议对我们非常重要，请认真答题哦～现在让我们开始吧！
        </div>
        {
          renderQuestionList.map((item, index) => {
            return matchType(item, index)
          })
        }
        <div className='phone-number'>预约电话：0531-86336666或0531-86336640</div>
        <Block>
          <Button
            large
            raised
            fill
            round
            onClick={() => {
              confirmToSubmit()
            }}
          >
            提交
          </Button>
        </Block>
      </div >
    </Page>
  )
}

export default Questionnaire