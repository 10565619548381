export default function mergeServiceItemAndQrCodeOptions(serviceItem, qrCodeOption) {
    // const startInfo = JSON.parse(serviceItem.startInfo || '{}');
    // let needShowServiceTerm = !!(startInfo && startInfo.liabilityUrl);
    let serviceItemWFV =
        (serviceItem &&
            serviceItem.serverParam &&
            serviceItem.serverParam.variables &&
            serviceItem.serverParam.variables.wfVariables) ||
        '{}';
    try {
        serviceItemWFV = JSON.parse(serviceItemWFV);
    } catch (e) { }
    let qrCodeWFV = qrCodeOption.wfVariables || '{}';
    try {
        qrCodeWFV = JSON.parse(qrCodeWFV);
    } catch (e) { }
    let wfVariables = {
        ...serviceItemWFV,
        ...qrCodeWFV
    };
    return {
        wfVariables
        // needShowServiceTerm,
        // startInfo
    };
}