import './historyReport.css';

import React, { Component } from 'react';
import moment from 'moment';
import { Page, LoginScreenTitle, Card } from 'framework7-react';
import { queryReport } from '../../modules/shufu.js';
import { getCurrentUserId, getOrganizationId } from '../../utils/localStorage.js';
// import CONFIG from '../../modules/config.js';
import CONFIG from 'GlobalConfigFile';

class HistoryReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportList: [],
      loading: true
    };
  }

  async componentDidMount() {
    const customerId = getCurrentUserId();
    const organizationId = getOrganizationId();
    // mock
    // let customerId = '5ef648f8-fdb9-4aa3-b9fe-e097d0fed5be'
    try {
      const reportList = await this.getReports(customerId, organizationId);
      this.setState({
        reportList,
        loading: false
      });
    } catch (e) {
      this.setState({
        loading: false
      });
    }
  }

  async getReports(customerId, organizationId) {
    return await queryReport({
      customerId,
      organizationId,
      status: "complete"
    });
  }

  render() {
    let state = this.state;
    let list = (state.reportList || {}).data || [];
    let baseUrl = window.location.origin;
    return (
      <Page noToolbar noNavbar className="history-report-title">
        <LoginScreenTitle>历史测试报告</LoginScreenTitle>
        {!this.state.loading && (
          <div>
            {list && list.length ? (
              list.map(it => {
                const reportUrl = ["lisa.followUp.diseaseAnxiety", "lisa.followUp.coronalGeneral", "lisa.followUp.diseaseAnxietyWithoutBasicInfo", "lisa.followUp.diseaseAnxietyYunnan"].includes(it.formId) ? it.patientReportUrl : `${CONFIG.getRemoteConfig().host}${it.relativePathOfReport}?type=report`;
                return (
                  <Card key={it.id} className="report-card">
                    <div
                      className="card-container"
                      onClick={() => {
                        // hard code sit.siuvo.cn ?
                        window.open(
                          reportUrl,
                          '_self'
                        );
                        // window.open(`${baseUrl}${it.relativePathOfReport}?type=report`, "_blank")
                      }}
                    >
                      <div className="info">
                        <p className="label">{it.name}</p>
                        <p className="time">
                          {moment(it.updatedTime).format('YYYY.MM.DD')}
                        </p>
                      </div>
                      <img className="report-pic" src={it.imageUrl} alt="img" />
                    </div>
                  </Card>
                );
              })
            ) : (
              <div className="empty-report">暂无历史报告</div>
            )}
          </div>
        )}
      </Page>
    );
  }
}

export default HistoryReport;
