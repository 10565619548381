/**
 * text 需要播放的内容
 */

let playCustomVoice = () => {
  console.log("placeholder playCustomVoice function");
}

try {
  const synth = window.speechSynthesis;
  const utterThis = new window.SpeechSynthesisUtterance();

  playCustomVoice = (text) => {
    const voices = synth.getVoices();
    utterThis.voice = voices.find(voice => voice.lang === 'zh-CN');
    utterThis.lang = 'zh-CN';

    const isSpeaking = synth.speaking;
    // cancel方法是异步的，当前一个未播完时，点击下一条，取消上一条，直接播放下一条
    synth.cancel();

    //do not speak the user has clicked again on the bubble after it started speaking. this should just stop the voice.
    if (text === utterThis.text && isSpeaking) return;

    //播放前，先移除队列中的语音
    setTimeout(() => {
      utterThis.text = text;
      synth.speak(utterThis);
    }, 500)
  }
} catch (e) {
  console.error("error setting up playCustomVoice: ", e);
}
export default playCustomVoice;
