// 用于徐牙防的不同证件类型的登录入口
import React, { useState, useCallback, useEffect } from 'react';
import {
  Page,
  Button,
  Input
} from 'framework7-react';
import { getYafangsuoPatientList } from '../../modules/shufu';
import robot_smile from '../../images/robot_smile.png';
import shufu_logo from '../../images/shufu-logo.png';

const LoginWithCertificate = function (props) {
  // 牙防所的id，因为不能用于通用的登录入口，所以这里写死
  const organizationId = 'yafangsuo';
  // 证件类型
  const [certificateType, setCertificateType] = useState('ID_CARD');
  // 证件id
  const [certificateVal, setCertificateVal] = useState('');

  const _getYafangsuoPatientList = async () => {
    let params = {
      organizationId: organizationId,
      idCard: certificateVal,
      idCardType: certificateType
    };
    let res = await getYafangsuoPatientList(params);
    if (res) {
      props.f7router.navigate('/medicalRecordList-xuyafang', {
        props: {
          medicalRocordList: res,
          idCardType: certificateType,
          organizationId
        }
      });
    }
  }

  const renderSubmitButton = () => {
    return <Button
      raised
      fill
      large
      style={{
        backgroundColor: certificateVal ? '#2979ff' : '#c2c2c2',
      }}
      className='welcome-login-button'
      onClick={() => {
        _getYafangsuoPatientList();
      }}
      // disabled={!certificateVal}
    >
      确定
    </Button>
  }

  return (
    <Page noToolbar noNavbar noSwipeback loginScreen className='welcome-container-body'>
      <img className='welcome-shufu-logo' src={shufu_logo}></img>
      <div className='welcome-words'>
        <span className='welcome-words-text'>欢迎！</span>
        <img className='welcome-words-image' src={robot_smile}></img>
      </div>
      <div>
        <div style={{ fontSize: '18px', margin: '20px' }}>
          请选择证件类型并输入:
        </div>
        <div style={{ display: 'flex', margin: '0 20px', justifyContent: 'center', alignItems: 'center' }}>
          <div
            style={{
              flex: 1,
              height: 35,
              borderRadius: 10,
              border: `solid 1px #2979ff`,
              backgroundColor: '#deeaff',
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
              justifyContent: 'center',
            }}>
            <Input
              type='select'
              value={certificateType}
              onChange={(e) => {
                setCertificateType(e.target.value);
                setCertificateVal('');
              }}
            >
              <option value='ID_CARD'>身份证</option>
              <option value='HOUSEHOLD'>户口簿</option>
              <option value='PASSPORT'>护照</option>
              <option value='SOLDIER'>军官证</option>
              <option value='DRIVERS'>驾驶证</option>
              <option value='LAISSEZ_PASSER_HK'>港澳通行证</option>
              <option value='LAISSEZ_PASSER_TW'>台湾通行证</option>
              <option value='DEFAULT'>其他法定有效证件</option>
            </Input>
            <Input
              type="text"
              placeholder='请输入你的证件号码'
              value={certificateVal}
              onBlur={(e) => {
                setCertificateVal(e.target.value);
              }}
              onChange={(e) => {
                setCertificateVal(e.target.value)
              }}
            />
          </div>
        </div>
      </div>
      {
        renderSubmitButton()
      }
      <div style={{ margin: 20, color: "crimson", fontSize: 15, fontWeight: "bold" }}>
        请患方谨慎填写量表，分享/多次随意测评导致报告结果错误，由患方承担全部责任）
      </div>
      <div style={{ fontSize: '14px', color: '#8c8c8c', position: 'fixed', textAlign: 'center', width: '100%', bottom: 40 }}>
        Powered by Siuvo.Inc
      </div>
    </Page>
  );
};
export default LoginWithCertificate;
