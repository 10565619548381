import React, { useState, useEffect } from 'react';

import { initShuaiCloud as initShuaiCloudFromClientInstance } from '../../modules/shuaicloud/clientInstance';
import getParameterByName from '../../utils/getParameterByName';
import { verifyLandingOrganizationIdAndDataEnc, getServiceInstance } from '../../modules/shufu';
import { setCurrentUserId, setToken, setServiceInstanceId, removeAnnouncement, setAnnouncement } from '../../utils/localStorage.js';
import {
  LoginScreenTitle,
  Page,
  Preloader,
  BlockFooter,
  List
} from 'framework7-react';

import './landing.css';

async function initUser(userId, token) {
  console.log({ userId, token });
  async function initShuaiCloud() {
    await initShuaiCloudFromClientInstance();
  }

  setCurrentUserId(userId);
  setToken(token);
  await initShuaiCloud();
}

const Landing = function (props) {
  const [title, setTitle] = useState(null);
  // const [initLoading, setInitLoading] = useState(false);

  const loadingSentences = [
    '正在获取AI神经元参数...',
    '正在呼叫人工智能AI...',
    '正在搭建智能模块链路...',
    '正在载入知识图谱...',
    '正在开启咨询服务...'
  ];

  const [sentenceIndex, setSentenceIndex] = useState(0);

  useEffect(() => {
    setTitle(getParameterByName('title') || '');
  }, []);

  useEffect(() => {
    async function getEncData() {
      const organizationId = getParameterByName('organizationId');
      const dataEnc = getParameterByName('dataEnc');
      const jumpToStaticForm = getParameterByName('jumpToStaticForm');

      if (organizationId) {
        try {
          // setInitLoading(true)
          // window.$$f7.preloader.show();
          setSentenceIndex(1);
          // setInitLoading(true);
          let result = await verifyLandingOrganizationIdAndDataEnc(
            organizationId,
            dataEnc
          );
          let { userId, token, serviceInstanceId } = result;
          setServiceInstanceId(serviceInstanceId);

          //TOOD: move this information to be returned from the backend to reduce api requests
          const serviceInstance = await getServiceInstance(serviceInstanceId);
          const uiConfig = _.get(serviceInstance, 'uiConfig') || '{}';
          const announcement = _.get(JSON.parse(uiConfig), 'announcement', null);
          if (announcement) {
            setAnnouncement(announcement);
          } else {
            removeAnnouncement();
          }
          setSentenceIndex(2);
          await initUser(userId, token);
          console.log('Init userInformation');
          setSentenceIndex(3);
          setTimeout(() => {
            let url = `/chat/?noRestart=true`;
            if (jumpToStaticForm) {
              url = `${url}&jumpToStaticForm=${jumpToStaticForm}`;
            }
            props.f7router.navigate(url, {
              reloadCurrent: true,
              props: {
                noRestart: true,
                jumpToStaticForm
              }
            });
          }, 200);
        } catch (e) {
          // setInitLoading(false);
          window.$$f7.dialog.alert(e);
        }
      } else {
        window.$$f7.dialog.alert('缺少必要的参数');
      }
    }

    setTimeout(() => {
      getEncData();
    }, 1000);

    // getEncData();
  }, [props.f7router]);

  return (
    <Page noToolbar noNavbar noSwipeback loginScreen className="landing-page">
      <LoginScreenTitle>{title}</LoginScreenTitle>

      <List>
        <div style={{ textAlign: 'center', marginBottom: 100, marginTop: 100 }}>
          <Preloader size={80} color="#2979ff"></Preloader>
        </div>
      </List>

      <List>
        <BlockFooter>
          初始化中，请稍候
          <br />
          <br />
          {loadingSentences[sentenceIndex]}
        </BlockFooter>
      </List>
    </Page>
  );
};

Landing.defaultProps = {
  onLoginSuccess: () => {
    console.log('onLoginSuccess is not defined');
  }
};

export default Landing;
