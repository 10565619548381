import {
    Page,
    Input,
    Button
} from 'framework7-react';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import robot_smile from '../../images/robot_smile.png';
import shufu_logo from '../../images/shufu-logo.png';
import { initShuaiCloud as initShuaiCloudFromClientInstance } from '../../modules/shuaicloud/clientInstance';
import getParameterByName from '../../utils/getParameterByName';
import {
    setCurrentUserId,
    setOrganizationId,
    setServiceInstanceId, 
    setToken
} from '../../utils/localStorage.js';
import {
    createUserAndStartServiceInternal
} from '../../modules/shufu';
import '../welcome/welcome.css';



export async function initUser(userId, organizationId, token) {
    async function initShuaiCloud() {
        await initShuaiCloudFromClientInstance();
    }

    setCurrentUserId(userId);
    setOrganizationId(organizationId);
    setToken(token);
    await initShuaiCloud();
}


const LoginWithPatientIdentifier = function (props) {
    const { t, i18n } = useTranslation();
    const [patientIdentifierStr, setPatientIdentifierStr] = useState("");
    const organizationId = getParameterByName('organizationId');
    const providerId = getParameterByName('providerId');

    // 获取患者信息（手动或者扫码）后，创建用户
    const submitHandler = useCallback(async ({ patientIdentifier }) => {
        window.$$f7.preloader.show();
        try {
            let params = { organizationId, patientIdentifier }
            if (organizationId === 'shandongjingwei') {
                params['encounterIdentifier'] = patientIdentifier
            }
            const data = await createUserAndStartServiceInternal(params);
            window.$$f7.preloader.hide();
            if (data.status === 'failed') {
                window.$$f7.dialog.alert(data.message, false);
            } else {
                let { userId, token, serviceInstanceId, sceneId } = data;
                setServiceInstanceId(serviceInstanceId);
                await initUser(userId, organizationId, token);
                setTimeout(() => {
                    let url = `/chat/?noRestart=true&sceneId=${sceneId}`
                    if (providerId) {
                        url += `&providerId=${providerId}`
                    }
                    props.f7router.navigate(url, {
                        reloadCurrent: true,
                        props: {
                            noRestart: true
                        }
                    });
                }, 200);
            }
        } catch (e) {
            console.log(e);
            window.$$f7.preloader.hide();
            window.$$f7.dialog.alert('服务器异常，请稍后再试', false);
        }
    }, [organizationId, props.f7router, providerId])

    const renderPatientIdentifierInput = () => {
        return <div>
            <div style={{ fontSize: '18px', margin: '20px' }}>
                请输入您的患者流水号
            </div>
            <div style={{ display: 'flex', margin: '0 20px', justifyContent: 'center', alignItems: 'center' }}>
                <div
                    style={{
                        flex: 1,
                        height: 35,
                        borderRadius: 10,
                        border: 'solid 1px #2979ff',
                        backgroundColor: '#deeaff',
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                    <Input
                        style={{ flex: '1', marginLeft: 10, marginRight: 10 }}
                        type="text"
                        value={patientIdentifierStr}
                        onChange={event => {
                            const input = event.target.value;
                            setPatientIdentifierStr(input);
                        }}
                        placeholder={"患者流水号"}
                    />
                </div>
            </div>
        </div>

    }

    const isInputsValid = () => {
        if (!patientIdentifierStr) return false;
        if (!organizationId) return false;
        return true;
    }

    const renderSubmitButton = () => {
        return <Button
            raised
            fill
            large
            style={{
                backgroundColor: isInputsValid() ? '#2979ff' : '#c2c2c2',
            }}
            className='welcome-login-button'
            onClick={() => {
                if (!isInputsValid()) return;
                submitHandler({ patientIdentifier: patientIdentifierStr })
            }}
        >确定
        </Button>
    }



    return (
        <Page noToolbar noNavbar noSwipeback loginScreen className='welcome-container-body'>
            <img className='welcome-shufu-logo' src={shufu_logo}></img>
            <div className='welcome-words'>
                <span className='welcome-words-text'>欢迎！</span>
                <img className='welcome-words-image' src={robot_smile}></img>
            </div>

            {renderPatientIdentifierInput()}
            {renderSubmitButton()}
            <div style={{ textAlign: 'center', fontSize: '14px', color: '#8c8c8c' }}>
                Powered by Siuvo.Inc
            </div>
        </Page>
    );
};

LoginWithPatientIdentifier.defaultProps = {

};
export default LoginWithPatientIdentifier;
