import React, { useState, useEffect } from 'react';
import { Page, Popup, Navbar, NavRight, Link, List, ListItem } from 'framework7-react';
import './scaleIntroduction.css';
import getParameterByName from '../../utils/getParameterByName';
import isWeixinBrowser from '../../utils/isWeixinBrowser';

import { getSceneDataBySceneId, wechatLoginWithWebAuthCode } from '../../modules/shufu';
import { useTranslation } from 'react-i18next';
import { initUser } from '../welcome/welcome';
import { initShuaiCloud as initShuaiCloudFromClientInstance } from '../../modules/shuaicloud/clientInstance';
import robot_icon from '../../images/robot_icon.png';

import {
  verifyCodeReturnUsersOrRegister,
} from '../../modules/shufu';
import CONFIG from 'GlobalConfigFile';

const ScaleIntroduction = function (props) {
  const [sceneId, setSceneId] = useState();
  const [chatUrl, setChatUrl] = useState();
  const [sn, setSn] = useState();
  const [imgUrl, setImgUrl] = useState();
  const [description, setDescription] = useState();
  const { t, i18n } = useTranslation();
  const [startButtonText, setStartButtonText] = useState(t("start_test"));
  const [popupOpened, setPopupOpened] = useState(false);
  const [popupChoose, setPopupChoose] = useState(false);
  const [liabilityUrl, setLiabilityUrl] = useState(null);


  useEffect(() => {
    const toPage = getParameterByName('showOption') === 'true' ? 'option' : 'chat';
    setChatUrl(
      `/${toPage}/?sceneId=${sceneId}&noRestart=true`
    );
  }, [sceneId]);
  // console.log(chatUrl);

  useEffect(() => {
    async function getSceneData() {
      const urlSceneId = getParameterByName('sceneId');
      const currentPath = $$f7.view.current.router.currentRoute.path;
      if (
        currentPath === '/scaleIntroduction/' &&
        urlSceneId &&
        urlSceneId !== sceneId
      ) {
        console.log('getting new sceneData: ', urlSceneId, sceneId);
        try {
          window.$$f7.preloader.show();
          setSceneId(urlSceneId);
          let sceneResult = await getSceneDataBySceneId(urlSceneId);
          const { sceneData } = sceneResult[0];
          const liabilityUrl = _.get(sceneData, 'liabilityUrl', null);
          setLiabilityUrl(liabilityUrl);
          if (!liabilityUrl) {
            setPopupChoose(true);
          }
          const { sn, imgUrl, description, startButtonText: customStartButtonText } = sceneData;
          setSn(sn);
          setImgUrl(imgUrl);
          setDescription(description);
          if (customStartButtonText) setStartButtonText(customStartButtonText)
          // window.$$f7.preloader.hide();
          // console.log(sn, imgUrl, description);
        } catch (e) {
          console.error(e);
          window.$$f7.dialog.alert(t('server_busy'));
          window.$$f7.preloader.hide();
        }
      }
    }
    getSceneData();
  });

  useEffect(() => {
    const loginWithCellphoneCode = async (_cellphone, _code) => {
      if (_cellphone && _code) {
        // do nothing
      } else {
        if (!cellphone || !code) {
          alert('请输入手机和验证码');
          return;
        }

        if (needName && !name) {
          alert('请输入姓名');
          return;
        }
      }

      window.$$f7.preloader.show();

      try {
        let result = await verifyCodeReturnUsersOrRegister(
          _cellphone || cellphone,
          _code || code,
          name
        );

        const userId = result.data.userId || result.data.userid; // there's maybe a typo from backend
        const token = result.data.token;
        await initUser(userId, null, token);
        window.$$f7.preloader.hide();

      } catch (err) {
        console.error(err);
        window.$$f7.preloader.hide();
        window.$$f7.dialog.alert(t('server_busy'));
      }
    }

    async function tryAutomaticLogin() {

      if (isWeixinBrowser() && window.location.href.match('h5.siuvo.cn')) {
        let code = getParameterByName('code');
        let redirectState = getParameterByName('state');

        const appId = CONFIG.APPID;
        // const appId = 'wx04ac68f0c2bb796d'; // sandbox
        // const appId = 'wx8457bf764fcc834b'; // real
        const state = 'wechat_login_tried';
        const scope = 'snsapi_base';
        const currentURL = window.location.href;
        // const currentURL = 'https://sit.siuvo.cn';

        console.log('!!!!CODECODE', { code, redirectState });
        if (code && redirectState === 'wechat_login_tried') {
          // try to use code to login
          window.$$f7.preloader.show();

          console.log('!!!!', { code, redirectState });

          try {
            let result = await wechatLoginWithWebAuthCode({ code, scope });
            const userId = result.data.userId || result.data.userid; // there's maybe a typo from backend
            const token = result.data.token;
            await initUser(userId, null, token);
            console.log(result);
            window.$$f7.preloader.hide();
            return;
          } catch (err) {
            window.$$f7.dialog.alert('微信登录失败');
            window.$$f7.preloader.hide();
            console.log(err);
          }
        } else if (!code && redirectState === 'wechat_login_tried') {
          // do nothing
        } else {
          // try to use wechat login

          let jumpUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${encodeURIComponent(
            currentURL
          )}&response_type=code&scope=${scope}&state=${state}#wechat_redirect`;
          try {
            window.location.replace(jumpUrl); // try to use wechat login
            return;
          } catch (e) {
            alert(e);
          }
        }
      }
      // if it's not wechat browser, or wechat login failed, use original login
      loginWithCellphoneCode(
        `GG_${Date.now()}_${Math.floor(Math.random() * 100)}`,
        '9999'
      );
    }

    async function initShuaiCloud() {
      try {
        await initShuaiCloudFromClientInstance();
        window.$$f7.preloader.hide();
      } catch (e) {
        console.err(e);
        window.$$f7.preloader.hide();
      }
    }

    if (!sessionStorage.getItem('token')) {
      tryAutomaticLogin();
    } else {
      initShuaiCloud();
    }
  }, [t])

  return (
    <Page
      className="scaleIntroductionSection"
      noToolbar
      noNavbar
      noSwipeback
    // loginScreen
    >
      <div className="pageContainer">
        <div
          style={{
            flex: 1,
            overflowY: 'auto'
          }}
        >
          <div>
            {imgUrl ? (<img className="scaleImg" src={imgUrl}></img>) : null}
          </div>
          <div style={{ position: 'relative' }}>
            <img style={{ zIndex: -1, width: 60, position: 'absolute', top: 0, right: 10 }} src={robot_icon}></img>
            <div className="scaleTitle" dangerouslySetInnerHTML={{ __html: sn }}></div>
            <div
              dangerouslySetInnerHTML={{ __html: description }}
              style={{
                color: '#414141',
                textAlign: 'left',
                padding: 10,
                color: '#414141',
                textAlign: 'left'
              }}
            ></div>
          </div>
        </div>
        {liabilityUrl ?
          <div style={{ margin: '20px auto' }}>
            <input
              type='checkbox'
              style={{ width: 20, height: 20, position: 'relative', top: 3 }}
              onClick={() => {
                setPopupChoose(!popupChoose);
                console.log(popupChoose);
              }}
            ></input>
            <span
              style={{ fontSize: 18 }}
              dangerouslySetInnerHTML={{ __html: t('consent_word') }}
            ></span>
            <span
              style={{ fontSize: 18, color: '#2979ff' }}
              onClick={() => setPopupOpened(true)}
              dangerouslySetInnerHTML={{ __html: t('consent_name') }}
            ></span>
            <Popup className="demo-popup" opened={popupOpened} onPopupClosed={() => setPopupOpened(false)}>
              <Page>
                <Navbar title={t('tips')}>
                  <NavRight>
                    <Link popupClose>{t('close')}</Link>
                  </NavRight>
                </Navbar>
                <iframe
                  src={liabilityUrl}
                  style={{ width: '100%', height: '100%', border: 'none' }}
                ></iframe>
              </Page>
            </Popup>
          </div>
          : null}
        <div className="actionButton">
          <button
            className="startTestButton"
            onClick={() => {
              // props.f7router.navigate(chatUrl);
              if (popupChoose) {
                props.f7router.navigate(chatUrl, {
                  reloadCurrent: true,
                  // pushState: false,
                  // props: {
                  //   noRestart: true
                  // }
                });
              } else {
                window.$$f7.dialog.alert(t('must_choose_consent'), false);
              }
            }}
          >
            <span className="buttonTip">{startButtonText}</span>
          </button>
        </div>
      </div>

      {/* <div className="contentSection">
        <p className="scaleTitle">{sn}</p>
        <p style={{ whiteSpace: 'pre-line' }} className="scaleIntroduction">
          {description}
        </p>
      </div>
      <ul className="buttonSection"></ul> */}
    </Page>
  );
};

export default ScaleIntroduction;
